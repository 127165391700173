import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
// import config from 'config.js';
// import { compose, withProps, withState, withHandlers } from 'recompose';

// const HallMap = compose(
//   withProps({
//     googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`,
//     loadingElement: <div style={{ height: '100%' }} />,
//     containerElement: <div style={{ height: '400px' }} />,
//     mapElement: <div style={{ height: '100%' }} />,
//   }),
//   withState('zoom', 'onZoomChange', 16),
//   withHandlers(() => {
//     const refs = {
//       map: undefined,
//     };

//     return {
//       onMapMounted: () => ref => {
//         refs.map = ref;
//       },
//       onZoomChanged: ({ onZoomChange }) => () => {
//         onZoomChange(refs.map.getZoom());
//         console.log({ zoom: refs.map.getZoom(), center: refs.map.getCenter() });
//       },
//     };
//   }),
//   withScriptjs,
//   withGoogleMap
// )(props => {
//   const { lat, lng, isMarkerShown, updateCoords, zoom, onMapMounted, onZoomChanged } = props;

//   return (
//     Boolean(lat) &&
//     Boolean(lng) && (
//       <GoogleMap
//         defaultZoom={16}
//         defaultCenter={{ lat, lng }}
//         zoom={zoom}
//         center={{ lat, lng }}
//         ref={onMapMounted}
//         onZoomChanged={onZoomChanged}
//       >
//         {isMarkerShown && (
//           <Marker
//             position={{ lat, lng }}
//             draggable
//             onDragEnd={pos => {
//               const newLat = pos.latLng.lat();
//               const newLng = pos.latLng.lng();
//               console.log({ newLat, newLng });
//               updateCoords(newLat, newLng);
//             }}
//           />
//         )}
//       </GoogleMap>
//     )
//   );
// });

const HallMap = withScriptjs(
  withGoogleMap(props => {
    const { lat, lng, isMarkerShown, updateCoords } = props;
    const defaultCenter = { lat: 50.0803044, lng: 14.4288364 };

    return (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={defaultCenter}
        center={Boolean(lat) && Boolean(lng) ? { lat, lng } : defaultCenter}
      >
        {isMarkerShown && (
          <Marker
            position={{ lat, lng }}
            draggable
            onDragEnd={pos => {
              const newLat = pos.latLng.lat();
              const newLng = pos.latLng.lng();
              console.log({ newLat, newLng });
              updateCoords(newLat, newLng);
            }}
          />
        )}
      </GoogleMap>
    );
  })
);

HallMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  isMarkerShown: PropTypes.bool,
  googleMapURL: PropTypes.string,
  loadingElement: PropTypes.element,
  containerElement: PropTypes.element,
  mapElement: PropTypes.element,
};

HallMap.defaultProps = {
  lat: 0,
  lng: 0,
  isMarkerShown: false,
  googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div style={{ height: '400px' }} />,
  mapElement: <div style={{ height: '100%' }} />,
};

export default HallMap;
