/**
 * @property {int} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string|null} contact_email
 * @property {string|null} phone_number
 * @property {string|null} job_title
 * @property {int} user_id
 */
export class MemberRepresentative {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.contact_email = data.contact_email || '';
    this.phone_number = data.phone_number || '';
    this.job_title = data.job_title || '';
    this.user_id = data.user_id || null;
  }

  static formatDataForEditForm(data) {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      contact_email: data.contact_email || '',
      phone_number: data.phone_number || '',
      job_title: data.job_title || '',
      // user_id: data.user_id,
    };
  }

  static formatFormData(data) {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      contact_email: data.contact_email || null,
      phone_number: data.phone_number || null,
      job_title: data.job_title || null,
      // user_id: data.user_id || null,
    };
  }
}
