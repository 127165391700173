import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const Title = styled(({ variant, ...rest }) => <Typography variant={variant} {...rest} />)`
  //&& {
  //  margin: 0 -16px -16px;
  //  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  //  padding: 0 16px 16px;
  //}
`;

Title.propTypes = {
  variant: PropTypes.string,
};

Title.defaultProps = {
  variant: 'h5',
};

const StandaloneCardTitle = ({ children }) => <Title>{children}</Title>;

StandaloneCardTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default StandaloneCardTitle;
