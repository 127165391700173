import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import routes from 'Routes.js';
import { HallManagementService } from 'api/hall.service';
import HallUpdateForm from 'components/Management/Halls/HallUpdateForm';
import { withSnackbar } from 'notistack';
import { i18n } from 'App.js';
import { Hall } from 'models/Hall';
import LoadingMessage from 'components/Base/LoadingMessage';
import PageHeader from 'components/PageHeader';

const HallNotFound = () => (
  <div>
    <Typography variant="subtitle1">
      <Trans id="msg.halls.hall_not_found" />
    </Typography>

    <Typography>
      <Link to={routes.management.halls.all}>
        <Trans id="msg.halls.back_to_halls" />
      </Link>
    </Typography>
  </div>
);

class HallUpdate extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    hall: null,
    hallNotFound: false,
    loading: false,
  };

  requestedImageSize = {
    width: 1280,
    height: 450,
  };

  fetchHall = async hallId => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loading: true,
    });

    await HallManagementService.get(hallId)
      .then(response => {
        this.setState(
          {
            hall: response,
            hallNotFound: false,
            loading: false,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.loading_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            hallNotFound: true,
            loading: false,
          },
          () => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  acceptUploadedImageId = uploadedImageId => {
    const { hall } = this.state;

    // difference between create and update scenario
    // when we are updating, we know Hall object data
    // so we can update them and don't need to use solo variable in state
    this.setState({
      // uploadedImageId,
      hall: {
        ...hall,
        image_id: uploadedImageId,
      },
    });
  };

  updateHall = async (values, actions) => {
    const { enqueueSnackbar } = this.props;
    const {
      // eslint-disable-next-line
      hall: { id, image_id },
    } = this.state;

    const formattedData = Hall.formatFormData({ ...values, image_id });

    await HallManagementService.update(id, formattedData)
      .then(response => {
        this.setState(
          {
            hall: response,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
    actions.setSubmitting(false);
  };

  componentDidMount = () => {
    const {
      match: {
        params: { hallId },
      },
    } = this.props;

    if (!hallId || Number.isNaN(parseInt(hallId))) {
      this.setState({
        hallNotFound: true,
      });
      return;
    }

    this.fetchHall(hallId);
  };

  render() {
    const { enqueueSnackbar } = this.props;
    const { hall, hallNotFound, loading } = this.state;

    return (
      <div>
        {hallNotFound && <HallNotFound />}

        {loading && <LoadingMessage />}

        {!loading && !hallNotFound && hall && (
          <React.Fragment>
            <PageHeader
              title={hall.name}
              breadcrumbsItems={[
                {
                  url: routes.management.halls.all,
                  // title: 'some title',
                  text: i18n._('msg.halls.page_title'),
                },
                { url: null, text: hall.name },
              ]}
            />

            <HallUpdateForm
              hall={hall}
              updateHall={this.updateHall}
              requestedImageSize={this.requestedImageSize}
              acceptUploadedImageId={this.acceptUploadedImageId}
              currentImageId={hall.image_id}
              enqueueSnackbar={enqueueSnackbar}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withSnackbar(HallUpdate);
