/**
 * @property {int} id
 * @property {string} name
 * @property {boolean} is_protected
 */
export class MemberType {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.is_protected = data.is_protected;
  }

  static formatFormData(data) {
    return {
      name: data.name,
    };
  }
}
