import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Textarea = styled.textarea`
  border: 1px solid red;
`;

class EosRichText extends Component {
  // static propTypes = {
  //   name: PropTypes.string.isRequired
  // };

  handleRichTextChange = value => {
    const {
      onChange,
      field: { name },
    } = this.props;
    console.log({ name, value });

    if (typeof value === 'string') {
      onChange([name], value);
    }
  };

  // handleRichTextBlur = () => {
  //   const { name } = this.props.field;
  //   console.log(name);
  //   this.props.onBlur([name], true);
  // };

  loadContent = () => {
    const {
      field: { name },
    } = this.props;
    this.editorInstance = window.$R(`#${name}`, {
      toolbar: true,
      // breakline: true,
      callbacks: {
        // source: {
        //   open: html => {
        //     console.log('SOURCE.OPEN', html.replace('&lt;', '<'));
        //     this.editorInstance.source.setCode(html.replace('&lt;', '<'));
        //   },
        //   opened: () => {
        //     console.log('SOURCE.OPENED');
        //   },
        //   close: html => {
        //     console.log('SOURCE.CLOSE', html);
        //   },
        //   closed: () => {
        //     console.log('SOURCE.CLOSED');
        //   },
        //   changed: html => {
        //     console.log('SOURCE.CHANGED', html);
        //     this.handleRichTextChange(html);
        //   }
        // },
        blur: e => {
          console.log(e.target.tagName, this.editorInstance.source.getCode());
          this.handleRichTextChange(this.editorInstance.source.getCode());
        },
      },
    });
  };

  componentDidUpdate = () => {
    const {
      field: { value },
    } = this.props;
    // if (typeof this.props.value === 'string') {
    console.log('RichText componentDidUpdate');
    this.editorInstance.source.setCode(value);
    // }
  };

  componentDidMount = () => {
    this.loadContent();
  };

  render() {
    const {
      id,
      field: { value, name },
    } = this.props;
    return (
      <Textarea
        id={id || name}
        name={name}
        value={value}
        // onChange={onChange}
        // onChange={e => {
        //   console.log({ e });
        // }}
        onChange={this.handleRichTextChange}
        // onBlur={this.handleRichTextBlur}
      />
    );
  }
}

EosRichText.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

EosRichText.defaultProps = {
  id: '',
};

export default EosRichText;
