import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import { Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Field, Formik } from 'formik';
import EosTextInput from 'components/Forms/EosTextInput';
import EosColorPicker from 'components/Forms/EosColorPicker';
import EosSelect from 'components/Forms/EosSelect';
import SubmitButton from 'components/Forms/SubmitButton';
import * as yup from 'yup';
import { hexColor as hexColorRegExp } from 'utils/regexp';
import { AuthConsumer } from 'AuthContext.js';

const BasicFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  resetForm,
  gamesDisplayConfig,
  trainingPlanConfig,
  // handleTabChange,
  // handleTabChangeIndex,
  // currentIndex,
  boolShowHide,
  // boolOnOff,
  gender,
  teamGroups,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          <Trans id="msg.teams.subtitle.basic_settings" />
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="name"
              required
              label={i18n._('msg.name')}
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Field
              name="team_group_id"
              label={i18n._('msg.teams.team_group_id_label')}
              options={teamGroups}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              type="text"
              name="description"
              label={i18n._('msg.teams.description_label')}
              multiline
              rows={3}
              component={EosTextInput}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          <Trans id="msg.restrictions" />
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <Field
              type="number"
              name="birth_year_range.from"
              label={i18n._('msg.year_from')} // "Year range from"
              inputProps={{
                min: 1900,
                max: new Date().getFullYear(),
                step: 1,
              }}
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              type="number"
              name="birth_year_range.to"
              label={i18n._('msg.year_to')} // Year range to
              inputProps={{
                min: 1900,
                max: new Date().getFullYear(),
                step: 1,
              }}
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="gender"
              label={i18n._('msg.restrictions.gender')}
              options={gender}
              component={EosSelect}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          <Trans id="msg.teams.subtitle.club_zone_settings" />
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              name="visible"
              label={i18n._('msg.teams.club_zone_settings.visible_label')} // "Zobrazení / skrytí družstva v členské sekci"
              // options={dummyShowHide}
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="games_display_config"
              label={i18n._('msg.teams.club_zone_settings.games_display_config_label')} // "Zobrazování zápasů na zdi družstva"
              options={gamesDisplayConfig}
              component={EosSelect}
            />
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Field
              name="dashboard"
              label={i18n._('msg.teams.club_zone_settings.team_dashboard')} // label="Nástěnka družstva"
              options={boolOnOff}
              component={EosSelect}
            />
          </Grid> */}

          <Grid item xs={12} md={4}>
            <Field
              type="text"
              name="color"
              placeholder="#ff6600"
              label={i18n._('msg.teams.club_zone_settings.color_label')} // "Barevné rozlišení družstva"
              component={EosColorPicker}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              type="number"
              name="attendance_watch"
              label={i18n._('msg.teams.club_zone_settings.attendance_watch_label')} // "Hlídač neuložené docházky (ve dnech)"
              helperText={i18n._('msg.teams.club_zone_settings.attendance_watcher_description')} // Počet dní, po které je trenérům zobrazováno upozornění na nevyplněnou docházku (0 = hlídání vypnuto)
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="training_plan_config"
              label={i18n._('msg.teams.club_zone_settings.training_planning_config_label')} // "Plánování a hodnocení tréninků"
              options={trainingPlanConfig}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="public"
              label={i18n._('msg.teams.club_zone_settings.public_label')} // "Zobrazení komentářů - omluv ostatním členům"
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12}>
            <SubmitArea align="left" isSubmitting={isSubmitting}>
              <SubmitButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
              </SubmitButton>{' '}
              <Button
                variant="contained"
                color="secondary"
                type="reset"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                }}
              >
                {i18n._('msg.reset')}
              </Button>
            </SubmitArea>
          </Grid>
          {/* <p>Zobrazení / skrytí družstva v členské sekci "visible"</p>
          <p>Zobrazování zápasů na zdi družstva "games_display_config"</p>
          <p>Nástěnka družstva</p>
          <p>Barevné rozlišení družstva "color"</p>
          <p>Hlídač neuložené docházky (ve dnech) "attendance_watch"</p>
          <p>Plánovaání a hodnocení tréninků "training_plan_config"</p>
          <p>Zobrazení komentářů - omluv ostatním členům "public"</p> */}
        </Grid>
      </CardContent>
    </Card>
  </EosForm>
);

BasicFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  gamesDisplayConfig: PropTypes.array,
  trainingPlanConfig: PropTypes.array,
  // handleTabChange: PropTypes.func.isRequired,
  // handleTabChangeIndex: PropTypes.func.isRequired,
  // currentIndex: PropTypes.number.isRequired,
  boolShowHide: PropTypes.array,
  // boolOnOff: PropTypes.array,
  gender: PropTypes.array,
  teamGroups: PropTypes.array.isRequired,
};

BasicFormComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
  gamesDisplayConfig: [],
  trainingPlanConfig: [],
  boolShowHide: [],
  // boolOnOff: [],
  gender: [],
};

class TeamsConfigBasicForm extends Component {
  render() {
    const {
      updateTeam,
      team,
      teamGroups /* , gamesDisplayConfig, trainingPlanConfig */,
    } = this.props;
    return (
      <AuthConsumer>
        {({ enumerators }) => (
          <Formik // initialValues={this.initialValues}
            initialValues={{
              ...team,
              birth_year_range: {
                from: team.birth_year_range.from || '',
                to: team.birth_year_range.to || '',
              },
              // dashboard: +(Math.random() > 0.49), // bool => int // TODO: update with correct value
            }}
            onSubmit={updateTeam}
            validationSchema={yup.object().shape({
              name: yup.string().required(i18n._('msg.form.required')),
              team_group_id: yup
                .number()
                .transform(value => (Number.isNaN(value) ? null : value))
                .oneOf(
                  teamGroups.map(i => i.value),
                  i18n._('msg.form.oneOfValues', {
                    values: teamGroups.map(i => i.label).join(', '),
                  })
                )
                .nullable(),
              description: yup.string().nullable(),
              birth_year_range: yup.object().shape(
                {
                  from: yup
                    .number()
                    .transform(value => (Number.isNaN(value) ? null : parseInt(value)))
                    .min(1900, i18n._('msg.form.range_min', { range_min: 1900 })) // 'Minimal range limit is ${min}'
                    .max(
                      new Date().getFullYear(),
                      i18n._('msg.form.range_max', { range_max: new Date().getFullYear() })
                    ) // 'Maximal range limit is ${max}')
                    .nullable()
                    .when('to', {
                      is: value => !Number.isNaN(parseInt(value)),
                      then: yup
                        .number()
                        .test(
                          'test-1',
                          i18n._(
                            'msg.form.range_min_error'
                          ) /* Please enter smaller or equal value than range end */,
                          function(value) {
                            if (
                              value !== null &&
                              !Number.isNaN(parseInt(this.parent.to)) &&
                              this.parent.to !== null
                            ) {
                              return value <= this.parent.to;
                            }
                            return true;
                          }
                        ),
                    }),
                  to: yup
                    .number()
                    .transform(value => (Number.isNaN(value) ? null : parseInt(value)))
                    .min(1900, i18n._('msg.form.range_min', { range_min: 1900 })) // 'Minimal range limit is ${min}'
                    .max(
                      new Date().getFullYear(),
                      i18n._('msg.form.range_max', { range_max: new Date().getFullYear() })
                    ) // 'Maximal range limit is ${max}')
                    .nullable()
                    .when('from', {
                      is: value => !Number.isNaN(parseInt(value)),
                      then: yup
                        .number()
                        .test(
                          'test-2',
                          i18n._(
                            'msg.form.range_max_error'
                          ) /* Please enter bigger or equal value than range start */,
                          function(value) {
                            if (
                              value !== null &&
                              !Number.isNaN(parseInt(this.parent.from)) &&
                              this.parent.from !== null
                            ) {
                              return value >= this.parent.from;
                            }
                            return true;
                          }
                        ),
                    }),
                },
                ['from', 'to']
              ),
              gender: yup.mixed().oneOf(
                enumerators.gender.map(i => i.value),
                i18n._('msg.form.oneOfValues', {
                  values: enumerators.gender.map(i => i.label).join(', '),
                })
              ),
              visible: yup.number().oneOf(
                enumerators.boolShowHide.map(i => i.value),
                i18n._('msg.form.oneOfValues', {
                  values: enumerators.boolShowHide.map(i => i.label).join(', '),
                })
              ),
              games_display_config: yup.string().oneOf(
                enumerators.gamesDisplayConfig.map(gdc => gdc.value),
                i18n._('msg.form.oneOfValues', {
                  values: enumerators.gamesDisplayConfig.map(gdc => gdc.label).join(', '),
                })
              ),
              // dashboard: yup.number().oneOf(
              //   enumerators.boolOnOff.map(i => i.value),
              //   i18n._('msg.form.oneOfValues', {
              //     values: enumerators.boolOnOff.map(i => i.label).join(', '),
              //   })
              // ),
              color: yup
                .string()
                .transform(value => (value.toString().length ? value : null))
                .matches(hexColorRegExp, i18n._('msg.form.invalid_format'), {
                  excludeEmptyString: true,
                }),
              attendance_watch: yup
                .number()
                .min(1, i18n._('msg.form.min_number', { min_number: 1 }))
                .max(65535, i18n._('msg.form.max_number', { max_number: 65535 }))
                .nullable(),
              // .required(i18n._('msg.form.required')),
              training_plan_config: yup.string().oneOf(
                enumerators.trainingPlanConfig.map(tpc => tpc.value),
                i18n._('msg.form.oneOfValues', {
                  values: enumerators.trainingPlanConfig.map(tpc => tpc.label).join(', '),
                })
              ),
              // TODO: comments
            })}
            render={formikProps => (
              <BasicFormComponent
                {...formikProps}
                gamesDisplayConfig={enumerators.gamesDisplayConfig}
                trainingPlanConfig={enumerators.trainingPlanConfig}
                boolShowHide={enumerators.boolShowHide}
                // boolOnOff={enumerators.boolOnOff}
                gender={enumerators.gender}
                teamGroups={teamGroups}
              />
            )}
          />
        )}
      </AuthConsumer>
    );
  }
}

TeamsConfigBasicForm.propTypes = {
  updateTeam: PropTypes.func.isRequired,
  team: PropTypes.object,
  // gamesDisplayConfig: PropTypes.array,
  // trainingPlanConfig: PropTypes.array,
  teamGroups: PropTypes.array.isRequired,
};
TeamsConfigBasicForm.defaultProps = {
  team: null,
  // gamesDisplayConfig: [],
  // trainingPlanConfig: [],
};

export default TeamsConfigBasicForm;
