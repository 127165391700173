import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { RoleManagementService } from 'api/role.service';
import { i18n } from 'App.js';
import RolesTable from 'components/Management/Roles/RolesTable';
import FabButton from 'components/PageHeader/FabButton';
import PageHeader from 'components/PageHeader';
import RoleDialog from 'components/Management/Roles/RoleDialog';
import { Role } from 'models/Role';
import { reverse } from 'named-urls';
import routes from 'Routes.js';
import ability from 'ability.js';

class Roles extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    loadingContent: false,
    roles: [],
    dialogOpened: false,
  };

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = async () => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loadingContent: true,
    });

    await RoleManagementService.getAll()
      .with('members_count')
      .fetch()
      .then(response => {
        this.setState(
          {
            loadingContent: false,
            roles: response,
          },
          () => {
            enqueueSnackbar(i18n._('msg.roles.loading_success'), { variant: 'success' });
          }
        );
      });
  };

  openCreateRole = () => {
    this.setState({
      dialogOpened: true,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogOpened: false,
    });
  };

  createRole = async data => {
    const { enqueueSnackbar, history } = this.props;

    const formattedData = Role.formatCreateData(data);

    await RoleManagementService.create(formattedData)
      .then(response => {
        const { roles } = this.state;

        this.setState(
          {
            roles: [...roles, response],
          },
          () => {
            enqueueSnackbar(i18n._('msg.roles.create_success'), { variant: 'success' });
            history.push(
              reverse(routes.management.roles.single.update, {
                roleId: response.id,
              })
            );
          }
        );
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  deleteRole = roleId => {
    const { enqueueSnackbar } = this.props;

    RoleManagementService.delete(roleId)
      .then(() => {
        const { roles } = this.state;

        const deletedRoleIndexInRoles = roles.findIndex(r => r.id === roleId);

        // eslint-disable-next-line
        roles.splice(deletedRoleIndexInRoles, 1);

        this.setState({
          roles,
        });
        enqueueSnackbar(i18n._('msg.roles.delete_success'), { variant: 'info' });
      })
      .catch(e => {
        console.log({ e });
        enqueueSnackbar(i18n._(e.response.data.message), { variant: 'error' });
      });
  };

  render() {
    const { roles, loadingContent, dialogOpened } = this.state;
    const { accessLevel } = this.props;
    return (
      <div>
        <PageHeader
          title={i18n._('msg.roles.page_title')}
          customControlsPrimary={[
            ability.can('manage', {
              __type: 'Roles',
              level: accessLevel,
            }) && <FabButton variant="round" size="small" onClick={this.openCreateRole} />,
          ]}
        />
        {roles && (
          <RolesTable
            deleteRole={this.deleteRole}
            roles={roles}
            loading={loadingContent}
            managePermissions={ability.can('manage', {
              __type: 'Roles',
              level: accessLevel,
            })}
          />
        )}

        <RoleDialog
          onClose={this.closeDialog}
          handleSubmit={this.createRole}
          dialogOpened={dialogOpened}
        />
      </div>
    );
  }
}

Roles.propTypes = {};

export default withSnackbar(Roles);
