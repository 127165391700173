import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const StyledHeaderButton = styled(Button)`
  && {
    &:not(button):hover {
      background: transparent;
      text-decoration: underline;
    }
    &:not(a):hover {
      & > span > div > div:nth-child(2) {
        text-decoration: underline;
      }
    }
  }
`;

const HeaderButton = ({ children, color, ...props }) => (
  <StyledHeaderButton variant="text" color={color || 'inherit'} disableRipple {...props}>
    {children}
  </StyledHeaderButton>
);

HeaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]).isRequired,
  color: PropTypes.string,
};

HeaderButton.defaultProps = {
  color: null,
};

export default HeaderButton;
