import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Card, CardContent, Divider } from '@material-ui/core';

class TeamsConfigPhotoForm extends Component {
  render() {
    return (
      <Card>
        <CardContent>Teams config photo</CardContent>
        <Divider />
        <CardContent>hello</CardContent>
        <Divider />
        <CardContent>world</CardContent>
      </Card>
    );
  }
}

TeamsConfigPhotoForm.propTypes = {};

export default TeamsConfigPhotoForm;
