import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, ListItem, ListItemIcon, ListItemText, Tooltip, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconWithTooltip = ({ drawerOpened, children, title, classes }) => {
  if (drawerOpened) {
    return <ListItemIcon classes={classes}>{children}</ListItemIcon>;
  }
  return (
    <Tooltip title={title} placement="right">
      <ListItemIcon classes={classes}>{children}</ListItemIcon>
    </Tooltip>
  );
};

IconWithTooltip.propTypes = {
  drawerOpened: PropTypes.bool,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

IconWithTooltip.defaultProps = {
  drawerOpened: true,
  title: '',
};

const styles = theme => ({
  drawerItem: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hidden: {
    opacity: 0,
  },
  activeParent: {
    color: theme.club.link_color,
    fontWeight: '500',
  },
  activeChild: {
    color: theme.club.secondary_color,
    fontWeight: '500',
  },
  colorInherit: {
    color: 'inherit',
  },
});

const SidebarItem = ({ drawerOpened, classes, label, path, icon, url }) => {
  const active = url.indexOf(path) > -1;
  return (
    <ListItem
      button
      to={path}
      component={path ? Link : 'div'}
      selected={active}
      classes={{
        button: classNames({
          [classes.activeParent]: active,
        }),
      }}
    >
      <IconWithTooltip
        drawerOpened={drawerOpened}
        title={label}
        active={active}
        classes={{
          root: classNames({
            [classes.colorInherit]: active,
          }),
        }}
      >
        {icon ? <Icon color="inherit">{icon}</Icon> : <Icon color="inherit">stars</Icon>}
      </IconWithTooltip>
      <ListItemText
        primary={label}
        className={classNames(classes.drawerItem, {
          [classes.hidden]: !drawerOpened,
        })}
        classes={{
          primary: classNames({
            [classes.colorInherit]: active,
          }),
        }}
      />
    </ListItem>
  );
};

SidebarItem.propTypes = {
  drawerOpened: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string.isRequired,
};

SidebarItem.defaultProps = {
  drawerOpened: true,
  icon: null,
  path: null,
};

export default withStyles(styles, { withTheme: true })(SidebarItem);
