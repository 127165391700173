import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ImageDisplayStandalone from './Base/ImageDisplayStandalone';

const TeamLogo = props => {
  const { theme } = props;
  return theme.club.logo_image_id ? (
    <ImageDisplayStandalone imageId={theme.club.logo_image_id} />
  ) : (
    <img src="/logo-eos.png" alt="" />
  );
};

TeamLogo.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(TeamLogo);
