import { apiCall } from '../utils/apiCall';
import { Token } from '../models/Token';
import { User } from '../models/User';

/**
 * @static
 */
export class AuthService {
  /**
   * @public
   * @param {string} login
   * @param {string} password
   * @param {string} domainName
   * @param {boolean} [remember=false]
   * @return {Promise<Token>}
   */
  static login(login, password, domainName, remember = false) {
    return apiCall
      .post('/auth/sessions', { login, password, domain_name: domainName, remember })
      .then(response => new Token(response.data));
  }

  static switchActiveMember(memberId) {
    return apiCall
      .patch('/auth/current', { active_member: memberId })
      .then(response => new User(response.data));
  }

  /**
   * @public
   * @return {Promise<any>}
   */
  static logout(sessionId) {
    return apiCall.delete(`/auth/sessions/${sessionId}`);
  }
}
