import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Button, CardContent, Grid } from '@material-ui/core';
import ErrorMessage from 'components/Forms/ErrorMessage';
import SubmitButton from 'components/Forms/SubmitButton';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import EosTextInput from 'components/Forms/EosTextInput';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

const InvitationStep3FormComponent = ({
  isSubmitting,
  errors,
  values,
  handleStepBack,
  // step1Response,
  // step2Response,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      {values.new_account && (
        <React.Fragment>
          <Grid item xs={12}>
            <Field
              name="first_name"
              label={i18n._('msg.invitations.first_name')}
              component={EosTextInput}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="last_name"
              label={i18n._('msg.invitations.last_name')}
              component={EosTextInput}
              required
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <Field
          name="password"
          type="password"
          label={i18n._('msg.invitations.password_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      {values.new_account && (
        <Grid item xs={12}>
          <Field
            name="password_confirm"
            type="password"
            label={i18n._('msg.invitations.password_confirm_label')}
            component={EosTextInput}
            required
          />
        </Grid>
      )}

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="center" noBottomPadding>
      <Button onClick={handleStepBack}>
        <Trans id="msg.back" />
      </Button>
      {' '}
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        <Trans id="msg.finish" />
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

InvitationStep3FormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
  // step1Response: PropTypes.object.isRequired,
  // step2Response: PropTypes.object.isRequired,
  handleStepBack: PropTypes.func.isRequired,
};

InvitationStep3FormComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
};

class InvitationStep3 extends Component {
  validationSchema = yup.object().shape({
    new_account: yup.bool(),
    first_name: yup.string().when('new_account', {
      is: true,
      then: yup
        .string()
        .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
        .required(i18n._('msg.form.required')),
      otherwise: yup.string().nullable(),
    }),
    last_name: yup.string().when('new_account', {
      is: true,
      then: yup
        .string()
        .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
        .required(i18n._('msg.form.required')),
      otherwise: yup.string().nullable(),
    }),
    password: yup
      .string()
      .min(4, i18n._('msg.form.min_length', { min_length: 4 }))
      .required(i18n._('msg.form.required')),
    password_confirm: yup.string().when('new_account', {
      is: true,
      then: yup
        .string()
        .oneOf([yup.ref('password'), null], i18n._('msg.form.password_not_match'))
        .required(i18n._('msg.form.required')),
      otherwise: yup.string().nullable(),
    }),
  });

  render() {
    const {
      handleSubmit,
      handleStepBack,
      step1Response,
      step2Response,
      invitationData,
    } = this.props;

    return (
      <CardContent>
        {/* {!step2Response.new_account && (
          <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 16 }}>
            <Trans id="msg.invitations.existing_user" />:{' '}
            <strong>
              {step1Response.member_representative.first_name}{' '}
              {step1Response.member_representative.last_name}
            </strong>
          </Typography>
        )} */}
        <Formik
          initialValues={{
            new_account: step2Response.new_account,
            first_name:
              (step1Response.member_representative &&
                step1Response.member_representative.first_name) ||
              (invitationData.type === 'user' ? step1Response.member.first_name : ''),
            last_name:
              (step1Response.member_representative &&
                step1Response.member_representative.last_name) ||
              (invitationData.type === 'user' ? step1Response.member.last_name : ''),
            password: '',
            password_confirm: step2Response.new_account ? '' : null,
          }}
          validationSchema={this.validationSchema}
          onSubmit={handleSubmit}
          render={formikProps => (
            <InvitationStep3FormComponent
              {...formikProps}
              handleStepBack={handleStepBack}
              // step1Response={step1Response}
              // step2Response={step2Response}
            />
          )}
        />
      </CardContent>
    );
  }
}

InvitationStep3.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func.isRequired,
  step1Response: PropTypes.object.isRequired,
  step2Response: PropTypes.object.isRequired,
  invitationData: PropTypes.object.isRequired,
};

export default InvitationStep3;
