import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { i18n } from 'App.js';
import { EnumeratorService } from 'api/enumerator.service';
import { t } from '@lingui/macro';
import { CurrentService } from 'api/current.service';
import { AuthService } from 'api/auth.service';
// import config from 'config.js';
// import { User } from 'models/User';
import { withRouter } from 'react-router-dom';
import auth from './utils/auth';

const AuthContext = React.createContext();

class AuthProvider extends Component {
  state = {
    isAuth: auth.getToken() !== null,
    sessionId: auth.getToken() ? auth.getToken().id : null,
    user: {}, // auth.getUserInfo() || {},
    club: auth.getClubInfo() || {},
    drawerOpened: true,
    memberPickerOpened: false,
    enumerators: null,
  };

  login = userData => {
    // console.log({ userData });
    const newState = {
      isAuth: !!userData.active_member, // !!auth.getUserInfo().active_member,
      sessionId: auth.getToken().id,
      user: userData, // auth.getUserInfo(),
      club: auth.getClubInfo(),
    };

    // if (!newState.user.active_member) {
    if (!userData.active_member) {
      newState.memberPickerOpened = true;
    }

    this.setState(newState, this.fetchEnumerators);
  };

  logout = async () => {
    const { sessionId } = this.state;
    if (sessionId) {
      await AuthService.logout(sessionId);
      auth.clearToken();
      auth.clearUserInfo();
      this.setState({ isAuth: false, sessionId: null, user: null, memberPickerOpened: false });
    }
  };

  toggleDrawer = () => {
    const { drawerOpened } = this.state;
    this.setState({ drawerOpened: !drawerOpened });
  };

  toggleMemberPicker = () => {
    const { memberPickerOpened } = this.state;
    this.setState({ memberPickerOpened: !memberPickerOpened });
  };

  switchActiveMember = async memberId => {
    const { history } = this.props;
    await AuthService.switchActiveMember(memberId)
      .then(response => {
        // console.log({ response });
        this.setState(
          {
            user: response,
            isAuth: true,
          },
          () => {
            // redirect to root of club zone
            history.push('/');

            this.toggleMemberPicker();
          }
        );
      })
      .catch(error => {
        console.log({ error });
      });

    // let localStorage = false;
    // if (window.localStorage.getItem(config.keys.userInfo)) {
    //   localStorage = true;
    // }
    // auth.setUserInfo(
    //   {
    //     user: new User({
    //       user: data.user,
    //       active_member: data.active_member || null,
    //       members: data.members || null,
    //     }),
    //   },
    //   localStorage
    // );
    // this.setState({
    //   user: auth.getUserInfo(),
    //   isAuth: true,
    // });
    // this.toggleMemberPicker();
  };

  fetchEnumerators = async () => {
    const rawEnumerators = await EnumeratorService.multiple([
      'games_display_config',
      'web_team_view_type',
      'training_plan_config',
      'gender',
      'role_type',
      'permission_level',
    ]);

    // this is only for lingui extractor - ID based translations of string
    // eslint-disable-next-line
    const labels = [
      t`msg.enumerators.games_display_config.hide`,
      t`msg.enumerators.games_display_config.show_grouped`,
      t`msg.enumerators.games_display_config.show_individually`,

      t`msg.enumerators.web_team_view_type.grid`,
      t`msg.enumerators.web_team_view_type.table`,

      t`msg.enumerators.training_plan_config.off`,
      t`msg.enumerators.training_plan_config.on`,
      t`msg.enumerators.training_plan_config.required`,

      t`msg.enumerators.bool.yes`,
      t`msg.enumerators.bool.no`,

      t`msg.enumerators.bool.on`,
      t`msg.enumerators.bool.off`,

      t`msg.enumerators.bool.show`,
      t`msg.enumerators.bool.hide`,

      t`msg.enumerators.gender.unknown`,
      t`msg.enumerators.gender.male`,
      t`msg.enumerators.gender.female`,
    ];

    const gamesDisplayConfigEnumerators = Object.keys(rawEnumerators.games_display_config).map(
      key => ({
        value: key,
        label: i18n._(`msg.enumerators.games_display_config.${key}`),
      })
    );
    const teamViewTypesEnumerators = Object.keys(rawEnumerators.web_team_view_type).map(key => ({
      value: key,
      label: i18n._(`msg.enumerators.web_team_view_type.${key}`),
    }));
    const trainingPlanConfigEnumerators = Object.keys(rawEnumerators.training_plan_config).map(
      key => ({
        value: key,
        label: i18n._(`msg.enumerators.training_plan_config.${key}`),
      })
    );
    const genderEnumerators = [
      { value: 0, label: i18n._('msg.enumerators.gender.unknown') }, // unknown label
      ...Object.keys(rawEnumerators.gender).map(key => ({
        value: key,
        label: i18n._(`msg.enumerators.gender.${key}`),
      })),
    ];
    const roleTypeEnumerators = [
      ...Object.keys(rawEnumerators.role_type).map(key => ({
        value: key,
        label: rawEnumerators.role_type[key],
      })),
    ];
    const permissionLevelEnumerators = [
      ...Object.keys(rawEnumerators.permission_level).map(key => ({
        value: key,
        label: rawEnumerators.permission_level[key],
      })),
    ];

    const boolYesNo = [
      {
        value: 1,
        label: i18n._(`msg.enumerators.bool.yes`),
      },
      {
        value: 0,
        label: i18n._(`msg.enumerators.bool.no`),
      },
    ];
    const boolOnOff = [
      {
        value: 1,
        label: i18n._(`msg.enumerators.bool.on`),
      },
      {
        value: 0,
        label: i18n._(`msg.enumerators.bool.off`),
      },
    ];
    const boolShowHide = [
      {
        value: 1,
        label: i18n._(`msg.enumerators.bool.show`),
      },
      {
        value: 0,
        label: i18n._(`msg.enumerators.bool.hide`),
      },
    ];

    this.setState({
      enumerators: {
        gamesDisplayConfig: gamesDisplayConfigEnumerators,
        teamViewTypes: teamViewTypesEnumerators,
        trainingPlanConfig: trainingPlanConfigEnumerators,
        roleType: roleTypeEnumerators,
        permissionLevel: permissionLevelEnumerators,
        gender: genderEnumerators,
        boolYesNo,
        boolOnOff,
        boolShowHide,
      },
    });
  };

  fetchClubInfo = async () => {
    await CurrentService.club().then(club => {
      this.setState({ club });
    });
  };

  componentDidMount = async () => {
    const { isAuth, user, club, enumerators } = this.state;

    if (Object.keys(club).length === 0) {
      this.fetchClubInfo();
    }

    if (isAuth && !enumerators) {
      this.fetchEnumerators();
    }

    if (Object.keys(user).length === 0) {
      await CurrentService.user()
        .then(response => {
          // console.log({ 'AuthContext response': response });
          this.setState({
            user: response,
          });
        })
        .catch(error => {
          console.log({ error });
        });
    }
  };

  render() {
    const { isAuth, user, club, drawerOpened, memberPickerOpened, enumerators } = this.state;
    const { children } = this.props;

    return (
      ((isAuth && !!user.modules_permissions && Object.keys(user.modules_permissions).length > 0) ||
        !isAuth) && (
        <AuthContext.Provider
          value={{
            isAuth,
            user,
            club,
            drawerOpened,
            memberPickerOpened,
            enumerators,
            login: this.login,
            logout: this.logout,
            toggleDrawer: this.toggleDrawer,
            toggleMemberPicker: this.toggleMemberPicker,
            switchActiveMember: this.switchActiveMember,
          }}
          isAuth={isAuth}
        >
          {children}
        </AuthContext.Provider>
      )
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AuthProvider);

const AuthConsumer = AuthContext.Consumer;

export { AuthConsumer };
