import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import { get } from './helpers';

const EosCheckbox = ({
  field: { name }, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  className,
  label,
  groupLabel,
  // checked,
  disabled,
  onChange,
  // ...props
}) => {
  const error = get(errors, name);
  const touch = get(touched, name);
  const value = get(values, name);

  const classes = classnames(
    'form-group',
    {
      error: !!error && !!touch,
      // disabled: !!disabled,
    },
    className
  );

  const checkboxHandleChange = evt => {
    if (typeof onChange === 'function') {
      onChange();
    }

    handleChange(evt);
  };

  // const input = <Input id={name} className="form-control" type='checkbox' error={error} checked={checked} {...field} {...props} />;
  return (
    <FormControl error={!!error && !!touch} className={classes} disabled={isSubmitting || disabled}>
      {groupLabel && <FormLabel>{groupLabel}</FormLabel>}
      <FormGroup>
        <FormControlLabel
          disabled={isSubmitting || disabled}
          control={<Checkbox name={name} onChange={checkboxHandleChange} checked={value} />}
          label={label}
        />
      </FormGroup>
      {touch && error && <FormHelperText>{String(error)}</FormHelperText>}
    </FormControl>
  );
};

EosCheckbox.propTypes = {
  // checked: PropTypes.bool,
  label: PropTypes.string,

  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  groupLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

EosCheckbox.defaultProps = {
  // checked: false,
  label: '',
  className: '',
  groupLabel: '',
  disabled: false,
  onChange: null,
  // form: {
  //   touched: {},
  //   errors: {},
  //   values: {},
  //   handleChange: null,
  // },
};

export default EosCheckbox;
