const possibleInsuranceCompanies = [
  {
    value: '111',
    label: '111 - Všeobecná zdravotní pojišťovna',
  },
  {
    value: '211',
    label: '211 - Zdravotní pojišťovna ministerstva vnitra',
  },
];

const dummyRoles = [
  {
    value: 1,
    label: 'admin',
  },
  {
    value: 2,
    label: 'member',
  },
  {
    value: 3,
    label: 'manager',
  },
];

const dummyGroups = [
  {
    value: 0,
    label: 'no group',
  },
  {
    value: 1,
    label: 'Group 1',
  },
  {
    value: 2,
    label: 'Gruppo 2',
  },
];

const eventTypesIcons = [
  {
    label: 'msg.icon_label.star', // 'Hvězda', // i18n._('msg.icon_label.star'),
    value: 'star',
  },
  {
    label: 'msg.icon_label.video', // 'Video', // i18n._('msg.icon_label.video'),
    value: 'ondemand_video',
  },
  {
    label: 'msg.icon_label.runner', // 'Běžec', // i18n._('msg.icon_label.runner'),
    value: 'directions_run',
  },
];

export { possibleInsuranceCompanies, dummyRoles, dummyGroups, eventTypesIcons };
