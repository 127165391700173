import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls/src';
import { MemberManagementService } from 'api/member.service';
import routes from 'Routes';
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import LoadingMessage from 'components/Base/LoadingMessage';
import MemberFormBaseInfo from 'components/Management/Members/MemberFormBaseInfo';
import MemberFormContactInfo from 'components/Management/Members/MemberFormContactInfo';
import MemberFormInternalInfo from 'components/Management/Members/MemberFormInternalInfo';
import MemberFormTemp from 'components/Management/Members/MemberFormTemp';
import { i18n } from 'App.js';
import SwipeableViews from 'react-swipeable-views';
// import styled from 'styled-components';
import { FullMember } from 'models/Member';
import { withSnackbar } from 'notistack';
import PageHeader from 'components/PageHeader';

const Err404 = () => <Typography>Not Found!</Typography>;

// const StyledTabPane = styled(CardContent)`
//   padding-top: ${props => `${props.theme.spacing.unit * 3}px`};
//   height: auto;
//   transition: all 0.5s;
//   overflow-x: hidden;
//
//   [aria-hidden='true'] > & {
//    max-height: 150px;
//   }
// `;

class Update extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    member: null,
    user: null,
    errorStatus: null,
    currentIndex: 1,
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { memberId },
      },
      enqueueSnackbar,
    } = this.props;

    await MemberManagementService.get(memberId)
      .then(fetchedMember => {
        this.setState(
          {
            member: fetchedMember,
            user: fetchedMember.user,
            currentIndex: 0,
          },
          () => {
            enqueueSnackbar(i18n._('msg.members.load_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        let errorStatus = null;
        switch (error.response.status) {
          default:
            errorStatus = 404;
        }
        this.setState(
          {
            errorStatus,
          },
          () => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  updateMember = (values, actions) => {
    const {
      match: {
        params: { memberId },
      },
      enqueueSnackbar,
    } = this.props;
    const formattedData = FullMember.formatFormData(values);

    MemberManagementService.update(memberId, formattedData)
      .then(response => {
        const { user } = this.state;
        actions.setSubmitting(false);
        this.setState({ member: { ...response, user } }, () => {
          enqueueSnackbar(i18n._('msg.members.update_success'), { variant: 'success' });
        });
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
        // enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  handleSubmit = (values, actions) => {
    this.updateMember(values, actions);
  };

  handleTabChange = (event, value) => {
    this.setState({ currentIndex: value });
  };

  handleTabChangeIndex = index => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { member, errorStatus, currentIndex } = this.state;

    return (
      <div>
        {member && (
          <React.Fragment>
            <PageHeader
              breadcrumbsItems={[
                {
                  url: routes.management.members.all,
                  title: 'some title',
                  text: i18n._('msg.members.page_title'),
                },
                { url: null, text: `${member.first_name} ${member.last_name}` },
              ]}
              title={`${member.first_name} ${member.last_name}`}
            />

            <Card>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={currentIndex}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  // fullWidth
                  // centered
                >
                  <Tab label={i18n._('msg.members.tabs.base_info')} />
                  <Tab label={i18n._('msg.members.tabs.contact')} />
                  <Tab label={i18n._('msg.members.tabs.internal_info')} />
                  <Tab label={i18n._('msg.members.tabs.temp')} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis="x"
                index={currentIndex}
                onChangeIndex={this.handleTabChangeIndex}
                // animateHeight
                // style={{ flex: 1 }}
              >
                <CardContent>
                  <MemberFormBaseInfo member={member} handleSubmit={this.handleSubmit} />
                </CardContent>
                <CardContent>
                  <MemberFormContactInfo member={member} handleSubmit={this.handleSubmit} />
                </CardContent>
                <CardContent>
                  <MemberFormInternalInfo member={member} handleSubmit={this.handleSubmit} />
                </CardContent>
                <CardContent>
                  <MemberFormTemp
                    member={member}
                    handleSubmit={this.handleSubmit}
                    requestedImageSize={{ width: 300, height: 400 }}
                  />
                </CardContent>
              </SwipeableViews>
            </Card>
            <Divider variant="middle" style={{ margin: '24px 0' }} />

            <Typography variant="body1" component="p">
              <Button
                component={Link}
                color="primary"
                variant="contained"
                to={routes.management.members.all}
              >
                <Trans id="msg.members.all_members" />
              </Button>{' '}
              <Button
                component={Link}
                color="primary"
                variant="contained"
                to={reverse(routes.management.members.single.detail, { memberId: member.id })}
              >
                <Trans id="msg.members.member_detail" />
              </Button>
            </Typography>
          </React.Fragment>
        )}

        {errorStatus === 404 && <Err404 />}

        {!member && !errorStatus && <LoadingMessage />}
      </div>
    );
  }
}

export default withSnackbar(Update);
