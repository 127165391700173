import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'Routes';
import Typography from '@material-ui/core/Typography';

const Page404Styles = {
  width: '100%',
  paddingTop: '100px',
  textAlign: 'center',
};

function Page404() {
  return (
    <div className="Page404" style={Page404Styles}>
      <Typography variant="h1" gutterBottom>
        Sorry, page not found!
      </Typography>
      <Typography variant="body1">
        <Link to={routes.home}>Back to safety</Link>
      </Typography>
    </div>
  );
}

export default Page404;
