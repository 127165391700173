import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datez/dist/css/react-datez.css';
import ability from 'ability.js';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

window.ability = ability;

ReactDOM.render(
  // <Provider theme={themes.teams}>
  <Router>
    <App />
  </Router>,
  // </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
