import React, { Component } from 'react';
import { PositionManagementService } from 'api/position.service';
import PositionsTable from 'components/Management/Settings/Positions/PositionsTable';
import PositionsDialog from 'components/Management/Settings/Positions/PositionsDialog';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Position } from 'models/Position';
import { isEqual } from 'lodash';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import routes from 'Routes.js';
import ability from 'ability.js';

class Positions extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    positions: [],
    loading: false,
    position: null,
    dialogOpened: false,
    newPositionsOrder: null,
    initialPositionsOrder: null,
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchPositions();
  }

  setInitialPositionsOrder = positions =>
    positions.map((position, index) => ({
      id: position.id,
      name: position.name,
      order: index + 1, // this is because of the order dont't have to be row of numbers
    }));

  fetchPositions = async () => {
    const { enqueueSnackbar } = this.props;

    this.setState({ loading: true });
    const positions = await PositionManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .fetch();

    const initialPositionsOrder = this.setInitialPositionsOrder(positions);

    this.setState(
      {
        initialPositionsOrder,
        positions,
        loading: false,
      },
      () => {
        enqueueSnackbar(i18n._('msg.positions.loading_success'), { variant: 'success' });
      }
    );
  };

  createPosition = async data => {
    const { enqueueSnackbar } = this.props;

    const formattedData = Position.formatFormData(data);
    await PositionManagementService.create(formattedData).then(response => {
      const { positions, initialPositionsOrder } = this.state;

      this.setState(
        {
          positions: [...positions, response],
          initialPositionsOrder: [...initialPositionsOrder, Position.formatForSorter(response)],
        },
        () => {
          enqueueSnackbar(i18n._('msg.positions.create_success'), { variant: 'success' });
        }
      );
    });
  };

  updatePosition = async (positionId, data) => {
    const { enqueueSnackbar } = this.props;

    const formattedData = Position.formatFormData(data);
    await PositionManagementService.update(positionId, formattedData).then(response => {
      const { positions } = this.state;

      const updatedPositionIndexInPositions = positions.findIndex(p => p.id === positionId);

      positions[updatedPositionIndexInPositions] = response;

      this.setState(
        {
          positions,
        },
        () => {
          enqueueSnackbar(i18n._('msg.positions.update_success'), { variant: 'success' });
        }
      );
    });
  };

  deletePosition = async positionId => {
    const { enqueueSnackbar } = this.props;

    await PositionManagementService.delete(positionId)
      .then(() => {
        const { positions, newPositionsOrder } = this.state;
        let { initialPositionsOrder } = this.state;
        const deletedPositionIndexInPositions = positions.findIndex(p => p.id === positionId);

        // remove deleted position from previous state
        positions.splice(deletedPositionIndexInPositions, 1);

        // remove deleted position from initialPositionsOrder and from newPositionOrder if this is set
        const deletedPositionIndexInInitialPositionsOrder = initialPositionsOrder.findIndex(
          position => position.id === positionId
        );
        if (deletedPositionIndexInInitialPositionsOrder > -1) {
          initialPositionsOrder = this.setInitialPositionsOrder(positions);
        }
        if (newPositionsOrder) {
          const deletedPositionIndexInNewPositionsOrder = newPositionsOrder.findIndex(
            position => position.id === positionId
          );

          if (deletedPositionIndexInNewPositionsOrder > -1) {
            newPositionsOrder.splice(deletedPositionIndexInNewPositionsOrder, 1);
          }
        }

        this.setState(
          {
            positions,
            initialPositionsOrder,
            newPositionsOrder,
          },
          () => {
            enqueueSnackbar(i18n._('msg.positions.delete_success'), { variant: 'info' });
          }
        );
      })
      .catch(e => enqueueSnackbar(e.response.data.message, { variant: 'error' }));
  };

  closeDialog = () => {
    this.setState({
      position: null,
      dialogComponent: '',
      dialogOpened: false,
    });
  };

  operationCallback = (promise, actions) =>
    promise.then(
      () => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      },
      error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      }
    );

  handleSubmit = (values, actions) => {
    const { position, dialogComponent, initialPositionsOrder, newPositionsOrder } = this.state;

    if (dialogComponent === 'sortable') {
      if (newPositionsOrder && !isEqual(initialPositionsOrder, newPositionsOrder)) {
        this.setState({
          isSubmitting: true,
        });
        this.requestForNewOrderedPositions(newPositionsOrder);
      } else {
        this.closeDialog();
      }

      return;
    }

    const promise = position
      ? this.updatePosition(position.id, values)
      : this.createPosition(values);

    this.operationCallback(promise, actions);
  };

  openSortablePositions = () => {
    this.setState({ dialogComponent: 'sortable', dialogOpened: true });
  };

  openCreatePosition = () => {
    this.setState({ dialogComponent: 'create', dialogOpened: true });
  };

  openUpdatePosition = position => {
    this.setState({ dialogComponent: 'update', dialogOpened: true, position });
  };

  setNewPositionsOrder = newPositionsOrder => {
    this.setState({ newPositionsOrder });
  };

  requestForNewOrderedPositions = async newOrder => {
    const { enqueueSnackbar } = this.props;
    const newOrderNormalized = newOrder.map(position => Position.formatForSimpleSorter(position));

    this.setState({
      loading: true,
    });

    await PositionManagementService.updateMultiple(newOrderNormalized)
      .then(response => {
        this.setState(
          {
            positions: response,
            initialPositionsOrder: response.map(p => Position.formatForSorter(p)),
            newPositionsOrder: null,
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(i18n._('msg.positions.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(e => {
        this.setState(
          {
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  render() {
    const {
      dialogOpened,
      positions,
      loading,
      position,
      dialogComponent,
      isSubmitting,
    } = this.state;
    const { accessLevel } = this.props;

    let data = false;
    switch (dialogComponent) {
      case 'sortable':
        data = positions;
        break;
      case 'update':
        data = position;
        break;
      default:
        data = {};
    }

    return (
      <div>
        <PageHeader
          title={i18n._('msg.positions.page_title')}
          customControlsPrimary={
            ability.can('manage', {
              __type: 'Members',
              level: accessLevel,
            })
              ? [
                  <FabButton variant="round" size="small" onClick={this.openCreatePosition} />,
                  <ContextMenu
                    text={i18n._('msg.actions')}
                    variant="withIcon"
                    menuItems={[
                      {
                        text: i18n._('msg.positions.positions_sort'),
                        action: this.openSortablePositions,
                      },
                    ]}
                  />,
                ]
              : []
          }
          breadcrumbsItems={[
            {
              url: routes.management.settings.all,
              // title: 'some title',
              text: i18n._('msg.settings.page_title'),
            },
            { url: null, text: i18n._('msg.positions.page_title') },
          ]}
        />

        <PositionsTable
          positions={positions}
          loading={loading}
          editPosition={this.openUpdatePosition}
          deletePosition={this.deletePosition}
          managePermissions={ability.can('manage', {
            __type: 'Members',
            level: accessLevel,
          })}
        />

        <PositionsDialog
          opened={dialogOpened}
          data={data}
          onClose={this.closeDialog}
          component={dialogComponent}
          requestForNewOrderedRecords={this.requestForNewOrderedPositions}
          setNewRecordsOrder={this.setNewPositionsOrder}
          handleSubmit={this.handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  }
}

Positions.propTypes = {};

export default withSnackbar(Positions);
