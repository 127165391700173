// import { Role } from './Role';
import { FullMember } from './Member';

/**
 * @property {int} id
 * @property {string} email
 * @property {string|null} login

 */
export class ReducedUser {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.login = data.login || null;
  }
}

/**
 * @property {FullMember|null} active_member
 * @property {object|null} representative
 * @property {boolean} manage_all_teams
 * @property {array<FullMember>|null} members
 * @property {object} modules_permissions
 * @property {boolean} access_management
 * @property {array<int>} manage_teams
 * @property {array<int>} access_teams
 */
export class User extends ReducedUser {
  /**
   * @param {object} data
   */
  constructor(data) {
    super(data);
    // this.id = data.user.id;
    // this.email = data.user.email;
    // this.login = data.user.login || null;
    this.active_member = data.active_member ? new FullMember(data.active_member) : null;
    this.representative = data.representative || null;
    this.manage_all_teams =
      typeof data.manage_all_teams !== 'undefined' ? data.manage_all_teams : false;
    this.members = data.members ? data.members.map(m => new FullMember(m)) : null;
    this.modules_permissions = data.modules_permissions || null;
    this.access_management =
      typeof data.access_management !== 'undefined' ? data.access_management : false;
    this.manage_teams = data.manage_teams || [];
    this.access_teams = data.access_teams || [];
  }
}
