import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { TeamMember } from '../models/TeamMember';

/**
 * @static
 */
export class TeamMembersManagementService extends ManagementService {
  static endpoint = 'team-members';

  /**
   * @param {number} teamId
   * @param {object} config={}
   * @return {Promise<TeamMember[]>}
   * @public
   */
  static async getAll(teamId, config = {}) {
    if (!teamId) {
      throw new Error('Team needs to be specified!');
    }

    return apiCall
      .get(TeamMembersManagementService.buildUrl(), {
        ...config,
        params: { team_id: teamId },
      })
      .then(response =>
        response.data.team_members.map(teamMemberData => new TeamMember(teamMemberData))
      );
  }

  /**
   * @param {number} teamId
   * @param {object} data
   * @return {Promise<TeamMember[]>}
   * @public
   */
  static async create(teamId, data) {
    return apiCall
      .post(TeamMembersManagementService.buildUrl(), { team_id: teamId, members: data })
      .then(response =>
        response.data.team_members.map(teamMemberData => new TeamMember(teamMemberData))
      );
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<TeamMember>}
   * @public
   */
  static async update(id, data) {
    return apiCall.put(TeamMembersManagementService.buildUrl(id), data).then(response => {
      // response.status 204 => member record was removed from roster
      if (response.status === 204) {
        return {};
      }

      // response.status 200 => member record was updated, return updated data
      if (response.status === 200) {
        return new TeamMember(response.data);
      }
    });
  }

  /**
   * @param {number} id
   * @public
   */
  static async delete(id) {
    return apiCall.delete(TeamMembersManagementService.buildUrl(id));
  }
}
