import { MemberType } from '../models/MemberType';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class MemberTypeManagementService extends ManagementService {
  static endpoint = 'member-types';

  /**
   * @param {number} id
   * @return {Promise<MemberType>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(MemberTypeManagementService.buildUrl(id))
      .then(response => new MemberType(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(MemberTypeManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<MemberType>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall
      .get(MemberTypeManagementService.buildUrl(), { ...config, params })
      .then(response => {
        const memberTypes = response.data.data.map(data => new MemberType(data));

        if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
          return memberTypes;
        }

        return new PaginatedData(memberTypes, response.data.meta);
      });
  }

  /**
   * @param {object} data
   * @return {Promise<MemberType>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(MemberTypeManagementService.buildUrl(), data)
      .then(response => new MemberType(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<MemberType>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(MemberTypeManagementService.buildUrl(id), data)
      .then(response => new MemberType(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise}
   * @public
   */
  static async delete(id) {
    return apiCall.delete(MemberTypeManagementService.buildUrl(id));
  }
}
