import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Blocker from 'components/Base/Blocker';

const StyledTableWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

class TableWrapper extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { loading, children } = this.props;

    return (
      <StyledTableWrapper>
        {children}
        {false && loading && <Blocker loading={loading} />}
      </StyledTableWrapper>
    );
  }
}

export default TableWrapper;
