import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadCrumbItem = ({ item, first }) => {
  if (first) {
    return item.url ? (
      <Link to={item.url} title={item.title || item.text}>
        {item.text}
      </Link>
    ) : (
      item.text
    );
  }
  return (
    <React.Fragment>
      <Icon>keyboard_arrow_right</Icon>
      {item.url ? (
        <Link to={item.url} title={item.title || item.text}>
          {item.text}
        </Link>
      ) : (
        item.text
      )}
    </React.Fragment>
  );
};

BreadCrumbItem.propTypes = {
  item: PropTypes.object.isRequired,
  first: PropTypes.bool.isRequired,
};

const StyledBreadCrumbsWrapper = styled(({ ...rest }) => <Typography {...rest} />)`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }
`;

const BreadCrumbs = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StyledBreadCrumbsWrapper>
      {items.map((item, index) => (
        <BreadCrumbItem item={item} key={index} first={index === 0} />
      ))}
    </StyledBreadCrumbsWrapper>
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.array,
};

BreadCrumbs.defaultProps = {
  items: [],
};

export default BreadCrumbs;
