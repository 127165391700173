import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  // Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Trans } from '@lingui/macro';
import ImageDisplayStandalone from 'components/Base/ImageDisplayStandalone';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class MemberPicker extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    switchActiveMember: PropTypes.func.isRequired,
    members: PropTypes.array,
    open: PropTypes.bool,
    disabledMemberId: PropTypes.number,
  };

  static defaultProps = {
    // members: [
    //   {
    //     id: 1,
    //     email: 'cenda.kovar@gmail.com',
    //     first_name: 'Cenda',
    //     last_name: 'Kovar',
    //   },
    //   {
    //     id: 2,
    //     email: 'jan.novak@email.cz',
    //     first_name: 'Jan',
    //     last_name: 'Novak',
    //   },
    // ],
    members: [],
    open: false,
    disabledMemberId: null,
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleListItemClick = memberId => {
    const { switchActiveMember } = this.props;
    switchActiveMember(memberId);
  };

  render() {
    const { open, classes, members, disabledMemberId } = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="simple-dialog-title">
        <DialogTitle id="simple-dialog-title">
          <Trans id="msg.dialog.select_member" />
        </DialogTitle>
        {members && members.length ? (
          <div>
            <List>
              {members.map(member => (
                <ListItem
                  button
                  onClick={() => this.handleListItemClick(member.id)}
                  key={member.id}
                  disabled={member.id === disabledMemberId}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      {member.image_id ? (
                        <ImageDisplayStandalone imageId={member.image_id} />
                      ) : (
                        <Icon>person</Icon>
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${member.first_name} ${member.last_name}`} />
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <DialogContent>
            <Typography>
              <Trans id="msg.dialog.no_possible_members" />
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(MemberPicker);
