import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthConsumer } from 'AuthContext.js';
import routes from 'Routes';
import Container from 'components/Base/Container';
import SidebarComponent from 'components/Sidebar';
import sidebarItemsConfig from 'sidebarItemsConfig.js';
import PropTypes from 'prop-types';
import {
  ABILITY_LEVEL_ACCESS_TEXT,
  ABILITY_LEVEL_READ_TEXT,
  computeMinimalPermissions,
  convertTextToNumLevel,
} from 'ability.js';

const AuthenticatedRoute = ({ component: C, props: childProps, ...rest }) => (
  <AuthConsumer>
    {({ isAuth, toggleDrawer, drawerOpened, user }) => (
      <Route
        {...rest}
        render={props => {
          const {
            // eslint-disable-next-line
            location: { pathname, search },
          } = props;

          if (isAuth) {
            // flags for requested area
            const managementAreaFlag = pathname.indexOf(routes.management.toString()) === 0;
            const adminAreaFlag = pathname.indexOf(routes.admin.toString()) === 0;
            const clubzoneRootFlag = pathname === routes.home.toString();

            // select an associated sidebar tree in dependency on current area flag
            let sidebarTree = 'default';

            if (adminAreaFlag) {
              sidebarTree = sidebarItemsConfig.admin;
            } else if (managementAreaFlag) {
              sidebarTree = sidebarItemsConfig.management;
            }

            // if requested area is management and logged in user doesn't have access_management set, redirect him to clubzone dashboard
            if (managementAreaFlag && Object.keys(user).length > 0 && !user.access_management) {
              return <Redirect to={routes.home} />;
            }

            /*
            let's specify current access level of user / member
             */
            let accessLevel = null;
            // if `requiredPermissions` type is string, it means it's not a module,
            // it's something like `area` of application (management, web admin,...)
            if (typeof childProps.requiredPermissions === 'string') {
              // so check it with currently logged in user and if it pass, set the `read` level
              // TODO: at the time, there is only ONE scenario `access_management`,
              //  in future there should by something like `access_admin` or someting like that
              accessLevel = user[childProps.requiredPermissions] ? ABILITY_LEVEL_READ_TEXT : null;
            }
            // if `requiredPermissions` type is object, it means, it's an array of modules
            // that user needs to access the page under that route
            else if (typeof childProps.requiredPermissions === 'object') {
              // it works like computing of minimal access level combination
              // so for modules list ['settings', 'seasons'],
              // when you have `read` access for `settings` and `write` for `seasons`,
              // you get the `read` for whole combination. That's it :)
              accessLevel = computeMinimalPermissions(
                childProps.requiredPermissions.map(key => user.modules_permissions[key])
              );
            }

            // if you:
            // 1
            // - don't have accessLevel set,
            // - OR the level is only `access` (lowest possible)
            // - AND you are NOT on clubzone homepage (some kind of clubzone dashboard)
            //
            // === OR ===
            //
            // 2
            // - specified route's `minimalPermissionLevel` is higher then your current `accessLevel`
            if (
              ((!accessLevel || accessLevel === ABILITY_LEVEL_ACCESS_TEXT) && !clubzoneRootFlag) ||
              (childProps.minimalPermissionLevel &&
                convertTextToNumLevel(childProps.minimalPermissionLevel) >
                  convertTextToNumLevel(accessLevel))
            ) {
              // check if user wants to access some management route and has management access
              // if so, we redirect him to management dashboard
              if (managementAreaFlag && user.access_management) {
                return <Redirect to={routes.management.dashboard} />;
              }

              // in other case, we redirect him to clubzone dashboard
              return <Redirect to={routes.home} />;
            }

            return (
              <React.Fragment>
                <SidebarComponent
                  sidebarTree={sidebarTree}
                  url={pathname}
                  drawerOpened={drawerOpened}
                  toggleDrawer={toggleDrawer}
                />
                <Container inner>
                  <C {...props} {...childProps} accessLevel={accessLevel} />
                </Container>
              </React.Fragment>
            );
          }

          return <Redirect to={`${routes.login}?redirect=${pathname}${search}`} />;
        }}
      />
    )}
  </AuthConsumer>
);

AuthenticatedRoute.propTypes = {
  props: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};

export default AuthenticatedRoute;
