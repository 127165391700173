import { Role } from '../models/Role';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';
import { Permission } from '../models/Permission';

/**
 * @static
 */
export class RoleManagementService extends ManagementService {
  static endpoint = 'roles';

  /**
   * @param {number} id
   * @param {object|null} params
   * @param {object|null} config
   * @return {Promise<Role>}
   * @public
   */
  static async get(id, params = null, config = null) {
    return apiCall
      .get(RoleManagementService.buildUrl(id), { config, params })
      .then(response => new Role(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(RoleManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<Role>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall.get(RoleManagementService.buildUrl(), { ...config, params }).then(response => {
      const roles = response.data.data.map(data => new Role(data));

      if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
        return roles;
      }

      return new PaginatedData(roles, response.data.meta);
    });
  }

  /**
   * @param {int} roleId
   * @returns {Promise<void | never>}
   */
  static fetchPermissions(roleId) {
    return apiCall
      .get(`${RoleManagementService.buildUrl(roleId)}/permissions`)
      .then(response => response.data.data.map(role => new Permission(role)));
  }

  /**
   * @param {object} data
   * @return {Promise<Role>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(RoleManagementService.buildUrl(), data)
      .then(response => new Role(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<Role>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(RoleManagementService.buildUrl(id), data)
      .then(response => new Role(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise<Role>}
   * @public
   */
  static delete(id) {
    return apiCall.delete(RoleManagementService.buildUrl(id));
  }
}
