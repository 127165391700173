import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid, Typography } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';
import EosSelect from 'components/Forms/EosSelect';
import EosRadio from 'components/Forms/EosRadio';
import styled from 'styled-components';
import { AuthConsumer } from 'AuthContext.js';
import { ABILITY_LEVEL_ACCESS_TEXT } from 'ability.js';

const RadioGroupWrapper = styled.div`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RoleUpdateFormComponent = ({
  isSubmitting,
  errors,
  values,
  // setFieldValue,
  // setFieldTouched,
  roleType,
  permissions,
  permissionLevel,
  modules,
  loadingPermissions,
  loadingModules,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              type="text"
              name="name"
              label={i18n._('msg.roles.role_name_label')}
              required
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="type"
              label={i18n._('msg.roles.role_type_label')}
              required
              component={EosSelect}
              options={roleType}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="description"
          label={i18n._('msg.roles.role_description_label')}
          multiline
          rows={5}
          component={EosTextInput}
        />
      </Grid>

      {loadingPermissions || loadingModules ? (
        <Grid item xs={12}>
          <div>load permissions</div>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={24}>
            {modules.management.length > 0 && (
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  <Trans id="msg.roles.permissions_title_management" />
                </Typography>
                {modules.management.map(m => {
                  const managementPermissionIndex = permissions.management.findIndex(
                    p => p.module === m.value
                  );
                  // console.log({ [m.value]: values.permissions.management[m.value] });
                  const valueOrDefaultAccess =
                    values.permissions.management[m.value] ||
                    (managementPermissionIndex === -1
                      ? ABILITY_LEVEL_ACCESS_TEXT
                      : permissions.management[managementPermissionIndex].level);

                  return (
                    <RadioGroupWrapper key={m.value}>
                      <Field
                        name={`permissions.management.${m.value}`}
                        groupLabel={m.label}
                        options={permissionLevel}
                        component={EosRadio}
                        inline
                        value={valueOrDefaultAccess}
                        // onChange={value => {
                        //   console.log(`field: ${m.value} has new value: ${value}`);
                        // }}
                      />
                    </RadioGroupWrapper>
                  );
                })}
              </Grid>
            )}

            {modules.web.length > 0 && (
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  <Trans id="msg.roles.permissions_title_web" />
                </Typography>
                {modules.web.map(m => {
                  const webPermissionIndex = permissions.web.findIndex(p => p.module === m.value);
                  // console.log({ [m.value]: values.permissions.web[m.value] });
                  const valueOrDefaultAccess =
                    values.permissions.web[m.value] ||
                    (webPermissionIndex === -1
                      ? ABILITY_LEVEL_ACCESS_TEXT
                      : permissions.web[webPermissionIndex].level);

                  return (
                    <RadioGroupWrapper key={m.value}>
                      <Field
                        name={`permissions.web.${m.value}`}
                        groupLabel={m.label}
                        options={permissionLevel}
                        component={EosRadio}
                        inline
                        value={valueOrDefaultAccess}
                        // onChange={value => {
                        //   console.log(`field: ${m.value} has new value: ${value}`);
                        // }}
                      />
                    </RadioGroupWrapper>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <Grid item xs={12}>
        <SubmitArea isSubmitting={isSubmitting} align="left">
          <SubmitButton
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting || loadingPermissions || loadingModules}
            isSubmitting={isSubmitting}
          >
            {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
          </SubmitButton>
        </SubmitArea>
      </Grid>
    </Grid>
  </EosForm>
);

RoleUpdateFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  roleType: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
  permissionLevel: PropTypes.array.isRequired,
  modules: PropTypes.object.isRequired,
  loadingPermissions: PropTypes.bool.isRequired,
  loadingModules: PropTypes.bool.isRequired,
};

RoleUpdateFormComponent.defaultProps = {
  isSubmitting: false,
};

class RoleUpdateForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    role: PropTypes.object,
    permissions: PropTypes.object.isRequired,
    modules: PropTypes.object.isRequired,
    loadingPermissions: PropTypes.bool,
    loadingModules: PropTypes.bool,
  };

  static defaultProps = {
    role: {},
    loadingPermissions: true,
    loadingModules: true,
  };

  render() {
    const {
      handleSubmit,
      role,
      permissions,
      modules,
      loadingPermissions,
      loadingModules,
    } = this.props;

    return (
      <AuthConsumer>
        {({ enumerators }) =>
          enumerators && (
            <Formik
              initialValues={{
                name: role.name,
                type: role.type,
                description: role.description,
                permissions: {
                  management: permissions.management.reduce((acc, cur) => {
                    acc[cur.module] = cur.level;
                    return acc;
                  }, {}),
                  web: permissions.web.reduce((acc, cur) => {
                    acc[cur.module] = cur.level;
                    return acc;
                  }, {}),
                },
              }}
              onSubmit={handleSubmit}
              validationSchema={yup.object().shape({
                name: yup
                  .string()
                  .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
                  .required(i18n._('msg.form.required')),
                description: yup.string().nullable(),
                type: yup.string().oneOf(
                  enumerators.roleType.map(i => i.value),
                  i18n._('msg.form.oneOfValues', {
                    values: enumerators.roleType.map(i => i.label).join(', '),
                  })
                ),
              })}
              enableReinitialize
              render={formikProps => (
                <RoleUpdateFormComponent
                  {...formikProps}
                  roleType={enumerators.roleType}
                  permissions={permissions}
                  permissionLevel={enumerators.permissionLevel}
                  modules={modules}
                  loadingPermissions={loadingPermissions}
                  loadingModules={loadingModules}
                />
              )}
            />
          )
        }
      </AuthConsumer>
    );
  }
}

export default RoleUpdateForm;
