import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/PageHeader';
import { i18n } from 'App.js';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import CustomizedLink from 'components/Base/Link';
import routes from 'Routes.js';
import Can from 'components/Can';
import { AuthConsumer } from 'AuthContext';
import { computeMinimalPermissions } from 'ability.js';

const SettingsCard = ({ title, description, to, linkText }) => (
  <Card>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography component="div">
        <div>{description}</div>
        <CustomizedLink to={to}>{linkText}</CustomizedLink>
      </Typography>
    </CardContent>
  </Card>
);

SettingsCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  to: PropTypes.string.isRequired,
  linkText: PropTypes.string,
};

SettingsCard.defaultProps = {
  linkText: 'go to settings',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid culpa ducimus, eius excepturi harum maiores modi necessitatibus nesciunt odit tempora?',
};

class Settings extends Component {
  render() {
    const settingsSubModules = [
      {
        module: 'seasons',
        path: routes.management.settings.seasons,
        title: i18n._('msg.seasons.page_title'),
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, rem.',
        requiredPermissions: ['seasons'],
      },
      {
        module: 'positions',
        path: routes.management.settings.positions,
        title: i18n._('msg.positions.page_title'),
        description:
          'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis, tempora.',
        requiredPermissions: ['members'],
      },
      {
        module: 'memberTypes',
        path: routes.management.settings.memberTypes,
        title: i18n._('msg.member_types.page_title'),
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, facilis?',
        requiredPermissions: ['members'],
      },
      {
        module: 'eventTypes',
        path: routes.management.settings.eventTypes,
        title: i18n._('msg.event_types.page_title'),
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, praesentium.',
        requiredPermissions: ['events'],
      },
      {
        module: 'teamGroups',
        path: routes.management.settings.teamGroups,
        title: i18n._('msg.team_groups.page_title'),
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, praesentium.',
        requiredPermissions: ['team_groups'],
      },
    ];

    return (
      <AuthConsumer>
        {({ user }) => (
          <div>
            <PageHeader title={i18n._('msg.settings.page_title')} />

            <Grid container spacing={24}>
              {settingsSubModules.map((subModule, index) => (
                <Can
                  key={index}
                  I="visit"
                  a={{
                    __type: 'SettingsSubModule',
                    level: computeMinimalPermissions(
                      subModule.requiredPermissions.map(key => user.modules_permissions[key])
                    ),
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <SettingsCard
                      to={subModule.path}
                      title={subModule.title}
                      description={subModule.description}
                    />
                  </Grid>
                </Can>
              ))}
            </Grid>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default Settings;
