import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { Trans } from '@lingui/macro';
import Sorter from 'components/Dialog/Sorter';
import PositionForm from 'components/Management/Settings/Positions/PositionForm';

const RenderedComponent = ({
  component,
  data,
  requestForNewOrderedRecords,
  setNewRecordsOrder,
  handleSubmit,
  closeDialog,
  isSubmitting,
}) => {
  switch (component) {
    case 'create':
      return <PositionForm position={data} handleSubmit={handleSubmit} closeDialog={closeDialog} />;
    case 'update':
      return <PositionForm position={data} handleSubmit={handleSubmit} closeDialog={closeDialog} />;
    case 'sortable':
      return (
        <Sorter
          data={data}
          requestForNewOrderedRecords={requestForNewOrderedRecords}
          setNewRecordsOrder={setNewRecordsOrder}
          handleSubmit={handleSubmit}
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
        />
      );
    default:
      return null;
  }
};
RenderedComponent.propTypes = {
  component: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
  requestForNewOrderedRecords: PropTypes.func.isRequired,
  setNewRecordsOrder: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

RenderedComponent.defaultProps = {
  component: '',
  data: null,
};

const dialogTitle = component => {
  switch (component) {
    case 'create':
      return <Trans id="msg.positions.popup_title_add_new" />;
    case 'update':
      return <Trans id="msg.positions.popup_title_update" />;
    case 'sortable':
      return <Trans id="msg.positions.popup_title_sortable" />;
    default:
      return '';
  }
};

class PositionsDialog extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
    requestForNewOrderedRecords: PropTypes.func.isRequired,
    setNewRecordsOrder: PropTypes.func.isRequired,
    component: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
  };

  static defaultProps = {
    opened: false,
    onClose: null,
    component: '',
    data: null,
    isSubmitting: false,
  };

  render() {
    const {
      opened,
      onClose,
      component,
      data,
      requestForNewOrderedRecords,
      setNewRecordsOrder,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <Dialog open={opened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>{dialogTitle(component)}</CustomDialogTitle>
        <DialogContent>
          <RenderedComponent
            component={component}
            data={data}
            requestForNewOrderedRecords={requestForNewOrderedRecords}
            setNewRecordsOrder={setNewRecordsOrder}
            handleSubmit={handleSubmit}
            closeDialog={onClose}
            isSubmitting={isSubmitting}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default PositionsDialog;
