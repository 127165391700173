import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { StyledTable } from 'components/Table/StyledTable';
import { i18n } from 'App.js';
import { reverse } from 'named-urls';
import routes from 'Routes.js';
import ContextMenu from 'components/Base/ContextMenu';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { Trans } from '@lingui/macro';
import CustomizedLink from 'components/Base/Link';

class RolesTable extends Component {
  static propTypes = {
    roles: PropTypes.array,
    loading: PropTypes.bool,
    deleteRole: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    roles: [],
    loading: false,
  };

  render() {
    const { roles, loading, deleteRole, managePermissions } = this.props;

    if (!loading && roles.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.roles.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <Card>
        <StyledTable padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans id="msg.id" />
              </TableCell>
              <TableCell>
                <Trans id="msg.name" />
              </TableCell>
              <TableCell>
                <Trans id="msg.teams" />
              </TableCell>
              <TableCell>
                <Trans id="msg.members" />
              </TableCell>
              <TableCell>
                <Trans id="msg.editable" />
              </TableCell>
              <TableCell align="center">
                <Trans id="msg.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableLoadingContent colSpan={6} />}
            {!loading &&
              roles.map(role => (
                <TableRow key={role.id}>
                  <TableCell>{role.id}</TableCell>
                  <TableCell>
                    {/* {role.editable ? (
                      <CustomizedLink
                        to={reverse(routes.management.roles.single.update, { roleId: role.id })}
                      >
                        {role.name}
                      </CustomizedLink>
                    ) : (
                      role.name
                    )} */}
                    <CustomizedLink
                      to={reverse(routes.management.roles.single.update, { roleId: role.id })}
                    >
                      {role.name}
                    </CustomizedLink>
                  </TableCell>
                  <TableCell>{role.type}</TableCell>
                  <TableCell>{role.members_count}</TableCell>
                  <TableCell>{+role.editable}</TableCell>
                  <TableCell align="center">
                    <ContextMenu
                      variant="icon"
                      // tooltip={
                      //   role.editable
                      //     ? i18n._('msg.actions')
                      //     : i18n._('msg.roles.actions_disabled_tooltip_text')
                      // }
                      tooltip={i18n._('msg.actions')}
                      color="inherit"
                      icon="more_vert"
                      // disabled={!role.editable}
                      menuItems={[
                        {
                          text: i18n._('msg.record_update'),
                          url: reverse(routes.management.roles.single.update, {
                            roleId: role.id,
                          }),
                        },
                        role.editable &&
                          managePermissions && {
                            text: i18n._('msg.record_delete'),
                            action: () => deleteRole(role.id),
                            variant: 'delete',
                          },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </Card>
    );
  }
}

export default RolesTable;
