import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Field, Formik } from 'formik';
import { AuthConsumer } from 'AuthContext.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Grid } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import EosRichText from 'components/Forms/EosRichText';
import ErrorMessage from 'components/Forms/ErrorMessage';

const MemberFormInternalInfoComponent = ({
  isSubmitting,
  errors,
  // values,
  setFieldValue,
  setFieldTouched,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          name="medical_records"
          label={i18n._('msg.members.medical_records')}
          component={EosRichText}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="left">
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

MemberFormInternalInfoComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
};

MemberFormInternalInfoComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
};

class MemberFormInternalInfo extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    memberPartialData: null,
  };

  componentDidMount() {
    const {
      // eslint-disable-next-line
      member: { medical_records },
    } = this.props;
    const memberPartialData = {
      medical_records,
    };

    this.setState({
      memberPartialData,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { memberPartialData } = this.state;

    if (!memberPartialData) {
      return null;
    }

    return (
      <AuthConsumer>
        {({ enumerators }) => (
          <div>
            <Formik
              initialValues={memberPartialData}
              onSubmit={handleSubmit}
              validationSchema={yup.object().shape({
                // internal info
                medical_records: yup.string(),
              })}
              render={formikProps => (
                <MemberFormInternalInfoComponent {...formikProps} gender={enumerators.gender} />
              )}
            />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default MemberFormInternalInfo;
