import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { i18n } from 'App.js';
import { MemberInvitation } from 'models/MemberInvitation';
import { StyledTable, StyledTableRow } from 'components/Table/StyledTable';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import ContextMenu from 'components/Base/ContextMenu';

class MemberRepresentativesTable extends Component {
  static propTypes = {
    representatives: PropTypes.array,
    openDialog: PropTypes.func.isRequired,
    // createInvitation: PropTypes.func.isRequired,
    editRepresentative: PropTypes.func.isRequired,
    removeRepresentative: PropTypes.func.isRequired,
    editInvitation: PropTypes.func.isRequired,
    isRepresentable: PropTypes.bool,
    invitationsIds: PropTypes.array,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    representatives: [],
    isRepresentable: true,
    invitationsIds: [],
  };

  render() {
    const {
      representatives,
      openDialog,
      // createInvitation,
      editRepresentative,
      removeRepresentative,
      editInvitation,
      isRepresentable,
      invitationsIds,
      managePermissions,
    } = this.props;

    if (!isRepresentable && representatives.length === 0) {
      return null;
    }

    return (
      <div>
        <Typography variant="h5">
          <Trans id="msg.members.representatives" />
        </Typography>

        {representatives.length === 0 && (
          <EmptyContentPlaceholder>
            <Trans id="msg.members.representatives_empty" />
          </EmptyContentPlaceholder>
        )}

        {representatives.length > 0 && (
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans id="msg.user_account" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.members.representatives.name" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.email" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.phone" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.job_title" />
                </TableCell>
                {managePermissions && <TableCell>invitations?</TableCell>}
                {managePermissions && (
                  <TableCell align="center">
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {representatives.map(r => (
                <StyledTableRow key={r.id} coloredBackground={!r.user_id}>
                  <TableCell>
                    {r.user_id ? (
                      <Tooltip title={r.user_id}>
                        <Icon>check_outline</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip title={i18n._('msg.members.user_not_set')}>
                        <Icon>error_outline</Icon>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {r.first_name} {r.last_name}
                  </TableCell>
                  <TableCell>{r.contact_email}</TableCell>
                  <TableCell>{r.phone_number}</TableCell>
                  <TableCell>{r.job_title}</TableCell>
                  {managePermissions && (
                    <TableCell>
                      {!r.user_id &&
                        isRepresentable &&
                        (invitationsIds.indexOf(r.id) === -1 ? (
                          <Button
                            onClick={() => {
                              editInvitation(
                                new MemberInvitation({
                                  email: r.contact_email || '',
                                  type: 'representative',
                                  member_representative_id: r.id,
                                })
                              );
                            }}
                          >
                            <Trans id="msg.members.invitation_send" />
                          </Button>
                        ) : (
                          <Trans id="msg.members.invitation_already_sent" />
                        ))}
                    </TableCell>
                  )}
                  {managePermissions && (
                    <TableCell align="center">
                      <ContextMenu
                        variant="icon"
                        tooltip={i18n._('msg.actions')}
                        color="inherit"
                        icon="more_vert"
                        menuItems={[
                          {
                            text: i18n._('msg.record_update'),
                            action: () => editRepresentative(r),
                          },
                          {
                            text: i18n._('msg.record_delete'),
                            action: () => removeRepresentative(r.id),
                            variant: 'delete',
                          },
                        ]}
                      />
                    </TableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
        {isRepresentable && managePermissions && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              openDialog();
            }}
          >
            <Trans id="msg.members.create_new_representative" />
          </Button>
        )}
      </div>
    );
  }
}

export default MemberRepresentativesTable;
