import { ApiService } from './api.service';
import { apiCall } from '../utils/apiCall';
import { UnlockedMemberInvitation } from '../models/UnlockedMemberInvitation';

/**
 * @static
 */
export class UnlockedMemberInvitationService extends ApiService {
  static endpoint = 'auth/unlocked-member-invitations';

  /**
   * @param {object} data
   * @return {Promise<UnlockedMemberInvitation>}
   * @public
   */
  static create(data) {
    return apiCall.post(UnlockedMemberInvitationService.buildUrl(), data).then(
      response =>
        // console.log('UnlockedMemberInvitation.create: ', { response });
        new UnlockedMemberInvitation(response.data)
    );
  }

  /**
   * @param {int} id
   * @param {object} data
   * @return {Promise<object>}
   * @public
   */
  static consume(id, data) {
    return apiCall
      .put(UnlockedMemberInvitationService.buildUrl(id), data)
      .then(response => response);
  }

  /**
   * @param {object} data
   * @return {Promise<object>}
   * @public
   */
  static renew(data) {
    return apiCall.put(UnlockedMemberInvitationService.buildUrl(), data).then(response => response);
  }

  /**
   * @param {int} id
   * @param {object} data
   * @return {Promise<object>}
   * @public
   */
  static verify(id, data) {
    return apiCall
      .patch(UnlockedMemberInvitationService.buildUrl(id), data)
      .then(response => response.data);
  }
}
