import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';

import Restore from 'components/Dialog/Restore';
import Sorter from 'components/Dialog/Sorter';
import TeamCreateForm from 'components/Management/Teams/TeamCreateForm';
import { Trans } from '@lingui/macro';

const RenderedComponent = ({
  component,
  teams,
  teamsDeleted,
  createTeam,
  requestForNewOrderedRecords,
  setNewRecordsOrder,
  selectedSeasonId,
  seasons,
  restoreRecord,
  loadingDeleted,
  isSubmitting,
  closeDialog,
  handleSubmit,
  teamGroups,
}) => {
  switch (component) {
    case 'deleted':
      return (
        <Restore
          data={teamsDeleted}
          restoreRecord={restoreRecord}
          loadingDeleted={loadingDeleted}
        />
      );
    case 'sortable':
      return (
        <Sorter
          data={teams}
          requestForNewOrderedRecords={requestForNewOrderedRecords}
          setNewRecordsOrder={setNewRecordsOrder}
          isSubmitting={isSubmitting}
          closeDialog={closeDialog}
          handleSubmit={handleSubmit}
        />
      );
    case 'create':
      return (
        <TeamCreateForm
          handleSubmit={createTeam}
          selectedSeasonId={selectedSeasonId}
          seasons={seasons}
          teamGroups={teamGroups}
        />
      );
    default:
      return null;
  }
};

RenderedComponent.propTypes = {
  component: PropTypes.string.isRequired,
  teams: PropTypes.array,
  teamsDeleted: PropTypes.array,
  createTeam: PropTypes.func.isRequired,
  requestForNewOrderedRecords: PropTypes.func.isRequired,
  setNewRecordsOrder: PropTypes.func.isRequired,
  selectedSeasonId: PropTypes.number,
  seasons: PropTypes.array,
  restoreRecord: PropTypes.func.isRequired,
  loadingDeleted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

RenderedComponent.defaultProps = {
  isSubmitting: false,
  teams: [],
  teamsDeleted: [],
  selectedSeasonId: null,
  seasons: [],
};

const dialogTitle = component => {
  switch (component) {
    case 'deleted':
      return <Trans id="msg.teams.popup_title_deleted_teams" />;
    case 'sortable':
      return <Trans id="msg.teams.popup_title_sortable_teams" />;
    case 'create':
      return <Trans id="msg.teams.popup_title_create_team" />;
    default:
      return '';
  }
};

class TeamsDialog extends Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    component: PropTypes.string.isRequired,
    teams: PropTypes.array,
    teamsDeleted: PropTypes.array,
    requestForNewOrderedRecords: PropTypes.func.isRequired,
    setNewRecordsOrder: PropTypes.func.isRequired,
    restoreRecord: PropTypes.func.isRequired,
    loadingDeleted: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    createTeam: PropTypes.func.isRequired,
    selectedSeasonId: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired,
    seasons: PropTypes.array,
    teamGroups: PropTypes.array.isRequired,
  };

  static defaultProps = {
    teams: [],
    teamsDeleted: [],
    loadingDeleted: false,
    isSubmitting: false,
    selectedSeasonId: null,
    seasons: null,
  };

  render() {
    const {
      opened,
      onClose,
      component,
      teams,
      teamsDeleted,
      requestForNewOrderedRecords,
      setNewRecordsOrder,
      restoreRecord,
      loadingDeleted,
      isSubmitting,
      createTeam,
      seasons,
      selectedSeasonId,
      handleSubmit,
      teamGroups,
    } = this.props;

    return (
      <Dialog
        open={opened}
        onClose={onClose}
        // aria-labelledby="form-dialog-title"
      >
        <CustomDialogTitle onClose={onClose}>{dialogTitle(component)}</CustomDialogTitle>
        <DialogContent>
          <RenderedComponent
            component={component}
            teams={teams}
            teamsDeleted={teamsDeleted}
            requestForNewOrderedRecords={requestForNewOrderedRecords}
            setNewRecordsOrder={setNewRecordsOrder}
            restoreRecord={restoreRecord}
            loadingDeleted={loadingDeleted}
            isSubmitting={isSubmitting}
            closeDialog={onClose}
            createTeam={createTeam}
            seasons={seasons}
            selectedSeasonId={selectedSeasonId}
            handleSubmit={handleSubmit}
            teamGroups={teamGroups}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default TeamsDialog;
