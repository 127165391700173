import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import routes from 'Routes.js';
import HallUpdateForm from 'components/Management/Halls/HallUpdateForm';
import { HallManagementService } from 'api/hall.service';
import { reverse } from 'named-urls';
import { withSnackbar } from 'notistack';
import { i18n } from 'App.js';
import { Hall } from 'models/Hall';
import PageHeader from 'components/PageHeader';

class HallCreate extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    uploadedImageId: null,
    newCreatedHallId: null,
  };

  requestedImageSize = {
    width: 1280,
    height: 450,
  };

  acceptUploadedImageId = uploadedImageId => {
    this.setState({
      uploadedImageId,
    });
  };

  createHall = async (values, actions) => {
    const { uploadedImageId } = this.state;
    const { enqueueSnackbar } = this.props;

    const formattedData = Hall.formatFormData({ ...values, image_id: uploadedImageId });
    await HallManagementService.create(formattedData)
      .then(response => {
        actions.setSubmitting(false);
        this.setState({ newCreatedHallId: response.id }, () => {
          enqueueSnackbar(i18n._('msg.halls.create_success'), { variant: 'success' });
        });
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  render() {
    const { newCreatedHallId } = this.state;
    const { enqueueSnackbar } = this.props;

    if (newCreatedHallId) {
      return (
        <Redirect
          to={reverse(routes.management.halls.single.update, { hallId: newCreatedHallId })}
        />
      );
    }
    return (
      <div>
        <PageHeader
          title={i18n._('msg.halls.create_title')}
          breadcrumbsItems={[
            {
              url: routes.management.halls.all,
              // title: 'some title',
              text: i18n._('msg.halls.page_title'),
            },
            { url: null, text: i18n._('msg.halls.create_title') },
          ]}
        />

        <HallUpdateForm
          updateHall={this.createHall}
          requestedImageSize={this.requestedImageSize}
          acceptUploadedImageId={this.acceptUploadedImageId}
          enqueueSnackbar={enqueueSnackbar}
        />
      </div>
    );
  }
}

export default withSnackbar(HallCreate);
