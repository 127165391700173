export class Address {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.city = data.city || '';
    this.street = data.street || '';
    this.postal_code = data.postal_code || '';
  }
}
