import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';
import StandaloneCardTitle from './StandaloneCardTitle';

const StandaloneCardBackground = styled.div`
  background: ${props => props.theme.club.secondary_bg_color};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

const StandaloneCardComponent = styled(Card)`
  width: 90%;
  max-width: 350px;

  && {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 48px);
  }
`;

const StandaloneCardTitleWrapper = styled(CardContent)`
  border-bottom: 1px solid #ccc;
`;

class StandaloneCard extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]).isRequired,
  };

  render() {
    const { title, children } = this.props;
    return (
      <StandaloneCardBackground>
        <StandaloneCardComponent>
          <StandaloneCardTitleWrapper>
            <StandaloneCardTitle>{title}</StandaloneCardTitle>
          </StandaloneCardTitleWrapper>
          {children}
        </StandaloneCardComponent>
      </StandaloneCardBackground>
    );
  }
}

export default StandaloneCard;
