import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core/es';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';

const MemberTypeFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  closeDialog,
  memberType,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          type="text"
          name="name"
          label={i18n._('msg.member_types.member_type_name_label')}
          required
          component={EosTextInput}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
          {isSubmitting ? (
            <Trans id="msg.saving_changes" />
          ) : memberType ? (
            <Trans id="msg.save_changes" />
          ) : (
            <Trans id="msg.member_types.add_new" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

MemberTypeFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  memberType: PropTypes.object,
};

MemberTypeFormComponent.defaultProps = {
  isSubmitting: false,
  memberType: null,
};

class MemberTypeForm extends Component {
  validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
  });

  initialValues = {
    name: '',
  };

  render() {
    const { memberType, closeDialog, handleSubmit } = this.props;
    return (
      <div>
        <Formik
          initialValues={memberType || this.initialValues}
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <MemberTypeFormComponent
              {...formikProps}
              closeDialog={closeDialog}
              memberType={memberType}
            />
          )}
        />
      </div>
    );
  }
}

MemberTypeForm.propTypes = {
  memberType: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

MemberTypeForm.defaultProps = {
  memberType: null,
};

export default MemberTypeForm;
