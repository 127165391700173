import { Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';

const StyledForm = styled(Form)``;

StyledForm.propTypes = {
  submitting: PropTypes.number.isRequired,
};

StyledForm.defaultProps = {
  submitting: 0,
};

const SubmitAreaWrapper = styled.div`
  position: relative;
  padding: 20px 0;

  & > div {
    position: absolute;
    // bottom: calc(100% + 15px);
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const SubmitAreaComponent = ({ isSubmitting, children, className }) => (
  <SubmitAreaWrapper className={className}>
    {isSubmitting && <LinearProgress />}
    {children}
  </SubmitAreaWrapper>
);
SubmitAreaComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  className: PropTypes.string,
};
SubmitAreaComponent.defaultProps = {
  isSubmitting: false,
  className: '',
};

const SubmitArea = styled(SubmitAreaComponent)`
  text-align: ${props => props.align || 'left'};
  padding-bottom: ${props => ((props.variant === 'dialog' || props.noBottomPadding) ? 0 : '20px')};
  width: 100%;
  margin: ${props => (props.sorter ? '0 -12px -12px 12px' : 0)};
`;

SubmitArea.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  isSubmitting: PropTypes.bool,
  sorter: PropTypes.bool,
};

SubmitArea.defaultProps = {
  align: 'center',
  isSubmitting: false,
  sorter: false,
};

const EosForm = ({ submitting, children, ...props }) => (
  <StyledForm submitting={submitting ? 1 : 0} {...props}>
    {children}
  </StyledForm>
);

EosForm.propTypes = {
  submitting: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

EosForm.defaultProps = {
  submitting: false,
};

export default EosForm;

export { SubmitArea };
