import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const AppliedRoute = ({ component: C, props: childProps, ...rest }) => (
  <Route {...rest} render={props => <C {...props} {...childProps} />} />
);
AppliedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  props: PropTypes.object.isRequired,
};
export default AppliedRoute;
