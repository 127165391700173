import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import { Trans } from '@lingui/macro';

class MembersBulkActions extends Component {
  static propTypes = {};

  state = {
    bulkAction: '',
  };

  render() {
    const { bulkAction } = this.state;

    return (
      <div>
        <FormControl fullWidth>
          <Select
            value={bulkAction || ''}
            onChange={e => {
              console.log(e.target.value);
              this.setState({ bulkAction: e.target.value });
            }}
            displayEmpty
            input={<Input id="select_bulk_action" name="select_bulk_action" />}
          >
            <MenuItem value="" disabled>
              <Trans id="msg.members.bulk_actions" />
            </MenuItem>
            {/* TODO: add real values */}
            {[{ label: 'mark as read', value: '1' }, { label: 'mark as paid', value: '2' }].map(
              option => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemText primary={option.label} />
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default MembersBulkActions;
