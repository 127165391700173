import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
// import { TeamsSeasonsList } from 'components/Management/Teams/TeamsSeasonsList';
import SeasonsList from 'components/Management/Shared/SeasonsList';

export class TeamsImport extends Component {
  static propTypes = {
    seasons: PropTypes.array.isRequired,
    handleImport: PropTypes.func.isRequired,
  };

  changeSeasonImportFrom = evt => {
    this.setState({
      seasonImportFrom: evt.target.value,
    });
  };

  handleImport = () => {
    const { handleImport } = this.props;
    const { seasonImportFrom } = this.state;
    handleImport(seasonImportFrom);
  };

  componentDidMount = () => {
    const { seasons } = this.props;
    this.setState({
      seasonImportFrom: seasons[0].id,
    });
  };

  render() {
    const { seasons } = this.props;
    if (seasons.length) {
      return (
        <Card style={{ marginTop: '24px' }}>
          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              <Trans id="msg.teams.import_no_teams" />
            </Typography>
            <Grid container spacing={24} alignItems="center">
              <Grid item md="auto">
                <SeasonsList
                  seasons={seasons}
                  handleChange={this.changeSeasonImportFrom}
                  selectedSeason={seasons[0].id}
                />
              </Grid>
              <Grid item md="auto">
                <Button variant="contained" color="primary" onClick={this.handleImport}>
                  <Trans id="msg.teams.import_teams" />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }

    return null;
  }
}

export default TeamsImport;
