import { apiCall } from '../utils/apiCall';
import { ApiService } from './api.service';

/**
 * @static
 */
export class EnumeratorService extends ApiService {
  static endpoint = 'enumerators';

  /**
   * @param {string} enumerator
   * @return {Promise<object>}
   * @public
   */
  static async single(enumerator) {
    return apiCall
      .get(EnumeratorService.buildUrl(enumerator))
      .then(response => response.data[enumerator]);
  }

  /**
   * @param {string[]} enumerators
   * @return {DataGridUrlBuilder}
   * @public
   */
  static multiple(enumerators) {
    return apiCall
      .get(EnumeratorService.buildUrl(), { params: { type: enumerators.join(',') } })
      .then(response => response.data);
  }
}
