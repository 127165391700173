import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialDatatable from 'components/MaterialDataTable/src/MaterialDatatable';
import TextFilter from 'components/Filters/TextFilter';
import SelectFilter from 'components/Filters/SelectFilter';
import CustomizedLink from 'components/Base/Link';
import ColorMessage from 'components/Base/ColorMessage';
import styled from 'styled-components';
import routes from 'Routes.js';
import { reverse } from 'named-urls';
import { possibleInsuranceCompanies } from 'data/staticDataConstants';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';
import MembersBulkActions from './MembersBulkActions';

const StyledDate = styled.div`
  text-align: right;
`;
const FormattedDate = ({ children }) => <StyledDate>{children}</StyledDate>;
FormattedDate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

class MembersTable extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    tableState: PropTypes.object.isRequired,
    deactivateMember: PropTypes.func.isRequired,
    fetchAllMembers: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const {
      tableState: { page, rowsPerPage, sortBy, customFilters, count, data },
      loading,
      deactivateMember,
      fetchAllMembers,
      managePermissions,
    } = this.props;
    return (
      <div>
        <MaterialDatatable
          title={i18n._('msg.members.subtitle')}
          columns={[
            {
              field: 'id',
              name: i18n._('msg.id'),
              options: {
                filter: false,
                display: 'true',
              },
            },
            {
              field: 'last_name,first_name',
              name: i18n._('msg.members.name'),
              options: {
                download: false,
                filterComponent: {
                  component: TextFilter,
                  type: 'text',
                  // value: 'xyz',
                  // selectedMethod: 'starts_with',
                },
                customBodyRender: value => (
                  <CustomizedLink
                    value={`${value.last_name} ${value.first_name}`}
                    to={reverse(routes.management.members.single.detail, { memberId: value.id })}
                    noWrap
                    bold
                  >
                    {value.last_name} {value.first_name}
                  </CustomizedLink>
                ),
              },
            },
            {
              field: 'name_plaintext',
              name: i18n._('msg.members.name'),
              options: {
                download: true,
                filter: false,
                display: 'excluded',
                customBodyRender: value => `${value.first_name} ${value.last_name}`,
              },
            },
            {
              field: 'first_name',
              name: i18n._('msg.members.first_name'),
              options: {
                filter: true,
                display: 'false',
              },
            },
            {
              field: 'last_name',
              name: i18n._('msg.members.last_name'),
              options: {
                filter: true,
                display: 'false',
              },
            },
            {
              field: 'birthdate',
              name: i18n._('msg.members.date_of_birth'),
              options: {
                customBodyRender: value => (
                  <FormattedDate value={value.birthdate.format('YYYY-MM-DD').toString()}>
                    {value.birthdate.format('D. M. YYYY')}
                  </FormattedDate>
                ),
                filter: true,
                download: false,
              },
            },
            {
              field: 'birthdate_print',
              name: i18n._('msg.members.date_of_birth'),
              options: {
                customBodyRender: value => `${value.birthdate.format('D. M. YYYY')}`,
                filter: false,
                display: 'excluded',
                download: true,
              },
            },
            {
              field: 'birthnumber',
              name: i18n._('msg.members.birth_number'),
              options: {
                display: 'false',
                filter: false,
                sort: false,
              },
            },
            {
              field: 'gender',
              name: i18n._('msg.members.gender'),
              options: {
                display: 'false',
              },
            },
            {
              field: 'permissions',
              name: i18n._('msg.permissions'),
              options: {
                customBodyRender: value =>
                  value.user ? (
                    <ColorMessage color={value.user.admin_role_id ? 'success' : 'error'}>
                      {value.user.admin_role_id ? value.user.admin_role_id : 'unknown'}
                    </ColorMessage>
                  ) : (
                    '-'
                  ),
              },
            },
            {
              field: 'membership',
              name: i18n._('msg.members.membership_type'),
              options: {
                customBodyRender: value =>
                  value.user ? (
                    <ColorMessage color={value.user.admin_role_id ? 'success' : 'warning'}>
                      {value.user.admin_role_id ? value.user.admin_role_id : 'unknown'}
                    </ColorMessage>
                  ) : (
                    '-'
                  ),
              },
            },
            {
              field: 'list',
              name: i18n._('msg.members.current_list'),
              options: {
                customBodyRender: value =>
                  value.user ? (
                    <ColorMessage color={value.user.admin_role_id ? 'success' : 'success'}>
                      {value.user.admin_role_id ? value.user.admin_role_id : 'unknown'}
                    </ColorMessage>
                  ) : (
                    '-'
                  ),
              },
            },
            {
              field: 'medical_check',
              name: i18n._('msg.members.medical_check'),
              options: {
                customBodyRender: value => (value.user ? `${value.user.admin_role_id}` : '-'),
              },
            },
            {
              field: 'insurance_company',
              name: i18n._('msg.members.insurance_company'),
              options: {
                sort: false,
                filterComponent: {
                  component: SelectFilter,
                  type: 'select',
                  options: possibleInsuranceCompanies,
                  // value: ['111'],
                },
              },
            },
            {
              field: 'fis',
              name: i18n._('msg.fis'),
              options: {
                sort: false,
                download: false,
                customBodyRender: value => {
                  if (!value.user) {
                    return '-';
                  }

                  return value.user.admin_role_id === 1 ? (
                    <CustomizedLink to={`/404/${value.id}`}>FIS</CustomizedLink>
                  ) : (
                    '---'
                  );
                },
              },
            },
            {
              field: 'phone_number',
              name: i18n._('msg.phone_number'),
              options: {
                sort: false,
                filterComponent: {
                  component: TextFilter,
                  type: 'number',
                  // value: 123,
                  // selectedMethod: 'lt',
                  // methods: ['lt', 'lte'],
                },
                customBodyRender: value => (
                  <CustomizedLink to={`tel:${value.phone_id}`}>{value.phone_number}</CustomizedLink>
                ),
              },
            },
            {
              field: 'email',
              name: i18n._('msg.email_address'),
              options: {
                sort: false,
                customBodyRender: value =>
                  value.user ? (
                    <CustomizedLink
                      to={`mailto:${value.user.email}`}
                      title={value.user.email}
                      noWrap
                    >
                      {value.user.email}
                    </CustomizedLink>
                  ) : (
                    '-'
                  ),
              },
            },
            {
              field: 'address_street',
              name: i18n._('msg.address'),
              options: {
                display: 'false',
                sort: false,
                customBodyRender: value => `${value.address.street}`,
              },
            },
            {
              field: 'address_city',
              name: i18n._('msg.address.city'),
              options: {
                display: 'false',
                sort: false,
                customBodyRender: value => `${value.address.city}`,
              },
            },
            {
              field: 'address_postal_code',
              name: i18n._('msg.address.postal_code'),
              options: {
                display: 'false',
                sort: false,
                customBodyRender: value => `${value.address.postal_code}`,
              },
            },
            {
              field: 'registration_date',
              name: i18n._('msg.members.date_of_registration'),
              options: {
                display: 'false',
                sort: false,
                customBodyRender: value => `${value.created_at}`,
              },
            },
            {
              field: 'action',
              name: i18n._('msg.actions'),
              options: {
                sort: false,
                download: false,
                customBodyRender: member => (
                  <ContextMenu
                    variant="icon"
                    tooltip={i18n._('msg.actions')}
                    color="inherit"
                    icon="more_vert"
                    menuItems={[
                      {
                        text: i18n._('msg.members.profile'),
                        url: reverse(routes.management.members.single.detail, {
                          memberId: member.id,
                        }),
                      },
                      {
                        text: i18n._('msg.members.account'),
                        url: reverse(routes.management.members.single.detail, {
                          memberId: member.id,
                        }),
                      },
                      managePermissions && {
                        text: i18n._('msg.members.deactivate'),
                        action: () => deactivateMember(member.id),
                        variant: 'delete',
                      },
                    ]}
                  />
                ),
              },
            },
          ]}
          data={data}
          options={{
            loading,
            pagination: true,
            rowsPerPageOptions: [5, 10, 20, 50],
            rowsPerPage,
            customFilters,
            page,
            count,
            sortBy,
            // onChangePage: currentPage => console.log({ currentPage }),
            // onChangeRowsPerPage: numOfRows => console.log({ numOfRows }),
            // onSearchChange: search => console.log({ search }),
            // onFilterChange: (column, data) => console.log({ column, data }),
            // onColumnSortChange: (column, direction) => console.log({ column, direction }),
            // onColumnViewChange: (column, action) => console.log({ column, action }),
            customFooterComponent: {
              component: <MembersBulkActions />,
              // onChange: () => {
              //   console.log('customBulkAction changed...');
              // },
            },
            // customFooter: (rowCount, page, rowsPerPage, changeRowsPerPage, changePage) => (
            //   <React.Fragment>
            //     <div>aaa</div>
            //     {/* <MaterialDatatablePagination
            //       count={rowCount}
            //       page={page}
            //       rowsPerPage={rowsPerPage}
            //       changeRowsPerPage={changeRowsPerPage}
            //       changePage={changePage}
            //       component={"div"}
            //     // options={options}
            //     /> */}
            //   </React.Fragment>
            // ),
            search: false,
            filter: false,
            responsive: 'scroll',
            serverSide: true,
            onTableChange: (action, tableState) => {
              console.log({ action, tableState });
              if (action !== 'rowsSelect' && action !== 'search' && action !== 'columnViewChange') {
                fetchAllMembers(tableState);
              }

              if (action === 'sort') {
                console.log({ sortBy: tableState.sortBy });
              }

              if (action === 'columnViewChange') {
                console.log('Currently visible columns are:');
                const visibleColumns = [];
                tableState.columns
                  .filter(c => c.display === 'true')
                  // TODO: refactor
                  // eslint-disable-next-line
                  .map(v => {
                    visibleColumns.push(v.field);
                  });
                console.log(visibleColumns.join(', '));
              }

              if (action === 'rowsSelect') {
                console.log("You have selected members with following ID's:");
                console.log('---');
                // TODO: refactor
                // eslint-disable-next-line
                tableState.selectedRows.data.map(selection => {
                  console.log(
                    tableState.data.filter(row => row.index === selection.index)[0].data.id
                  );
                });
                console.log('---');
              }
            },
          }}
        />
      </div>
    );
  }
}

export default MembersTable;
