import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { CardContent, Grid, Typography } from '@material-ui/core';
import ErrorMessage from 'components/Forms/ErrorMessage';
import SubmitButton from 'components/Forms/SubmitButton';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import EosTextInput from 'components/Forms/EosTextInput';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { pin as pinRegExp } from 'utils/regexp';

const InvitationStep1FormComponent = ({ isSubmitting, errors }) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          name="pin_code"
          label={i18n._('msg.invitations.pin_code_label')}
          component={EosTextInput}
          inputProps={{
            maxLength: 4,
            style: { textAlign: 'center' },
          }}
          placeholder="1234"
          required
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="center" noBottomPadding>
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        <Trans id="msg.confirm" />
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

InvitationStep1FormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
};

InvitationStep1FormComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
};

const InvitationExpiredContentDefault = ({ requestNewInvitation, invitationData }) => (
  <Typography component="div">
    <p>
      <Trans id="msg.invitations.invitation_expired" />
    </p>
    <p>
      <Link
        to="#"
        onClick={evt => {
          evt.preventDefault();
          requestNewInvitation(invitationData);
        }}
      >
        <Trans id="msg.invitations.request_new" />
      </Link>
    </p>
  </Typography>
);
InvitationExpiredContentDefault.propTypes = {
  requestNewInvitation: PropTypes.func.isRequired,
  invitationData: PropTypes.object.isRequired,
};

const InvitationExpiredContentRenewed = () => (
  <Typography component="div">
    <p>
      <Trans id="msg.invitations.invitation_renewed_success" />
    </p>
  </Typography>
);

const InvitationExpiredContentFailed = message => (
  <Typography component="div">
    <p>
      <Trans id="msg.invitations.invitation_renew_failed" />
    </p>
    <ErrorMessage>{message.message}</ErrorMessage>
  </Typography>
);

class InvitationStep1 extends Component {
  validationSchema = yup.object().shape({
    pin_code: yup
      .string()
      .matches(pinRegExp, i18n._('msg.form.pin_code_format'))
      // .min(4, i18n._('msg.form.min_length', { min_length: 4 }))
      // .max(4, i18n._('msg.form.max_length', { max_length: 4 }))
      .required(i18n._('msg.form.required')),
  });

  render() {
    const {
      handleSubmit,
      invitationExpired,
      invitationData,
      invitationRenewed,
      invitationRenewError,
      requestNewInvitation,
    } = this.props;

    return (
      <CardContent>
        {!invitationExpired && (
          <Formik
            initialValues={{
              pin_code: '',
            }}
            validationSchema={this.validationSchema}
            onSubmit={handleSubmit}
            render={formikProps => <InvitationStep1FormComponent {...formikProps} />}
          />
        )}
        {/* default expired invitation content variant  */}
        {invitationExpired && typeof invitationRenewed === 'object' && (
          <InvitationExpiredContentDefault
            requestNewInvitation={requestNewInvitation}
            invitationData={invitationData}
          />
        )}

        {/* successfully renewed invitation content variant  */}
        {invitationExpired && typeof invitationRenewed === 'boolean' && invitationRenewed && (
          <InvitationExpiredContentRenewed />
        )}

        {/* invitation can't be renewed content variant  */}
        {invitationExpired && typeof invitationRenewed === 'boolean' && !invitationRenewed && (
          <InvitationExpiredContentFailed message={invitationRenewError} />
        )}
      </CardContent>
    );
  }
}

InvitationStep1.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invitationExpired: PropTypes.bool,
  invitationData: PropTypes.object.isRequired,
  invitationRenewed: PropTypes.bool,
  invitationRenewError: PropTypes.string,
  requestNewInvitation: PropTypes.func,
};

InvitationStep1.defaultProps = {
  invitationExpired: false,
  requestNewInvitation: null,
  invitationRenewed: null,
  invitationRenewError: null,
};

export default InvitationStep1;
