import React, { Component } from 'react';
import { AuthConsumer } from 'AuthContext.js';
import ResetPasswordComponent from 'components/ResetPassword/ResetPasswordComponent';

class ResetPassword extends Component {
  static propTypes = {};

  state = {
    resetPasswordDone: false,
  };

  handleSubmit = (values, actions) => {
    console.log(`TODO: reset password for member with given email: ${values.email}`, { values });
    setTimeout(() => {
      actions.setSubmitting(false);
      this.setState({
        resetPasswordDone: true,
      });
    }, 1000);
  };

  render() {
    const { resetPasswordDone } = this.state;
    return (
      <AuthConsumer>
        {({ logout }) => {
          logout();
          return (
            <ResetPasswordComponent
              handleSubmit={this.handleSubmit}
              resetPasswordDone={resetPasswordDone}
            />
          );
        }}
      </AuthConsumer>
    );
  }
}

export default ResetPassword;
