import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import RosterUpdateForm from './RosterUpdateForm';

class RosterDialog extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    manageMemberVisibility: PropTypes.bool,
    updateMemberRoster: PropTypes.func.isRequired,
    trainerRoles: PropTypes.array,
  };

  static defaultProps = {
    opened: false,
    onClose: null,
    manageMemberVisibility: true,
    trainerRoles: [],
  };

  render() {
    const {
      opened,
      onClose,
      member,
      manageMemberVisibility,
      updateMemberRoster,
      trainerRoles,
    } = this.props;

    return (
      <Dialog open={opened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {member.first_name} {member.last_name}
        </CustomDialogTitle>
        <DialogContent>
          <RosterUpdateForm
            member={member}
            trainerRoles={trainerRoles}
            manageMemberVisibility={manageMemberVisibility}
            updateMemberRoster={updateMemberRoster}
            onClose={onClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default RosterDialog;
