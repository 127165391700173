import React from 'react';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GeneralFilter from './GeneralFilter';

class TextFilter extends GeneralFilter {
  // constructor() {
  //   super();
  // }

  state = {
    timer: null,
  };

  setTimer = (field, value) => {
    this.clearTimer();
    this.timer = setTimeout(() => {
      if (this.state.value !== value) {
        this.handleChange(field, value);
        console.log({ field, value });
      }
    }, 200);
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  render() {
    const { field, value, type } = this.props;

    const Switcher = this.methodSwitcher;

    return (
      <Grid container wrap="nowrap">
        <Grid item>
          <Switcher />
        </Grid>
        <Grid item>
          <TextField
            type={type}
            name={field}
            defaultValue={value}
            // onChange={e => this.handleChange(field, e.target.value)}
            // onKeyUp={e => this.setTimer(field, e.target.value)}
            onChange={e => this.setTimer(field, e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default TextFilter;
