import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import MemberRepresentativeForm from './MemberRepresentativeForm';

class MemberRepresentativeDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    representative: PropTypes.object,
  };

  static defaultProps = {
    dialogOpened: false,
    representative: null,
  };

  render() {
    const { dialogOpened, onClose, handleSubmit, representative } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {representative ? (
            <Trans id="msg.members.representative.edit_representative_title" />
          ) : (
            <Trans id="msg.members.representative.create_new_representative_title" />
          )}
        </CustomDialogTitle>
        <DialogContent>
          <MemberRepresentativeForm
            handleSubmit={handleSubmit}
            closeDialog={onClose}
            representative={representative}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default MemberRepresentativeDialog;
