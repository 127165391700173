import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Field, Formik } from 'formik';
import { AuthConsumer } from 'AuthContext.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Grid, Typography } from '@material-ui/core';
import EosTextInput from 'components/Forms/EosTextInput';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import ErrorMessage from 'components/Forms/ErrorMessage';

const MemberFormContactInfoComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  // resetForm,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} lg={6}>
        <Field
          type="email"
          name="user.email"
          label={i18n._('msg.email_address')}
          InputProps={{
            readOnly: true,
          }}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field
          type="text"
          name="phone_number"
          label={i18n._('msg.phone_number')}
          component={EosTextInput}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <Trans id="msg.address" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="address.street"
              label={i18n._('msg.address.street')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="address.postal_code"
              label={i18n._('msg.address.postal_code')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="address.city"
              label={i18n._('msg.address.city')}
              component={EosTextInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h2">{i18n._('msg.address_contact')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="contact_address.street"
              label={i18n._('msg.address.street')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="contact_address.postal_code"
              label={i18n._('msg.address.postal_code')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="contact_address.city"
              label={i18n._('msg.address.city')}
              component={EosTextInput}
            />
          </Grid>
        </Grid>
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="left">
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

MemberFormContactInfoComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
};

MemberFormContactInfoComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
};

class MemberFormContactInfo extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    memberPartialData: null,
  };

  componentDidMount() {
    const {
      // eslint-disable-next-line
      member: { phone_number, user, address, contact_address },
    } = this.props;

    const memberPartialData = {
      phone_number,
      address,
      contact_address,
      user: { email: user ? user.email : '' },
    };

    this.setState({
      memberPartialData,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { memberPartialData } = this.state;

    if (!memberPartialData) {
      return null;
    }

    return (
      <AuthConsumer>
        {({ enumerators }) => (
          <div>
            <Formik
              initialValues={memberPartialData}
              onSubmit={handleSubmit}
              validationSchema={yup.object().shape({
                // contact:
                user: yup.object().shape({
                  email: yup
                    .string()
                    .email(i18n._('msg.form.invalid_email'))
                    .required(i18n._('msg.form.required')),
                }),
                phone_number: yup
                  .string()
                  .phone(i18n._('msg.form.invalid_format'))
                  .required(i18n._('msg.form.required')),
                address: yup.object().shape(
                  {
                    city: yup
                      .string()
                      .nullable(true)
                      .when(['postal_code', 'street'], {
                        // eslint-disable-next-line
                        is: (postal_code, street) => postal_code !== '' || street !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                    postal_code: yup
                      .string()
                      .nullable(true)
                      .when(['city', 'street'], {
                        is: (city, street) => city !== '' || street !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                    street: yup
                      .string()
                      .nullable(true)
                      .when(['city', 'postal_code'], {
                        // eslint-disable-next-line
                        is: (city, postal_code) => city !== '' || postal_code !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                  },
                  [['city', 'postal_code'], ['city', 'street'], ['postal_code', 'street']]
                ),
                contact_address: yup.object().shape(
                  {
                    city: yup
                      .string()
                      .nullable(true)
                      .when(['postal_code', 'street'], {
                        // eslint-disable-next-line
                        is: (postal_code, street) => postal_code !== '' || street !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                    postal_code: yup
                      .string()
                      .nullable(true)
                      .when(['city', 'street'], {
                        is: (city, street) => city !== '' || street !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                    street: yup
                      .string()
                      .nullable(true)
                      .when(['city', 'postal_code'], {
                        // eslint-disable-next-line
                        is: (city, postal_code) => city !== '' || postal_code !== '',
                        then: s => s.required(i18n._('msg.form.required')),
                      }),
                  },
                  [['city', 'postal_code'], ['city', 'street'], ['postal_code', 'street']]
                ),
              })}
              render={formikProps => (
                <MemberFormContactInfoComponent {...formikProps} gender={enumerators.gender} />
              )}
            />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default MemberFormContactInfo;
