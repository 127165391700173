import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Typography, Grid } from '@material-ui/core';
// import * as moment from 'moment';
import { Trans } from '@lingui/macro';

class WhispererItem extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    // rosterType: PropTypes.string.isRequired,
    whispererItemAction: PropTypes.func.isRequired,
    recordType: PropTypes.string.isRequired,
    usage: PropTypes.string.isRequired,
  };

  render() {
    const { record, whispererItemAction, /* rosterType, */ recordType, usage } = this.props;

    // TODO: replace with props variable
    const rosterType = null;

    const isPlayer = typeof rosterType === 'undefined' ? null : rosterType === 'player';
    const isOrganizer = typeof rosterType === 'undefined' ? null : rosterType === 'organizer';

    return (
      <div style={{ padding: '6px 0', borderBottom: '1px solid #dedede' }}>
        <Grid container alignItems="center">
          <Grid item>
            <Icon>{recordType === 'members' ? 'person' : 'control_point'}</Icon>
          </Grid>
          <Grid item style={{ flex: 1, padding: '0 18px' }}>
            {recordType === 'members' ? (
              <Typography variant="body1">
                <span>
                  {record.last_name} {record.first_name}
                </span>
                {/* {!!record.user && (
                <span style={{ marginLeft: '16px' }}>{record.user.email || ' - '}</span>
              )} */}
                <span style={{ marginLeft: '16px' }}>{record.contact_email || '-'}</span>
                {record.birthdate && (
                  <span style={{ marginLeft: '16px' }}>
                    {record.birthdate.format('D. M. YYYY')}
                  </span>
                )}
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                if (usage === 'roster') {
                  whispererItemAction(record.id, isPlayer, isOrganizer);
                } else {
                  whispererItemAction(record.id);
                }
              }}
            >
              <Trans id="msg.add" />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WhispererItem;
