import React, { Component } from 'react';
import { SeasonManagementService } from 'api/season.service';
import SeasonsTable from 'components/Management/Settings/Seasons/SeasonsTable';
import { Season } from 'models/Season';
import { i18n } from 'App.js';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import SeasonDialog from 'components/Management/Settings/Seasons/SeasonDialog';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import routes from 'Routes.js';
import ability from 'ability';

class Seasons extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    seasons: null,
    season: null,
    loading: false,
    dialogOpened: false,
  };

  componentDidMount = () => {
    this.fetchSeasons();
  };

  editSeason = season => {
    this.setState({
      season,
      dialogOpened: true,
    });
  };

  makeSeasonActive = async season => this.updateSeason(season.id, { ...season, is_active: true });

  deleteSeason = seasonId => {
    const { enqueueSnackbar } = this.props;

    SeasonManagementService.delete(seasonId).then(() => {
      const { seasons } = this.state;

      const deletedSeasonIndexInSeasons = seasons.findIndex(s => s.id === seasonId);

      // eslint-disable-next-line
      seasons.splice(deletedSeasonIndexInSeasons, 1);

      this.setState({
        seasons,
      });
      enqueueSnackbar(i18n._('msg.seasons.delete_success'), { variant: 'info' });
    });
  };

  showForm = () => {
    this.setState({ dialogOpened: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpened: false, season: null });
  };

  fetchSeasons = async () => {
    const { enqueueSnackbar } = this.props;

    this.setState({ loading: true });
    const seasons = await SeasonManagementService.getAll()
      .sortBy('-id')
      .with('is_constrained')
      .turnOffPagination()
      .fetch();
    this.setState(
      {
        seasons,
        season: null,
        loading: false,
      },
      () => {
        enqueueSnackbar(i18n._('msg.seasons.loading_success'), { variant: 'success' });
      }
    );
  };

  createSeason = async data => {
    const { enqueueSnackbar } = this.props;

    const formattedData = Season.formatFormData(data);
    await SeasonManagementService.create(formattedData).then(response => {
      let { seasons } = this.state;

      // if new created season is active, deactivate all previous seasons
      // (in state only, not on backend)
      if (response.is_active) {
        seasons = seasons.map(s => ({
          ...s,
          is_active: false,
        }));
      }

      this.setState(
        {
          seasons: [response, ...seasons],
        },
        () => {
          enqueueSnackbar(i18n._('msg.seasons.create_success'), { variant: 'success' });
        }
      );
    });
  };

  updateSeason = async (seasonId, values) => {
    const { enqueueSnackbar } = this.props;

    const formattedData = Season.formatFormData(values);
    await SeasonManagementService.update(seasonId, formattedData).then(response => {
      let { seasons } = this.state;

      const updatedSeasonIndexInSeasons = seasons.findIndex(p => p.id === seasonId);
      const seasonActiveFlagToggle =
        seasons[updatedSeasonIndexInSeasons].is_active !== response.is_active;

      if (seasonActiveFlagToggle) {
        // if current updated season changed is_active flag
        // it means that current updated season is active, because active season can't be unset
        // set all flags to inactive...
        seasons = seasons.map(s => ({
          ...s,
          is_active: false,
        }));
      }

      // ...and update array item with returned data
      seasons[updatedSeasonIndexInSeasons] = response;

      this.setState(
        {
          seasons,
        },
        () => {
          enqueueSnackbar(i18n._('msg.seasons.update_success'), { variant: 'success' });
        }
      );
    });
  };

  operationCallback = (promise, actions) =>
    promise
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });

  handleSubmit = (values, actions) => {
    const { season } = this.state;
    const promise = season ? this.updateSeason(season.id, values) : this.createSeason(values);

    this.operationCallback(promise, actions);
  };

  render() {
    const { seasons, loading, dialogOpened, season } = this.state;
    const { accessLevel } = this.props;
    const data = seasons || [];

    return (
      <div>
        <PageHeader
          title={i18n._('msg.seasons.page_title')}
          customControlsPrimary={[
            ability.can('manage', {
              __type: 'Seasons',
              level: accessLevel,
            }) && <FabButton variant="round" size="small" onClick={this.showForm} />,
          ]}
          breadcrumbsItems={[
            {
              url: routes.management.settings.all,
              // title: 'some title',
              text: i18n._('msg.settings.page_title'),
            },
            { url: null, text: i18n._('msg.seasons.page_title') },
          ]}
        />

        <SeasonsTable
          seasons={data}
          loading={loading}
          editSeason={this.editSeason}
          deleteSeason={this.deleteSeason}
          makeSeasonActive={this.makeSeasonActive}
          managePermissions={ability.can('manage', {
            __type: 'Seasons',
            level: accessLevel,
          })}
        />

        <SeasonDialog
          dialogOpened={dialogOpened}
          onClose={this.closeDialog}
          handleSubmit={this.handleSubmit}
          season={season}
        />
      </div>
    );
  }
}

export default withSnackbar(Seasons);
