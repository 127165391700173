/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ArrowDropDown } from '@material-ui/icons';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  input: {
    display: 'flex',
    padding: '5px 0 4px 14px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 4}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 14,
    fontSize: 16,
  },
  menu: {
    // marginTop: theme.spacing.unit,
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const NoOptionsMessage = props => {
  const {
    children,
    innerProps,
    selectProps: {
      classes: { noOptionsMessage },
    },
  } = props;
  return (
    <Typography color="textSecondary" className={noOptionsMessage} {...innerProps}>
      {children}
    </Typography>
  );
};
NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;
inputComponent.propTypes = {
  inputRef: PropTypes.func,
};

const Control = props => {
  const {
    selectProps: {
      classes: { input },
      textFieldProps,
    },
    innerRef,
    children,
    innerProps,
  } = props;
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...textFieldProps}
    />
  );
};

Control.propTypes = {
  innerRef: PropTypes.func,
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const Option = props => {
  const { innerRef, innerProps, children, isFocused, isSelected } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

Option.propTypes = {
  innerRef: PropTypes.func,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const Placeholder = props => {
  const {
    selectProps: {
      classes: { placeholder },
    },
    innerProps,
    children,
  } = props;
  return (
    <Typography color="textSecondary" className={placeholder} {...innerProps}>
      {children}
    </Typography>
  );
};
Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const SingleValue = props => {
  const {
    innerProps,
    selectProps: {
      classes: { singleValue },
    },
    children,
  } = props;
  return (
    <Typography className={singleValue} {...innerProps}>
      {children}
    </Typography>
  );
};
SingleValue.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const ValueContainer = props => {
  const {
    selectProps: {
      classes: { valueContainer },
    },
    children,
  } = props;
  return <div className={valueContainer}>{children}</div>;
};
ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.node,
};

const MultiValue = props => {
  const {
    children,
    selectProps: {
      classes: { chip, chipFocused },
    },
    isFocused,
    removeProps,
  } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(chip, {
        [chipFocused]: isFocused,
      })}
      onDelete={event => {
        removeProps.onClick();
        removeProps.onMouseDown(event);
      }}
    />
  );
};
MultiValue.propTypes = {
  selectProps: PropTypes.object,
  isFocused: PropTypes.bool,
  children: PropTypes.node,
  removeProps: PropTypes.object,
};

const Menu = props => {
  const {
    selectProps: {
      classes: { menu },
    },
    innerProps,
    children,
  } = props;
  return (
    <Paper square className={menu} {...innerProps}>
      {children}
    </Paper>
  );
};
Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node,
};

const DropdownIndicator = props => {
  const { children, className, cx, getStyles, innerProps } = props;
  const dropdownIndicatorStyles = getStyles('dropdownIndicator', props);
  return (
    <div
      {...innerProps}
      style={{ ...dropdownIndicatorStyles }}
    // className={cx(
    //   // classNames(getStyles('dropdownIndicator', props)),
    //   {
    //     'indicator': true,
    //     'dropdown-indicator': true,
    //   },
    //   className,
    // )}
    >
      {children}
    </div>
  );
};
DropdownIndicator.defaultProps = {
  children: <ArrowDropDown />,
};

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu,
  DropdownIndicator,
};

class Autocomplete extends React.PureComponent {
  render() {
    const {
      required,
      classes,
      theme,
      label,
      field,
      isMulti,
      form: { dirty, errors, values, setFieldValue, isSubmitting },
      disabled,
      options,
    } = this.props;
    const errorText = errors[field.name];
    const hasError = dirty && errorText !== undefined;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
      }),
      dropdownIndicator: base => ({
        ...base,
        color: 'red',
      }),
    };

    return (
      <div className={classes.root}>
        <FormControl fullWidth error={hasError} required={required} variant="outlined">
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label,
              error: hasError,
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={options}
            components={components}
            onChange={values => setFieldValue(field.name, values)}
            value={values[field.name]}
            isMulti={isMulti}
            isDisabled={isSubmitting || disabled}
          />
          {hasError && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
