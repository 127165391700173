import { Date } from '../shared/date.model';
/**
 * @property {int} id
 * @property {string} email
 * @property {Date} expires_at
 * @property {Date} sent_at
 * @property {string|null} type
 * @property {int} member_id
 * @property {int|null} member_representative_id
 */
export class MemberInvitation {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id || null;
    this.email = data.email;
    this.expires_at = new Date(data.expires_at) || null;
    this.sent_at = data.sent_at ? new Date(data.sent_at) : null;
    this.created_at = new Date(data.created_at) || null;
    this.type = data.type;
    this.member_id = data.member_id || null;
    this.member_representative_id = data.member_representative_id || null;
  }
}
