import React, { Component } from 'react';
import { I18nProvider } from '@lingui/react';
// eslint-disable-next-line
import { setupI18n } from '@lingui/core';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Container from 'components/Base/Container';
import RoutesSwitch from 'RoutesSwitch';
import Header from 'components/Header';
import AuthProvider, { AuthConsumer } from 'AuthContext.js';
import baseTheme from 'baseTheme.js';
import muiTheme from 'muiTheme.js';
import catalogCs from 'locale/cs/messages.js';
import catalogEn from 'locale/en/messages.js';
import { SnackbarProvider } from 'notistack';
import { Icon, IconButton } from '@material-ui/core';

const errorsCs = {
  'Invalid username or password': 'Uživatelské jméno nebo e-mail nejsou validní',
};

const messagesCs = catalogCs.messages;
catalogCs.messages = { ...messagesCs, ...errorsCs };

const catalogs = { en: catalogEn, cs: catalogCs };
const i18n = setupI18n({ catalogs, language: 'cs' });
export { i18n };

class App extends Component {
  render() {
    const childProps = {
      someInfoForChildComponents: 'hello world!',
    };

    // const {
    //   location: { pathname },
    // } = this.props;
    return (
      <I18nProvider i18n={i18n}>
        <AuthProvider>
          <AuthConsumer>
            {({ club }) => {
              if (Object.keys(club).length === 0) {
                return null;
              }

              const appliedTheme = {
                ...baseTheme,
                ...muiTheme,
                club: club.ui_settings,
              };

              return (
                Object.keys(club).length > 0 && (
                  <MuiThemeProvider theme={appliedTheme}>
                    <CssBaseline />
                    <Helmet titleTemplate="%s | eos club zone">
                      <title>Welcome to eos club zone</title>
                      <meta
                        name="msapplication-TileColor"
                        content={club.ui_settings.primary_bg_color}
                      />
                      <meta name="theme-color" content={club.ui_settings.primary_bg_color} />
                    </Helmet>
                    <ThemeProvider theme={appliedTheme}>
                      <div className="App">
                        <React.Fragment>
                          <AuthConsumer>{({ isAuth }) => isAuth && <Header />}</AuthConsumer>
                          <Container>
                            <SnackbarProvider
                              maxSnack={3}
                              action={[
                                <IconButton key="default_close">
                                  <Icon style={{ color: 'white' }}>close</Icon>
                                </IconButton>,
                              ]}
                            >
                              <RoutesSwitch childProps={childProps} />
                            </SnackbarProvider>
                          </Container>
                        </React.Fragment>
                      </div>
                    </ThemeProvider>
                  </MuiThemeProvider>
                )
              );
            }}
          </AuthConsumer>
        </AuthProvider>
      </I18nProvider>
    );
  }
}

// App.propTypes = {
//   location: PropTypes.object.isRequired,
// };

export default withRouter(App);
