import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@mdi/react';
import {
  mdiLessThan,
  mdiLessThanOrEqual,
  mdiGreaterThan,
  mdiGreaterThanOrEqual,
  mdiContain,
  mdiContainStart,
  mdiContainEnd,
  mdiEqual,
  mdiNotEqualVariant,
  mdiSkullCrossbones,
  // mdiCancel,
  mdiFilterVariant,
} from '@mdi/js';

class GeneralFilter extends Component {
  state = {
    // field: null,
    value: null,
    method: null,
    anchorEl: null,
  };

  acceptedMethods = [
    {
      key: 'starts_with',
      title: 'Starts with',
      icon: mdiContainStart,
    },
    {
      key: 'ends_with',
      title: 'Ends with',
      icon: mdiContainEnd,
    },
    {
      key: 'contains',
      title: 'Contains',
      icon: mdiContain,
    },
    {
      key: 'eq',
      title: 'Equals',
      icon: mdiEqual,
    },
    {
      key: 'neq',
      title: 'Does not equal',
      icon: mdiNotEqualVariant,
    },
    {
      key: 'gt',
      title: 'Greater than',
      icon: mdiGreaterThan,
    },
    {
      key: 'gte',
      title: 'Greater than or equal to',
      icon: mdiGreaterThanOrEqual,
    },
    {
      key: 'lt',
      title: 'Less than',
      icon: mdiLessThan,
    },
    {
      key: 'lte',
      title: 'Less than or equal to',
      icon: mdiLessThanOrEqual,
    },
    {
      key: 'in',
      title: 'One of',
      icon: mdiSkullCrossbones,
    },
  ];

  defaultTextMethods = ['contains', 'starts_with', 'ends_with', 'eq', 'neq'];

  defaultNumberMethods = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'];

  defaultSelectMethod = ['in'];

  static propTypes = {
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    customFiltersUpdate: PropTypes.func.isRequired,
    method: PropTypes.oneOf([
      'starts_with',
      'ends_with',
      'contains',
      'eq',
      'neq',
      'gt',
      'gte',
      'lt',
      'lte',
      'in',
    ]),
    methods: PropTypes.arrayOf(PropTypes.string),
    selectedMethod: PropTypes.oneOf([
      'starts_with',
      'ends_with',
      'contains',
      'eq',
      'neq',
      'gt',
      'gte',
      'lt',
      'lte',
      'in',
    ]),
    // options: PropTypes.array,
  };

  static defaultProps = {
    type: 'text',
    value: null,
    method: null,
    methods: null,
    selectedMethod: null,
    // options: null,
  };

  componentDidMount() {
    const { value, selectedMethod, type } = this.props;

    const newState = {};

    if (selectedMethod) {
      newState.method = selectedMethod;
      // this.setState({
      //   method: selectedMethod,
      // });
    }

    if (value) {
      // this.setState({
      //   value,
      // });
      newState.value = value;
    } else if (type === 'select') {
      // because of multiselect, it has to be empty array, not null
      newState.value = [];
    }

    if (type === 'select') {
      // this.setState({
      //   method: 'in',
      // });
      newState.method = 'in';
    }

    if (Object.keys(newState).length) {
      this.setState({ ...newState });
    }

    // console.log(`filter component ${field} has been mounted`);
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   // console.log(`filter component ${prevProps.field} has been updated`);
  // };

  handleMethodChange = method => {
    const { field, customFiltersUpdate } = this.props;
    this.setState(
      {
        // field,
        method,
      },
      () => {
        const { value } = this.state;
        if (value) {
          customFiltersUpdate(field, { value, method });
        }
      }
    );
  };

  handleChange = (name, value) => {
    const { field, customFiltersUpdate, type } = this.props;
    let { method } = this.state;

    if (!method) {
      switch (type) {
        case 'select':
          // eslint-disable-next-line
          method = this.defaultSelectMethod[0];
          break;
        case 'number':
          // eslint-disable-next-line
          method = this.defaultNumberMethods[0];
          break;
        default:
          // eslint-disable-next-line
          method = this.defaultTextMethods[0];
      }
    }

    this.setState(
      {
        // field,
        value,
        method,
      },
      () => {
        customFiltersUpdate(field, {
          value,
          // eslint-disable-next-line
          method: method || this.props.method,
          fields: field.split(','),
        });
      }
    );
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  setMethod = method => {
    this.handleMethodChange(method);
    this.handleClose();
  };

  methodSwitcher = () => {
    const { anchorEl, method } = this.state;
    const { methods, type } = this.props;

    let methodsByType = null;

    switch (type) {
      case 'number':
        methodsByType = this.defaultNumberMethods;
        break;
      case 'select':
        methodsByType = this.defaultSelectMethod;
        break;
      default:
        methodsByType = this.defaultTextMethods;
    }

    const appliedMethods = methods || methodsByType;

    const buttonIcon = method
      ? this.acceptedMethods.filter(m => m.key === method)[0].icon
      : mdiFilterVariant;

    return (
      <React.Fragment>
        <IconButton
          aria-label="Filter"
          onClick={this.handleClick}
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
        >
          <Icon path={buttonIcon} size={1} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {appliedMethods.map(m => {
            const methodData = this.acceptedMethods.filter(data => data.key === m)[0];

            return (
              <MenuItem
                key={`key_${m}`}
                onClick={() => this.setMethod(methodData.key)}
                selected={method === methodData.key}
              >
                <ListItemIcon>
                  <Icon path={methodData.icon} size={1} />
                </ListItemIcon>
                <ListItemText>{methodData.title}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  };
}

export default GeneralFilter;
