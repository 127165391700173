import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  // Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Switch,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import CustomizedLink from 'components/Base/Link';
import { reverse } from 'named-urls';
import routes from 'Routes.js';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import { i18n } from 'App.js';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { StyledTable } from 'components/Table/StyledTable';
import ContextMenu from 'components/Base/ContextMenu';

class HallsTable extends Component {
  static propTypes = {
    halls: PropTypes.array,
    loading: PropTypes.bool,
    deleteHall: PropTypes.func.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    halls: [],
    loading: false,
  };

  render() {
    const { halls, loading, deleteHall, toggleVisibility, managePermissions } = this.props;

    if (!loading && halls.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.halls.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <React.Fragment>
        <Card>
          <CardContent>
            <Typography variant="subtitle1">
              <Trans id="msg.halls.description" />
            </Typography>
          </CardContent>
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Trans id="msg.id" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.name" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.address" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.address.city" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.address.postal_code" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.gps_coords" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.visible_on_web" />
                </TableCell>
                {managePermissions && (
                  <TableCell align="center">
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoadingContent colSpan={managePermissions ? 8 : 7} />}
              {!loading &&
                halls.map(hall => (
                  <TableRow key={hall.id}>
                    <TableCell align="center">{hall.id}</TableCell>
                    <TableCell>
                      {managePermissions ? (
                        <CustomizedLink
                          to={reverse(routes.management.halls.single.update, { hallId: hall.id })}
                        >
                          {hall.name}
                        </CustomizedLink>
                      ) : (
                        hall.name
                      )}
                    </TableCell>
                    <TableCell>{hall.address.street}</TableCell>
                    <TableCell>{hall.address.city}</TableCell>
                    <TableCell>
                      {hall.address.postal_code.replace(/^(\d{3})(\d{2})/, '$1 $2')}
                    </TableCell>
                    <TableCell>
                      {hall.longitude}
                      <br />
                      {hall.latitude}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title={
                          managePermissions
                            ? i18n._('msg.halls.switch_hall_visibility')
                            : i18n._('msg.halls.switch_hall_visibility_disabled')
                        }
                      >
                        <div>
                          <Switch
                            color="primary"
                            checked={hall.visible_on_web}
                            disabled={!managePermissions}
                            onChange={() => toggleVisibility(hall.id)}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    {managePermissions && (
                      <TableCell align="center">
                        <ContextMenu
                          variant="icon"
                          tooltip={i18n._('msg.actions')}
                          color="inherit"
                          icon="more_vert"
                          menuItems={[
                            {
                              text: i18n._('msg.record_update'),
                              url: reverse(routes.management.halls.single.update, {
                                hallId: hall.id,
                              }),
                            },
                            {
                              text: i18n._('msg.record_delete'),
                              action: () => deleteHall(hall.id),
                              variant: 'delete',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </Card>
      </React.Fragment>
    );
  }
}

export default HallsTable;
