import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import { StyledTable } from 'components/Table/StyledTable';
import { Trans } from '@lingui/macro';
import CustomizedLink from 'components/Base/Link';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';

class TeamGroupsTable extends Component {
  static propTypes = {
    teamGroups: PropTypes.array,
    loading: PropTypes.bool,
    editTeamGroup: PropTypes.func.isRequired,
    deleteTeamGroup: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    teamGroups: [],
    loading: false,
  };

  render() {
    const { teamGroups, loading, editTeamGroup, deleteTeamGroup, managePermissions } = this.props;

    if (!loading && teamGroups.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.team_groups.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <div>
        <Card>
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Trans id="msg.id" />
                </TableCell>
                <TableCell style={{ width: '100%' }}>
                  <Trans id="msg.name" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.order" />
                </TableCell>
                {managePermissions && (
                  <TableCell align="center">
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoadingContent colSpan={managePermissions ? 4 : 3} />}
              {!loading &&
                teamGroups.map(tg => (
                  <TableRow key={tg.id}>
                    <TableCell align="center">{tg.id}</TableCell>
                    <TableCell>
                      {managePermissions ? (
                        <CustomizedLink to="#" noWrap onClick={() => editTeamGroup(tg)}>
                          {tg.name}
                        </CustomizedLink>
                      ) : (
                        tg.name
                      )}
                    </TableCell>
                    <TableCell align="center">{tg.order}</TableCell>
                    {managePermissions && (
                      <TableCell style={{ textAlign: 'center' }}>
                        <ContextMenu
                          variant="icon"
                          tooltip={i18n._('msg.actions')}
                          color="inherit"
                          icon="more_vert"
                          menuItems={[
                            {
                              text: i18n._('msg.record_update'),
                              action: () => editTeamGroup(tg),
                            },
                            {
                              text: i18n._('msg.record_delete'),
                              action: () => deleteTeamGroup(tg.id),
                              variant: 'delete',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </Card>
      </div>
    );
  }
}

export default TeamGroupsTable;
