import routes from './Routes';

const sidebarItemsConfig = {
  admin: {
    test: {
      label: 'Test',
      path: null,
      icon: 'settings',
    },
  },
  management: {
    dashboard: {
      label: 'Nástěnka',
      path: routes.management.dashboard,
      icon: 'dashboard',
    },
    teams: {
      label: 'Družstva',
      path: routes.management.teams.all,
      icon: 'group',
      module: 'teams',
    },
    members: {
      label: 'Členové',
      path: routes.management.members.all,
      icon: 'persons',
      module: 'members',
    },
    // rosters: {
    //   label: 'Soupisky',
    //   path: routes.management.rosters.all,
    //   icon: 'toc',
    // },
    halls: {
      label: 'Haly',
      path: routes.management.halls.all,
      icon: 'place',
      module: 'halls',
    },
    settings: {
      label: 'Nastavení',
      path: routes.management.settings.all,
      icon: 'settings',
      module: 'settings',
    },
    roles: {
      label: 'Role',
      path: routes.management.roles.all,
      icon: 'verified_user',
      module: 'permissions',
    },
  },
};

// PREV NAV TREE SETTINGS:

// const sidebarItemsConfig = {
//   admin: {
//     test: {
//       label: 'Test',
//       path: null,
//       icon: 'settings',
//     },
//   },
//   management: {
//     members: {
//       id: '20',
//       label: 'Členové a družstva',
//       path: null,
//       icon: 'contacts',
//       subItems: [
//         {
//           id: '10',
//           label: 'Členové',
//           path: routes.management.members.all,
//           icon: 'persons',
//         },
//         {
//           id: '20',
//           label: 'Družstva',
//           path: routes.management.teams.all,
//           icon: 'group',
//         },
//         // {
//         //   id: '30',
//         //   label: 'Soupisky',
//         //   path: routes.management.rosters.all,
//         //   icon: 'toc',
//         // },
//       ],
//     },
//     settings: {
//       id: '30',
//       label: 'Nastavení',
//       path: null,
//       icon: 'settings',
//       subItems: [
//         {
//           id: '10',
//           label: 'Sezony',
//           path: routes.management.settings.seasons,
//           icon: 'calendar_today',
//         },
//         {
//           id: '20',
//           label: 'Pozice',
//           path: routes.management.settings.positions,
//           icon: 'stars',
//         },
//         {
//           id: '30',
//           label: 'Typy členů',
//           path: routes.management.settings.memberTypes,
//           icon: 'stars',
//         },
//         {
//           id: '40',
//           label: 'Typy událostí',
//           path: routes.management.settings.eventTypes,
//           icon: 'stars',
//         },
//       ],
//     },
//     halls: {
//       id: '40',
//       label: 'Haly',
//       path: routes.management.halls.all,
//       icon: 'place',
//     },
//     roles: {
//       id: '50',
//       label: 'Role',
//       path: routes.management.roles.all,
//       icon: 'verified_user',
//     },
//   },
// };

export default sidebarItemsConfig;
