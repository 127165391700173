import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import InvitationStep1 from 'components/Invitation/InvitationStep1';
import InvitationStep2 from 'components/Invitation/InvitationStep2';
import InvitationStep3 from 'components/Invitation/InvitationStep3';
import { UnlockedMemberInvitationService } from 'api/unlocked-member-invitation.service';
import { UnlockedMemberInvitation } from 'models/UnlockedMemberInvitation';
import { Trans } from '@lingui/macro';
import StandaloneCard from 'components/StandaloneCard';
import routes from 'Routes.js';
import { AuthConsumer } from 'AuthContext.js';

class Invitation extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  state = {
    displayedStep: 1,
    invitationData: {},
    invitationTypeChangeEnabled: false,
    invitationExpired: false,
    invitationFinished: false,
    invitationRenewed: null,
    invitationRenewError: null,
    step1Response: null,
    step2Response: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props;
    this.setState({
      invitationData: {
        // token: queryString('token'),
        token,
      },
    });
  }

  handleStepBack = () => {
    const { displayedStep } = this.state;
    this.setState({
      displayedStep: displayedStep - 1,
    });
  };

  handleSubmit = (values, actions) => {
    const { displayedStep } = this.state;
    actions.setSubmitting(true);

    switch (displayedStep) {
      case 1:
        this.step1Submit(values, actions);
        break;
      case 2:
        this.step2Submit(values, actions);
        break;
      case 3:
        this.step3Submit(values, actions);
        break;
      default:
        return null;
    }
  };

  // STEP1 : send data as combination of this data:
  // - token
  // - pin_code
  step1Submit = async (values, actions) => {
    const { invitationData } = this.state;

    // format requested data
    const formattedData = UnlockedMemberInvitation.formatForCreate({
      token: invitationData.token,
      pin_code: values.pin_code,
    });

    // send POST request and wait for response,
    // there should be info about member for which is invitation created
    // and optionally a representative
    await UnlockedMemberInvitationService.create(formattedData)
      .then(resp => {
        this.setState({
          invitationData: {
            ...invitationData,
            pin_code: values.pin_code,
          },
          step1Response: resp,
          invitationTypeChangeEnabled: resp.type === null,
          displayedStep: 2,
        });
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        // expired invitation
        if (error.response.status === 489) {
          this.setState({
            invitationExpired: true,
            invitationData: {
              ...invitationData,
              pin_code: values.pin_code,
            },
          });
          return;
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }
        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });
  };

  // STEP2 : send data as combination of this data:
  // - token
  // - pin_code
  // - email
  // - type
  step2Submit = async (values, actions) => {
    const { invitationData, step1Response } = this.state;

    const formattedData = UnlockedMemberInvitation.formatForVerify({
      // id: step1Response.id,
      token: invitationData.token,
      pin_code: invitationData.pin_code,
      email: values.email,
      type: values.type,
    });

    // send PATCH request with type and email for confirmation and for check
    // if any user/representative with this email is in system yet
    await UnlockedMemberInvitationService.verify(step1Response.id, formattedData)
      .then(resp => {
        this.setState({
          invitationData: {
            ...invitationData,
            ...values,
          },
          step2Response: resp,
          displayedStep: 3,
        });
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }
        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });
  };

  // STEP3 : send final data for invitation consume
  // - token
  // - pin_code
  // - email
  // - type
  // - first_name
  // - last_name
  // - password
  // - password_confirmation
  // - phone_number (will be removed)
  step3Submit = (values, actions) => {
    const { step1Response, invitationData } = this.state;
    // eslint-disable-next-line
    const { new_account, ...data } = values;

    this.setState(
      {
        invitationData: {
          ...invitationData,
          ...data,
        },
      },
      () => {
        // eslint-disable-next-line
        const newStateInvitationData = this.state.invitationData;
        const formattedData = UnlockedMemberInvitation.formatForConsume(newStateInvitationData);

        // send PUT request with all data
        UnlockedMemberInvitationService.consume(step1Response.id, {
          ...formattedData,
        })
          .then(response => {
            // there we will wait for response type,
            // if it's 204, it means it's OK and we will set flag for redirect (redirected in render method)
            if (response.status === 204) {
              this.setState({
                invitationFinished: true,
              });
            }
          })
          .catch(error => {
            const errors = {};
            if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
              // eslint-disable-next-line
              Object.keys(error.response.data.errors).map(e => {
                errors[e] = error.response.data.errors[e]
                  .map(errorRule => errorRule.message)
                  .join(', ');
              });
            }

            if (error.response.data.message) {
              errors.status = error.response.data.message;
            }
            actions.setErrors({ ...errors });
            actions.setSubmitting(false);
          });
      }
    );
  };

  // TODO: make this functionality
  requestNewInvitation = invitationData => {
    const formattedData = UnlockedMemberInvitation.formatForCreate(invitationData);
    UnlockedMemberInvitationService.renew(formattedData)
      .then(response => {
        this.setState({
          invitationRenewed: response.status === 204,
        });
      })
      .catch(error => {
        console.log({ error });
        this.setState({
          invitationRenewed: false,
          invitationRenewError: error.response.data.message,
        });
      });
  };

  getDisplayedTitle = () => {
    const { displayedStep, invitationExpired, invitationRenewed } = this.state;

    switch (displayedStep) {
      case 2:
        return <Trans id="msg.invitations.title_step2" />;
      case 3:
        return <Trans id="msg.invitations.title_step3" />;
      default:
        if (invitationExpired && !invitationRenewed) {
          return <Trans id="msg.invitations.title_expired" />;
        }
        if (invitationExpired && invitationRenewed) {
          return <Trans id="msg.invitations.title_renewed" />;
        }
        return <Trans id="msg.invitations.title_step1" />;
    }
  };

  getDisplayedComponent = () => {
    const {
      displayedStep,
      invitationExpired,
      step1Response,
      step2Response,
      invitationData,
      invitationRenewed,
      invitationRenewError,
      invitationTypeChangeEnabled,
    } = this.state;

    switch (displayedStep) {
      case 1:
        return (
          <InvitationStep1
            handleSubmit={this.handleSubmit}
            invitationExpired={invitationExpired}
            invitationData={invitationData}
            invitationRenewed={invitationRenewed}
            invitationRenewError={invitationRenewError}
            requestNewInvitation={this.requestNewInvitation}
          />
        );

      case 2:
        return (
          <InvitationStep2
            handleSubmit={this.handleSubmit}
            // handleStepBack={this.handleStepBack}
            step1Response={step1Response}
            invitationData={invitationData}
            invitationTypeChangeEnabled={invitationTypeChangeEnabled}
          />
        );

      case 3:
        return (
          <InvitationStep3
            handleSubmit={this.handleSubmit}
            handleStepBack={this.handleStepBack}
            step1Response={step1Response}
            step2Response={step2Response}
            invitationData={invitationData}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { invitationFinished } = this.state;

    if (invitationFinished) {
      return <Redirect to={routes.login} />;
    }

    return (
      <AuthConsumer>
        {({ logout }) => {
          logout();
          return (
            <StandaloneCard title={this.getDisplayedTitle()}>
              {this.getDisplayedComponent()}
            </StandaloneCard>
          );
        }}
      </AuthConsumer>
    );
  }
}

export default Invitation;
