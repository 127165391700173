import React from 'react';
import Typography from '@material-ui/core/Typography';

const Announcements = () => (
  <div>
    <Typography variant="h1" gutterBottom>
      Announcements
    </Typography>
  </div>
);

export default Announcements;
