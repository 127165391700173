import classnames from 'classnames';
import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import { get } from './helpers';

const EosTextInput = ({
  field: { name, type, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  className,
  label,
  disabled,
  required,
  helperText,
  ...props
}) => {
  const error = get(errors, name);
  const touch = get(touched, name);

  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const isPasswordField = props.type === 'password';

  const classes = classnames(
    'form-group',
    {
      error: !!error && !!touch,
    },
    className
  );

  return (
    <TextField
      id={name}
      className={classes}
      type={type}
      error={!!error && !!touch}
      disabled={isSubmitting || disabled}
      label={label}
      fullWidth
      variant="outlined"
      margin="dense"
      required={required}
      InputProps={{
        autoComplete: 'off',
        // eslint-disable-next-line
        type: isPasswordField && showPassword ? 'text' : props.type,
        endAdornment: isPasswordField ? (
          <InputAdornment position="end">
            <IconButton
              disabled={isSubmitting}
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...field}
      {...props}
      helperText={touch && error ? String(error) : helperText}
    />
  );
};

EosTextInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

EosTextInput.defaultProps = {
  className: '',
  label: null,
  disabled: false,
  required: false,
  helperText: null,
};

export default EosTextInput;
