const fallbackClubTheme = {
  logo_image_id: null,
  primary_bg_color: '#111111',
  primary_color: '#FFFFFF',
  secondary_bg_color: '#E94938',
  secondary_color: '#FFFFFF',
  link_color: '#E94938',
  app_icon_image_id: null,
};

export default fallbackClubTheme;
