import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, Grid, Icon, IconButton, InputAdornment } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import WhispererItem from './WhispererItem';

class Whisperer extends Component {
  static propTypes = {
    possibleRecords: PropTypes.array.isRequired,
    whispererItemAction: PropTypes.func,
    // rosterType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    recordType: PropTypes.oneOf(['members']),
    usage: PropTypes.oneOf(['roles', 'rosters']),
  };

  static defaultProps = {
    disabled: false,
    placeholder: '', // i18n._('msg.members.name'),
    recordType: 'members',
    whispererItemAction: () => {
      console.log('whisperer item action fired');
    },
    usage: 'roles',
  };

  state = {
    searchTerm: '', // stored in lower case
    filteredRecords: [],
  };

  componentDidMount() {
    const { possibleRecords } = this.props;
    this.setState({
      possibleRecords,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.possibleRecords !== prevState.possibleRecords) {
      return {
        possibleRecords: nextProps.possibleRecords,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { possibleRecords } = this.props;
    if (prevProps.possibleRecords !== possibleRecords) {
      this.filterRecords();
    }
  }

  filterRecords = () => {
    const { searchTerm, possibleRecords } = this.state;
    const { recordType } = this.props;

    if (searchTerm.length >= 3) {
      let filteredRecords = [];

      if (recordType === 'members') {
        filteredRecords = possibleRecords
          .map(record => ({
            ...record,
            full_name: `${record.first_name.toLowerCase()} ${record.last_name.toLowerCase()}`,
          }))
          .filter(record => record.full_name.indexOf(searchTerm) > -1);
      }

      this.setState({ filteredRecords });
    } else {
      this.setState({ filteredRecords: [] });
    }

    if (searchTerm === '') {
      this.setState({
        filteredRecords: [],
      });
    }
  };

  handleChange = evt => {
    console.log(evt.target.value);
    this.setState(
      {
        searchTerm: evt.target.value.toLowerCase(),
      },
      this.filterRecords
    );
  };

  clearSearchTerm = () => {
    this.setState(
      {
        searchTerm: '',
      },
      this.filterRecords
    );
  };

  render() {
    const {
      whispererItemAction,
      /* rosterType, */ disabled,
      placeholder,
      recordType,
      usage,
    } = this.props;
    const { searchTerm, filteredRecords } = this.state;
    return (
      <div style={{ marginBottom: 16 }}>
        <Grid container spacing={8} style={{ alignItems: 'center' }}>
          <Grid item>
            <Icon>person_add</Icon>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <TextField
              id="searchTerm"
              // label="Member name"
              placeholder={placeholder}
              value={searchTerm}
              onChange={evt => this.handleChange(evt)}
              fullWidth
              variant="outlined"
              margin="dense"
              disabled={disabled}
              InputProps={{
                autoComplete: 'off',
                endAdornment: searchTerm.length ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.clearSearchTerm}
                    >
                      <Icon>close</Icon>
                      {/* {this.state.showPassword ? <VisibilityOff /> : <Visibility />} */}
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            maxHeight: '400px',
            overflow: 'auto',
            padding: '0 16px',
            background: '#efefef',
            borderRadius: 4,
          }}
        >
          {filteredRecords.length
            ? filteredRecords.map(record => (
                <WhispererItem
                  record={record}
                  key={record.id}
                  // rosterType={rosterType}
                  whispererItemAction={whispererItemAction}
                  recordType={recordType}
                  usage={usage}
                />
              ))
            : searchTerm.length >= 3 && (
                <Typography variant="body2">
                  <Trans id="msg.rosters.no_search_match" />
                </Typography>
              )}
        </div>
      </div>
    );
  }
}

export default Whisperer;
