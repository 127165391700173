import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AuthConsumer } from 'AuthContext.js';
import TeamLogo from 'components/TeamLogo';
import UserShortcut from 'components/UserShortcut';
import routes from 'Routes';
import HeaderButton from 'components/Header/HeaderButton';
import auth from 'utils/auth';
import MemberPicker from './MemberPicker';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: 'black',
    top: -49,
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  inlineList: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButtonRotated: {
    transform: 'rotate(90deg)',
  },
  hide: {
    // display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

// const StyledToolbar = styled(Toolbar)`
//   justify-content: flex-end;
//   background: ${props => props.theme.club.secondary_bg_color};
//   color: ${props => props.theme.club.secondary_color};
//   // a {
//   //   color: ${props => props.theme.club.secondary_color};
//   //
//   //   &:hover {
//   //     text-decoration: underline;
//   //   }
//   // }
// `;

const StyledToolbarMain = styled(Toolbar)`
  background: ${props => props.theme.club.primary_bg_color};
  justify-content: space-between;
  color: ${props => props.theme.club.primary_color};

  & > div:first-child {
    display: flex;
    align-items: center;

    & > a {
      display: inline-block;
      padding: 5px 0;
      margin: 0 15px 0 0;
      //background: #fff;
      //border-radius: 70px;

      img {
        max-width: 100px;
        max-height: 60px;
        transition: all 0.25s;
        transform-origin: 50% 50%;
      }

      &:hover {
        img {
          transform: scale(1.15);
        }
      }
    }
  }
`;

// const MainBarButton = styled(Button)`
//   && {
//     color: ${props => props.theme.club.primary_color};

//     &:hover {
//       background: transparent;
//       text-decoration: underline;
//     }
//   }
// `;

class Header extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <AuthConsumer>
        {({
          user,
          toggleDrawer,
          drawerOpened,
          memberPickerOpened,
          toggleMemberPicker,
          switchActiveMember,
        }) => (
          <AppBar
            position="sticky"
            // color="secondary"
            className={classnames(classes.appBar, {
              [classes.appBarShift]: drawerOpened,
            })}
            elevation={2}
          >
            {/*
            <StyledToolbar variant="dense">
              <List disablePadding dense className={classnames(classes.inlineList)}>
                <ListItem disableGutters dense>
                  <HeaderButton component={Link} to="/404">
                    Moje události
                  </HeaderButton>
                </ListItem>
                <ListItem disableGutters dense>
                  <HeaderButton component={Link} to="/404">
                    E-shop
                  </HeaderButton>
                </ListItem>
                <ListItem disableGutters dense>
                  <HeaderButton
                    component={Link}
                    to="https://www.eosmedia.cz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Webové stránky
                  </HeaderButton>
                </ListItem>
              </List>
            </StyledToolbar>
            <Divider />
            */}
            <StyledToolbarMain disableGutters={Boolean(1) || !drawerOpened}>
              <div>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={toggleDrawer}
                  className={classnames(
                    classes.menuButton,
                    drawerOpened && classes.hide,
                    drawerOpened && classes.menuButtonRotated
                  )}
                >
                  <MenuIcon />
                </IconButton>
                <Link to="/">
                  <TeamLogo />
                </Link>
                <Typography variant="h6" color="inherit" noWrap>
                  {auth.getClubInfo().name}
                </Typography>
              </div>
              <div>
                <List disablePadding dense className={classnames(classes.inlineList)}>
                  {/* TODO: correct condition */}
                  {!!user.access_admin && (
                    <ListItem disableGutters style={{ marginRight: 20 }}>
                      <HeaderButton component={Link} to={routes.admin.dashboard}>
                        Administrace webu
                      </HeaderButton>
                    </ListItem>
                  )}
                  {user.access_management && (
                    <ListItem disableGutters style={{ marginRight: 20 }}>
                      <HeaderButton component={Link} to={routes.management.dashboard}>
                        Management
                      </HeaderButton>
                    </ListItem>
                  )}
                  <ListItem disableGutters>
                    <UserShortcut />
                  </ListItem>
                </List>
              </div>
            </StyledToolbarMain>

            <MemberPicker
              open={memberPickerOpened}
              onClose={toggleMemberPicker}
              members={user.members}
              switchActiveMember={switchActiveMember}
              disabledMemberId={
                typeof user !== 'undefined' && user.active_member ? user.active_member.id : null
              }
            />
          </AppBar>
        )}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Header);
