import { Address } from '../shared/address.model';
// import { Image } from '../shared/image.model';

/**
 * @property {int} id
 * @property {string} name
 * @property {Address} address
 * @property {string} description
 * @property {float} longitude
 * @property {float} latitude
 * @property {int} image_id
 * @property {int} order
 * @property {boolean} visible_on_web
 */
export class Hall {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.address = new Address(data.address || null);
    this.description = data.description || '';
    this.longitude = data.longitude || '';
    this.latitude = data.latitude || '';
    this.image_id = data.image_id;
    this.order = data.order;
    this.visible_on_web = data.visible_on_web;
  }

  static formatFormData(data) {
    return {
      name: data.name,
      address: new Address(data.address || null),
      description: data.description,
      longitude: Number.isNaN(parseFloat(data.longitude.toString().replace(',', '.')))
        ? null
        : parseFloat(data.longitude.toString().replace(',', '.')),
      latitude: Number.isNaN(parseFloat(data.latitude.toString().replace(',', '.')))
        ? null
        : parseFloat(data.latitude.toString().replace(',', '.')),
      image_id: data.image_id || null,
      order: data.order || null,
      visible_on_web: data.visible_on_web,
    };
  }

  static formatForSorter(data) {
    return {
      id: data.id,
      name: data.name,
      order: data.order,
    };
  }

  static formatForSimpleSorter(data) {
    return {
      id: data.id,
      order: data.order,
    };
  }
}
