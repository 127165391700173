import configLocal from './config.local.js';

const config = {
  url: {
    prefix: 'http://localhost:8380/api',
  },
  keys: {
    token: 'CLUB_ZONE_TOKEN',
    userInfo: 'CLUB_ZONE_USER',
    clubInfo: 'CLUB_ZONE_CLUB',
  },
  googleMapsApiKey: 'AIzaSyA48eYY_7nQp39VU7mcxeB9QSI4C0FcmdE',
  local: configLocal,
};

export default config;
