import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { TeamManagementService } from 'api/team.service';
import { SeasonManagementService } from 'api/season.service';
import { Card, CardContent } from '@material-ui/core';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import LoadingMessage from 'components/Base/LoadingMessage';
import TeamsDialog from 'components/Management/Teams/TeamsDialog';
import routes from 'Routes';
import { Trans } from '@lingui/macro';
import TeamsTable from 'components/Management/Teams/TeamsTable';
import { TeamsImport } from 'components/Management/Teams/TeamsImportFromSeason';
import SeasonsList from 'components/Management/Shared/SeasonsList';
import { FullTeam, TeamWebSettings } from 'models/Team';
import { withSnackbar } from 'notistack';
import { i18n } from 'App.js';
import FabButton from 'components/PageHeader/FabButton';
import ContextMenu from 'components/Base/ContextMenu';
import PageHeader from 'components/PageHeader';
import ability from 'ability.js';
import { AuthConsumer } from 'AuthContext';
import { TeamGroupManagementService } from '../../../api/team-group.service';

class Teams extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    teams: null,
    teamGroups: [],
    teamsDeleted: null,
    loadingFilters: false,
    loadingContent: false,
    loadingDeleted: false,
    seasons: null,
    requestedSeasonId: null,
    activeSeasonId: null,
    selectedSeasonId: null,
    defaultSelectedSeasonId: null,
    isSubmitting: false,

    initialTeamsOrder: null,
    newTeamsOrder: null,
    dialogComponent: '',
    dialogOpened: false,

    seasonNotFound: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { seasonId },
      },
    } = nextProps;
    // const { seasons } = prevState;

    // season ID in URL changed and still it's number
    if (!Number.isNaN(parseInt(seasonId)) && parseInt(seasonId) !== prevState.requestedSeasonId) {
      // console.log(`getDerivedStateFromProps - new season id is ${seasonId} ${typeof seasonId}`);
      return {
        requestedSeasonId: parseInt(seasonId),
      };
    }

    // team ID is not number
    if (Number.isNaN(parseInt(seasonId)) && seasonId !== prevState.requestedSeasonId) {
      // console.log(
      //   `getDerivedStateFromProps - new season id is not number (${typeof seasonId} "${seasonId}")`
      // );
      return {
        requestedSeasonId: seasonId || null,
      };
    }

    return null;
  }

  componentDidMount = () => {
    const teamGroups = [{ value: 0, label: i18n._('msg.team_groups.team_group_not_selected_label') }];

    TeamGroupManagementService.getAll()
      .sortBy('order')
      .fetch()
      .then(fetchedTeamGroups => {
        const formattedTeamGroups = fetchedTeamGroups.map(group => ({
          label: group.name,
          value: group.id,
        }));
        this.setState({
          teamGroups: [...teamGroups, ...formattedTeamGroups],
        });
      });

    this.fetchSeasons();
  };

  componentDidUpdate(prevProps, prevState) {
    const { requestedSeasonId } = this.state;
    if (prevState.requestedSeasonId !== requestedSeasonId) {
      // console.log(`!!! C H A N G E ${prevState.requestedSeasonId} -> ${requestedSeasonId}!!!`);
      // this.getSeasonByTeamId(requestedSeasonId);
      this.fetchTeams();
    }
  }

  setInitialTeamsOrder = teams =>
    teams.map((team, index) => ({
      id: team.id,
      name: team.name,
      order: index + 1, // this is because of the order dont't have to be row of numbers
    }));

  // refactored
  fetchSeasons = async () => {
    this.setState({ loadingFilters: true });

    const seasons = await SeasonManagementService.getAll()
      .sortBy('-id')
      .turnOffPagination()
      .fetch();

    const activeSeasonObject = seasons.find(season => season.is_active) || {};
    const activeSeasonId = Object.prototype.hasOwnProperty.call(activeSeasonObject, 'id')
      ? activeSeasonObject.id
      : null;
    const defaultSelectedSeasonId = activeSeasonId || seasons[0].id || 0;

    this.setState(
      {
        defaultSelectedSeasonId,
        activeSeasonId,
        seasons,
        loadingFilters: false,
      },
      this.fetchTeams
    );
  };

  // refactored
  fetchTeams = async () => {
    const { enqueueSnackbar } = this.props;
    const { defaultSelectedSeasonId, requestedSeasonId, seasons } = this.state;
    let tempSelectedSeasonId = defaultSelectedSeasonId;
    let tempSeasonNotFound = false;

    // eslint-disable-next-line
    if (!requestedSeasonId) {
    } else if (!seasons.find(s => s.id === requestedSeasonId)) {
      tempSeasonNotFound = true;
    } else {
      tempSelectedSeasonId = parseInt(requestedSeasonId);
    }

    this.setState({ loadingContent: true });

    await TeamManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .whereEquals('season_id', tempSelectedSeasonId)
      .fetch()
      .then(response => {
        const initialTeamsOrder = this.setInitialTeamsOrder(response);

        this.setState(
          {
            teams: response,
            initialTeamsOrder,
            loadingContent: false,
            seasonNotFound: tempSeasonNotFound,
            selectedSeasonId: tempSelectedSeasonId,
          },
          () => {
            enqueueSnackbar(i18n._('msg.teams.loading_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            teams: [],
            loadingContent: false,
            seasonNotFound: tempSeasonNotFound,
            selectedSeasonId: tempSelectedSeasonId,
          },
          () => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  // refactored
  fetchDeletedTeams = () => {
    const { enqueueSnackbar } = this.props;
    const { defaultSelectedSeasonId, requestedSeasonId /* , seasons */ } = this.state;
    let tempSelectedSeasonId = defaultSelectedSeasonId;
    // let tempSeasonNotFound = false;

    // eslint-disable-next-line
    if (!requestedSeasonId) {
    } /* else if (!seasons.find(s => s.id === requestedSeasonId)) {
      tempSeasonNotFound = true;
    } */ else {
      tempSelectedSeasonId = parseInt(requestedSeasonId);
    }

    this.setState({
      loadingDeleted: true,
    });

    TeamManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .whereEquals('season_id', tempSelectedSeasonId)
      .deleted()
      .fetch()
      .then(response => {
        this.setState({
          teamsDeleted: response,
          loadingDeleted: false,
        });
        // enqueueSnackbar(i18n._('msg.halls.loading_success'), { variant: 'success' });
      })
      .catch(error => {
        this.setState({
          teamsDeleted: null,
          loadingDeleted: false,
        });
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  handleSeasonChange = evt => {
    const { history } = this.props;
    const seasonId = evt.target.value;
    this.setState({ selectedSeasonId: seasonId }, () => {
      history.push(reverse(routes.management.teams.season.list, { seasonId }));
    });
  };

  handleVisibilityChange = async (teamId, field, value) => {
    const { enqueueSnackbar } = this.props;
    const { teams } = this.state;

    const teamIndex = teams.findIndex(team => team.id === teamId);

    if (teamIndex > -1) {
      const formattedData =
        field === 'visible'
          ? { visible: value }
          : {
              web_settings: {
                show: value,
              },
            };

      TeamManagementService.updateVisibility(teamId, formattedData)
        .then(response => {
          teams[teamIndex] = response;
          this.setState(
            {
              teams,
            },
            () => {
              enqueueSnackbar(i18n._('msg.teams.update_success'), { variant: 'success' });
            }
          );
        })
        .catch(error => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
  };

  handleSubmit = () => {
    const { dialogComponent, newTeamsOrder, initialTeamsOrder } = this.state;

    switch (dialogComponent) {
      case 'sortable':
        if (newTeamsOrder && !isEqual(initialTeamsOrder, newTeamsOrder)) {
          this.setState({
            isSubmitting: true,
          });
          this.requestForNewOrderedTeams(newTeamsOrder);
        } else {
          this.closeDialog();
        }
        break;
      case 'deleted':
        this.closeDialog();
        console.log('handleSubmit - deleted case');
        break;
      default:
        this.closeDialog();
        console.log('handleSubmit - rest case');
    }
  };

  // refactored
  openDeletedTeams = () => {
    const { teamsDeleted } = this.state;
    this.setState({
      dialogComponent: 'deleted',
      dialogOpened: true,
      loadingDeleted: !teamsDeleted,
    });
    if (!teamsDeleted) {
      this.fetchDeletedTeams();
    }
  };

  openSortableTeams = () => {
    this.setState({ dialogComponent: 'sortable', dialogOpened: true });
  };

  openCreateTeam = () => {
    this.setState({ dialogComponent: 'create', dialogOpened: true });
  };

  closeDialog = () => {
    // const { dialogComponent, newTeamsOrder, initialTeamsOrder } = this.state;

    this.setState({
      dialogComponent: '',
      dialogOpened: false,
    });
  };

  setNewTeamsOrder = newTeamsOrder => {
    this.setState({ newTeamsOrder });
  };

  // refactored
  requestForNewOrderedTeams = async newOrder => {
    const { enqueueSnackbar } = this.props;
    const newOrderNormalized = newOrder.map(team => FullTeam.formatForSimpleSorter(team));

    this.setState({
      loadingContent: true,
    });

    await TeamManagementService.updateMultiple(newOrderNormalized)
      .then(response => {
        this.setState(
          {
            teams: response,
            initialTeamsOrder: response.map(team => FullTeam.formatForSorter(team)),
            newTeamsOrder: null,
            loadingContent: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(i18n._('msg.teams.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            loadingContent: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  createTeam = async values => {
    const { enqueueSnackbar } = this.props;
    const { selectedSeasonId, teams } = this.state;
    const formattedData = FullTeam.formatCreateFormData({ ...values, season_id: selectedSeasonId });

    await TeamManagementService.create(formattedData)
      .then(response => {
        this.setState(
          {
            teams: [...teams, response],
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(i18n._('msg.teams.create_success'), { variant: 'success' });
          }
        );
      })
      .catch(() => {
        this.closeDialog();
        enqueueSnackbar(i18n._('msg.teams.create_error'), { variant: 'error' });
      });
  };

  restoreTeam = async teamId => {
    const restoredTeam = await TeamManagementService.restore(teamId);

    if (restoredTeam) {
      const { teams, teamsDeleted } = this.state;
      const restoredTeamIndexInDeletedTeams = teamsDeleted.findIndex(
        dt => dt.id === restoredTeam.id
      );

      teamsDeleted.splice(restoredTeamIndexInDeletedTeams, 1);

      this.setState({
        teams: [...teams, restoredTeam],
        teamsDeleted,
      });
    }
  };

  deleteTeam = async teamId => {
    const { enqueueSnackbar } = this.props;

    await TeamManagementService.delete(teamId)
      .then(() => {
        const { teams, teamsDeleted } = this.state;

        const deletedTeamIndexInTeams = teams.findIndex(t => t.id === teamId);

        const newlyDeletedTeam = teams.splice(deletedTeamIndexInTeams, 1);
        const tempTeamsDeleted = teamsDeleted || [];
        this.setState(
          {
            teams,
            teamsDeleted: [...tempTeamsDeleted, newlyDeletedTeam[0]],
          },
          () => {
            enqueueSnackbar(i18n._('msg.teams.delete_success'), { variant: 'info' });
            this.fetchDeletedTeams();
          }
        );
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  importTeams = async seasonId => {
    console.log(`TODO: Try to import teams from season with ID ${seasonId}`);
  };

  render() {
    const {
      teams,
      teamGroups,
      teamsDeleted,
      loadingFilters,
      loadingContent,
      loadingDeleted,
      seasons,
      activeSeasonId,
      selectedSeasonId,
      isSubmitting,

      dialogOpened,
      dialogComponent,

      seasonNotFound,
    } = this.state;

    const { accessLevel } = this.props;

    return (
      <AuthConsumer>
        {({ user }) => (
          <div>
            {/* there are some seasons, so create filter for them */}
            <PageHeader
              title={i18n._('msg.teams.page_title')}
              customControlsPrimary={[
                // when seasons loading done and some seasons are present
                seasons &&
                  seasons.length > 0 &&
                  !loadingFilters &&
                  ability.can('manage', { __type: 'Teams', level: accessLevel }) && (
                    <FabButton
                      variant="round"
                      size="small"
                      disabled={loadingFilters || loadingContent}
                      onClick={this.openCreateTeam}
                    />
                  ),
                teams &&
                  teams.length > 0 &&
                  ability.can('manage', { __type: 'Teams', level: accessLevel }) && (
                    <ContextMenu
                      text={i18n._('msg.actions')}
                      variant="withIcon"
                      disabled={loadingFilters || loadingContent}
                      menuItems={[
                        {
                          text: i18n._('msg.teams.teams_deleted'),
                          action: this.openDeletedTeams,
                        },
                        {
                          text: i18n._('msg.teams.teams_sort'),
                          action: this.openSortableTeams,
                        },
                      ]}
                    />
                  ),
              ]}
              customControlsSecondary={[
                <SeasonsList
                  seasons={seasons || []}
                  loading={loadingFilters || loadingContent}
                  handleChange={this.handleSeasonChange}
                  selectedSeason={selectedSeasonId || activeSeasonId || 0}
                />,
              ]}
            />

            {seasons &&
              !seasons.length &&
              (ability.can('manage', {
                __type: 'Seasons',
                level: user.modules_permissions.seasons,
              }) ? (
                <Typography>
                  {/* TODO: check permissions for create new season */}
                  <Trans id="msg.teams.no_season_yet">
                    There is currently no season created, so you can't add teams. You can{' '}
                    <Link to={routes.management.settings.seasons}>create new season</Link>.
                  </Trans>
                </Typography>
              ) : (
                <Typography>
                  <Trans id="msg.teams.no_season_yet_no_permissions">
                    There is currently no season created and you don't have permissions to create.
                  </Trans>
                </Typography>
              ))}

            {/* while loading seasons, show general loading message / spinner / whatever */}
            {loadingFilters && <LoadingMessage />}

            {!loadingFilters && !loadingContent && seasonNotFound && (
              <Card>
                <CardContent>
                  <Typography>
                    <Trans id="msg.teams.season_not_found" />
                  </Typography>
                </CardContent>
              </Card>
            )}
            {/* after seasons are loaded and if there are some, show teams table, with appropriate loading state */}
            {!loadingFilters && seasons && Boolean(seasons.length) && (
              <React.Fragment>
                {/* TODO: debug only */}
                <Typography component="div" style={{ marginBottom: 24 }}>
                  <Link to={reverse(routes.management.teams.season.list, { seasonId: 1 })}>1</Link>{' '}
                  <Link to={reverse(routes.management.teams.season.list, { seasonId: 2 })}>2</Link>{' '}
                  <Link to={reverse(routes.management.teams.season.list, { seasonId: 99 })}>
                    99
                  </Link>{' '}
                  <Link to={reverse(routes.management.teams.season.list, { seasonId: 'abc' })}>
                    abc
                  </Link>
                </Typography>

                <TeamsTable
                  loading={loadingContent}
                  teams={teams || []}
                  selectedSeasonId={selectedSeasonId}
                  handleVisibilityChange={this.handleVisibilityChange}
                  deleteTeam={this.deleteTeam}
                  managePermissions={ability.can('manage', {
                    __type: 'Teams',
                    level: accessLevel,
                  })}
                  teamGroups={teamGroups}
                />
              </React.Fragment>
            )}
            {/* if some seasons are loaded and no teams assigned,
          show component for import from another season */}
            {!loadingFilters &&
              !loadingContent &&
              teams &&
              !teams.length &&
              seasons &&
              Boolean(seasons.filter(season => season.id !== selectedSeasonId).length) && (
                <TeamsImport
                  seasons={seasons.filter(season => season.id !== selectedSeasonId)}
                  selectedSeasonId={selectedSeasonId}
                  handleImport={this.importTeams}
                  managePermissions={ability.can('manage', {
                    __type: 'Teams',
                    level: accessLevel,
                  })}
                />
              )}
            {/* dialog for "create team" / "sorting" / "restore" teams */}
            <TeamsDialog
              opened={dialogOpened}
              component={dialogComponent}
              onClose={this.closeDialog}
              teams={teams || []}
              teamsDeleted={teamsDeleted || []}
              createTeam={this.createTeam}
              selectedSeasonId={selectedSeasonId}
              requestForNewOrderedRecords={this.requestForNewOrderedTeams}
              setNewRecordsOrder={this.setNewTeamsOrder}
              restoreRecord={this.restoreTeam}
              seasons={seasons}
              isSubmitting={isSubmitting}
              loadingDeleted={loadingDeleted}
              handleSubmit={this.handleSubmit}
              teamGroups={teamGroups}
            />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withSnackbar(Teams);
