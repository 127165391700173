import axios from 'axios';
import config from '../config';
import auth from './auth';

const handle404 = response => {
  console.log(`${response.status}: ${response.data.message}`);
  // TODO: do something

  return { aaa: 'bbb' };
};

const handle403 = response => {
  console.log(`${response.status}: ${response.data.message}`);
  if (response.data.message === 'Invalid token') {
    // TODO: do something to redirect user
    auth.clearToken();
    auth.clearUserInfo();
    window.location.href = `/login?redirect=${window.location.pathname}`;
  }
};

const handle401 = response => {
  console.log(`${response.status}: ${response.data.message}`);
  auth.clearToken();
  auth.clearUserInfo();
  // window.location.href = `/login?redirect=${window.location.pathname}`;
  if (
    window.location.pathname.indexOf('/login') === -1 &&
    window.location.pathname.indexOf('/invitation') === -1
  ) {
    window.location.href = `/login?redirect=${window.location.pathname}`;
  }
};

/**
 * @param {AxiosRequestConfig} axiosRequestConfig
 */
const alterRequest = axiosRequestConfig => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const token = auth.getToken();
  if (token) {
    headers.Authorization = `Bearer ${token.token}`;
  }

  axiosRequestConfig.baseURL = config.local.url.prefix || config.url.prefix;
  axiosRequestConfig.headers = { ...headers, ...axiosRequestConfig.headers };
  axiosRequestConfig.responseType = 'json';

  return axiosRequestConfig;
};

/**
 * @param {Error} error
 */
const handleError = error => {
  if (typeof error.response !== 'undefined' && error.response.status === 404) {
    handle404(error.response);
  }

  if (typeof error.response !== 'undefined' && error.response.status === 403) {
    handle403(error.response);
  }

  if (typeof error.response !== 'undefined' && error.response.status === 401) {
    handle401(error.response);
  }

  return Promise.reject(error);
};

axios.interceptors.request.use(alterRequest);
axios.interceptors.response.use(response => response, error => handleError(error));

const apiCall = axios;
export { apiCall };
