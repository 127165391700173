import React from 'react';
import Typography from '@material-ui/core/Typography';

const Dashboard = () => (
  <div>
    <Typography variant="h1" gutterBottom>
      Admin Dashboard
    </Typography>
  </div>
);

Dashboard.propTypes = {};

export default Dashboard;
