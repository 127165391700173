import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ContainerElement = styled.div`
  /* padding: 24px; */
  padding: 0;
  // height: calc(100% - 74px); // 74 = header height
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  position: relative;
  z-index: ${props => props.zIndex || 1};

  overflow: hidden;
  flex: 1;
`;

const ContainerInnerElement = styled.div`
  padding: 24px;
  overflow: auto;
  width: 100%;
`;

const Container = ({ children, inner, ...props }) => {
  if (inner) {
    return <ContainerInnerElement {...props}>{children}</ContainerInnerElement>;
  }
  return <ContainerElement {...props}>{children}</ContainerElement>;
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
  inner: PropTypes.bool,
};
Container.defaultProps = {
  inner: false,
};

export default Container;
