import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import MemberInvitationForm from './MemberInvitationForm';

class MemberInvitationDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invitation: PropTypes.object,
  };

  static defaultProps = {
    dialogOpened: false,
    invitation: null,
  };

  render() {
    const { dialogOpened, onClose, handleSubmit, invitation } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {invitation && invitation.id ? (
            <Trans id="msg.members.resend_invitation_title" />
          ) : (
            <Trans id="msg.members.create_new_invitation_title" />
          )}
        </CustomDialogTitle>
        <DialogContent>
          <MemberInvitationForm
            handleSubmit={handleSubmit}
            closeDialog={onClose}
            invitation={invitation}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

MemberInvitationDialog.propTypes = {};

export default MemberInvitationDialog;
