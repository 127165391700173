import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ImageDisplay extends Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
  };

  state = {
    file: null,
  };

  componentDidMount() {
    const { file } = this.props;
    this.setFile(file);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.file !== prevState.file) {
      return {
        file: nextProps.file,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { file } = this.props;
    if (prevProps.file !== file) {
      this.setFile(file);
    }
  }

  setFile = file => {
    this.setState({ file });
  };

  encodeData = () => {
    const { file } = this.state;

    return `data:${file.mime_type};base64,${file.content}`;
  };

  render() {
    const { file } = this.state;

    if (file) {
      return (
        <div
          style={{
            border: '1px solid #dedede',
            borderRadius: '4px',
            padding: '8px',
            background: '#fff',
            margin: '8px',
            display: 'inline-block',
          }}
        >
          <img src={this.encodeData()} alt="" style={{ maxWidth: '100%', display: 'block' }} />
        </div>
      );
    }

    return null;
  }
}

export default ImageDisplay;
