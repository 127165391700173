import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Icon } from '@material-ui/core';
import compose from 'recompose/compose';
import withState from 'recompose/withState';

import { ChromePicker } from 'react-color';
import { get } from './helpers';

const DEFAULT_CONVERTER = 'rgba_hex';
const converters = {
  rgba: c => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
  rgb: c => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`,
  hex: c => c.hex,

  rgba_rgb: c => (c.rgb.a === 1 ? converters.rgb(c) : converters.rgba(c)),
  rgba_hex: c => (c.rgb.a === 1 ? converters.hex(c) : converters.rgba(c)),
};

const PickerDialog = ({ value, onClick, onChange }) => (
  <div style={{ position: 'absolute', zIndex: '2' }}>
    {/* close layer, after click, cose the color picker dialog */}
    <button
      type="button"
      style={{
        outline: 'none',
        background: 'transparent',
        border: 0,
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        display: 'block',
        width: '100%',
      }}
      onClick={onClick}
    />
    <ChromePicker color={value} onChange={onChange} disableAlpha />
  </div>
);

PickerDialog.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
PickerDialog.defaultProps = {
  value: '',
};

const ColorPicker = ({
  // ColorPicker
  // defaultValue,
  onChange,
  convert,

  // Text field
  name,
  id,
  hintText,
  placeholder,
  floatingLabelText,
  label,
  TextFieldProps,
  value,

  // State
  showPicker,
  setShowPicker,
  internalValue,
  setValue,

  // Rest
  ...rest
}) => (
  <React.Fragment>
    <TextField
      name={name}
      id={id}
      value={value === undefined ? internalValue : value}
      label={floatingLabelText || label}
      placeholder={hintText || placeholder}
      onClick={() => setShowPicker(true)}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      InputProps={{
        style: { color: value === undefined ? internalValue : value },
        autoComplete: 'off',
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              style={{
                color: value === undefined ? internalValue : value,
              }}
            >
              format_color_fill
            </Icon>
          </InputAdornment>
        ),
      }}
      {...TextFieldProps}
      {...rest}
    />
    {showPicker && (
      <PickerDialog
        value={value === undefined ? internalValue : value}
        onClick={() => {
          setShowPicker(false);
          onChange(value);
        }}
        onChange={c => {
          const newValue = converters[convert](c);
          setValue(newValue);
          onChange(newValue);
        }}
      />
    )}
  </React.Fragment>
);

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  convert: PropTypes.oneOf(Object.keys(converters)),
  // defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  floatingLabelText: PropTypes.string,
  TextFieldProps: PropTypes.shape(TextField.propTypes),
  showPicker: PropTypes.bool.isRequired,
  setShowPicker: PropTypes.func.isRequired,
  internalValue: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  convert: DEFAULT_CONVERTER,
  // defaultValue: '',
  name: '',
  hintText: '',
  floatingLabelText: '',
  TextFieldProps: {},
  internalValue: '',
};

const makeColorPicker = compose(
  withState('showPicker', 'setShowPicker', false),
  withState('internalValue', 'setValue', ({ defaultValue }) => defaultValue)
);

const MakedColorPicker = makeColorPicker(ColorPicker);

const EosColorPicker = ({
  field: { name, type, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  className,
  label,
  disabled,
  required,
  onChange,
  ...props
}) => {
  const error = get(errors, name);
  const touch = get(touched, name);

  const classes = classnames(
    'form-group',
    {
      error: !!error && !!touch,
    },
    className
  );
  return (
    <MakedColorPicker
      id={name}
      className={classes}
      type={type}
      error={!!error && !!touch}
      disabled={isSubmitting || disabled}
      label={label}
      helperText={touch && error && String(error)}
      fullWidth
      variant="outlined"
      margin="dense"
      required={required}
      {...field}
      {...props}
      onChange={color => {
        setFieldValue(name, color);
        if (onChange) {
          onChange(color);
        }
      }}
    />
  );
};

EosColorPicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

EosColorPicker.defaultProps = {
  className: '',
  label: null,
  disabled: false,
  required: false,
  onChange: null,
};

export default EosColorPicker;
