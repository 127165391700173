import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { i18n } from 'App.js';
import RoleCreateForm from './RoleCreateForm';

class RoleDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dialogOpened: false,
  };

  render() {
    const { dialogOpened, onClose, handleSubmit } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {i18n._('msg.roles.popup_title_add_new')}
        </CustomDialogTitle>
        <DialogContent>
          <RoleCreateForm handleSubmit={handleSubmit} closeDialog={onClose} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default RoleDialog;
