import { DateRange } from '../shared/date.model';

/**
 * @property {int} id
 * @property {string} name
 * @property {boolean} is_active
 * @property {DateRange} range
 * @property {boolean|null} is_constrained
 */
export class Season {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.is_active = data.is_active;
    this.range = new DateRange(data.range);
    this.is_constrained = data.is_constrained || false;
  }

  static formatFormData(data) {
    return {
      name: data.name,
      is_active: data.is_active,
      range: DateRange.formatFormData(data.range),
    };
  }
}
