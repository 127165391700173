import moment from 'moment';
import { NullableNumberRange } from '../shared/number-range.model';

/**
 * @property {bool} show
 * @property {bool} table
 * @property {string} view_type
 * @property {bool} show_positions
 * @property {bool} show_stats
 * @property {bool} show_matches
 * @property {bool} show_results
 * // @property {bool} show_lineup_only
 */
export class TeamWebSettings {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.show = +data.show;
    this.view_type = data.view_type || 'table';
    this.show_positions = +data.show_positions;
    this.show_stats = +data.show_stats;
    this.show_matches = +data.show_matches;
    this.show_results = +data.show_results;
    this.table = +data.table;
    // this.show_lineup_only = +data.show_lineup_only;
  }

  static formatData(data = {}) {
    return {
      show: typeof data.show === 'undefined' ? false : Boolean(data.show),
      view_type: typeof data.view_type === 'undefined' ? 'table' : data.view_type,
      show_positions:
        typeof data.show_positions === 'undefined' ? true : Boolean(data.show_positions),
      show_stats: typeof data.show_stats === 'undefined' ? true : Boolean(data.show_stats),
      show_matches: typeof data.show_matches === 'undefined' ? true : Boolean(data.show_matches),
      show_results: typeof data.show_results === 'undefined' ? true : Boolean(data.show_results),
      table: typeof data.table === 'undefined' ? true : Boolean(data.table),
    };
  }
}

/**
 * @property {int} id
 * @property {int|null} season_id
 * @property {string} name
 * @property {string|null} league_name
 * @property {string|null} description
 * @property {int|null} image_id
 * @property {string|null} color
 * @property {string|null} trainings
 * @property {string|null} stats_link
 * @property {int|null} order
 * @property {NullableNumberRange} birth_year_range
 * @property {string|null} games_display_config
 * @property {string|null} training_plan_config
 * @property {int|null} team_group_id
 * @property {string|null} gender
 * @property {bool} visible
 * @property {bool} public
 * // @property {string|null} ical_name
 * // @property {int|null} source_team_id
 * @property {moment.Moment|null} deleted_at
 */
export class ReducedTeam {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.season_id = data.season_id;
    this.name = data.name;
    this.league_name = data.league_name || '';
    this.description = data.description || '';
    this.image_id = data.image_id;
    this.color = data.color || '';
    this.trainings = data.trainings || '';
    this.stats_link = data.stats_link || '';
    this.order = data.order;
    this.birth_year_range = new NullableNumberRange(data.birth_year_range);
    this.games_display_config = data.games_display_config;
    this.training_plan_config = data.training_plan_config;
    this.team_group_id = data.team_group_id || 0;
    this.gender = data.gender || 0;
    this.visible = +data.visible;
    this.public = +data.public;
    // this.ical_name = data.ical_name;
    // this.source_team_id = data.source_team_id;
    this.deleted_at = data.deleted_at === null ? null : moment(data.deleted_at);
  }
}

/**
 * @property {TeamWebSettings} web_settings
 * @property {int|null} attendance_watch
 */
export class FullTeam extends ReducedTeam {
  /**
   * @param {object} data
   */
  constructor(data) {
    super(data);
    this.web_settings = new TeamWebSettings(data.web_settings);
    this.attendance_watch = data.attendance_watch === 0 ? 0 : data.attendance_watch || 90;
  }

  static formatCreateFormData(data) {
    return {
      season_id: data.season_id,
      team_group_id: data.team_group_id !== 0 ? data.team_group_id : null,
      name: data.name,
      league_name: data.league_name || null,
      description: data.description || null,
      color: data.color || null,
      birth_year_range: new NullableNumberRange(data.birth_year_range),
      trainings: data.trainings || null,
      stats_link: data.stats_link || null,
      web_settings: TeamWebSettings.formatData(),
      training_plan_config:
        typeof data.training_plan_config === 'undefined' ? 'on' : data.training_plan_config,
      games_display_config:
        typeof data.games_display_config === 'undefined'
          ? 'show_individually'
          : data.games_display_config,
      attendance_watch: typeof data.attendance_watch === 'undefined' ? 90 : data.attendance_watch,
      visible: typeof data.visible === 'undefined' ? true : data.visible,
      public: typeof data.public === 'undefined' ? false : data.public,
      order: data.order || null,
      gender: typeof data.gender === 'string' ? data.gender : null,
      image_id: data.image_id || null,
    };
  }

  static formatForSorter(data) {
    return {
      id: data.id,
      name: data.name,
      order: data.order,
    };
  }

  static formatForSimpleSorter(data) {
    return {
      id: data.id,
      order: data.order,
    };
  }
}
