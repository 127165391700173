/**
 * @property {string} module
 * @property {string} level
 */
export class Permission {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.module = data.module;
    this.level = data.level;
  }
}
