import { DataGridState } from './datagrid.state.model';
import { apiCall } from '../utils/apiCall';

/**
 * @callback fetchCallback
 * @return {DataGridUrlBuilder}
 */

/**
 * @callback builderCallback
 * @param {DataGridUrlBuilder} builder
 */

export class DataGrid {
  /**
   * @param {fetchCallback} fetchCallback
   * @param {builderCallback=} builderCallback
   */
  constructor(fetchCallback, builderCallback) {
    this.fetchCallback = fetchCallback;
    this.builderCallback = builderCallback;
    this.cancelToken = null;
  }

  /**
   * @param {DataGridState} state
   * @return {Promise<DataGridState>}
   */
  async fetchData(state) {
    if (this.cancelToken !== null) {
      this.cancelToken.cancel();
    }

    this.cancelToken = apiCall.CancelToken.source();
    const dataGridState = new DataGridState(state);
    const config = {
      cancelToken: this.cancelToken.token,
    };

    const builder = this.fetchCallback();

    if (typeof this.builderCallback === 'function') {
      this.builderCallback(builder);
    }

    return builder
      .fillFromState(dataGridState)
      .fetch(config)
      .then(response => {
        this.cancelToken = null;
        return DataGridState.fromResponse(response, dataGridState);
      })
      .catch(thrown => {
        this.cancelToken = null;
        throw thrown;
      });
  }
}
