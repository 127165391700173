import { AbilityBuilder } from '@casl/ability';

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
const subjectName = item => {
  if (!item || typeof item === 'string') {
    return item;
  }

  return item.__type;
};

const modulesList = [
  { module: 'seasons', type: 'Seasons' },
  { module: 'members', type: 'Members' },
  { module: 'event_types', type: 'EventTypes' },
  { module: 'roles', type: 'Roles' },
  { module: 'halls', type: 'Halls' },
  { module: 'teams', type: 'Teams' },
];

const ABILITY_LEVEL_NONE = 0;
const ABILITY_LEVEL_ACCESS = 1;
const ABILITY_LEVEL_READ = 2;
const ABILITY_LEVEL_WRITE = 3;

const ABILITY_LEVEL_NONE_TEXT = null;
const ABILITY_LEVEL_ACCESS_TEXT = 'access';
const ABILITY_LEVEL_READ_TEXT = 'read';
const ABILITY_LEVEL_WRITE_TEXT = 'write';

export {
  ABILITY_LEVEL_NONE_TEXT,
  ABILITY_LEVEL_ACCESS_TEXT,
  ABILITY_LEVEL_READ_TEXT,
  ABILITY_LEVEL_WRITE_TEXT,
};

const convertNumToTextLevel = numLevel => {
  switch (numLevel) {
    case 1:
      return ABILITY_LEVEL_ACCESS_TEXT;
    case 2:
      return ABILITY_LEVEL_READ_TEXT;
    case 3:
      return ABILITY_LEVEL_WRITE_TEXT;
    default:
      return ABILITY_LEVEL_NONE_TEXT;
  }
};

const convertTextToNumLevel = numLevel => {
  switch (numLevel) {
    case ABILITY_LEVEL_ACCESS_TEXT:
      return 1;
    case ABILITY_LEVEL_READ_TEXT:
      return 2;
    case ABILITY_LEVEL_WRITE_TEXT:
      return 3;
    default:
      return 0;
  }
};

const computeMinimalPermissions = permissions => {
  if (typeof permissions === 'string') {
    return permissions;
  }

  const convertedLevels = permissions.map(level => {
    switch (level) {
      case ABILITY_LEVEL_ACCESS_TEXT:
        return ABILITY_LEVEL_ACCESS;
      case ABILITY_LEVEL_READ_TEXT:
        return ABILITY_LEVEL_READ;
      case ABILITY_LEVEL_WRITE_TEXT:
        return ABILITY_LEVEL_WRITE;
      default:
        return ABILITY_LEVEL_NONE;
    }
  });

  return convertNumToTextLevel(Math.min(...convertedLevels));
};
export { computeMinimalPermissions, convertTextToNumLevel };

window.convertTextToNumLevel = convertTextToNumLevel;
window.convertNumToTextLevel = convertNumToTextLevel;
window.computeMinimalPermissions = computeMinimalPermissions;

export default AbilityBuilder.define({ subjectName }, can => {
  can(['visit'], 'Module', { level: ABILITY_LEVEL_READ_TEXT });
  can(['visit'], 'Module', { level: ABILITY_LEVEL_WRITE_TEXT });

  can(['visit'], 'SettingsSubModule', { level: ABILITY_LEVEL_READ_TEXT });
  can(['visit'], 'SettingsSubModule', { level: ABILITY_LEVEL_WRITE_TEXT });

  can(['read'], modulesList.map(item => item.type), { level: ABILITY_LEVEL_READ_TEXT });
  can(['read', 'manage'], modulesList.map(item => item.type), { level: ABILITY_LEVEL_WRITE_TEXT });
});
