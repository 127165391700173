const configLocal = {
  url: {
    prefix: "https://api.eosclubzone.cz/api/v0",
  },
  keys: {
    token: 'CLUB_ZONE_TOKEN',
    userInfo: 'CLUB_ZONE_USER',
    clubInfo: 'CLUB_ZONE_CLUB',
  },
  credentials: {},
  googleMapsApiKey: 'AIzaSyA48eYY_7nQp39VU7mcxeB9QSI4C0FcmdE',
};

export default configLocal;
