import moment from 'moment';

/**
 * @property {int} id
 * @property {int} member_id
 * @property {int} team_id
 * @property {int|null} dress_number
 * @property {boolean} show_on_web
 * @property {boolean} is_player
 * @property {boolean} is_organizer
 * @property {int|null} trainer_role_id
 * @property {int|null} position_id
 * @property {moment.Moment|null} created
 */
export class TeamMember {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = Number(data.id);
    this.show_on_web = Boolean(data.show_on_web);
    this.is_player = Boolean(data.is_player);
    this.is_organizer = Boolean(data.is_organizer);
    this.member_id = Number(data.member_id);
    this.team_id = Number(data.team_id);

    this.dress_number = data.dress_number ? Number(data.dress_number) : null;
    this.trainer_role_id = data.trainer_role_id ? Number(data.trainer_role_id) : null;
    this.position_id = data.position_id ? Number(data.position_id) : null;
    this.created = data.created ? moment(data.created) : null;
  }
}
