import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import { Trans } from '@lingui/macro';
import CustomizedLink from 'components/Base/Link';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { StyledTable } from 'components/Table/StyledTable';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';
import EventTypeIcon from './EventTypeIcon';

class EventTypesTable extends Component {
  static propTypes = {
    eventTypes: PropTypes.array,
    loading: PropTypes.bool,
    editEventType: PropTypes.func.isRequired,
    deleteEventType: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    eventTypes: [],
    loading: false,
  };

  render() {
    const { eventTypes, loading, editEventType, deleteEventType, managePermissions } = this.props;

    if (!loading && eventTypes.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.event_types.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <div>
        <Card>
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Trans id="msg.id" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.icon" />
                </TableCell>
                <TableCell style={{ width: '100%' }}>
                  <Trans id="msg.name" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.event_types.event_type_has_attendance" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.event_types.event_type_requires_excuse" />
                </TableCell>
                {managePermissions && (
                  <TableCell align="center">
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoadingContent colSpan={managePermissions ? 6 : 5} />}
              {!loading &&
                eventTypes.map(et => (
                  <TableRow key={et.id}>
                    <TableCell align="center">{et.id}</TableCell>
                    <TableCell align="center">
                      <EventTypeIcon size={40} color={et.color} icon={et.icon} />
                    </TableCell>
                    <TableCell>
                      {!et.is_protected && managePermissions ? (
                        <CustomizedLink to="#" noWrap onClick={() => editEventType(et)}>
                          {et.name}
                        </CustomizedLink>
                      ) : (
                        et.name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {et.has_attendance ? <Icon>check</Icon> : <Icon>remove</Icon>}
                    </TableCell>
                    <TableCell align="center">
                      {et.requires_excuse ? <Icon>check</Icon> : <Icon>remove</Icon>}
                    </TableCell>

                    {managePermissions && (
                      <TableCell style={{ textAlign: 'center' }}>
                        <ContextMenu
                          variant="icon"
                          tooltip={
                            et.is_protected
                              ? i18n._('msg.event_types.protected_event_type_tooltip')
                              : i18n._('msg.actions')
                          }
                          color="inherit"
                          icon="more_vert"
                          disabled={et.is_protected}
                          menuItems={[
                            {
                              text: i18n._('msg.record_update'),
                              action: () => editEventType(et),
                            },
                            !et.is_constrained && {
                              text: i18n._('msg.record_delete'),
                              action: () => deleteEventType(et.id),
                              variant: 'delete',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </Card>
      </div>
    );
  }
}

EventTypesTable.propTypes = {
  eventTypes: PropTypes.array,
};

EventTypesTable.defaultProps = {
  eventTypes: [],
};

export default EventTypesTable;
