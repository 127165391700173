import * as yup from 'yup';
import libphonenumber from 'google-libphonenumber';
// import * as moment from 'moment';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

yup.addMethod(yup.string, 'phone', function(message) {
  return this.test({
    name: 'phone',
    // exclusive: true,
    message: message || 'Must be a phone number',
    test: value => {
      try {
        if (typeof value === 'undefined') {
          return true;
        }
        const phone = phoneUtil.parse(value, 'CZ');
        return phoneUtil.isValidNumber(phone);
      } catch (e) {
        return false;
      }
    },
  });
});

// const invalidDate = new Date('');
//
// function parseDateFromFormats(formats, parseStrict) {
//   console.log({ formats, parseStrict });
//
//   return this.transform(function(value, originalValue) {
//     window.x = this;
//     if (this.isType(value)) {
//       console.log({ value });
//       return value;
//     }
//
//     // const newValue = moment(originalValue, formats, parseStrict);
//     value = moment(originalValue);
//     console.log({
//       value,
//       formats,
//       parseStrict,
//       originalValue,
//       isValid: value.isValid(),
//     });
//     return value.isValid() ? value.format(formats) : invalidDate;
//   });
// }
//
// // `addMethod` doesn't do anything special it's
// // equivalent to: yup.date.prototype.format = parseDateFromFormats
// yup.addMethod(yup.date, 'format', parseDateFromFormats);
