import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { AppBar, Badge, Card, CardContent, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { RoleManagementService } from 'api/role.service';
import LoadingMessage from 'components/Base/LoadingMessage';
import PageHeader from 'components/PageHeader';
import routes from 'Routes.js';
import { i18n } from 'App.js';
import RoleUpdateForm from 'components/Management/Roles/RolesUpdateForm';
import SwipeableViews from 'react-swipeable-views';
import { Role } from 'models/Role';
import { CurrentService } from 'api/current.service';
import { MemberManagementService } from 'api/member.service';
import { withStyles } from '@material-ui/core/styles';
import RoleMembersTable from 'components/Management/Roles/RoleMembersTable';
import Whisperer from 'components/Whisperer';
import { differenceWith, sortBy } from 'lodash';
import ability, { ABILITY_LEVEL_WRITE_TEXT } from 'ability.js';
import RoleStaticView from 'components/Management/Roles/RoleStaticView';

const RoleNotFound = () => (
  <div>
    <Typography variant="subtitle1">
      <Trans id="msg.roles.role_not_found" />
    </Typography>

    <Typography>
      <Link to={routes.management.roles.all}>
        <Trans id="msg.roles.back_to_roles" />
      </Link>
    </Typography>
  </div>
);

const styles = theme => ({
  badge: {
    top: '50%',
    right: -16,
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
});

class Update extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    loadingRoleInfo: false,
    loadingPermissions: false,
    loadingModules: false,
    loadingMembers: false,
    roleNotFound: false,
    role: null,
    permissions: {
      management: [],
      web: [],
    },
    modules: {
      management: [],
      web: [],
    },
    value: 1,
    members: [],
    possibleMembers: [],
    assignedMembers: [],
  };

  componentDidMount() {
    const {
      match: {
        params: { roleId },
      },
      // enqueueSnackbar,
    } = this.props;

    this.setState({
      roleId,
    });

    if (!roleId || Number.isNaN(parseInt(roleId))) {
      this.setState({
        roleNotFound: true,
      });
      return;
    }

    this.fetchRole(roleId);
    this.fetchPermissions(roleId);
    this.fetchModules();

    // TODO: add possibility to fetch only role assigned members if user has no access to Members module
    this.fetchMembers();
  }

  fetchRole = async roleId => {
    const { enqueueSnackbar } = this.props;

    this.setState({
      loadingRoleInfo: true,
    });

    await RoleManagementService.get(roleId, { with: 'members_count' })
      .then(response => {
        this.setState({
          role: response,
          roleNotFound: false,
          loadingRoleInfo: false,
          value: response.editable ? 0 : 1,
        });
      })
      .catch(error => {
        this.setState(
          {
            roleNotFound: true,
            loadingRoleInfo: false,
          },
          () => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  fetchPermissions = async roleId => {
    this.setState({
      loadingPermissions: true,
    });

    await RoleManagementService.fetchPermissions(roleId).then(response => {
      const permissions = {
        web: [],
        management: [],
      };

      response.map(p =>
        p.module.indexOf('web_') === 0 ? permissions.web.push(p) : permissions.management.push(p)
      );

      this.setState({
        permissions,
        loadingPermissions: false,
      });
    });
  };

  fetchModules = async () => {
    this.setState({
      loadingModules: true,
    });
    await CurrentService.modules().then(response => {
      this.setState({
        modules: response,
        loadingModules: false,
      });
    });
  };

  fetchMembers = () => {
    const {
      match: {
        params: { roleId },
      },
    } = this.props;

    this.setState({
      loadingMembers: true,
    });

    MemberManagementService.getAll()
      .sortBy('last_name,first_name')
      .turnOffPagination()
      .fetch()
      .then(response => {
        this.setState(
          {
            members: response,
            assignedMembers: response.filter(m => m.role_id === Number.parseInt(roleId)),
            // possibleMembers: () => this.refreshPossibleMembers(),
            loadingMembers: false,
          },
          this.refreshPossibleMembers
        );
      })
      .catch(error => {
        console.log({ error });
        this.setState({
          loadingMembers: false,
        });
      });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  refreshPossibleMembers = () => {
    const { members, assignedMembers } = this.state;

    const possibleMembers = differenceWith(members, assignedMembers, (a, b) => a.id === b.id);

    this.setState({
      possibleMembers,
    });
  };

  updateRole = async (values, actions) => {
    const { roleId } = this.state;
    const { enqueueSnackbar } = this.props;

    const formattedData = Role.formatFormData(values);
    await RoleManagementService.update(roleId, formattedData)
      .then(response => {
        this.setState(
          {
            role: response,
          },
          () => {
            enqueueSnackbar(i18n._('msg.roles.update_success'), { variant: 'success' });
          }
        );

        // TODO: check if updated role is current user's role
        // if so, there should be GET request for /api/auth/current endpoint
        // CurrentService.user().then(data => {
        //   console.log({ data });
        //   // auth.setUserInfo({ user: data.user }, values.rememberMe);
        //   // auth.setClubInfo(data.club, values.rememberMe);
        //   // actions.setSubmitting(false);
        //   // login();
        // });
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));

    actions.setSubmitting(false);
  };

  assignMemberRole = async memberId => {
    const { enqueueSnackbar } = this.props;
    const { roleId, assignedMembers } = this.state;

    await MemberManagementService.assignRole(memberId, { role_id: parseInt(roleId) })
      .then(response => {
        const sortedAssignedMembers = sortBy(
          [...assignedMembers, response],
          ['last_name', 'first_name'],
          'asc'
        );
        this.setState(
          {
            assignedMembers: sortedAssignedMembers,
          },
          () => {
            this.refreshPossibleMembers();
            enqueueSnackbar(i18n._('msg.roles.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  resignMemberRole = async memberId => {
    const { enqueueSnackbar } = this.props;
    const { assignedMembers } = this.state;

    await MemberManagementService.assignRole(memberId, { role_id: null })
      .then(response => {
        const resignedMemberIndexInAssignedMembers = assignedMembers.findIndex(
          am => am.id === response.id
        );
        assignedMembers.splice(resignedMemberIndexInAssignedMembers, 1);

        this.setState(
          {
            assignedMembers,
          },
          () => {
            this.refreshPossibleMembers();
            enqueueSnackbar(i18n._('msg.roles.remove_success'), { variant: 'info' });
          }
        );
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  render() {
    const {
      role,
      roleNotFound,
      loadingRoleInfo,
      loadingPermissions,
      loadingModules,
      permissions,
      value,
      modules,
      loadingMembers,
      // members,
      possibleMembers,
      assignedMembers,
    } = this.state;

    const { classes, accessLevel } = this.props;

    return (
      <div>
        {roleNotFound && <RoleNotFound />}

        {loadingRoleInfo && <LoadingMessage />}

        {!loadingRoleInfo && !roleNotFound && role && (
          <React.Fragment>
            <PageHeader
              title={role.name}
              breadcrumbsItems={[
                {
                  url: routes.management.roles.all,
                  // title: 'some title',
                  text: i18n._('msg.roles.page_title'),
                },
                { url: null, text: role.name },
              ]}
            />

            <Paper>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  // fullWidth
                  // centered
                >
                  <Tab
                    label={i18n._('msg.roles.detail.tabs.settings_info')}
                    disabled={Boolean(!role.editable)}
                  />
                  <Tab
                    label={
                      <Badge
                        color="secondary"
                        invisible={loadingMembers || assignedMembers.length === 0}
                        classes={{ badge: classes.badge }}
                        badgeContent={assignedMembers.length}
                      >
                        {/* <Trans id="msg.roles.detail.tabs.members_info" /> */}
                        members
                      </Badge>
                    }
                    disabled={loadingMembers}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis="x"
                index={value}
                onChangeIndex={this.handleChangeIndex}
                style={{ flex: 1 }}
              >
                <div>
                  <Card elevation={0} square>
                    {role.editable && accessLevel === ABILITY_LEVEL_WRITE_TEXT ? (
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          <Trans id="msg.roles.detail.settings_info" />
                        </Typography>
                        <RoleUpdateForm
                          handleSubmit={this.updateRole}
                          role={role}
                          permissions={permissions}
                          modules={modules}
                          loadingPermissions={loadingPermissions}
                          loadingModules={loadingModules}
                        />
                      </CardContent>
                    ) : (
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          <Trans id="msg.roles.read_only_settings" />
                        </Typography>
                        <RoleStaticView role={role} permissions={permissions} modules={modules} />
                      </CardContent>
                    )}
                  </Card>
                </div>

                <div>
                  <Card elevation={0} square>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        <Trans id="msg.roles.detail.members_info" />
                      </Typography>

                      {accessLevel === ABILITY_LEVEL_WRITE_TEXT && (
                        <Whisperer
                          possibleRecords={possibleMembers}
                          placeholder="nějakej text"
                          usage="roles"
                          recordType="members"
                          whispererItemAction={id => {
                            this.assignMemberRole(id);
                          }}
                        />
                      )}

                      <RoleMembersTable
                        loadingMembers={loadingMembers}
                        assignedMembers={assignedMembers}
                        resignMemberRole={this.resignMemberRole}
                        managePermissions={ability.can('manage', {
                          __type: 'Roles',
                          level: accessLevel,
                        })}
                      />
                    </CardContent>
                  </Card>
                </div>
              </SwipeableViews>
            </Paper>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(Update));
