import { Trans, t } from '@lingui/macro';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { i18n } from 'App.js';
import { AuthConsumer } from 'AuthContext.js';
import EosCheckbox from 'components/Forms/EosCheckbox.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import TeamLogo from 'components/TeamLogo';
import auth from 'utils/auth';
import { AuthService } from 'api/auth.service';
import { CurrentService } from 'api/current.service';
import config from 'config';
import SubmitButton from 'components/Forms/SubmitButton';
import MemberPicker from 'components/Header/MemberPicker';
import CustomizedLink from 'components/Base/Link';
import routes from 'Routes.js';

const LoginForm = ({ isSubmitting, errors, values }) => (
  <EosForm noValidate submitting={isSubmitting}>
    <Grid container spacing={24}>
      {!values.signInWithLogin && (
        <React.Fragment>
          <Grid item xs={12}>
            <Field
              type="email"
              name="login"
              label={i18n._('msg.email_address')}
              autoComplete="off"
              required
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="password"
              name="password"
              label={i18n._('msg.password')}
              required
              component={EosTextInput}
            />
          </Grid>
          {/* <Grid item xs={12}>
        <Field
          type="text"
          name="domain_name"
          label={i18n._('msg.domain')}
          required
          component={EosTextInput}
        />
      </Grid> */}
          <Grid item xs={12}>
            <Field
              type="checkbox"
              name="rememberMe"
              label={i18n._('msg.remember_me')}
              checked={values.rememberMe}
              component={EosCheckbox}
            />
          </Grid>
        </React.Fragment>
      )}

      {values.signInWithLogin && (
        <Grid item xs={12}>
          <Field
            name="loginOldType"
            label={i18n._('msg.login')}
            autoComplete="off"
            required
            component={EosTextInput}
          />
        </Grid>
      )}

      {errors.state && (
        <Grid item xs={12}>
          <div>{errors.state}</div>
        </Grid>
      )}
    </Grid>

    <SubmitArea isSubmitting={isSubmitting}>
      {!values.signInWithLogin && (
        <SubmitButton
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          color="primary"
          variant="contained"
        >
          {isSubmitting ? <Trans id="msg.login.logging_in" /> : <Trans id="msg.login.log_in" />}
        </SubmitButton>
      )}

      {values.signInWithLogin && (
        <SubmitButton
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          color="primary"
          variant="contained"
        >
          {isSubmitting ? (
            <Trans id="msg.login.sending_invitation" />
          ) : (
            <Trans id="msg.login.send_invitation" />
          )}
        </SubmitButton>
      )}
    </SubmitArea>
  </EosForm>
);

LoginForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

/* const LoginFormInvite = ({ isSubmitting, errors }) => (
  <EosForm noValidate submitting={isSubmitting}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          name="loginOldType"
          label={i18n._('msg.login')}
          autoComplete="off"
          required
          component={EosTextInput}
        />
      </Grid>
      {errors.state && (
        <Grid item xs={12}>
          <div>{errors.state}</div>
        </Grid>
      )}
    </Grid>

    <SubmitArea isSubmitting={isSubmitting}>
      <SubmitButton
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        color="primary"
        variant="contained"
      >
        {isSubmitting ? (
          <Trans id="msg.login.sending_invitation" />
        ) : (
          <Trans id="msg.login.send_invitation" />
        )}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

LoginFormInvite.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}; */

const LoginComponent = styled.div`
  background: ${props => props.theme.club.secondary_bg_color};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  padding: ${props => `${props.theme.spacing.unit * 3}px`};
  width: 90%;
  max-width: 350px;
  text-align: center;
  border-radius: ${props => `${props.theme.shape.borderRadius}px`};

  form {
    text-align: left;
  }
`;

class Login extends Component {
  state = {
    signInWithLogin: false,
  };

  initialValues = {
    login: config.local.credentials.login || 'dev@eosmedia.cz',
    password: config.local.credentials.password || '22Eos33',
    // domain_name: config.local.credentials.domain_name || window.location.hostname,
    rememberMe: config.local.credentials.rememberMe || true,
    loginOldType: '',
  };

  validationSchema = yup.object().shape({
    signInWithLogin: yup.bool(),
    login: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .required(i18n._('msg.form.required')),
    password: yup
      .string()
      .min(6, i18n._('msg.form.min_length', { min_length: 6 }))
      .required(i18n._(t`msg.form.required`)),
    // domain_name: yup.string().required(i18n._(t`msg.form.required`)),
    loginOldType: yup.string().when('signInWithLogin', {
      is: true,
      then: yup
        .string()
        // .matches(/^\d{2}[a-z]+$/, i18n._('msg.form.invalid_format'))
        .required(i18n._('msg.form.required')),
      otherwise: yup.string().nullable(),
    }),
  });

  /* initialValuesInvite = {
    loginOldType: '',
  };

  validationSchemaInvite = yup.object().shape({
    loginOldType: yup.string().required(i18n._('msg.form.required')),
  }); */

  handleSubmit = (values, actions, login) => {
    const { signInWithLogin } = this.state;

    if (signInWithLogin) {
      // TODO: make some request
      setTimeout(() => {
        alert(`TODO: send some request for new invitation with login: ${values.loginOldType}`);
        actions.setSubmitting(false);
      }, 500);
      return;
    }

    AuthService.login(values.login, values.password, values.domain_name, values.rememberMe)
      .then(token => {
        auth.setToken(token, values.rememberMe);

        CurrentService.userAndClub().then(data => {
          // auth.setUserInfo({ user: data.user }, values.rememberMe);
          auth.setClubInfo(data.club, values.rememberMe);
          actions.setSubmitting(false);
          login(data.user);
        });
      })
      .catch(error => {
        actions.setSubmitting(false);
        actions.setErrors({ state: <Trans id={`${error.response.data.message}`} /> });
      });
  };

  /* handleSubmitInvite = (values, actions) => {
    // TODO: make some request
    setTimeout(() => {
      alert(`TODO: send some request for new invitation with login: ${values.loginOldType}`);
      actions.setSubmitting(false);
    }, 500);
  }; */

  handleLoginTypeChange = () => {
    const { signInWithLogin } = this.state;
    this.setState({
      signInWithLogin: !signInWithLogin,
    });
  };

  handleChange = values => {
    const { authValues } = this.state;
    this.setState({
      authValues: { ...authValues, ...values },
    });
  };

  /* getRenderedForm = (club, login) => {
    const { signInWithLogin } = this.state;

    if (signInWithLogin) {
      return (
        <Formik
          initialValues={this.initialValuesInvite}
          onSubmit={(values, actions) => {
            this.handleSubmitInvite({ ...values }, actions);
          }}
          validationSchema={this.validationSchemaInvite}
          render={LoginFormInvite}
        />
      );
    }

    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={(values, actions) => {
          this.handleSubmit({ ...values, domain_name: club.domain_name }, actions, login);
        }}
        validationSchema={this.validationSchema}
        render={LoginForm}
      />
    );
  }; */

  render() {
    const { signInWithLogin } = this.state;

    return (
      <AuthConsumer>
        {({ login, user, club, memberPickerOpened, switchActiveMember, toggleMemberPicker }) => (
          <React.Fragment>
            <LoginComponent>
              <Helmet>
                <title>{i18n._('msg.login.page_title')}</title>
              </Helmet>
              <StyledPaper>
                <TeamLogo />
                <Typography variant="h6" component="h3" gutterBottom>
                  <Trans id="msg.product_name" />
                </Typography>
                <Typography variant="h3" component="h1" gutterBottom>
                  {club.name}
                </Typography>
                <Typography variant="subtitle1" component="p" gutterBottom>
                  <Trans id="msg.login.log_in_first" />
                </Typography>
                {/* {this.getRenderedForm(club, login)} */}
                <Formik
                  initialValues={{ ...this.initialValues, signInWithLogin }}
                  onSubmit={(values, actions) => {
                    this.handleSubmit({ ...values, domain_name: club.domain_name }, actions, login);
                  }}
                  enableReinitialize
                  validationSchema={this.validationSchema}
                  render={LoginForm}
                />
                <Typography style={{ textAlign: 'center' }}>
                  <CustomizedLink
                    to="#"
                    onClick={evt => {
                      evt.preventDefault();
                      this.handleLoginTypeChange();
                    }}
                  >
                    {!signInWithLogin ? (
                      <Trans id="msg.login.sign_in_with_login" />
                    ) : (
                      <Trans id="msg.login.sign_in_with_email" />
                    )}
                  </CustomizedLink>{' '}
                  <CustomizedLink to={routes.resetPassword}>
                    <Trans id="msg.login.password_reset" />
                  </CustomizedLink>
                </Typography>
              </StyledPaper>
            </LoginComponent>

            <MemberPicker
              open={memberPickerOpened}
              onClose={toggleMemberPicker}
              members={user ? user.members : []}
              switchActiveMember={switchActiveMember}
            />
          </React.Fragment>
        )}
      </AuthConsumer>
    );
  }
}

export default Login;
