import { createMuiTheme } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a1a1a',
      dark: '#333',
    },
    secondary: purple,
  },
  typography: {
    // fontSize: 14,
    // htmlFontSize: 10,
    useNextVariants: true,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.4rem',
    },
    h3: {
      fontSize: '2rem',
    },
  },
});

export default muiTheme;
