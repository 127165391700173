import { ApiService } from './api.service';

export class ManagementService extends ApiService {
  static managementEndpoint = 'management';

  static endpointSuffix = null;

  /**
   * @param id
   * @return {string}
   * @protected
   */
  static buildUrl(id = null) {
    let url = `/${this.managementEndpoint}/${this.endpoint}`;
    if (id) {
      url = `${url}/${id}`;
    }
    if (this.endpointSuffix) {
      url = `${url}/${this.endpointSuffix}`;
    }
    return url;
  }
}
