import React, { Component } from 'react';
import MemberTypesTable from 'components/Management/Settings/MemberTypes/MemberTypesTable';
import MemberTypeDialog from 'components/Management/Settings/MemberTypes/MemberTypeDialog';
import { MemberTypeManagementService } from 'api/member-type.service';
import { i18n } from 'App.js';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { MemberType } from 'models/MemberType';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import routes from 'Routes.js';
import ability from 'ability.js';

class MemberTypes extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    memberTypes: [],
    memberType: null,
    dialogOpened: false,
    loading: false,
  };

  componentDidMount() {
    this.fetchMemberTypes();
  }

  fetchMemberTypes = () => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loading: true,
    });

    MemberTypeManagementService.getAll()
      .fetch()
      .then(response => {
        if (response.length > 0) {
          this.setState(
            {
              memberTypes: response,
              loading: false,
            },
            () => {
              enqueueSnackbar(i18n._('msg.member_types.loading_success'), { variant: 'success' });
            }
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({
          memberTypes: [],
          loading: false,
        });
        enqueueSnackbar(i18n._('msg.member_types.loading_error'), { variant: 'error' });
      });
  };

  openEditMemberType = memberType => {
    this.setState({
      memberType,
      dialogOpened: true,
    });
  };

  openCreateMemberType = () => {
    this.setState({
      memberType: null,
      dialogOpened: true,
    });
  };

  deleteMemberType = memberTypeId => {
    const { enqueueSnackbar } = this.props;

    MemberTypeManagementService.delete(memberTypeId)
      .then(() => {
        const { memberTypes } = this.state;

        const deletedMemberTypeIndexInMemberTypes = memberTypes.findIndex(
          mt => mt.id === memberTypeId
        );

        // eslint-disable-next-line
        memberTypes.splice(deletedMemberTypeIndexInMemberTypes, 1);

        this.setState({
          memberTypes,
        });
        enqueueSnackbar(i18n._('msg.member_types.delete_success'), { variant: 'info' });
      })
      .catch(e => {
        console.log({ e });
        enqueueSnackbar(i18n._(e.response.data.message), { variant: 'error' });
      });
  };

  closeDialog = () => {
    this.setState({
      dialogOpened: false,
      memberType: null,
    });
  };

  createMemberType = async data => {
    const { enqueueSnackbar } = this.props;

    const formattedData = MemberType.formatFormData(data);
    await MemberTypeManagementService.create(formattedData).then(response => {
      const { memberTypes } = this.state;

      this.setState(
        {
          memberTypes: [...memberTypes, response],
        },
        () => {
          enqueueSnackbar(i18n._('msg.member_types.create_success'), { variant: 'success' });
        }
      );
    });
  };

  updateMemberType = async (memberTypeId, data) => {
    const { enqueueSnackbar } = this.props;

    const formattedData = MemberType.formatFormData(data);
    await MemberTypeManagementService.update(memberTypeId, formattedData).then(response => {
      const { memberTypes } = this.state;

      const updatedMemberTypeIndexInMemberTypes = memberTypes.findIndex(p => p.id === memberTypeId);

      memberTypes[updatedMemberTypeIndexInMemberTypes] = response;

      this.setState(
        {
          memberTypes,
        },
        () => {
          enqueueSnackbar(i18n._('msg.member_types.update_success'), { variant: 'success' });
        }
      );
    });
  };

  operationCallback = (promise, actions) =>
    promise
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });

  handleSubmit = (values, actions) => {
    const { memberType } = this.state;
    const promise = memberType
      ? this.updateMemberType(memberType.id, values)
      : this.createMemberType(values);

    this.operationCallback(promise, actions);
  };

  render() {
    const { memberTypes, memberType, dialogOpened, loading } = this.state;
    const { accessLevel } = this.props;
    return (
      <div>
        <PageHeader
          title={i18n._('msg.member_types.page_title')}
          customControlsPrimary={[
            ability.can('manage', {
              __type: 'Members',
              level: accessLevel,
            }) && <FabButton variant="round" size="small" onClick={this.openCreateMemberType} />,
          ]}
          breadcrumbsItems={[
            {
              url: routes.management.settings.all,
              // title: 'some title',
              text: i18n._('msg.settings.page_title'),
            },
            { url: null, text: i18n._('msg.member_types.page_title') },
          ]}
        />

        <MemberTypesTable
          memberTypes={memberTypes}
          loading={loading}
          editMemberType={this.openEditMemberType}
          deleteMemberType={this.deleteMemberType}
          managePermissions={ability.can('manage', {
            __type: 'Members',
            level: accessLevel,
          })}
        />

        <MemberTypeDialog
          dialogOpened={dialogOpened}
          memberType={memberType}
          onClose={this.closeDialog}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default withSnackbar(MemberTypes);
