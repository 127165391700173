import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Badge,
  withStyles,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  root: {},
  badgeRoot: {
    paddingRight: theme.spacing.unit * 2,
  },
  badgeActiveSeason: {
    top: '50%',
    right: theme.spacing.unit / 2,
    width: theme.spacing.unit,
    height: theme.spacing.unit,
    background: green[500],
  },
});

class SeasonsList extends Component {
  static propTypes = {
    seasons: PropTypes.array,
    selectedSeason: PropTypes.number,
    loading: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    seasons: [],
    selectedSeason: 0,
    loading: false,
  };

  state = {
    labelWidthSeasons: 0,
  };

  componentDidMount = () => {
    this.setState({
      // eslint-disable-next-line
      labelWidthSeasons: ReactDOM.findDOMNode(this.InputLabelRefSeasons) && ReactDOM.findDOMNode(this.InputLabelRefSeasons).offsetWidth || 0,
    });
  };

  render() {
    const { seasons, handleChange, selectedSeason, classes, loading } = this.props;
    const { labelWidthSeasons } = this.state;
    return (
      <FormControl variant="outlined" margin="dense" fullWidth disabled={loading}>
        <InputLabel
          ref={ref => {
            this.InputLabelRefSeasons = ref;
          }}
          htmlFor="season"
          margin="dense"
        >
          <Trans id="msg.season" />
          {/* Season */}
        </InputLabel>
        <Select
          value={selectedSeason || 0}
          onChange={handleChange}
          input={
            <OutlinedInput
              labelWidth={labelWidthSeasons}
              margin="dense"
              name="season"
              id="season"
            />
          }
        >
          <MenuItem value={0} disabled>
            <em>
              <Trans id="msg.select_season" />
            </em>
          </MenuItem>
          {seasons.length > 0 &&
            seasons.map(season => (
              <MenuItem value={season.id} key={season.id} classes={{ root: classes.menuItem }}>
                {season.active ? (
                  <Badge
                    color="primary"
                    variant="dot"
                    classes={{ root: classes.badgeRoot, badge: classes.badgeActiveSeason }}
                  >
                    {season.name} ({season.id})
                  </Badge>
                ) : (
                  `${season.name} (${season.id})`
                )}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(SeasonsList);
