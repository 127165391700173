import { sortBy } from 'lodash';
import { TeamGroup } from '../models/TeamGroup';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class TeamGroupManagementService extends ManagementService {
  static endpoint = 'team-groups';

  /**
   * @param {number} id
   * @return {Promise<TeamGroup>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(TeamGroupManagementService.buildUrl(id))
      .then(response => new TeamGroup(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(TeamGroupManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<TeamGroup>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall
      .get(TeamGroupManagementService.buildUrl(), { ...config, params })
      .then(response => {
        const teamGroups = response.data.data.map(data => new TeamGroup(data));

        if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
          return teamGroups;
        }

        return new PaginatedData(teamGroups, response.data.meta);
      });
  }

  /**
   * @param {object} data
   * @return {Promise<TeamGroup>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(TeamGroupManagementService.buildUrl(), data)
      .then(response => new TeamGroup(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<TeamGroup>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(TeamGroupManagementService.buildUrl(id), data)
      .then(response => new TeamGroup(response.data));
  }

  /**
   * @param {object[]} data
   * @return {Promise<TeamGroup[]>}
   */
  static async updateMultiple(data) {
    return apiCall
      .patch(TeamGroupManagementService.buildUrl(), { team_groups: data })
      .then(response => {
        const teamGroupsSortedByOrder = sortBy(response.data.data, 'order', 'asc');
        return teamGroupsSortedByOrder.map(teamGroupData => new TeamGroup(teamGroupData));
      });
  }

  /**
   * @param {number} id
   * @public
   */
  static async delete(id) {
    return apiCall.delete(TeamGroupManagementService.buildUrl(id));
  }
}
