export const DataGridFilterMethods = {
  EQUAL: 'eq',
  CONTAINS: 'contains',
  STARTS_WITH: 'starts_with',
  ENDS_WITH: 'ends_with',
  NOT_EQUAL: 'neq',
  GREATER_THAN: 'gt',
  GREATER_THAN_EQUAL: 'gte',
  LESS_THAN: 'lt',
  LESS_THAN_EQUAL: 'lte',
  IN: 'in',
};

export class DataGridFilter {
  /**
   *
   * @param {(array.<string>|string)} fields
   * @param {string} method
   * @param {(string|number|boolean|array.<string>|array.<number>)} value
   */
  constructor(fields, method, value) {
    this.fields = typeof fields === 'string' ? [fields] : fields;
    this.method = method;
    this.value = value;
  }
}

/**
 * @private fetchCallback
 */
export class DataGridUrlBuilder {
  static SORT_ASC = 'asc';

  static SORT_DESC = 'desc';

  constructor(fetchCallback) {
    this.page = 0;
    this.perPage = null;
    this.paginated = null;
    this.fetchDeleted = null;
    this.sort = {};
    this.filters = [];
    this.onlyColumns = [];
    this.withRelations = [];
    this.fetchCallback = fetchCallback;
  }

  /**
   * Fetch data.
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array>)}
   */
  async fetch(config = {}) {
    return this.fetchCallback(this.getUrlParameters(), config);
  }

  /**
   * @return {object}
   */
  getUrlParameters() {
    const params = {};

    // Pagination
    if (this.page !== 0) {
      params.page = this.page;
    }

    if (this.perPage !== 0) {
      params.items_per_page = this.perPage;
    }

    if (this.paginated !== null) {
      params.paginated = this.paginated ? 1 : 0;
    }

    if (this.fetchDeleted !== null) {
      params.deleted = this.fetchDeleted ? 1 : 0;
    }

    // Sorting
    if (Object.keys(this.sort).length) {
      const sortValues = [];

      // OLD ONE
      // for (const column in this.sort) {
      //   const sign = this.sort[column] === DataGridUrlBuilder.SORT_ASC ? '' : '-';
      //   sortValues.push(`${sign}${column}`);
      // }

      // NEW ONE START:
      Object.keys(this.sort).map(column => {
        const sign = this.sort[column] === DataGridUrlBuilder.SORT_ASC ? '' : '-';
        return sortValues.push(`${sign}${column}`);
      });
      // NEW ONE END

      params.sort = sortValues.join(',');
    }

    // Filtering
    if (this.filters.length) {
      params.filters = btoa(JSON.stringify(this.filters));
    }

    // With relations
    if (this.withRelations.length) {
      params.with = this.withRelations.join(',');
    }

    // Only columns
    if (this.onlyColumns.length) {
      params.only = this.onlyColumns.join(',');
    }

    return params;
  }

  /**
   * @param {DataGridState} state
   */
  fillFromState(state) {
    return this.setPage(state.page)
      .itemsPerPage(state.rowsPerPage)
      .sortBy(state.sortBy)
      .setFilters(state.customFilters);
  }

  /**
   * Set page.
   * @param {number} page
   * @return {DataGridUrlBuilder}
   */
  setPage(page) {
    this.page = Number(page);
    return this;
  }

  /**
   * Set maximal number of items per each page.
   * @param {number} value
   * @return {DataGridUrlBuilder}
   */
  itemsPerPage(value) {
    this.perPage = Number(value);
    return this;
  }

  /**
   * Turn pagination on or off.
   * @param {boolean} off
   * @return {DataGridUrlBuilder}
   */
  turnOffPagination(off = true) {
    this.paginated = !off;
    return this;
  }

  /**
   * Select only data where column value equals to given value.
   * @param {string} column
   * @param {(string|number|boolean)} value
   * @returns {DataGridUrlBuilder}
   */
  whereEquals(column, value) {
    const filter = new DataGridFilter(column, DataGridFilterMethods.EQUAL, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where column value is not equal to given value.
   * @param {string} column
   * @param {(string|number|boolean)} value
   * @returns {DataGridUrlBuilder}
   */
  whereNot(column, value) {
    const filter = new DataGridFilter(column, DataGridFilterMethods.NOT_EQUAL, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified columns starts with given value.
   * @param {(string|array.<string>)} columns
   * @param {string} value
   * @returns {DataGridUrlBuilder}
   */
  whereStartsWith(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.STARTS_WITH, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified columns ends with given value.
   * @param {(string|array.<string>)} columns
   * @param {string} value
   * @returns {DataGridUrlBuilder}
   */
  whereEndsWith(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.ENDS_WITH, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified columns contains given value.
   * @param {(string|array.<string>)} columns
   * @param {string} value
   * @returns {DataGridUrlBuilder}
   */
  whereContains(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.CONTAINS, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified column is withing given values.
   * @param {string} columns
   * @param {(array.<string>|array.<number>)} value
   * @returns {DataGridUrlBuilder}
   */
  whereIn(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.IN, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified column is greater than given value.
   * @param {(string|array.<string>)} columns
   * @param {number} value
   * @returns {DataGridUrlBuilder}
   */
  whereGreaterThan(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.GREATER_THAN, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified column is greater or equal to given value.
   * @param {(string|array.<string>)} columns
   * @param {number} value
   * @returns {DataGridUrlBuilder}
   */
  whereGreaterOrEqual(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.GREATER_THAN_EQUAL, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified column is less than given value.
   * @param {(string|array.<string>)} columns
   * @param {number} value
   * @returns {DataGridUrlBuilder}
   */
  whereLessThan(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.LESS_THAN, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Select only data where value of specified column is less or equal to given value.
   * @param {(string|array.<string>)} columns
   * @param {number} value
   * @returns {DataGridUrlBuilder}
   */
  whereLessOrEqual(columns, value) {
    const filter = new DataGridFilter(columns, DataGridFilterMethods.LESS_THAN_EQUAL, value);
    this.filters.push(filter);
    return this;
  }

  /**
   * Fetch only specified columns.
   * @param {(string|array.<string>)} columns
   * @return {DataGridUrlBuilder}
   */
  only(columns) {
    this.onlyColumns = [];
    let columnsToFetch = columns;

    if (typeof columns === 'string') {
      columnsToFetch = columns.split(',');
    }

    if (Array.isArray(columnsToFetch)) {
      columnsToFetch.forEach(column => {
        if (typeof column === 'string') {
          this.onlyColumns.push(column);
        } else {
          console.error('Column name must be string!', column);
        }
      });
    } else {
      console.error('Columns must be string or array!', columns);
    }

    return this;
  }

  /**
   * Fetch data with specified relations.
   * @param {(string|array.<string>)} relations
   * @return {DataGridUrlBuilder}
   */
  with(relations) {
    this.withRelations = [];
    let relationsToFetch = relations;

    if (typeof relations === 'string') {
      relationsToFetch = relations.split(',');
    }

    if (Array.isArray(relationsToFetch)) {
      relationsToFetch.forEach(relation => {
        if (typeof relation === 'string') {
          this.withRelations.push(relation);
        } else {
          console.error('Relation name must be string!', relation);
        }
      });
    } else {
      console.error('Relations must be string or array!', relations);
    }

    return this;
  }

  /**
   * Sort results by given columns in given direction.
   * @param {(string|array.<string>|object)} columns
   * @param {string} direction='asc' use 'asc' or 'desc'
   * @return {DataGridUrlBuilder}
   */
  sortBy(columns, direction = DataGridUrlBuilder.SORT_ASC) {
    let columnsToParse = columns;
    const sortBy = {};

    // When string given (e.g. '-first_name,email'), split column to array
    if (typeof columns === 'string') {
      columnsToParse = columns.split(',');
    }

    // Process array (e.g. ['-first_name', 'email'])
    if (Array.isArray(columnsToParse)) {
      columnsToParse.forEach(column => {
        if (typeof column !== 'string') {
          console.error('Column name for sorting must be string!', columns);
          return;
        }

        if (column.charAt(0) === '-') {
          // e.g. '-first_name' -> sort column 'first_name' in descendant order
          const columnName = column.substr(1);
          sortBy[columnName] = DataGridUrlBuilder.SORT_DESC;
        } else {
          // e.g. 'email' -> sort column 'email' in given order
          sortBy[column] = direction;
        }
      });
    } else if (typeof columns === 'object') {
      // eg. {'first_name': 'desc', 'email': 'asc'}

      // OLD VERSION:
      /*
      for (const column in columnsToParse) {
        if (Object.prototype.hasOwnProperty.call(columnsToParse, column)) {
          continue;
        }

        sortBy[column] =
          columnsToParse[column].toLowerCase() === DataGridUrlBuilder.SORT_DESC
            ? DataGridUrlBuilder.SORT_DESC
            : DataGridUrlBuilder.SORT_ASC;
      }
      */

      // NEW ONE START:
      if (columnsToParse) {
        Object.keys(columnsToParse).map(column => {
          sortBy[column] =
            columnsToParse[column].toLowerCase() === DataGridUrlBuilder.SORT_DESC
              ? DataGridUrlBuilder.SORT_DESC
              : DataGridUrlBuilder.SORT_ASC;
          return column;
        });
      }
      // NEW ONE END
    } else {
      console.error('Sorting definition must be string, array or object!', columns);
    }

    this.sort = sortBy;
    return this;
  }

  /**
   * Sort results by given columns in descendant order.
   * @param {(string|array.<string>|object)} columns
   * @return {DataGridUrlBuilder}
   */
  sortByDesc(columns) {
    this.sortBy(columns, DataGridUrlBuilder.SORT_DESC);
    return this;
  }

  /**
   * Set filters.
   * @param {array.<DataGridFilter>} filters
   * @return {DataGridUrlBuilder}
   */
  setFilters(filters) {
    if (!Array.isArray(filters)) {
      return this;
    }

    const validFilters = [];

    filters.forEach(filter => {
      if (filter instanceof DataGridFilter) {
        validFilters.push(filter);
      } else if (
        typeof filter === 'object' &&
        Object.prototype.hasOwnProperty.call(filter, 'fields') &&
        Object.prototype.hasOwnProperty.call(filter, 'method') &&
        Object.prototype.hasOwnProperty.call(filter, 'value')
      ) {
        const newFilter = new DataGridFilter(filter.fields, filter.method, filter.value);
        validFilters.push(newFilter);
      } else {
        console.error('Filter definition must be valid object!', filter);
      }
    });

    this.filters = validFilters;
    return this;
  }

  /**
   * Set if deleted records should be fetched or not.
   * @param {boolean} deleted=true
   * @return {DataGridUrlBuilder}
   */
  deleted(deleted = true) {
    this.fetchDeleted = deleted;
    return this;
  }
}
