import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import Input from '@material-ui/core//Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import GeneralFilter from './GeneralFilter';

class SelectFilter extends GeneralFilter {
  // constructor() {
  //   super();
  // }

  render() {
    const { field, value, options } = this.props;

    const appliedValue = this.state.value ? this.state.value : value;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Select
              multiple
              value={appliedValue}
              onChange={e => this.handleChange(field, e.target.value)}
              input={<Input id={`select_${field}`} name={field} />}
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={appliedValue && appliedValue.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

SelectFilter.defaultProps = {
  value: [],
};

export default SelectFilter;
