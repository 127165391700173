/**
 * @property {int} id
 * @property {string} name
 * @property {int} order
 */
export class Position {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.order = data.order;
  }

  static formatFormData(data) {
    return {
      name: data.name,
      order: data.order || null,
    };
  }

  static formatForSorter(data) {
    return {
      id: data.id,
      name: data.name,
      order: data.order,
    };
  }

  static formatForSimpleSorter(data) {
    return {
      id: data.id,
      order: data.order,
    };
  }
}
