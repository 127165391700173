import React from 'react';
import Typography from '@material-ui/core/Typography';
import Can from 'components/Can';
// import { Button } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import routes from 'Routes.js';
import { AuthConsumer } from 'AuthContext.js';

const Dashboard = () => (
  <AuthConsumer>
    {({ user }) =>
      Object.keys(user).length > 0 && (
        <div>
          <Typography variant="h1" gutterBottom>
            User Dashboard
          </Typography>
          {Object.keys(user.modules_permissions).map(key => (
            <Can
              key={key}
              I="visit"
              a={{ module: key, level: user.modules_permissions[key], __type: 'Module' }}
            >
              <p>
                {key}: {user.modules_permissions[key]}
              </p>
              {/* <Button component={Link} to={routes.management.settings.all}>
                  Settings
                </Button> */}
            </Can>
          ))}
        </div>
      )
    }
  </AuthConsumer>
);

Dashboard.propTypes = {};

export default Dashboard;
