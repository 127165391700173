import { ReducedMember } from './Member';
import { Date } from '../shared/date.model';
/**
 * @property {int} id
 * @property {string} email
 * @property {Date} expires_at
 * @property {Date} sent_at
 * @property {string|null} type
 * @property {object|null} member
 * @property {object|null} member_representative
 */
export class UnlockedMemberInvitation {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.email = data.email;
    this.expires_at = new Date(data.expires_at);
    this.sent_at = new Date(data.sent_at);
    this.type = typeof data.type === 'object' ? null : data.type; // todo: after fix change it to check if is / is not NULL
    this.member = new ReducedMember(data.member);
    this.member_representative = data.member_representative;
  }

  static formatForCreate(data) {
    return {
      token: data.token,
      pin_code: data.pin_code,
    };
  }

  static formatForVerify(data) {
    return {
      // id: data.id,
      token: data.token,
      pin_code: data.pin_code,
      email: data.email,
      type: data.type,
    };
  }

  static formatForConsume(data) {
    return {
      // id: data.id,
      token: data.token,
      pin_code: data.pin_code,
      type: data.type,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirm,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
    };
  }
}
