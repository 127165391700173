import { sortBy } from 'lodash';
import { Hall } from '../models/Hall';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class HallManagementService extends ManagementService {
  static endpoint = 'halls';

  /**
   * @param {number} id
   * @return {Promise<Hall>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(HallManagementService.buildUrl(id))
      .then(response => new Hall(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(HallManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<Hall>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall.get(HallManagementService.buildUrl(), { ...config, params }).then(response => {
      const halls = response.data.data.map(data => new Hall(data));

      if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
        return halls;
      }

      return new PaginatedData(halls, response.data.meta);
    });
  }

  /**
   * @param {object} data
   * @return {Promise<Hall>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(HallManagementService.buildUrl(), data)
      .then(response => new Hall(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<Hall>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(HallManagementService.buildUrl(id), data)
      .then(response => new Hall(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise<Hall>}
   */
  static async restore(id) {
    return apiCall
      .patch(`/trash/halls/${id}`, { recover: true })
      .then(response => new Hall(response.data));
  }

  /**
   * @param {object[]} data
   * @return {Promise<Hall[]>}
   */
  static async updateMultiple(data) {
    return apiCall.patch(HallManagementService.buildUrl(), { halls: data }).then(response => {
      const hallsSortedByOrder = sortBy(response.data.halls, 'order', 'asc');
      return hallsSortedByOrder.map(hallData => new Hall(hallData));
    });
  }

  /**
   * @param {number} id
   * @public
   */
  static async delete(id) {
    return apiCall.delete(HallManagementService.buildUrl(id));
  }
}
