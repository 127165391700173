import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { i18n } from 'App.js';
import MemberTypeForm from './MemberTypeForm';

class MemberTypeDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    memberType: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dialogOpened: false,
    memberType: null,
  };

  render() {
    const { dialogOpened, onClose, memberType, handleSubmit } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {memberType
            ? i18n._('msg.member_types.popup_title_update')
            : i18n._('msg.member_types.popup_title_add_new')}
        </CustomDialogTitle>
        <DialogContent>
          <MemberTypeForm
            memberType={memberType}
            handleSubmit={handleSubmit}
            closeDialog={onClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default MemberTypeDialog;
