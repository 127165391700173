import { EventType } from '../models/EventType';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class EventTypeManagementService extends ManagementService {
  static endpoint = 'event-types';

  /**
   * @param {number} id
   * @return {Promise<EventType>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(EventTypeManagementService.buildUrl(id))
      .then(response => new EventType(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(EventTypeManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<EventType>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall
      .get(EventTypeManagementService.buildUrl(), { ...config, params })
      .then(response => {
        const eventTypes = response.data.data.map(data => new EventType(data));

        if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
          return eventTypes;
        }

        return new PaginatedData(eventTypes, response.data.meta);
      });
  }

  /**
   * @param {object} data
   * @return {Promise<EventType>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(EventTypeManagementService.buildUrl(), data)
      .then(response => new EventType(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<EventType>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(EventTypeManagementService.buildUrl(id), data)
      .then(response => new EventType(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise}
   * @public
   */
  static async delete(id) {
    return apiCall.delete(EventTypeManagementService.buildUrl(id));
  }
}
