import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow } from '@material-ui/core';
import styled from 'styled-components';

const StyledTableWrapper = styled(({ ...rest }) => <div {...rest} />)`
  position: relative;
  overflow-x: auto;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

// eslint-disable-next-line
const StyledTable = styled(({ minWidth, ...rest }) => (
  <StyledTableWrapper>
    <Table {...rest} />
  </StyledTableWrapper>
))`
  min-width: ${props => props.minWidth || '750px'};
`;
StyledTable.propTypes = {
  minWidth: PropTypes.number,
};
StyledTable.defaultProps = {
  minWidth: null,
};

// eslint-disable-next-line
const StyledTableRow = styled(({ coloredBackground, ...rest }) => <TableRow {...rest} />)`
  td {
    background: ${props => (props.coloredBackground ? '#f9f9f9' : 'transparent')};
  }
`;
StyledTableRow.propTypes = {
  coloredBackground: PropTypes.bool,
};
StyledTableRow.defaultProps = {
  coloredBackground: false,
};

export { StyledTable, StyledTableRow };
