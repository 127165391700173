import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { TableRow, TableCell, CircularProgress, Typography } from '@material-ui/core';

class TableLoadingContent extends Component {
  static propTypes = {
    colSpan: PropTypes.number.isRequired,
    loadingMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    loadingMessage: <Trans id="msg.loading_data" />,
  };

  render() {
    const { colSpan, loadingMessage } = this.props;
    return (
      <TableRow>
        <TableCell style={{ padding: '24px 0' }} align="center" colSpan={colSpan}>
          <CircularProgress color="secondary" />
          <div style={{ marginTop: '24px' }}>
            <Typography>{loadingMessage}</Typography>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default TableLoadingContent;
