import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';

const MemberInvitationFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  closeDialog,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          type="text"
          name="email"
          label={i18n._('msg.members.new_invitation_email_label')}
          required
          component={EosTextInput}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
          {isSubmitting ? (
            <Trans id="msg.sending" />
          ) : (
            <Trans id="msg.send" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

MemberInvitationFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  // values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
};

MemberInvitationFormComponent.defaultProps = {
  isSubmitting: false,
};

class MemberInvitationForm extends Component {
  validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .required(i18n._('msg.form.required')),
  });

  initialValues = {
    email: '',
    type: null,
    member_representative_id: null,
  };

  render() {
    const { closeDialog, handleSubmit, invitation } = this.props;
    const formattedInvitationData = invitation
      ? {
          email: invitation.email,
          type: invitation.type,
          member_representative_id: invitation.member_representative_id,
        }
      : null;
    return (
      <div>
        <Formik
          initialValues={invitation ? formattedInvitationData : this.initialValues}
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <MemberInvitationFormComponent {...formikProps} closeDialog={closeDialog} />
          )}
        />
      </div>
    );
  }
}

MemberInvitationForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invitation: PropTypes.object,
};

MemberInvitationForm.defaultProps = {
  invitation: null,
};

export default MemberInvitationForm;
