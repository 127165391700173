import { sortBy } from 'lodash';
import { FullTeam } from '../models/Team';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class TeamManagementService extends ManagementService {
  static endpoint = 'teams';

  /**
   * @param {number} id
   * @return {Promise<FullTeam>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(TeamManagementService.buildUrl(id))
      .then(response => new FullTeam(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(TeamManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<FullTeam[]>)}
   * @private
   */
  static async fetchAll(params = {}, config = {}) {
    return apiCall.get(TeamManagementService.buildUrl(), { ...config, params }).then(response => {
      const teams = response.data.data.map(data => new FullTeam(data));
      if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
        return teams;
      }
      return new PaginatedData(teams, response.data.meta);
    });
  }

  /**
   * @param {object} data
   * @return {Promise<FullTeam>}
   * @public
   */
  static async create(data) {
    return apiCall
      .post(TeamManagementService.buildUrl(), data)
      .then(response => new FullTeam(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<FullTeam>}
   * @public
   */
  static async update(id, data) {
    return apiCall
      .put(TeamManagementService.buildUrl(id), data)
      .then(response => new FullTeam(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<FullTeam>}
   * @public
   */
  static async updateVisibility(id, data) {
    return apiCall
      .patch(TeamManagementService.buildUrl(id), data)
      .then(response => new FullTeam(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise<FullTeam>}
   */
  static async restore(id) {
    return apiCall
      .patch(`/trash/teams/${id}`, { recover: true })
      .then(response => new FullTeam(response.data));
  }

  /**
   * @param {object[]} data
   * @return {Promise<FullTeam[]>}
   */
  static async updateMultiple(data) {
    return apiCall.patch(TeamManagementService.buildUrl(), { teams: data }).then(response => {
      const teamsSortedByOrder = sortBy(response.data.data, 'order', 'asc');
      return teamsSortedByOrder.map(teamData => new FullTeam(teamData));
    });
  }

  /**
   * @param {number} id
   * @public
   */
  static async delete(id) {
    return apiCall.delete(TeamManagementService.buildUrl(id));
  }
}
