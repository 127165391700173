import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import { StyledTable } from 'components/Table/StyledTable';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import CustomizedLink from 'components/Base/Link';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import ContextMenu from 'components/Base/ContextMenu';

class MemberTypesTable extends Component {
  static propTypes = {
    memberTypes: PropTypes.array,
    loading: PropTypes.bool,
    editMemberType: PropTypes.func.isRequired,
    deleteMemberType: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    memberTypes: [],
    loading: false,
  };

  render() {
    const {
      memberTypes,
      loading,
      editMemberType,
      deleteMemberType,
      managePermissions,
    } = this.props;

    if (!loading && memberTypes.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.member_types.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <div>
        <Card>
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Trans id="msg.id" />
                </TableCell>
                <TableCell style={{ width: '100%' }}>
                  <Trans id="msg.name" />
                </TableCell>
                {managePermissions && (
                  <TableCell align="center">
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoadingContent colSpan={managePermissions ? 3 : 2} />}
              {!loading &&
                memberTypes.map(mt => (
                  <TableRow key={mt.id}>
                    <TableCell align="center">{mt.id}</TableCell>
                    <TableCell>
                      {mt.is_protected || !managePermissions ? (
                        <React.Fragment>{mt.name}</React.Fragment>
                      ) : (
                        <CustomizedLink to="#" noWrap onClick={() => editMemberType(mt)}>
                          {mt.name}
                        </CustomizedLink>
                      )}
                      {mt.is_protected && (
                        <React.Fragment>
                          <br />
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {i18n._('msg.member_types.protected')}
                          </span>
                        </React.Fragment>
                      )}
                    </TableCell>
                    {managePermissions && (
                      <TableCell style={{ textAlign: 'center' }}>
                        <ContextMenu
                          variant="icon"
                          tooltip={i18n._('msg.actions')}
                          color="inherit"
                          icon="more_vert"
                          menuItems={[
                            {
                              text: i18n._('msg.record_update'),
                              action: () => editMemberType(mt),
                            },
                            {
                              text: i18n._('msg.record_delete'),
                              action: () => deleteMemberType(mt.id),
                              variant: 'delete',
                            },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </Card>
      </div>
    );
  }
}

export default MemberTypesTable;
