import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { i18n } from 'App.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import ErrorMessage from 'components/Forms/ErrorMessage';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import routes from 'Routes.js';
import CustomizedLink from 'components/Base/Link';
import StandaloneCard from 'components/StandaloneCard';

const ResetPasswordFormComponent = ({ isSubmitting, errors }) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field name="email" label={i18n._('msg.email_label')} component={EosTextInput} required />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          type="password"
          label={i18n._('msg.password_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password_confirm"
          type="password"
          label={i18n._('msg.password_confirm_label')}
          component={EosTextInput}
          required
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="center" noBottomPadding>
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        <Trans id="msg.reset_password_label" />
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

ResetPasswordFormComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

class ResetPasswordComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetPasswordDone: PropTypes.bool.isRequired,
  };

  validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .required(i18n._('msg.form.required')),
    password: yup
      .string()
      .min(4, i18n._('msg.form.min_length', { min_length: 4 }))
      .required(i18n._('msg.form.required')),
    password_confirm: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n._('msg.form.password_not_match'))
      .required(i18n._('msg.form.required')),
  });

  render() {
    const { handleSubmit, resetPasswordDone } = this.props;
    return (
      <StandaloneCard title={i18n._('msg.password_reset.page_title')}>
        <CardContent>
          {resetPasswordDone ? (
            <Typography>
              <Trans id="msg.password_reset.confirm_message">
                Password reset done, continue to{' '}
                <CustomizedLink to={routes.login}>login</CustomizedLink>
              </Trans>
            </Typography>
          ) : (
            <Formik
              initialValues={{
                email: '',
                password: '',
                password_confirm: '',
              }}
              validationSchema={this.validationSchema}
              onSubmit={handleSubmit}
              render={formikProps => <ResetPasswordFormComponent {...formikProps} />}
            />
          )}
        </CardContent>
      </StandaloneCard>
    );
  }
}

export default ResetPasswordComponent;
