import { hex } from 'wcag-contrast';

const computeEventTypeIconContrast = backgroundColor => {
  const lightSymbolColor = '#efefef';
  const darkSymbolColor = '#111';
  const contrast = hex(backgroundColor, lightSymbolColor);

  return contrast > 1.45 ? lightSymbolColor : darkSymbolColor;
};

export { computeEventTypeIconContrast };
