import React from 'react';
import PropTypes from 'prop-types';
import ImageDisplayStandalone from 'components/Base/ImageDisplayStandalone';
import styled from 'styled-components';

const StyledHeaderImage = styled.div`
  margin-right: 16px;
  overflow: hidden;
  width: 70px;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-bottom: 120%;
  }

  img,
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    object-fit: cover;
  }
`;

const HeaderImage = ({ imageId }) => (
  <StyledHeaderImage>
    {!Number.isNaN(parseInt(imageId)) ? (
      <ImageDisplayStandalone imageId={imageId} />
    ) : (
      <img src="//satyr.io/300x400" alt="user profile" />
    )}
  </StyledHeaderImage>
);

HeaderImage.propTypes = {
  imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default HeaderImage;
