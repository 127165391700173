// import 'utils/customYupValidators';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { CardContent, Grid, Typography } from '@material-ui/core';
import ErrorMessage from 'components/Forms/ErrorMessage';
import SubmitButton from 'components/Forms/SubmitButton';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import EosTextInput from 'components/Forms/EosTextInput';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import EosRadio from 'components/Forms/EosRadio';

const invitationTypes = [
  { label: 'Me', value: 'user' },
  { label: 'Representative', value: 'representative' },
];

const InvitationStep2FormComponent = ({
  isSubmitting,
  errors,
  // initialValues,
  setFieldValue,
  // handleStepBack,
  invitationTypeChangeEnabled,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      {invitationTypeChangeEnabled && (
        <Grid item xs={12}>
          <Field
            name="type"
            groupLabel="Type of invited person"
            options={invitationTypes}
            component={EosRadio}
            inline
            onChange={value => {
              if (value === 'representative') {
                setFieldValue('first_name', '');
                setFieldValue('last_name', '');
              }
            }}
            // disabled={values.type !== null}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          name="email"
          label={i18n._('msg.invitations.email_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Field
          name="first_name"
          label={i18n._('msg.invitations.first_name')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="last_name"
          label={i18n._('msg.invitations.last_name')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="phone_number"
          label={i18n._('msg.invitations.phone_number')}
          component={EosTextInput}
          required
        />
      </Grid> */}

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="center" noBottomPadding>
      {/* <Button onClick={handleStepBack}>
        <Trans id="msg.back" />
      </Button> */}

      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        <Trans id="msg.confirm" />
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

InvitationStep2FormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleStepBack: PropTypes.func.isRequired,
  invitationTypeChangeEnabled: PropTypes.bool.isRequired,
  // values: PropTypes.object.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
};

InvitationStep2FormComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
};

class InvitationStep2 extends Component {
  validationSchema = yup.object().shape({
    type: yup
      .string()
      // .notOneOf([null], i18n._('msg.form.required'))
      .oneOf(
        invitationTypes.map(i => i.value),
        i18n._('msg.form.oneOfValues', {
          values: invitationTypes.map(i => i.label).join(', '),
        })
      )
      .required(i18n._('msg.form.required')),
    email: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .required(i18n._('msg.form.required')),
    // first_name: yup
    //   .string()
    //   .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
    //   .required(i18n._('msg.form.required')),
    // last_name: yup
    //   .string()
    //   .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
    //   .required(i18n._('msg.form.required')),
    // phone_number: yup
    //   .string()
    //   .phone(i18n._('msg.form.invalid_phone'))
    //   .required(i18n._('msg.form.required')),
  });

  render() {
    const {
      handleSubmit,
      // handleStepBack,
      step1Response,
      invitationData,
      invitationTypeChangeEnabled,
    } = this.props;

    return (
      <CardContent>
        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 16 }}>
          <Trans id="msg.invitations.connected_member" />:{' '}
          <strong>
            {step1Response.member.first_name} {step1Response.member.last_name}
          </strong>
        </Typography>

        <Formik
          initialValues={{
            type: invitationData.type || step1Response.type || 'not-set',
            email: invitationData.email || step1Response.email || '',
            // first_name:
            //   invitationData.first_name ||
            //   (step1Response.type === 'representative' ? '' : step1Response.member.first_name),
            // last_name:
            //   invitationData.last_name ||
            //   (step1Response.type === 'representative' ? '' : step1Response.member.last_name),
            // phone_number: invitationData.phone_number || '',
          }}
          validationSchema={this.validationSchema}
          onSubmit={handleSubmit}
          render={formikProps => (
            <InvitationStep2FormComponent
              {...formikProps}
              // handleStepBack={handleStepBack}
              invitationTypeChangeEnabled={invitationTypeChangeEnabled}
            />
          )}
        />
      </CardContent>
    );
  }
}

InvitationStep2.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  // handleStepBack: PropTypes.func.isRequired,
  step1Response: PropTypes.object.isRequired,
  invitationData: PropTypes.object.isRequired,
  invitationTypeChangeEnabled: PropTypes.bool.isRequired,
};

export default InvitationStep2;
