import React, { Component } from 'react';
import { TeamGroupManagementService } from 'api/team-group.service';
import TeamGroupsTable from 'components/Management/Settings/TeamGroups/TeamGroupsTable';
import TeamGroupsDialog from 'components/Management/Settings/TeamGroups/TeamGroupsDialog';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TeamGroup } from 'models/TeamGroup';
import { isEqual } from 'lodash';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import routes from 'Routes.js';
import ability from 'ability.js';

class TeamGroups extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    teamGroups: [],
    loading: false,
    teamGroup: null,
    dialogOpened: false,
    newTeamGroupsOrder: null,
    initialTeamGroupsOrder: null,
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchTeamGroups();
  }

  setInitialTeamGroupsOrder = teamGroups =>
    teamGroups.map((teamGroup, index) => ({
      id: teamGroup.id,
      name: teamGroup.name,
      order: index + 1, // this is because of the order dont't have to be row of numbers
    }));

  fetchTeamGroups = async () => {
    const { enqueueSnackbar } = this.props;

    this.setState({ loading: true });
    const teamGroups = await TeamGroupManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .fetch();

    const initialTeamGroupsOrder = this.setInitialTeamGroupsOrder(teamGroups);

    this.setState(
      {
        initialTeamGroupsOrder,
        teamGroups,
        loading: false,
      },
      () => {
        enqueueSnackbar(i18n._('msg.team_groups.loading_success'), { variant: 'success' });
      }
    );
  };

  createTeamGroup = async data => {
    const { enqueueSnackbar } = this.props;

    const formattedData = TeamGroup.formatFormData(data);
    await TeamGroupManagementService.create(formattedData).then(response => {
      const { teamGroups, initialTeamGroupsOrder } = this.state;

      this.setState(
        {
          teamGroups: [...teamGroups, response],
          initialTeamGroupsOrder: [...initialTeamGroupsOrder, TeamGroup.formatForSorter(response)],
        },
        () => {
          enqueueSnackbar(i18n._('msg.team_groups.create_success'), { variant: 'success' });
        }
      );
    });
  };

  updateTeamGroup = async (teamGroupId, data) => {
    const { enqueueSnackbar } = this.props;

    const formattedData = TeamGroup.formatFormData(data);
    await TeamGroupManagementService.update(teamGroupId, formattedData).then(response => {
      const { teamGroups } = this.state;

      const updatedTeamGroupIndexInTeamGroups = teamGroups.findIndex(p => p.id === teamGroupId);

      teamGroups[updatedTeamGroupIndexInTeamGroups] = response;

      this.setState(
        {
          teamGroups,
        },
        () => {
          enqueueSnackbar(i18n._('msg.team_groups.update_success'), { variant: 'success' });
        }
      );
    });
  };

  deleteTeamGroup = async teamGroupId => {
    const { enqueueSnackbar } = this.props;

    await TeamGroupManagementService.delete(teamGroupId)
      .then(() => {
        const { teamGroups, newTeamGroupsOrder } = this.state;
        let { initialTeamGroupsOrder } = this.state;
        const deletedTeamGroupIndexInTeamGroups = teamGroups.findIndex(p => p.id === teamGroupId);

        // remove deleted teamGroup from previous state
        teamGroups.splice(deletedTeamGroupIndexInTeamGroups, 1);

        // remove deleted teamGroup from initialTeamGroupsOrder and from newTeamGroupOrder if this is set
        const deletedTeamGroupIndexInInitialTeamGroupsOrder = initialTeamGroupsOrder.findIndex(
          teamGroup => teamGroup.id === teamGroupId
        );
        if (deletedTeamGroupIndexInInitialTeamGroupsOrder > -1) {
          initialTeamGroupsOrder = this.setInitialTeamGroupsOrder(teamGroups);
        }
        if (newTeamGroupsOrder) {
          const deletedTeamGroupIndexInNewTeamGroupsOrder = newTeamGroupsOrder.findIndex(
            teamGroup => teamGroup.id === teamGroupId
          );

          if (deletedTeamGroupIndexInNewTeamGroupsOrder > -1) {
            newTeamGroupsOrder.splice(deletedTeamGroupIndexInNewTeamGroupsOrder, 1);
          }
        }

        this.setState(
          {
            teamGroups,
            initialTeamGroupsOrder,
            newTeamGroupsOrder,
          },
          () => {
            enqueueSnackbar(i18n._('msg.team_groups.delete_success'), { variant: 'info' });
          }
        );
      })
      .catch(e => enqueueSnackbar(e.response.data.message, { variant: 'error' }));
  };

  closeDialog = () => {
    this.setState({
      teamGroup: null,
      dialogComponent: '',
      dialogOpened: false,
    });
  };

  operationCallback = (promise, actions) =>
    promise.then(
      () => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      },
      error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      }
    );

  handleSubmit = (values, actions) => {
    const { teamGroup, dialogComponent, initialTeamGroupsOrder, newTeamGroupsOrder } = this.state;

    if (dialogComponent === 'sortable') {
      if (newTeamGroupsOrder && !isEqual(initialTeamGroupsOrder, newTeamGroupsOrder)) {
        this.setState({
          isSubmitting: true,
        });
        this.requestForNewOrderedTeamGroups(newTeamGroupsOrder);
      } else {
        this.closeDialog();
      }

      return;
    }

    const promise = teamGroup
      ? this.updateTeamGroup(teamGroup.id, values)
      : this.createTeamGroup(values);

    this.operationCallback(promise, actions);
  };

  openSortableTeamGroups = () => {
    this.setState({ dialogComponent: 'sortable', dialogOpened: true });
  };

  openCreateTeamGroup = () => {
    this.setState({ dialogComponent: 'create', dialogOpened: true });
  };

  openUpdateTeamGroup = teamGroup => {
    this.setState({ dialogComponent: 'update', dialogOpened: true, teamGroup });
  };

  setNewTeamGroupsOrder = newTeamGroupsOrder => {
    this.setState({ newTeamGroupsOrder });
  };

  requestForNewOrderedTeamGroups = async newOrder => {
    const { enqueueSnackbar } = this.props;
    const newOrderNormalized = newOrder.map(teamGroup =>
      TeamGroup.formatForSimpleSorter(teamGroup)
    );

    this.setState({
      loading: true,
    });

    await TeamGroupManagementService.updateMultiple(newOrderNormalized)
      .then(response => {
        this.setState(
          {
            teamGroups: response,
            initialTeamGroupsOrder: response.map(p => TeamGroup.formatForSorter(p)),
            newTeamGroupsOrder: null,
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(i18n._('msg.team_groups.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(e => {
        this.setState(
          {
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  render() {
    const {
      dialogOpened,
      teamGroups,
      loading,
      teamGroup,
      dialogComponent,
      isSubmitting,
    } = this.state;
    const { accessLevel } = this.props;

    let data = false;
    switch (dialogComponent) {
      case 'sortable':
        data = teamGroups;
        break;
      case 'update':
        data = teamGroup;
        break;
      default:
        data = {};
    }

    return (
      <div>
        <PageHeader
          title={i18n._('msg.team_groups.page_title')}
          customControlsPrimary={
            ability.can('manage', {
              __type: 'Members',
              level: accessLevel,
            })
              ? [
                  <FabButton variant="round" size="small" onClick={this.openCreateTeamGroup} />,
                  <ContextMenu
                    text={i18n._('msg.actions')}
                    variant="withIcon"
                    menuItems={[
                      {
                        text: i18n._('msg.team_groups.team_groups_sort'),
                        action: this.openSortableTeamGroups,
                      },
                    ]}
                  />,
                ]
              : []
          }
          breadcrumbsItems={[
            {
              url: routes.management.settings.all,
              // title: 'some title',
              text: i18n._('msg.settings.page_title'),
            },
            { url: null, text: i18n._('msg.team_groups.page_title') },
          ]}
        />

        <TeamGroupsTable
          teamGroups={teamGroups}
          loading={loading}
          editTeamGroup={this.openUpdateTeamGroup}
          deleteTeamGroup={this.deleteTeamGroup}
          managePermissions={ability.can('manage', {
            __type: 'Members',
            level: accessLevel,
          })}
        />

        <TeamGroupsDialog
          opened={dialogOpened}
          data={data}
          onClose={this.closeDialog}
          component={dialogComponent}
          requestForNewOrderedRecords={this.requestForNewOrderedTeamGroups}
          setNewRecordsOrder={this.setNewTeamGroupsOrder}
          handleSubmit={this.handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  }
}

TeamGroups.propTypes = {};

export default withSnackbar(TeamGroups);
