const get = (pObject, pKeys, defaultVal = null) => {
  const keys = Array.isArray(pKeys) ? pKeys : pKeys.replace(/(\[(\d)\])/g, '.$2').split('.');
  const object = pObject[keys[0]];

  if (object && keys.length > 1) {
    return get(object, keys.slice(1), defaultVal);
  }

  return object === undefined ? defaultVal : object;
};

// inspired by @developit's linkState
// kindly stolen from here: https://github.com/jaredpalmer/formik/pull/207#issuecomment-336193634
const set = (path, value, obj) => {
  let res = {};
  let i = 0;
  for (; i < path.length - 1; i += 1) {
    res = res[path[i]] || (res[path[i]] = (!i && obj[path[i]]) || {});
  }
  res[path[i]] = value;
  return res;
};

export { get, set };
