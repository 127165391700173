import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MemberManagementService } from 'api/member.service';
import routes from 'Routes';
import LoadingMessage from 'components/Base/LoadingMessage';
import { DataGrid } from 'shared/datagrid.model';
import { DataGridState } from 'shared/datagrid.state.model';
import MembersTable from 'components/Management/Members/MembersTable';
import { i18n } from 'App.js';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import PropTypes from 'prop-types';
import ability from 'ability.js';

class Members extends Component {
  static propTypes = {
    accessLevel: PropTypes.string.isRequired,
  };

  /** @type {DataGrid} */
  dataGrid = new DataGrid(MemberManagementService.getAll, builder => {
    builder.with('user');
  });

  state = {
    tableState: new DataGridState(),
    loading: true,
  };

  componentDidMount() {
    const { tableState } = this.state;
    this.fetchAllMembers(tableState);
  }

  fetchAllMembers = async state => {
    this.setState({ loading: true });
    const tableState = await this.dataGrid.fetchData(state);
    this.setState({
      tableState,
      loading: false,
    });
  };

  deactivateMember = async memberId => {
    console.log(`Try to deactivate member with id ${memberId}`);
  };

  render() {
    const { tableState, loading } = this.state;
    const { accessLevel } = this.props;

    if (tableState.data === null) {
      return <LoadingMessage />;
    }

    return (
      <div>
        <PageHeader
          title={i18n._('msg.members.page_title')}
          customControlsPrimary={[
            ability.can('manage', { __type: 'Members', level: accessLevel }) && (
              <FabButton
                variant="round"
                size="small"
                component={Link}
                to={routes.management.members.create}
              />
            ),
          ]}
        />

        <MembersTable
          loading={loading}
          tableState={tableState}
          deactivateMember={this.deactivateMember}
          fetchAllMembers={this.fetchAllMembers}
          managePermissions={ability.can('manage', {
            __type: 'Members',
            level: accessLevel,
          })}
        />
      </div>
    );
  }
}

export default Members;
