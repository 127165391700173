import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Typography,
  Icon,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { t, plural, Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import RosterMultiAddItem from './RosterMultiAddItem';

class RosterMultiAdd extends Component {
  static propTypes = {
    possibleMembers: PropTypes.array.isRequired,
    addMembersToRoster: PropTypes.func.isRequired,
  };

  state = {
    possibleMembers: [],
    selectedMembers: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.possibleMembers !== prevState.possibleMembers) {
      return {
        possibleMembers: nextProps.possibleMembers,
        selectedMembers: [],
      };
    }

    return null;
  }

  componentDidMount() {
    const { possibleMembers } = this.props;

    this.setState({
      possibleMembers,
    });
  }

  handleMemberCheck = evt => {
    const { possibleMembers, selectedMembers } = this.state;
    let newSelectedMembers = [...selectedMembers];
    const memberId = parseInt(evt.currentTarget.value);
    const index = selectedMembers.findIndex(s => s.id === memberId);

    if (index === -1) {
      const member = possibleMembers.find(o => o.id === memberId);

      if (member) {
        newSelectedMembers = [...selectedMembers, member];
      }
    } else {
      // eslint-disable-next-line
      const throwAway = newSelectedMembers.splice(index, 1);
    }

    this.setState({
      selectedMembers: newSelectedMembers,
    });
  };

  render() {
    const { possibleMembers, selectedMembers } = this.state;
    const { addMembersToRoster } = this.props;
    return (
      <div>
        {possibleMembers && (
          <React.Fragment>
            {/* <Divider variant="middle" style={{ margin: '16px 0' }} /> */}
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography>
                  <Trans id="msg.rosters.add_multiple" />
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  {possibleMembers.map((m, index) => (
                    <React.Fragment key={`fragment_${m.id}`}>
                      {index > 0 && <Divider variant="middle" style={{ margin: 0 }} />}
                      <RosterMultiAddItem
                        member={m}
                        key={m.id}
                        handleMemberCheck={this.handleMemberCheck}
                        isSelected={selectedMembers.findIndex(sm => sm.id === m.id) > -1}
                      />
                    </React.Fragment>
                  ))}

                  {selectedMembers.length > 0 && (
                    <React.Fragment>
                      <Divider variant="middle" style={{ margin: '0 0 24px 0' }} />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => addMembersToRoster(selectedMembers.map(m => m.id))}
                      >
                        {i18n._(
                          t`Add ${plural({
                            value: selectedMembers.length,
                            one: '# selected member',
                            few: '# selected members',
                            other: '# selected members',
                          })} to roster`
                        )}
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </React.Fragment>
        )}

        {/* {possibleMembers &&
          possibleMembers.map(m => (
            <RosterMultiAddItem
              member={m}
              key={m.id}
              handleMemberCheck={this.handleMemberCheck}
              isSelected={selectedMembers.findIndex(sm => sm.id === m.id) > -1}
            />
          ))}

        {selectedMembers.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => addMembersToRoster(selectedMembers.map(m => m.id))}
          >
            Add selected members to roster
          </Button>
        )} */}
      </div>
    );
  }
}

RosterMultiAdd.propTypes = {};

export default RosterMultiAdd;
