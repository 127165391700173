import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  && {
    color: inherit;
    font-size: inherit;
  }
`;

// eslint-disable-next-line
const StyledEmptyContentPlaceholder = styled(({ variant, ...rest }) => <div {...rest} />)`
  font-size: ${props => (props.variant === 'condensed' ? '16px' : '18px')};
  color: ${props => (props.variant === 'condensed' ? '#999' : '#999')};
  background: ${props => (props.variant === 'condensed' ? 'transparent' : 'transparent')};
  padding: 1.5em 1em;
  text-align: center;
  height: ${props => (props.variant === 'condensed' ? 'auto' : '100px')};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

StyledEmptyContentPlaceholder.propTypes = {
  variant: PropTypes.string.isRequired,
};

const EmptyContentPlaceholder = ({ variant, children }) => (
  <StyledEmptyContentPlaceholder variant={variant}>
    <StyledTypography>{children}</StyledTypography>
  </StyledEmptyContentPlaceholder>
);

EmptyContentPlaceholder.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['condensed', 'large']),
};
EmptyContentPlaceholder.defaultProps = {
  variant: 'condensed',
};

export default EmptyContentPlaceholder;
