// import { RoleModule } from './Module';

/**
 * @property {int} id
 * @property {string} name
 * @property {string} description
 * @property {string} type
 // * @property {Array<RoleModule>|null} modules
 */
export class Role {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description || '';
    this.type = data.type;
    this.editable = data.editable;
    this.members_count = typeof data.members_count === 'undefined' ? null : data.members_count;

    // if (data.modules) {
    //   this.modules = data.modules.map(moduleData => new RoleModule(moduleData));
    // }
  }

  static formatCreateData(data) {
    return {
      name: data.name,
      description: null,
      type: 'teams_organizer',
      permissions: [],
    };
  }

  static formatFormData(data) {
    return {
      name: data.name,
      description: data.description === '' ? null : data.description,
      type: data.type,
      permissions:
        data.permissions && Object.keys(data.permissions).length > 0
          ? [
              ...Object.keys(data.permissions.management).map(key => ({
                module: key,
                level: data.permissions.management[key],
              })),
              ...Object.keys(data.permissions.web).map(key => ({
                module: key,
                level: data.permissions.web[key],
              })),
            ]
          : [],
    };
  }
}
