import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import EosTextInput from 'components/Forms/EosTextInput';
import EosCheckbox from 'components/Forms/EosCheckbox';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import Geocode from 'react-geocode';
import config from 'config.js';
// import EosRichText from 'components/Forms/EosRichText';
import ImageUploader from 'components/ImageUploader';
import ErrorMessage from 'components/Forms/ErrorMessage';
import { postalCode as postalCodeRegExp, latLongCoords as latLongCoordsRegExp } from 'utils/regexp';
import HallMap from './HallMap';

const HallUpdateFormComponent = ({
  isSubmitting,
  errors,
  values,
  translateAddress,
  updateCoords,
  setFieldValue,
  requestedImageSize,
  acceptUploadedImageId,
  currentImageId,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography>
              <Trans id="msg.base_info" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="name"
              required
              label={i18n._('msg.halls.update_hall_name_label')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address.street"
              label={i18n._('msg.halls.update_hall_street_label')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address.city"
              label={i18n._('msg.halls.update_hall_city_label')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address.postal_code"
              label={i18n._('msg.halls.update_hall_postal_code_label')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              label={i18n._('msg.halls.update_hall_description_label')}
              component={EosTextInput}
              multiline
              rows={4}
              // onChange={setFieldValue}
              // onBlur={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="checkbox"
              name="visible_on_web"
              label={i18n._('msg.halls.visible_on_web_label')}
              // groupLabel="Checkbox upper label"
              // checked={values.is_active}
              component={EosCheckbox}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography>
              <Trans id="msg.image" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageUploader
              multiple={false}
              width={requestedImageSize.width}
              height={requestedImageSize.height}
              acceptedFileTypes={['jpeg', 'png']}
              acceptUploadedImageId={acceptUploadedImageId}
              currentImageId={currentImageId}
              // maxSize={2*1024}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <Trans id="msg.map" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              disabled={values.address.street.length < 3 || values.address.city.length < 3}
              onClick={() => {
                translateAddress(values, setFieldValue);
              }}
            >
              <Trans id="msg.halls.address_to_coords" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <HallMap
              lat={parseFloat(values.latitude.toString().replace(',', '.')) || 0}
              lng={parseFloat(values.longitude.toString().replace(',', '.')) || 0}
              isMarkerShown={
                !(
                  Number.isNaN(parseFloat(values.longitude)) &&
                  Number.isNaN(parseFloat(values.latitude))
                )
              }
              updateCoords={(lat, lng) => updateCoords(lat, lng, setFieldValue)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="longitude"
              InputProps={{
                readOnly: true,
              }}
              label={i18n._('msg.halls.update_hall_longitude_label')}
              component={EosTextInput}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="latitude"
              InputProps={{
                readOnly: true,
              }}
              label={i18n._('msg.halls.update_hall_latitude_label')}
              component={EosTextInput}
            />
          </Grid>
        </Grid>
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <Grid item xs={12}>
        <SubmitArea isSubmitting={isSubmitting} align="left">
          <SubmitButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
          >
            {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
          </SubmitButton>
        </SubmitArea>
      </Grid>
    </Grid>
  </EosForm>
);

HallUpdateFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  translateAddress: PropTypes.func.isRequired,
  updateCoords: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  requestedImageSize: PropTypes.object.isRequired,
  acceptUploadedImageId: PropTypes.func,
  currentImageId: PropTypes.number,
};

HallUpdateFormComponent.defaultProps = {
  isSubmitting: false,
  acceptUploadedImageId: null,
  currentImageId: null,
};

class HallUpdateForm extends Component {
  static propTypes = {
    hall: PropTypes.object,
    updateHall: PropTypes.func.isRequired,
    requestedImageSize: PropTypes.object.isRequired,
    acceptUploadedImageId: PropTypes.func,
    currentImageId: PropTypes.number,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hall: null,
    acceptUploadedImageId: null,
    currentImageId: null,
  };

  state = {
    hall: null,
  };

  validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
    address: yup.object().shape({
      street: yup
        .string()
        .transform(value => (value.toString().length ? value : null))
        .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
        .nullable(),
      city: yup
        .string()
        .transform(value => (value.toString().length ? value : null))
        .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
        .nullable(),
      postal_code: yup
        .string()
        .transform(value => (value.toString().length ? value : null))
        .matches(postalCodeRegExp, i18n._('msg.form.invalid_format'), {
          excludeEmptyString: true,
        })
        .nullable(),
    }),
    longitude: yup
      .string()
      .matches(latLongCoordsRegExp, i18n._('msg.form.invalid_format'))
      .nullable(),
    latitude: yup
      .string()
      .matches(latLongCoordsRegExp, i18n._('msg.form.invalid_format'))
      .nullable(),
    show_on_web: yup.boolean(),
  });

  componentDidMount = () => {
    Geocode.setApiKey(config.googleMapsApiKey);
    Geocode.enableDebug();
    window.Geocode = Geocode;

    const { hall } = this.props;
    this.setState({
      hall: hall || {},
    });
  };

  updateCoords = (lat, lng, setFieldValue) => {
    setFieldValue('latitude', Number.parseFloat(lat.toFixed(5)));
    setFieldValue('longitude', Number.parseFloat(lng.toFixed(5)));
  };

  translateAddress = (values, setFieldValue) => {
    const { enqueueSnackbar } = this.props;

    const { address } = values;
    const stringifiedAddress = Object.keys(address)
      .reduce((acc, key) => acc + (address[key].toString().length ? `, ${address[key]}` : ''), '')
      .replace(/^, /, '');

    Geocode.fromAddress(stringifiedAddress).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;

        this.updateCoords(lat, lng, setFieldValue);

        enqueueSnackbar(i18n._('msg.halls.address_to_coords_success'), { variant: 'success' });
      },
      error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    );
  };

  render() {
    const { updateHall, requestedImageSize, acceptUploadedImageId, currentImageId } = this.props;
    const { hall } = this.state;

    return (
      <Card>
        <CardContent>
          {hall && (
            <Formik
              initialValues={
                Object.keys(hall).length > 0
                  ? { ...hall }
                  : {
                      name: '',
                      address: {
                        street: '',
                        city: '',
                        postal_code: '',
                      },
                      latitude: '',
                      longitude: '',
                      visible_on_web: true,
                      description: '',
                    }
              }
              validationSchema={this.validationSchema}
              onSubmit={updateHall}
              render={formikProps => (
                <HallUpdateFormComponent
                  {...formikProps}
                  hall={hall}
                  translateAddress={this.translateAddress}
                  updateCoords={this.updateCoords}
                  requestedImageSize={requestedImageSize}
                  acceptUploadedImageId={acceptUploadedImageId}
                  currentImageId={currentImageId}
                />
              )}
            />
          )}
        </CardContent>
      </Card>
    );
  }
}

export default HallUpdateForm;
