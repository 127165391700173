// import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

// eslint-disable-next-line
const HeaderCell = withStyles(theme => ({
  root: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}))(TableCell);

export default HeaderCell;
