/**
 * @property {int} id
 * @property {string} name
 * @property {string} extension
 * @property {int} width
 * @property {int} height
 * @property {string} mime_type
 * @property {string} content
 */
export class Image {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.extension = data.extension;
    this.width = data.width;
    this.height = data.height;
    this.mime_type = data.mime_type;
    this.content = data.content;
  }

  static formatForUpdate(data) {
    return {
      id: data.id,
      x: data.x || null,
      y: data.y || null,
      width: data.width || null,
      height: data.height || null,
    };
  }
}

/**
 * @property {int} id
 * @property {string} name
 * @property {string} extension
 * @property {int} width
 * @property {int} height
 * @property {string} mime_type
 * @property {string} content
 */
export class ImageUpdate {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.x = data.x || null;
    this.y = data.y || null;
    this.width = data.width || null;
    this.height = data.height || null;
  }
}
