import { flatten } from 'flat';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

// background: ${props => props.theme.colors.danger && rgba(props.theme.colors.danger, 0.75)};
// color: white;
// padding: ${props => props.theme.spacing.vertical.small}
//   ${props => props.theme.spacing.horizontal.default};
// display: inline-block;
// border-radius: ${props => props.theme.radius.default};
// margin: ${props => props.theme.spacing.vertical.small} 0 0;

const MessageItem = styled(Typography)`
  margin-top: 0;
  && {
    color: inherit;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Message = styled.div`
  background: ${props => props.theme.palette.error.dark};
  color: ${props => props.theme.palette.error.contrastText};
  padding: ${props => `${props.theme.spacing.unit * 2}px`}
    ${props => `${props.theme.spacing.unit * 2}px`};
  border-radius: ${props => `${props.theme.spacing.unit / 2}px`};
`;

const extractErrors = (errors, statusOnly) => {
  let message = errors;
  console.log({ message, statusOnly });

  if (typeof message === 'string') {
    return <MessageItem>{message}</MessageItem>;
  }

  if (statusOnly) {
    if (Object.keys(errors).indexOf('status') > -1) {
      return <MessageItem>{errors.status}</MessageItem>;
    }

    return null;
  }

  if (typeof errors === 'object') {
    const flattenErrors = flatten(errors);
    message = [];
    Object.keys(flattenErrors).map(index =>
      // console.log({ index, message: flattenErrors[index] });
      message.push(<MessageItem key={index}>{flattenErrors[index]}</MessageItem>)
    );
  }

  return message;
};

const ErrorMessage = ({ children, statusOnly }) => {
  const errorMessageContent = extractErrors(children, statusOnly);

  return errorMessageContent ? (
    <Message className="text-danger">{errorMessageContent}</Message>
  ) : null;
};

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]).isRequired,
  statusOnly: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  statusOnly: true,
};

export default ErrorMessage;
