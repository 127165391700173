import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const RecordName = styled.span`
  display: block;
  margin-right: 16px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
`;

const LoadingMessage = styled.div`
  margin-top: 24px;
`;

class Restore extends Component {
  render() {
    const { data, restoreRecord, loadingDeleted, loadingMessage } = this.props;

    if (loadingDeleted) {
      return (
        <LoadingWrapper>
          <CircularProgress color="secondary" />
          <LoadingMessage>
            <Typography>{loadingMessage}</Typography>
          </LoadingMessage>
        </LoadingWrapper>
      );
    }

    if (data.length) {
      return (
        <List>
          {data.map(item => (
            <ListItem key={item.id}>
              <RecordName>
                <Typography component="span">{item.name}</Typography>
              </RecordName>
              <Button onClick={() => restoreRecord(item.id)}>
                <Trans id="msg.restore" />
              </Button>
            </ListItem>
          ))}
        </List>
      );
    }
    return <Typography id="msg.restore.no_data">Sorry, no deleted records</Typography>;
  }
}

Restore.propTypes = {
  data: PropTypes.array,
  restoreRecord: PropTypes.func.isRequired,
  loadingDeleted: PropTypes.bool,
  loadingMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Restore.defaultProps = {
  data: [],
  loadingDeleted: false,
  loadingMessage: <Trans id="msg.loading_data" />,
};

export default Restore;
