import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { TableRow, TableCell, Typography } from '@material-ui/core';

class TableEmptyContent extends Component {
  static propTypes = {
    colSpan: PropTypes.number.isRequired,
    emptyMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    emptyMessage: <Trans id="msg.table.empty_data" />,
  };

  render() {
    const { colSpan, emptyMessage } = this.props;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell colSpan={colSpan} />
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, minHeight: 48 }} align="center" colSpan={colSpan}>
            <Typography>{emptyMessage}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colSpan} />
        </TableRow>
      </React.Fragment>
    );
  }
}

export default TableEmptyContent;
