import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Switch, Tooltip } from '@material-ui/core';
import * as moment from 'moment';
import { i18n } from 'App.js';
import CustomizedLink from 'components/Base/Link';
import RosterActionsMenu from './RosterActionsMenu';

class RosterTableItem extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    toggleMemberVisibility: PropTypes.func.isRequired,
    manageMemberVisibility: PropTypes.bool,
    removeMemberFromRoster: PropTypes.func.isRequired,
    setMemberToUpdate: PropTypes.func.isRequired,
    rosterType: PropTypes.string,
    trainerRoles: PropTypes.array.isRequired,
  };

  static defaultProps = {
    manageMemberVisibility: false,
    rosterType: 'player',
  };

  state = {
    showOnWeb: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.member.show_on_web !== prevState.showOnWeb && nextProps.rosterType === 'player') {
      return {
        showOnWeb: nextProps.member.show_on_web,
      };
    }

    return null;
  }

  componentDidMount() {
    const {
      // eslint-disable-next-line camelcase
      member: { show_on_web, trainer_role_id },
      rosterType,
      trainerRoles,
    } = this.props;

    // eslint-disable-next-line
    let show_on_web_organizer = false;
    // eslint-disable-next-line
    const trainerRole = trainerRoles.find(role => role.id === trainer_role_id);

    if (rosterType === 'organizer' && trainerRole) {
      // eslint-disable-next-line
      show_on_web_organizer = trainerRole.visible_on_web;
    }

    this.setState({
      // eslint-disable-next-line
      showOnWeb: rosterType === 'organizer' ? show_on_web_organizer : show_on_web, // TODO: add correct value from trainer role instead of 0
    });
  }

  toggleVisibility = teamMemberId => {
    const { showOnWeb } = this.state;
    const { toggleMemberVisibility } = this.props;
    // this.setState({
    //   showOnWeb: !showOnWeb,
    // });
    toggleMemberVisibility(teamMemberId, !showOnWeb);
  };

  render() {
    const {
      member,
      manageMemberVisibility,
      removeMemberFromRoster,
      setMemberToUpdate,
      rosterType,
      trainerRoles,
    } = this.props;
    const { showOnWeb } = this.state;
    const toggleTitlePlayer = manageMemberVisibility
      ? i18n._('msg.rosters.show_on_web_tooltip')
      : i18n._('msg.rosters.show_on_web_disabled_tooltip');
    const toggleTitleOrganizer = i18n._('msg.rosters.show_on_web_organizer_tooltip');

    if (
      (rosterType === 'player' && !member.is_player) ||
      (rosterType === 'organizer' && !member.is_organizer)
    ) {
      return null;
    }

    const trainerRoleName = trainerRoles.find(r => r.id === member.trainer_role_id)
      ? trainerRoles.find(r => r.id === member.trainer_role_id).name
      : '-';

    return (
      <TableRow>
        <TableCell>{member.id}</TableCell>
        <TableCell>{member.member_id}</TableCell>
        {rosterType === 'player' && (
          <TableCell align="center">
            {member.dress_number ? `#${member.dress_number}` : '-'}
          </TableCell>
        )}
        {rosterType === 'player' && <TableCell>{member.position_id}</TableCell>}
        {rosterType === 'organizer' && <TableCell>{trainerRoleName}</TableCell>}
        <TableCell>
          <CustomizedLink
            noWrap
            to="#"
            onClick={evt => {
              evt.preventDefault();
              setMemberToUpdate(member.id);
            }}
          >
            {member.last_name} {member.first_name} ({member.team_id})
          </CustomizedLink>
        </TableCell>
        <TableCell>
          <span style={{ whiteSpace: 'nowrap' }}>
            {moment(member.birthdate).format('D. M. YYYY')}
          </span>
        </TableCell>
        <TableCell align="center">
          {rosterType === 'player' ? (
            <Tooltip title={toggleTitlePlayer}>
              <div>
                <Switch
                  color="primary"
                  checked={showOnWeb}
                  disabled={!manageMemberVisibility}
                  onChange={rosterType === 'player' ? () => this.toggleVisibility(member.id) : null}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={toggleTitleOrganizer}>
              <div>
                <Switch color="primary" checked={showOnWeb} disabled />
              </div>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center">
          <RosterActionsMenu
            member={member}
            removeMemberFromRoster={removeMemberFromRoster}
            setMemberToUpdate={setMemberToUpdate}
            rosterType={rosterType}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default RosterTableItem;
