import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import {
  Avatar,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Switch,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import HeaderCell from 'components/Table/HeaderCell';
import routes from 'Routes.js';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import CustomizedLink from 'components/Base/Link';
import ContextMenu from 'components/Base/ContextMenu';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { StyledTable } from 'components/Table/StyledTable';

const RestrictionRange = ({ from, to }) => {
  const isFromValid = !Number.isNaN(parseInt(from, 10));
  const isToValid = !Number.isNaN(parseInt(to, 10));
  let finalMessage;

  if (!isFromValid && !isToValid) {
    finalMessage = <Trans id="msg.restrictions.no_restrictions">Without restrictions</Trans>;
  } else if (isFromValid && isToValid) {
    finalMessage = `${from} - ${to}`;
  } else if (!isFromValid && isToValid) {
    finalMessage = <Trans id="{year} and older" values={{ year: to }} />;
  } else if (isFromValid && !isToValid) {
    finalMessage = <Trans id="{year} and younger" values={{ year: from }} />;
  }

  return <div style={{ whiteSpace: 'nowrap' }}>{finalMessage}</div>;
};

RestrictionRange.propTypes = {
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

RestrictionRange.defaultProps = {
  from: null,
  to: null,
};

const RestrictionGender = ({ gender }) => {
  let finalMessage;

  switch (gender) {
    case 'female':
      finalMessage = <Trans id="msg.restrictions.gender.females_only" />;
      break;
    case 'male':
      finalMessage = <Trans id="msg.restrictions.gender.males_only" />;
      break;
    default:
      finalMessage = <Trans id="msg.restrictions.gender.no_restriction" />;
      break;
  }

  return <div style={{ whiteSpace: 'nowrap' }}>{finalMessage}</div>;
};

RestrictionGender.propTypes = {
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

class TeamsTable extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    teams: PropTypes.array,
    handleVisibilityChange: PropTypes.func.isRequired,
    deleteTeam: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
    teamGroups: PropTypes.array.isRequired,
  };

  static defaultProps = {
    loading: false,
    teams: [],
  };

  render() {
    const {
      teams,
      loading,
      handleVisibilityChange,
      deleteTeam,
      managePermissions,
      teamGroups,
    } = this.props;

    if (!loading && teams.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.teams.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <Card>
        <StyledTable padding="dense">
          <TableHead>
            <TableRow>
              <HeaderCell align="center">ID</HeaderCell>
              <HeaderCell padding="none" align="center" />
              <HeaderCell
              /* style={{ width: '100%' }} */
              >
                <Trans id="msg.name" />
                {/* Name */}
              </HeaderCell>
              <HeaderCell align="center">
                <Trans id="msg.order" />
                {/* Order */}
              </HeaderCell>
              <HeaderCell>
                <Trans id="msg.group" />
                {/* Group */}
              </HeaderCell>
              <HeaderCell>
                <Trans id="msg.restrictions" />
              </HeaderCell>
              <HeaderCell align="center">
                <Trans id="msg.web" />
                {/* Web */}
              </HeaderCell>
              <HeaderCell align="center">
                <Trans id="msg.club_zone" />
                {/* ČS */}
              </HeaderCell>
              {managePermissions && (
                <HeaderCell align="center">
                  <Trans id="msg.actions" />
                  {/* Actions */}
                </HeaderCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableLoadingContent colSpan={managePermissions ? 9 : 8} />}

            {!loading &&
              teams.map((team, index) => (
                <TableRow key={team.id}>
                  <TableCell align="center">{team.id}</TableCell>
                  <TableCell padding="none" align="center">
                    {team.color ? (
                      <Avatar style={{ background: team.color, width: 20, height: 20 }} />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={team.description} placement="right">
                      <div>
                        {managePermissions ? (
                          <CustomizedLink
                            noWrap
                            to={reverse(routes.management.teams.single.update, {
                              teamId: team.id,
                            })}
                          >
                            {team.name}
                          </CustomizedLink>
                        ) : (
                          team.name
                        )}
                        {team.league_name && (
                          <React.Fragment>
                            <br />
                            {team.league_name}
                          </React.Fragment>
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>
                    {teamGroups.find(tg => tg.value === team.team_group_id && tg.value !== 0)
                      ? teamGroups.find(tg => tg.value === team.team_group_id).label
                      : '-'}
                  </TableCell>
                  <TableCell>
                    <RestrictionRange
                      from={team.birth_year_range.from}
                      to={team.birth_year_range.to}
                    />
                    <RestrictionGender gender={team.gender} />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={i18n._('msg.teams.show_on_web_tooltip')}>
                      <Switch
                        color="primary"
                        checked={Boolean(team.web_settings.show)}
                        disabled={!managePermissions}
                        onChange={() => {
                          handleVisibilityChange(
                            team.id,
                            'web_settings.show',
                            !team.web_settings.show
                          );
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={i18n._('msg.teams.club_zone_settings.show_team')}>
                      <Switch
                        color="primary"
                        checked={Boolean(team.visible)}
                        disabled={!managePermissions}
                        onChange={() => {
                          handleVisibilityChange(team.id, 'visible', !team.visible);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  {managePermissions && (
                    <TableCell align="center">
                      <ContextMenu
                        variant="icon"
                        tooltip={i18n._('msg.actions')}
                        color="inherit"
                        icon="more_vert"
                        menuItems={[
                          {
                            text: i18n._('msg.record_update'),
                            url: reverse(routes.management.teams.single.update, {
                              teamId: team.id,
                            }),
                          },
                          managePermissions && {
                            text: i18n._('msg.record_delete'),
                            action: () => deleteTeam(team.id),
                            variant: 'delete',
                          },
                        ]}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </Card>
    );
  }
}

export default TeamsTable;
