import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import * as moment from 'moment';
import { Grid } from '@material-ui/core/es';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import EosCheckbox from 'components/Forms/EosCheckbox';
import SubmitButton from 'components/Forms/SubmitButton';
import EosDatepicker from 'components/Forms/EosDatepicker';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';

const SeasonFormComponent = ({ isSubmitting, errors, closeDialog, season }) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          type="text"
          name="name"
          label={i18n._('msg.seasons.season_name_label')}
          required
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field
          name="range.from"
          type="date"
          label={i18n._('msg.seasons.season_start_label')}
          format="D. M. YYYY"
          required
          placeholder={`${moment().format('D. M. YYYY')}`}
          component={EosDatepicker}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field
          name="range.to"
          type="date"
          label={i18n._('msg.seasons.season_end_label')}
          format="D. M. YYYY"
          required
          placeholder={`${moment().format('D. M. YYYY')}`}
          component={EosDatepicker}
        />
      </Grid>
      {!(season && season.is_active) && (
        <Grid item xs={12}>
          <Field
            type="checkbox"
            name="is_active"
            label={i18n._('msg.seasons.season_active_label')}
            component={EosCheckbox}
          />
        </Grid>
      )}

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
            {isSubmitting ? (
            <Trans id="msg.saving_changes" />
          ) : season ? (
            <Trans id="msg.save_changes" />
          ) : (
            <Trans id="msg.seasons.add_new" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

SeasonFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  season: PropTypes.object,
};

SeasonFormComponent.defaultProps = {
  isSubmitting: false,
  season: null,
};

window.moment = moment;

class SeasonForm extends Component {
  validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
    range: yup.object().shape({
      from: yup
        .date(
          'format',
          i18n._('msg.form.invalid_format_with_helper', {
            helper_string: `17. 2. ${new Date().getFullYear()}`,
          })
        )
        .required(i18n._('msg.form.required')),
      to: yup
        .date(
          'format',
          i18n._('msg.form.invalid_format_with_helper', {
            helper_string: `17. 2. ${new Date().getFullYear()}`,
          })
        )
        .when(
          'from',
          (from, schema) =>
            from &&
            schema.min(from, input => {
              console.log({ input });
              return i18n._('msg.form.date_range_min', {
                min_date: moment(input.min).format('D. M. YYYY'),
              });
              // return `Please insert later date then ${moment(input.min).format('D. M. YYYY')}`;
            })
        )
        .required(i18n._('msg.form.required')),
    }),
    is_active: yup.boolean().oneOf([true, false]),
  });

  initialValues = {
    name: '',
    range: {
      from: moment().subtract(1, 'days'),
      to: moment(),
    },
    is_active: false,
  };

  render() {
    const { season, closeDialog, handleSubmit } = this.props;
    return (
      <div>
        <Formik
          initialValues={season || this.initialValues}
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <SeasonFormComponent {...formikProps} closeDialog={closeDialog} season={season} />
          )}
        />
      </div>
    );
  }
}

SeasonForm.propTypes = {
  season: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SeasonForm.defaultProps = {
  season: null,
};

export default SeasonForm;
