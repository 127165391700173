import React, { Component } from 'react';
import { IconButton, Icon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import { i18n } from 'App.js';
import routes from 'Routes.js';

class RosterActionsMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      // eslint-disable-next-line
      member: { id, member_id, first_name, last_name },
      removeMemberFromRoster,
      setMemberToUpdate,
      rosterType,
    } = this.props;
    return (
      <div>
        <Tooltip title={i18n._('msg.more_actions')} disableFocusListener>
          <IconButton
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Icon>more_vert</Icon>
          </IconButton>
        </Tooltip>
        <Menu
          id="teams-actions-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem divider>
            {/* eslint-disable-next-line */}
            {first_name} {last_name}
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              console.log(`UPDATE MEMBER ${id}`);
              setMemberToUpdate(id);
              this.handleClose();
            }}
          >
            <Trans id="msg.record_update" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={reverse(routes.management.members.single.detail, { memberId: member_id })}
            target="_blank"
          >
            <Trans id="msg.members.profile" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={reverse(routes.management.members.single.detail, { memberId: member_id })}
            target="_blank"
          >
            <Trans id="msg.members.profile" />
          </MenuItem>
          <MenuItem
            button
            style={{ color: 'red' }}
            onClick={() => {
              console.log(`REMOVE MEMBER ${id}`);
              removeMemberFromRoster(id, rosterType);
              this.handleClose();
            }}
          >
            <Trans id="msg.record_delete" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

RosterActionsMenu.propTypes = {
  member: PropTypes.object.isRequired,
  removeMemberFromRoster: PropTypes.func.isRequired,
  setMemberToUpdate: PropTypes.func.isRequired,
  rosterType: PropTypes.string,
};

RosterActionsMenu.defaultProps = {
  rosterType: 'player',
};

export default RosterActionsMenu;
