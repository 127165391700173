import React, { Component } from 'react';
import EventTypesTable from 'components/Management/Settings/EventTypes/EventTypesTable';
import EventTypeDialog from 'components/Management/Settings/EventTypes/EventTypeDialog';
import { EventTypeManagementService } from 'api/event-type.service';
import { i18n } from 'App.js';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { EventType } from 'models/EventType';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import routes from 'Routes.js';
import ability from 'ability.js';

class EventTypes extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    eventTypes: [],
    eventType: null,
    dialogOpened: false,
    loading: false,
  };

  componentDidMount() {
    this.fetchEventTypes();
  }

  fetchEventTypes = async () => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loading: true,
    });

    EventTypeManagementService.getAll()
      .with('is_constrained')
      .fetch()
      .then(response => {
        if (response.length > 0) {
          this.setState(
            {
              eventTypes: response,
              loading: false,
            },
            () => {
              enqueueSnackbar(i18n._('msg.event_types.loading_success'), { variant: 'success' });
            }
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({
          eventTypes: [],
          loading: false,
        });
        enqueueSnackbar(i18n._('msg.event_types.loading_error'), { variant: 'error' });
      });
  };

  openEditEventType = eventType => {
    this.setState({
      eventType,
      dialogOpened: true,
    });
  };

  openCreateEventType = () => {
    this.setState({
      eventType: null,
      dialogOpened: true,
    });
  };

  deleteEventType = eventTypeId => {
    const { enqueueSnackbar } = this.props;

    EventTypeManagementService.delete(eventTypeId)
      .then(() => {
        const { eventTypes } = this.state;

        const deletedEventTypeIndexInEventTypes = eventTypes.findIndex(mt => mt.id === eventTypeId);

        // eslint-disable-next-line
        eventTypes.splice(deletedEventTypeIndexInEventTypes, 1);

        this.setState({
          eventTypes,
        });
        enqueueSnackbar(i18n._('msg.event_types.delete_success'), { variant: 'info' });
      })
      .catch(e => {
        console.log({ e });
        enqueueSnackbar(i18n._(e.response.data.message), { variant: 'error' });
      });
  };

  closeDialog = () => {
    this.setState({
      dialogOpened: false,
      eventType: null,
    });
  };

  createEventType = async data => {
    const { enqueueSnackbar } = this.props;

    const formattedData = EventType.formatFormData(data);

    await EventTypeManagementService.create(formattedData).then(response => {
      const { eventTypes } = this.state;

      this.setState(
        {
          eventTypes: [...eventTypes, response],
        },
        () => {
          enqueueSnackbar(i18n._('msg.event_types.create_success'), { variant: 'success' });
        }
      );
    });
  };

  updateEventType = async (eventTypeId, data) => {
    const { enqueueSnackbar } = this.props;

    const formattedData = EventType.formatFormData(data);

    await EventTypeManagementService.update(eventTypeId, formattedData).then(response => {
      const { eventTypes } = this.state;

      const updatedEventTypeIndexInEventTypes = eventTypes.findIndex(p => p.id === eventTypeId);

      eventTypes[updatedEventTypeIndexInEventTypes] = response;

      this.setState(
        {
          eventTypes,
        },
        () => {
          enqueueSnackbar(i18n._('msg.event_types.update_success'), { variant: 'success' });
        }
      );
    });
  };

  operationCallback = (promise, actions) =>
    promise
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });

  handleSubmit = (values, actions) => {
    const { eventType } = this.state;
    const promise = eventType
      ? this.updateEventType(eventType.id, values)
      : this.createEventType(values);

    this.operationCallback(promise, actions);
  };

  render() {
    const { eventTypes, eventType, dialogOpened, loading } = this.state;
    const { accessLevel } = this.props;
    return (
      <div>
        <PageHeader
          title={i18n._('msg.event_types.page_title')}
          customControlsPrimary={[
            ability.can('manage', {
              __type: 'EventTypes',
              level: accessLevel,
            }) && <FabButton variant="round" size="small" onClick={this.openCreateEventType} />,
          ]}
          breadcrumbsItems={[
            {
              url: routes.management.settings.all,
              // title: 'some title',
              text: i18n._('msg.settings.page_title'),
            },
            { url: null, text: i18n._('msg.event_types.page_title') },
          ]}
        />

        <EventTypesTable
          eventTypes={eventTypes}
          loading={loading}
          editEventType={this.openEditEventType}
          deleteEventType={this.deleteEventType}
          managePermissions={ability.can('manage', {
            __type: 'EventTypes',
            level: accessLevel,
          })}
        />

        <EventTypeDialog
          dialogOpened={dialogOpened}
          eventType={eventType}
          onClose={this.closeDialog}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default withSnackbar(EventTypes);
