/**
 * @property {number} from
 * @property {number} to
 */
export class NumberRange {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.from = Number(data.from);
    this.to = Number(data.to);
  }
}

/**
 * @property {(number|null)} from
 * @property {(number|null)} to
 */
export class NullableNumberRange {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.from = data.from === null || data.from === '' ? null : Number(data.from);
    this.to = data.to === null || data.to === '' ? null : Number(data.to);
  }
}
