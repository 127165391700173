import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, OutlinedInput, Select, MenuItem } from '@material-ui/core';
import { Trans } from '@lingui/macro';

class TeamsList extends Component {
  static propTypes = {
    teams: PropTypes.array,
    selectedTeam: PropTypes.number,
    loading: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    teams: [],
    selectedTeam: 0,
    loading: false,
  };

  state = {
    labelWidthTeams: 0,
  };

  componentDidMount = () => {
    this.setState({
      // eslint-disable-next-line
      labelWidthTeams: ReactDOM.findDOMNode(this.InputLabelRefTeams) && ReactDOM.findDOMNode(this.InputLabelRefTeams).offsetWidth || 0,
    });
  };

  render() {
    const { teams, handleChange, selectedTeam, loading } = this.props;
    const { labelWidthTeams } = this.state;
    return (
      <FormControl variant="outlined" margin="dense" fullWidth disabled={loading}>
        <InputLabel
          ref={ref => {
            this.InputLabelRefTeams = ref;
          }}
          htmlFor="team"
          margin="dense"
        >
          <Trans id="msg.team" />
          {/* Team */}
        </InputLabel>
        <Select
          value={selectedTeam || 0}
          onChange={handleChange}
          input={
            <OutlinedInput labelWidth={labelWidthTeams} margin="dense" name="team" id="team" />
          }
        >
          <MenuItem value={0} disabled>
            <em>
              <Trans id="msg.select_team" />
            </em>
          </MenuItem>
          {teams.length > 0 &&
            teams.map(team => (
              <MenuItem value={team.id} key={team.id}>
                {team.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default TeamsList;
