import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { i18n } from 'App.js';
import { MemberInvitation } from 'models/MemberInvitation';
import { StyledTable, StyledTableRow } from 'components/Table/StyledTable';
import ContextMenu from 'components/Base/ContextMenu';

class MemberUsersTable extends Component {
  static propTypes = {
    users: PropTypes.array,
    openDialog: PropTypes.func.isRequired,
    // createInvitation: PropTypes.func.isRequired,
    editInvitation: PropTypes.func.isRequired,
    deleteInvitation: PropTypes.func.isRequired,
    isRepresentable: PropTypes.bool,
    revokeUser: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    users: [],
    isRepresentable: true,
  };

  render() {
    const {
      openDialog,
      /* createInvitation, */ users,
      editInvitation,
      deleteInvitation,
      isRepresentable,
      revokeUser,
      managePermissions,
    } = this.props;

    return (
      <div>
        <Typography variant="h5">
          <Trans id="msg.members.users" />
        </Typography>
        <StyledTable padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans id="msg.user_account" />
              </TableCell>
              <TableCell>
                <Trans id="msg.login_email" />
              </TableCell>
              <TableCell>
                <Trans id="msg.members.users.name" />
              </TableCell>
              {managePermissions && <TableCell>invitations?</TableCell>}
              {managePermissions && (
                <TableCell align="center">
                  <Trans id="msg.actions" />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 &&
              users.map(u => (
                <StyledTableRow key={u.id || u.invitation.id} coloredBackground={!u.user_id}>
                  <TableCell>
                    {u.user_id ? (
                      <Tooltip title={u.user_id}>
                        <Icon>check_outline</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip title={i18n._('msg.members.user_not_set')}>
                        <Icon>error_outline</Icon>
                      </Tooltip>
                    )}
                  </TableCell>
                  {u.invitation ? (
                    <TableCell colSpan={2}>
                      {u.invitation.sent_at ? (
                        <React.Fragment>
                          <Trans id="msg.members.invitation_sent_at" />:{' '}
                          {u.invitation.sent_at.date.format('D. M. YYYY H:mm:ss')}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Trans id="msg.members.invitation_created_at" />:{' '}
                          {u.invitation.created_at.date.format('D. M. YYYY H:mm:ss')}
                        </React.Fragment>
                      )}
                      <br />
                      <Trans id="msg.members.invitation_sent_to" />: {u.invitation.email}
                    </TableCell>
                  ) : (
                    <React.Fragment>
                      <TableCell>{u.user_id ? u.login_email || u.contact_email : ''}</TableCell>
                      <TableCell>
                        {u.first_name && u.last_name ? (
                          <React.Fragment>
                            {u.first_name || ''} {u.last_name}
                          </React.Fragment>
                        ) : (
                          'Mr. Anonymous :)'
                        )}
                      </TableCell>
                    </React.Fragment>
                  )}
                  {managePermissions && (
                    <TableCell>
                      {((isRepresentable && !u.user_id) ||
                        (!isRepresentable && !u.user_id && u.main_user)) && (
                        <Button
                          onClick={() => {
                            if (u.invitation) {
                              editInvitation(u.invitation);
                            } else if (u.main_user) {
                              editInvitation(
                                new MemberInvitation({
                                  email: u.contact_email || '',
                                  type: 'user',
                                  member_representative_id: null,
                                })
                              );
                            } else {
                              editInvitation(
                                new MemberInvitation({
                                  email: u.contact_email || '',
                                  type: 'representative',
                                  member_representative_id: u.id,
                                })
                              );
                            }
                          }}
                        >
                          {u.invitation ? (
                            <Trans id="msg.members.invitation_resend" />
                          ) : (
                            <Trans id="msg.members.invitation_send" />
                          )}
                        </Button>
                      )}

                      {u.invitation && (
                        <Button onClick={() => deleteInvitation(u.invitation.id)}>
                          <Trans id="msg.members.invitation_remove" />
                        </Button>
                      )}
                    </TableCell>
                  )}
                  {managePermissions && (
                    <TableCell align="center">
                      {u.user_id && (
                        <ContextMenu
                          variant="icon"
                          tooltip={i18n._('msg.actions')}
                          color="inherit"
                          icon="more_vert"
                          menuItems={[
                            {
                              text: i18n._('msg.record_delete'),
                              action: () => revokeUser(u.user_id),
                              variant: 'delete',
                            },
                          ]}
                        />
                      )}
                    </TableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </StyledTable>
        {isRepresentable && managePermissions && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              openDialog();
            }}
          >
            <Trans id="msg.members.invitation_create_new" />
          </Button>
        )}
      </div>
    );
  }
}

export default MemberUsersTable;
