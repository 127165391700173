export class PaginatedData {
  /**
   * @param {array.<object>} data
   * @param {object} meta
   */
  constructor(data, meta) {
    this.data = data;
    this.meta = meta;
  }
}
