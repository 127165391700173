import { sortBy } from 'lodash';
import { Position } from '../models/Position';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';

/**
 * @static
 */
export class PositionManagementService extends ManagementService {
  static endpoint = 'positions';

  /**
   * @param {number} id
   * @return {Promise<Position>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(PositionManagementService.buildUrl(id))
      .then(response => new Position(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(PositionManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<Position>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall
      .get(PositionManagementService.buildUrl(), { ...config, params })
      .then(response => {
        const positions = response.data.data.map(data => new Position(data));

        if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
          return positions;
        }

        return new PaginatedData(positions, response.data.meta);
      });
  }

  /**
   * @param {object} data
   * @return {Promise<Position>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(PositionManagementService.buildUrl(), data)
      .then(response => new Position(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<Position>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(PositionManagementService.buildUrl(id), data)
      .then(response => new Position(response.data));
  }

  /**
   * @param {object[]} data
   * @return {Promise<Position[]>}
   */
  static async updateMultiple(data) {
    return apiCall
      .patch(PositionManagementService.buildUrl(), { positions: data })
      .then(response => {
        const positionsSortedByOrder = sortBy(response.data.positions, 'order', 'asc');
        return positionsSortedByOrder.map(positionData => new Position(positionData));
      });
  }

  /**
   * @param {number} id
   * @public
   */
  static async delete(id) {
    return apiCall.delete(PositionManagementService.buildUrl(id));
  }
}
