export class ApiService {
  static endpoint = null;

  static endpointSuffix = null;

  /**
   * @param id
   * @return {string}
   * @protected
   */
  static buildUrl(id = null) {
    let url = `/${this.endpoint}`;
    if (id) {
      url = `${url}/${id}`;
    }
    if (this.endpointSuffix) {
      url = `${url}/${this.endpointSuffix}`;
    }
    return url;
  }
}
