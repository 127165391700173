import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Checkbox,
  Icon,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import styled from 'styled-components';
import { get } from './helpers';

const StyledSelect = styled(Select)`
  & > div {
    & > div {
      box-sizing: border-box;
    }
    & > svg {
      right: 8px;
    }
  }
`;

const StyledIcon = styled(({ ...rest }) => <Icon {...rest} />)``;

const StyledMenuItemWithIcon = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;

  ${StyledIcon} {
    margin-right: 8px;
  }
`;

class EosSelect extends React.PureComponent {
  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleSelectChange = evt => {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched, values },
    } = this.props;

    if (name.indexOf('.') > -1) {
      const parts = name.split('.');
      const parent = parts[0];
      const child = parts[1];
      const currentParentValue = values[parent];

      currentParentValue[child] = evt.target.value;

      setFieldValue([parent], currentParentValue);
    } else {
      setFieldValue([name], evt.target.value);
    }
    // setFieldValue([name], evt.target.value);
    setFieldTouched([name], true);
  };

  render() {
    const {
      required,
      label,
      field: { name, value },
      form: { touched, errors, isSubmitting },
      options,
      disabled,
      multiple,
      customVariant,
      ...other
    } = this.props;
    const id = `sel_${name}`;
    const { labelWidth } = this.state;
    const error = get(errors, name);
    const touch = get(touched, name);
    return (
      <FormControl
        fullWidth
        required={required}
        error={!!error && !!touch}
        variant="outlined"
        margin="dense"
      >
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor={id}
          margin="dense"
        >
          {label}
        </InputLabel>
        <StyledSelect
          onChange={this.handleSelectChange}
          value={value}
          input={<OutlinedInput labelWidth={labelWidth} name={name} id={id} margin="dense" />}
          renderValue={
            multiple
              ? selected => {
                  const result = [];
                  // TODO: refactor
                  // eslint-disable-next-line
                  selected.map(s => {
                    const indexOfValue = options.findIndex(item => item.value === s);
                    result.push(options[indexOfValue].label);
                  });
                  return result.join(', ');
                }
              : null
          }
          multiple={multiple}
          disabled={isSubmitting || disabled}
          {...other}
        >
          {options.map((item, index) => {
            let MenuItemContent = String(item.label);

            if (multiple) {
              MenuItemContent = (
                <React.Fragment>
                  <Checkbox checked={value.indexOf(item.value) > -1} />
                  <ListItemText primary={item.label} />
                </React.Fragment>
              );
            }

            if (customVariant === 'withIconAtStart') {
              MenuItemContent = (
                <StyledMenuItemWithIcon>
                  <StyledIcon fontSize="small">{item.icon}</StyledIcon>
                  {String(item.label)}
                </StyledMenuItemWithIcon>
              );
            }

            return (
              <MenuItem key={`${id}_${index}`} value={item.value}>
                {MenuItemContent}
              </MenuItem>
            );
          })}
        </StyledSelect>
        {touch && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

EosSelect.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    // onChange: PropTypes.func,
    // onBlur: PropTypes.func,
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    touched: PropTypes.object,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  customVariant: PropTypes.string,
};

EosSelect.defaultProps = {
  required: false,
  label: '',
  disabled: false,
  multiple: false,
  customVariant: null,
};

export default EosSelect;
