import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import EosTextInput from 'components/Forms/EosTextInput';
import EosSelect from 'components/Forms/EosSelect';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import ErrorMessage from 'components/Forms/ErrorMessage';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';

const TeamCreateFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  resetForm,
  seasons,
  selectedSeasonId,
  teamGroups,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans id="msg.teams.season_for">For season</Trans>:{' '}
          {seasons.filter(s => s.id === selectedSeasonId)[0].name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          type="text"
          name="name"
          label={i18n._('msg.name')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type="text"
          name="league_name"
          label={i18n._('msg.teams.league_name')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="team_group_id"
          label={i18n._('msg.group')}
          options={teamGroups}
          component={EosSelect}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="number"
          name="birth_year_range.from"
          inputProps={{
            min: 1900,
            max: new Date().getFullYear(),
            step: 1,
          }}
          label={i18n._('msg.year_from')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="number"
          name="birth_year_range.to"
          inputProps={{
            min: 1900,
            max: new Date().getFullYear(),
            step: 1,
          }}
          label={i18n._('msg.year_to')}
          component={EosTextInput}
        />
      </Grid>

      {0 && Object.keys(errors).length > 0 ? (
        <Grid item xs={12}>
          <ErrorMessage>{errors}</ErrorMessage>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <SubmitArea align="left">
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
          </Button>{' '}
          <Button
            variant="contained"
            color="secondary"
            type="reset"
            disabled={isSubmitting}
            onClick={() => {
              resetForm();
            }}
          >
            {i18n._('msg.reset')}
          </Button>
        </SubmitArea>
      </Grid>
    </Grid>
  </EosForm>
);

TeamCreateFormComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  // values: PropTypes.object.isRequired,
  // setFieldValue,
  // setFieldTouched,
  resetForm: PropTypes.func.isRequired,
  seasons: PropTypes.array.isRequired,
  selectedSeasonId: PropTypes.number.isRequired,
  teamGroups: PropTypes.array.isRequired,
};

class TeamCreateForm extends Component {
  initialValues = {
    name: '',
    league_name: '',
    team_group_id: 0,
    birth_year_range: {
      from: '',
      to: '',
    },
  };

  render() {
    const { handleSubmit, seasons, selectedSeasonId, teamGroups } = this.props;
    return (
      <div>
        <Formik
          initialValues={this.initialValues}
          validationSchema={yup.object().shape({
            name: yup.string().required(i18n._('msg.form.required')),
            league_name: yup.string(),
            team_group_id: yup
              .number()
              .transform(value => (Number.isNaN(value) ? null : value))
              .oneOf(
                teamGroups.map(i => i.value),
                i18n._('msg.form.oneOfValues', {
                  values: teamGroups.map(i => i.label).join(', '),
                })
              )
              .nullable(),
            birth_year_range: yup.object().shape(
              {
                from: yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : parseInt(value)))
                  .min(1900, i18n._('msg.form.range_min', { range_min: 1900 })) // 'Minimal range limit is ${min}'
                  .max(
                    new Date().getFullYear(),
                    i18n._('msg.form.range_max', { range_max: new Date().getFullYear() })
                  ) // 'Maximal range limit is ${max}')
                  .nullable()
                  .when('to', {
                    is: value => !Number.isNaN(parseInt(value)),
                    then: yup
                      .number()
                      .test(
                        'test-1',
                        i18n._(
                          'msg.form.range_min_error'
                        ) /* Please enter smaller or equal value than range end */,
                        function(value) {
                          if (
                            value !== null &&
                            !Number.isNaN(parseInt(this.parent.to)) &&
                            this.parent.to !== null
                          ) {
                            return value <= this.parent.to;
                          }
                          return true;
                        }
                      ),
                  }),
                to: yup
                  .number()
                  .transform(value => (Number.isNaN(value) ? null : parseInt(value)))
                  .min(1900, i18n._('msg.form.range_min', { range_min: 1900 })) // 'Minimal range limit is ${min}'
                  .max(
                    new Date().getFullYear(),
                    i18n._('msg.form.range_max', { range_max: new Date().getFullYear() })
                  ) // 'Maximal range limit is ${max}')
                  .nullable()
                  .when('from', {
                    is: value => !Number.isNaN(parseInt(value)),
                    then: yup
                      .number()
                      .test(
                        'test-2',
                        i18n._(
                          'msg.form.range_max_error'
                        ) /* Please enter bigger or equal value than range start */,
                        function(value) {
                          if (
                            value !== null &&
                            !Number.isNaN(parseInt(this.parent.from)) &&
                            this.parent.from !== null
                          ) {
                            return value >= this.parent.from;
                          }
                          return true;
                        }
                      ),
                  }),
              },
              ['from', 'to']
            ),
          })}
          onSubmit={handleSubmit}
          // component={TeamCreateFormComponent}
          render={formikProps => (
            <TeamCreateFormComponent
              {...formikProps}
              seasons={seasons}
              selectedSeasonId={selectedSeasonId}
              teamGroups={teamGroups}
            />
          )}
        />
      </div>
    );
  }
}

TeamCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  seasons: PropTypes.array.isRequired,
  selectedSeasonId: PropTypes.number.isRequired,
  teamGroups: PropTypes.array.isRequired,
};

export default TeamCreateForm;
