import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import MemberCreateForm from 'components/Management/Members/MemberCreateForm';
import routes from 'Routes';
import { MemberManagementService } from 'api/member.service';
import { withSnackbar } from 'notistack';
import { reverse } from 'named-urls';
import { i18n } from 'App.js';
import PropTypes from 'prop-types';
import { FullMember } from 'models/Member';
import PageHeader from 'components/PageHeader';
import { RoleManagementService } from 'api/role.service';

export class Create extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    newCreatedMemberId: null,
    roles: [{ label: i18n._('msg.members.role_default_label'), value: 0 }],
  };

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = async () => {
    const { roles: rolesDefault } = this.state;
    let roles = [];
    await RoleManagementService.getAll()
      .fetch()
      .then(response => {
        roles = response.map(role => ({ label: role.name, value: role.id }));

        this.setState({
          roles: [...rolesDefault, ...roles],
        });
      });
  };

  createMember = (values, actions) => {
    const { enqueueSnackbar } = this.props;
    const formattedData = FullMember.formatCreateFormData(values);

    MemberManagementService.create(formattedData)
      .then(response => {
        console.log('submit response (create)', { response });

        actions.setSubmitting(false);
        this.setState({ newCreatedMemberId: response.id }, () => {
          enqueueSnackbar(i18n._('msg.members.create_success'), { variant: 'success' });
        });

        if (values.send_invitation) {
          this.createInvitation(response.id, {
            email: values.contact_email,
            type: null,
            member_representative_id: null,
          });
        }
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
        Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }
        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  createInvitation = (id, data) => {
    MemberManagementService.createInvitation(id, data)
      .then(response => {
        console.log({ response });
      })
      .catch(error => console.log({ error }));
  };

  handleSubmit = (values, actions) => {
    this.createMember(values, actions);
  };

  render() {
    const { newCreatedMemberId, roles } = this.state;

    if (newCreatedMemberId) {
      return (
        <Redirect
          to={reverse(routes.management.members.single.detail, { memberId: newCreatedMemberId })}
        />
      );
    }
    return (
      <div>
        <PageHeader
          breadcrumbsItems={[
            {
              url: routes.management.members.all,
              title: 'some title',
              text: i18n._('msg.members.page_title'),
            },
            { url: null, text: i18n._('msg.members.create_title') },
          ]}
          title={i18n._('msg.members.create_title')}
        />

        <MemberCreateForm handleSubmit={this.handleSubmit} roles={roles} />
      </div>
    );
  }
}

export default withSnackbar(Create);
