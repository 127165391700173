import moment from 'moment';

/**
 * @property {string} id
 * @property {string} token
 * @property {moment.Moment} expiration_at
 * @property {string} user_agent
 * @property {string} client_ip
 */
export class Token {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.token = data.token;
    this.expiration_at = moment(data.expiration_at);
    this.user_agent = data.user_agent;
    this.client_ip = data.client_ip;
  }

  isExpired() {
    return this.expiration_at.isBefore(); // TODO: check if works
  }
}
