import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ClickAwayListener,
  Fab,
  Grow,
  Icon,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const styles = (/* theme */) => ({
  root: {
    display: 'flex',
  },
  popper: {
    // marginRight: theme.spacing.unit * 2,
    zIndex: 2,
  },
});

const ContextMenuOpener = ({ text, variant, icon, ...rest }) => {
  switch (variant) {
    case 'icon':
      return (
        <IconButton {...rest}>
          <Icon>{icon}</Icon>
        </IconButton>
      );
    case 'withIcon':
      if (text && text.length > 0) {
        return (
          <Fab size="medium" variant="extended" {...rest}>
            <Icon style={{ marginRight: 8 }}>{icon}</Icon>
            {text}
          </Fab>
        );
      }

      return (
        <Fab size="small" {...rest}>
          <Icon>{icon}</Icon>
        </Fab>
      );
    case 'text':
      return <Button {...rest}>{text}</Button>;
    default:
      return (
        <Button variant="contained" {...rest}>
          {text}
        </Button>
      );
  }
};
ContextMenuOpener.propTypes = {
  variant: PropTypes.oneOf(['withIcon', 'button', 'text', 'icon']).isRequired,
  icon: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
ContextMenuOpener.defaultProps = {
  text: null,
  icon: null,
};

// eslint-disable-next-line
const StyledMenuItem = styled(({ variant, ...rest }) => <MenuItem {...rest} />)`
  && {
    color: ${props => (props.variant === 'delete' ? red[400] : 'inherit')};
  }
`;

const StyledContextMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

class ContextMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['withIcon', 'button', 'text', 'icon']),
    icon: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    menuItems: PropTypes.array,
    color: PropTypes.oneOf(['primary', 'secondary', 'inherit', 'default']),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    variant: 'button',
    icon: 'list',
    text: null,
    menuItems: [],
    color: 'primary',
    tooltip: null,
    disabled: false,
  };

  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes, variant, text, icon, menuItems, color, tooltip, disabled } = this.props;
    const { open } = this.state;

    if (menuItems.length === 0) {
      return null;
    }

    const ContextMenuOpenerElement = (
      <StyledContextMenuWrapper>
        <ContextMenuOpener
          variant={variant}
          text={text}
          icon={icon}
          color={color}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
          disabled={disabled}
        />
      </StyledContextMenuWrapper>
    );

    return (
      <div className={classes.root}>
        {/* <Button
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          Toggle Menu Grow
        </Button> */}

        {tooltip ? (
          <Tooltip title={tooltip}>{ContextMenuOpenerElement}</Tooltip>
        ) : (
          ContextMenuOpenerElement
        )}

        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          // disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {menuItems.map((menuItem, index) => {
                      if (menuItem.action) {
                        return (
                          <StyledMenuItem
                            key={index}
                            onClick={menuItem.action}
                            variant={menuItem.variant}
                          >
                            {menuItem.text}
                          </StyledMenuItem>
                        );
                      }
                      if (menuItem.url) {
                        return (
                          <StyledMenuItem
                            key={index}
                            button
                            component={Link}
                            to={menuItem.url}
                            variant={menuItem.variant}
                          >
                            {menuItem.text}
                          </StyledMenuItem>
                        );
                      }
                      return null;
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(ContextMenu);
