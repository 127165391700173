import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import IconButton from '@material-ui/core/IconButton';
import { AuthConsumer } from 'AuthContext';
import SidebarItem from 'components/Sidebar/SidebarItem';
import Can from 'components/Can';

const drawerWidth = 250;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, // theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: 0, // theme.spacing.unit * 9 + 1,
    },
  },
  drawerPaper: {
    // top: 130,
    top: 0,
    position: 'absolute',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {},
  arrowButtonWrapper: {
    marginLeft: 12,
  },
  arrowButton: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  arrowButtonOpened: {
    transform: 'rotate(180deg)',
  },
});

class Sidebar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    url: PropTypes.string.isRequired,
    sidebarTree: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    drawerOpened: PropTypes.bool.isRequired,
    // toggleDrawer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    sidebarTree: {},
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.url !== prevState.url && nextProps.sidebarTree !== prevState.sidebarTree) {
      return {
        url: nextProps.url,
        sidebarTree: nextProps.sidebarTree,
      };
    }

    if (nextProps.url !== prevState.url) {
      return {
        url: nextProps.url,
      };
    }

    if (nextProps.sidebarTree !== prevState.sidebarTree) {
      return {
        sidebarTree: nextProps.sidebarTree,
      };
    }

    return null;
  }

  componentDidMount() {
    const { url, sidebarTree } = this.props;
    this.setState({ url, sidebarTree });
  }

  render() {
    const { classes, url, sidebarTree, drawerOpened /* , toggleDrawer */ } = this.props;

    return (
      <AuthConsumer>
        {({ user }) => (
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: drawerOpened,
              [classes.drawerClose]: !drawerOpened,
            })}
            classes={{
              paper: classNames(classes.drawerPaper, {
                [classes.drawerOpen]: drawerOpened,
                [classes.drawerClose]: !drawerOpened,
              }),
            }}
            open={drawerOpened}
          >
            {/* <div className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer} className={classNames(classes.arrowButtonWrapper)}>
                {
                  <ChevronRightIcon
                    className={classNames(classes.arrowButton, {
                      [classes.arrowButtonOpened]: drawerOpened,
                    })}
                  />
                }
              </IconButton>
            </div>
            <Divider /> */}
            {typeof sidebarTree === 'object' && (
              <List component="nav">
                {Object.keys(sidebarTree).map((item, index) => {
                  const currentItem = sidebarTree[item];

                  const SidebarItemElement = key => (
                    <SidebarItem
                      key={key}
                      drawerOpened={drawerOpened}
                      icon={currentItem.icon}
                      label={currentItem.label}
                      path={currentItem.path}
                      url={url}
                    />
                  );

                  if (typeof currentItem.module === 'undefined') {
                    return <SidebarItemElement key={index} />;
                  }

                  const module = {
                    module: currentItem.module,
                    level: user.modules_permissions[currentItem.module],
                    __type: 'Module',
                  };

                  return (
                    <Can I="visit" a={module} key={index}>
                      <SidebarItemElement />
                    </Can>
                  );
                })}
              </List>
            )}
            {typeof sidebarTree === 'string' && <span>club zone</span>}
          </Drawer>
        )}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Sidebar);
