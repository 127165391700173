import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { i18n } from 'App.js';
import EventTypeForm from './EventTypeForm';

class EventTypeDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    eventType: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dialogOpened: false,
    eventType: null,
  };

  render() {
    const { dialogOpened, onClose, eventType, handleSubmit } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>
          {eventType
            ? i18n._('msg.event_types.popup_title_update')
            : i18n._('msg.event_types.popup_title_add_new')}
        </CustomDialogTitle>
        <DialogContent>
          <EventTypeForm eventType={eventType} handleSubmit={handleSubmit} closeDialog={onClose} />
        </DialogContent>
      </Dialog>
    );
  }
}

export default EventTypeDialog;
