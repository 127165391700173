import 'utils/customYupValidators';
import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';
import EosCheckbox from 'components/Forms/EosCheckbox';
import { MemberRepresentative } from 'models/MemberRepresentative';

const MemberRepresentativeFormComponent = ({
  isSubmitting,
  errors,
  values,
  // setFieldValue,
  // setFieldTouched,
  closeDialog,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="last_name"
          label={i18n._('msg.members.representative.last_name_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="first_name"
          label={i18n._('msg.members.representative.first_name_label')}
          component={EosTextInput}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Field
          type="email"
          name="contact_email"
          label={i18n._('msg.members.representative.contact_email_label')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="phone_number"
          label={i18n._('msg.members.representative.phone_number_label')}
          component={EosTextInput}
        />
      </Grid>

      {values.new_representative && (
        <Grid item xs={12}>
          <Field
            type="checkbox"
            name="send_invitation"
            label={i18n._('msg.members.representative.send_invitation_label')}
            component={EosCheckbox}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Field
          type="text"
          name="job_title"
          label={i18n._('msg.members.representative.job_title_label')}
          component={EosTextInput}
          multiline
          rows={2}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
          {isSubmitting ? (
            <Trans id="msg.saving_changes" />
          ) : (
            <Trans id="msg.save_changes" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

MemberRepresentativeFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

MemberRepresentativeFormComponent.defaultProps = {
  isSubmitting: false,
};

class MemberRepresentativeForm extends Component {
  validationSchema = yup.object().shape({
    new_representative: yup.bool(),
    last_name: yup.string().required(i18n._('msg.form.required')),
    first_name: yup.string().required(i18n._('msg.form.required')),
    send_invitation: yup.bool(),
    contact_email: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .when('send_invitation', {
        is: true,
        then: yup.string().required(i18n._('msg.form.required')),
      }),
    phone_number: yup.string().phone(i18n._('msg.form.invalid_format')),
    // .required(i18n._('msg.form.required')),
    job_title: yup.string().nullable(),
  });

  initialValues = {
    new_representative: true,
    first_name: '',
    last_name: '',
    send_invitation: false,
    contact_email: '',
    phone_number: '',
    job_title: '',
  };

  render() {
    const { closeDialog, handleSubmit, representative } = this.props;
    const representativeInitialValues = representative
      ? { new_representative: false, ...MemberRepresentative.formatDataForEditForm(representative) }
      : null;

    return (
      <div>
        <Formik
          initialValues={representativeInitialValues || this.initialValues}
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <MemberRepresentativeFormComponent {...formikProps} closeDialog={closeDialog} />
          )}
        />
      </div>
    );
  }
}

MemberRepresentativeForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  representative: PropTypes.object,
};

MemberRepresentativeForm.defaultProps = {
  representative: null,
};

export default MemberRepresentativeForm;
