import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Avatar,
  ClickAwayListener,
  Icon,
  Grow,
  ListItemText,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExitIcon from '@material-ui/icons/ExitToApp';

import { AuthConsumer } from 'AuthContext.js';
import HeaderButton from 'components/Header/HeaderButton';
import ImageDisplayStandalone from 'components/Base/ImageDisplayStandalone';

const UserShortcutComponent = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 12px;
  }
`;

const UserShortcutContent = ({ user }) => {
  if (!user || Object.keys(user).length === 0) {
    return null;
  }

  const hasProfileImage = !!user.active_member.image_id;
  const representativeLoggedIn = !!user.representative && Object.keys(user.representative).length;
  const avatarAltProp = representativeLoggedIn
    ? `${user.representative.first_name} ${user.representative.last_name}`
    : `${user.active_member.first_name} ${user.active_member.last_name}`;
  const avatarPlaceholderText = avatarAltProp
    .split(' ')
    .map(part => part.substr(0, 1))
    .join('');
  // !!user.active_member && user.user && user.user.id !== user.active_member.id;
  const activeMemberName = `${user.active_member.first_name} ${user.active_member.last_name}`;

  return (
    <React.Fragment>
      {hasProfileImage ? (
        <Avatar alt={avatarAltProp}>
          <ImageDisplayStandalone imageId={user.active_member.image_id} />
        </Avatar>
      ) : (
        <Avatar alt={avatarAltProp}>{avatarPlaceholderText}</Avatar>
      )}
      <UserName>
        {avatarAltProp}
        {representativeLoggedIn && <p>active member: {activeMemberName}</p>}
      </UserName>
    </React.Fragment>
  );
};

UserShortcutContent.propTypes = {
  user: PropTypes.object,
};

UserShortcutContent.defaultProps = {
  user: {},
};

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: { marginRight: 0 },
});

const UserName = styled.div`
  text-align: left;
  p {
    font-size: 10px;
    margin-top: 0.25em;
    margin-bottom: 0;
  }
`;

class UserShortcut extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    open: false,
  };

  handleToggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;
    return (
      <AuthConsumer>
        {({ user, toggleMemberPicker }) => (
          <HeaderButton onClick={this.handleToggle}>
            <UserShortcutComponent>
              <UserShortcutContent user={user} />
            </UserShortcutComponent>
            <Popper
              open={open}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              style={{ position: 'absolute', right: 0, top: '100%' }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <MenuItem onClick={this.handleClose}>
                          <ListItemIcon className={classes.icon}>
                            <Icon>account_circle</Icon>
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.primary }}
                            inset
                            primary="Profile"
                          />
                        </MenuItem>
                        <MenuItem onClick={this.handleClose}>
                          <ListItemIcon className={classes.icon}>
                            <Icon>settings</Icon>
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.primary }}
                            inset
                            primary="Account"
                          />
                        </MenuItem>
                        {user.members.length > 1 ? (
                          <MenuItem
                            onClick={() => {
                              toggleMemberPicker();
                              this.handleClose();
                            }}
                          >
                            <ListItemIcon className={classes.icon}>
                              <Icon>supervisor_account</Icon>
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.primary }}
                              inset
                              primary="Toggle user"
                            />
                          </MenuItem>
                        ) : null}
                        <MenuItem onClick={this.handleClose}>
                          <ListItemIcon className={classes.icon}>
                            <ExitIcon />
                          </ListItemIcon>
                          <AuthConsumer>
                            {({ logout }) => (
                              <ListItemText
                                classes={{ primary: classes.primary }}
                                inset
                                primary="Log out"
                                onClick={logout}
                              />
                            )}
                          </AuthConsumer>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </HeaderButton>
        )}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles)(UserShortcut);
