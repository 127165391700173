// more info at: https://github.com/tricoder42/named-urls#quickstart

import { include } from 'named-urls';

const routes = {
  home: '/',
  // login
  login: '/login/',
  // invitation
  invitation: '/invitation/:token/',
  // password reset
  resetPassword: '/reset-password',
  // registration
  registration: '/registration',
  // dashboard
  dashboard: '/dashboard/',
  // management
  management: include('/management', {
    dashboard: 'dashboard/',
    documents: 'documents/',
    announcements: 'announcements/',
    members: include('members/', {
      all: '',
      create: 'create/',
      single: include(':memberId/', {
        detail: 'detail/',
        update: 'update/',
      }),
    }),
    settings: include('settings/', {
      all: '',
      seasons: 'seasons/',
      positions: 'positions/',
      memberTypes: 'member-types/',
      eventTypes: 'event-types/',
      teamGroups: 'team-groups/',
    }),
    teams: include('teams/', {
      all: '',
      single: include(':teamId/', {
        update: 'update/',
      }),
      season: include(':seasonId/', {
        list: 'list/',
      }),
    }),
    rosters: include('rosters/', {
      all: '',
      single: include(':teamId/', {
        update: 'update/',
      }),
    }),
    halls: include('halls/', {
      all: '',
      create: 'create/',
      single: include(':hallId/', {
        update: 'update/',
      }),
    }),
    roles: include('roles/', {
      all: '',
      single: include(':roleId/', {
        update: 'update/',
      }),
    }),
  }),
  // admin
  admin: include('/admin', {
    dashboard: '',
  }),
  // 404
  // err404: '/404'
  // playground
  playground: '/playground/',
  playground2: '/playground2/',
};

window.rts = routes;

export default routes;
