import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon } from '@material-ui/core';
import { hex } from 'wcag-contrast';
import styled from 'styled-components';
import { computeEventTypeIconContrast } from 'utils/contrast';

const IconAvatar = styled(Avatar)`
  && {
    background: ${props => props.background};
    width: ${props => `${props.size || 60}px`};
    height: ${props => `${props.size || 60}px`};
    font-size: ${props => `${Math.round((props.size || 60) / 2)}px`};
    ${props =>
      props.variant === 'withShadow'
        ? {
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, .5)',
          }
        : null}
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: inherit;
  }
`;

class EventTypeIcon extends Component {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    icon: PropTypes.string,
    variant: PropTypes.oneOf(['withShadow', 'withoutShadow']),
  };

  static defaultProps = {
    size: 60,
    color: '#ffffff',
    icon: 'star',
    variant: 'withoutShadow',
  };

  componentDidMount() {
    window.hex = hex;
  }

  render() {
    const { size, color, icon, variant } = this.props;

    const symbolColor = computeEventTypeIconContrast(color);

    return (
      <IconAvatar size={size} background={color} variant={variant}>
        <IconWrapper color={symbolColor}>
          <Icon color="inherit">{icon}</Icon>
        </IconWrapper>
      </IconAvatar>
    );
  }
}

export default EventTypeIcon;
