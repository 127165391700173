import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Typography, Grid } from '@material-ui/core';
import * as moment from 'moment';
import { Trans } from '@lingui/macro';

class WhispererItem extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    rosterType: PropTypes.string.isRequired,
    addMembersToRoster: PropTypes.func.isRequired,
  };

  render() {
    const { member, addMembersToRoster, rosterType } = this.props;

    const isPlayer = rosterType === 'player';
    const isOrganizer = rosterType === 'organizer';

    return (
      <div>
        <Grid container alignItems="center">
          <Grid item>
            <Icon>person</Icon>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <span>
                {member.last_name} {member.first_name}
              </span>
              {!!member.user && (
                <span style={{ marginLeft: '16px' }}>{member.user.email || ' - '}</span>
              )}
              {member.birthdate && (
                <span style={{ marginLeft: '16px' }}>
                  {moment(member.birthdate).format('D. M. YYYY')}
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => addMembersToRoster(member.id, isPlayer, isOrganizer)}
            >
              <Trans id="msg.add" />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default WhispererItem;
