import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { StyledTable } from 'components/Table/StyledTable';
import { i18n } from 'App.js';
import { reverse } from 'named-urls';
import routes from 'Routes.js';
import ContextMenu from 'components/Base/ContextMenu';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { Trans } from '@lingui/macro';
import CustomizedLink from 'components/Base/Link';

class RoleMembersTable extends Component {
  static propTypes = {
    assignedMembers: PropTypes.array,
    loadingMembers: PropTypes.bool,
    resignMemberRole: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    assignedMembers: [],
    loadingMembers: false,
  };

  render() {
    const { assignedMembers, loadingMembers, resignMemberRole, managePermissions } = this.props;

    if (!loadingMembers && assignedMembers.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.roles.members.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <Card>
        <StyledTable padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>member ID</TableCell>
              <TableCell>
                <Trans id="msg.name" />
              </TableCell>
              <TableCell>
                <Trans id="msg.members.date_of_birth" />
              </TableCell>
              <TableCell>
                <Trans id="msg.email_address" />
              </TableCell>
              {managePermissions && (
                <TableCell align="center">
                  <Trans id="msg.actions" />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingMembers && <TableLoadingContent colSpan={managePermissions ? 5 : 4} />}
            {!loadingMembers &&
              assignedMembers.map(member => (
                <TableRow key={member.id}>
                  <TableCell>{member.id}</TableCell>
                  <TableCell>
                    <CustomizedLink
                      to={reverse(routes.management.members.single.detail, { memberId: member.id })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {member.last_name} {member.first_name}
                    </CustomizedLink>
                  </TableCell>
                  <TableCell>{member.birthdate.format('D. M. YYYY')}</TableCell>
                  <TableCell>{member.contact_email}</TableCell>
                  {managePermissions && (
                    <TableCell align="center">
                      <ContextMenu
                        variant="icon"
                        tooltip={i18n._('msg.actions')}
                        color="inherit"
                        icon="more_vert"
                        // disabled={!role.editable}
                        menuItems={[
                          {
                            text: i18n._('msg.record_delete'),
                            action: () => resignMemberRole(member.id),
                            variant: 'delete',
                          },
                        ]}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </Card>
    );
  }
}

export default RoleMembersTable;
