import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import TableWrapper from 'components/Table/TableWrapper';
import { Trans } from '@lingui/macro';
import TableEmptyContent from 'components/Table/TableEmptyContent';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import RosterTableItem from './RosterTableItem';

// // TODO: refactor
// const EmptyContent = ({ rosterType }) => (
//   <React.Fragment>
//     <TableRow>
//       <TableCell align="center" colSpan={rosterType === 'player' ? 8 : 7} />
//     </TableRow>
//     <TableRow>
//       <TableCell align="center" colSpan={rosterType === 'player' ? 8 : 7}>
//         No record here
//       </TableCell>
//     </TableRow>
//     <TableRow>
//       <TableCell align="center" colSpan={rosterType === 'player' ? 8 : 7} />
//     </TableRow>
//   </React.Fragment>
// );
// EmptyContent.propTypes = {
//   rosterType: PropTypes.string.isRequired,
// };

// // TODO: refactor
// const LoadingContent = ({ rosterType }) => (
//   <TableRow>
//     <TableCell
//       style={{ padding: '24px 0' }}
//       align="center"
//       colSpan={rosterType === 'player' ? 8 : 7}
//     >
//       <CircularProgress color="secondary" />
//       <div style={{ marginTop: '24px' }}>
//         <Typography>Loading content</Typography>
//       </div>
//     </TableCell>
//   </TableRow>
// );
// LoadingContent.propTypes = {
//   rosterType: PropTypes.string.isRequired,
// };

class RosterTable extends Component {
  static propTypes = {
    members: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    toggleMemberVisibility: PropTypes.func.isRequired,
    removeMemberFromRoster: PropTypes.func.isRequired,
    manageMemberVisibility: PropTypes.bool,
    setMemberToUpdate: PropTypes.func.isRequired,
    teamRestrictions: PropTypes.object,
    rosterType: PropTypes.string,
    trainerRoles: PropTypes.array,
    loadingContent: PropTypes.bool,
  };

  static defaultProps = {
    manageMemberVisibility: false,
    teamRestrictions: {},
    rosterType: 'player',
    trainerRoles: [],
    loadingContent: false,
  };

  render() {
    const {
      members,
      title,
      toggleMemberVisibility,
      manageMemberVisibility,
      removeMemberFromRoster,
      setMemberToUpdate,
      teamRestrictions,
      rosterType,
      trainerRoles,
      loadingContent,
    } = this.props;

    return (
      <Card>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {rosterType === 'player' &&
            (teamRestrictions.from || teamRestrictions.to || teamRestrictions.gender) && (
              <React.Fragment>
                <Typography>
                  <Trans id="msg.restrictions" />:
                </Typography>
                {teamRestrictions.from && (
                  <Typography>
                    <Trans id="msg.restrictions.birth_year_from" />: {teamRestrictions.from}
                  </Typography>
                )}
                {teamRestrictions.to && (
                  <Typography>
                    <Trans id="msg.restrictions.birth_year_to" />: {teamRestrictions.from}
                  </Typography>
                )}
                {teamRestrictions.gender && (
                  <Typography>
                    <Trans id="msg.restrictions.gender" />: {teamRestrictions.gender}
                  </Typography>
                )}
              </React.Fragment>
            )}
        </CardContent>
        <TableWrapper loading={loadingContent}>
          <Table padding="dense" style={{ minWidth: '750px' }}>
            <TableHead>
              <TableRow>
                <TableCell>record ID</TableCell>
                <TableCell>member ID</TableCell>
                {rosterType === 'player' && (
                  <TableCell align="center">
                    <Trans id="msg.rosters.dress_number" />
                  </TableCell>
                )}
                {rosterType === 'player' && (
                  <TableCell>
                    <Trans id="msg.rosters.position" />
                  </TableCell>
                )}
                {rosterType === 'organizer' && (
                  <TableCell>
                    <Trans id="msg.rosters.role" />
                  </TableCell>
                )}
                <TableCell>
                  <Trans id="msg.members.name" />
                </TableCell>
                <TableCell>
                  <Trans id="msg.members.date_of_birth" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.rosters.show_on_web" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.actions" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.length > 0 &&
                !loadingContent &&
                members.map(member => (
                  <RosterTableItem
                    key={member.id}
                    member={member}
                    rosterType={rosterType}
                    toggleMemberVisibility={toggleMemberVisibility}
                    manageMemberVisibility={manageMemberVisibility}
                    removeMemberFromRoster={removeMemberFromRoster}
                    setMemberToUpdate={setMemberToUpdate}
                    trainerRoles={trainerRoles}
                  />
                ))}

              {members.length === 0 && !loadingContent && (
                <TableEmptyContent colSpan={rosterType === 'player' ? 8 : 7} />
              )}

              {loadingContent && <TableLoadingContent colSpan={rosterType === 'player' ? 8 : 7} />}
            </TableBody>
          </Table>
        </TableWrapper>
      </Card>
    );
  }
}

export default RosterTable;
