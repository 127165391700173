const baseTheme = {
  colors: {
    danger: '#CC4236',
    warning: '#fc0',
    border: '#999',
  },
  radius: {
    default: '4px',
  },
  spacing: {
    vertical: {
      small: '8px',
      default: '15px',
    },
    horizontal: {
      small: '15px',
      default: '30px',
    },
  },
};

export default baseTheme;
