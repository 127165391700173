import * as moment from 'moment';

/**
 * @property {moment.Moment} from
 * @property {moment.Moment} to
 */
export class DateRange {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.from = moment(data.from);
    this.to = moment(data.to);
  }

  static formatFormData(data) {
    return {
      from: data.from.format('YYYY-MM-DD'),
      to: data.to.format('YYYY-MM-DD'),
    };
  }
}

export class Date {
  /**
   * @param {object} date
   */
  constructor(date) {
    this.date = moment(date);
  }
}
