/**
 * @property {int} id
 * @property {string} name
 * @property {boolean} visible_on_web
 * @property {boolean} attendance
 * @property {int} order
 */
export class TrainerRole {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.visible_on_web = data.visible_on_web;
    this.attendance = data.attendance;
    this.order = data.order;
  }
}
