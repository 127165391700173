import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageService } from 'api/image.service';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  //max-width: 100%;
  //max-height: 100%;
`;

class ImageDisplayStandalone extends Component {
  static propTypes = {
    imageId: PropTypes.number.isRequired,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    alt: '',
    width: null,
    height: null,
  };

  state = {
    loading: true,
    image: null,
  };

  componentDidMount() {
    this.fetchImage();
  }

  fetchImage = () => {
    const { imageId } = this.props;
    ImageService.get(imageId)
      .then(response => {
        this.setState({
          loading: false,
          image: response,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          image: null,
        });
      });
  };

  render() {
    const { loading, image } = this.state;
    const { alt, width, height } = this.props;
    return (
      <ImageContainer width={width} height={height}>
        {loading && <CircularProgress color="secondary" />}
        {!loading && image && (
          <Image src={`data:${image.mime_type};base64,${image.content}`} alt={alt} />
        )}
      </ImageContainer>
    );
  }
}

export default ImageDisplayStandalone;
