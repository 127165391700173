import moment from 'moment';
import { Address } from '../shared/address.model';
import { ReducedUser } from './User';
// import { Image } from '../shared/image.model';

/**
 * @property {int} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string|null} job_title
 * @property {string|null} school
 * @property {string|null} phone_number
 */
export class ReducedMember {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.job_title = data.job_title || '';
    this.school = data.school || '';
    this.phone_number = data.phone_number || '';
  }
}

/**
 * @property {ReducedUser|null} user
 * @property {int|null} user_id
 * @property {moment.Moment} birthdate
 * @property {string} insurance_company
 * @property {string|null} medical_records
 * @property {Address} address
 * @property {Address} contact_address
 * @property {string} contact_email
 * @property {int} role_id
 * @property {int} member_type_id
 * @property {string|null} gender
 * @property {int|null} dress_number
 * @property {int|null} position_id
 * @property {int|null} image_id
 * @property {bool|null} is_representable
 */
export class FullMember extends ReducedMember {
  /**
   * @param {object} data
   */
  constructor(data) {
    super(data);
    this.user = data.user ? new ReducedUser(data.user) : null;
    this.user_id = data.user_id;
    this.birthdate = data.birthdate ? moment(data.birthdate) : null;
    this.insurance_company = data.insurance_company || '';
    this.medical_records = data.medical_records || '';
    this.address = new Address(data.address || {});
    this.contact_address = new Address(data.contact_address || {});
    this.contact_email = data.contact_email || '';
    this.role_id = data.role_id;
    this.member_type_id = data.member_type_id;
    this.gender = data.gender || 0;
    this.birthnumber = data.birthnumber || '';
    this.phone_number = data.phone_number || '';
    this.dress_number = data.dress_number || '';
    this.position_id = data.position_id || '';
    this.image_id = data.image_id || null;
    this.is_representable =
      typeof data.is_representable !== 'undefined' ? data.is_representable : null;
  }

  static formatCreateFormData(data) {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      contact_email: data.contact_email,
      birthdate: data.birthdate.format('YYYY-MM-DD'),
      birthnumber: null,
      gender: null,
      insurance_company: null,
      phone_number: null,
      school: null,
      job_title: null,
      dress_number: null,
      medical_records: null,
      position_id: null,
      address: new Address(data.address || {}),
      contact_address: new Address(data.contact_address || {}),
      role_id: data.role_id || null,
      member_type_id: null,
      image_id: null,
    };
  }

  static formatFormData(data) {
    const formattedData = {};

    // base info start
    if (typeof data.first_name !== 'undefined') {
      formattedData.first_name = data.first_name;
    }
    if (typeof data.last_name !== 'undefined') {
      formattedData.last_name = data.last_name;
    }
    if (typeof data.birthdate !== 'undefined') {
      formattedData.birthdate = data.birthdate.format('YYYY-MM-DD');
    }
    if (typeof data.birthnumber !== 'undefined') {
      formattedData.birthnumber = data.birthnumber;
    }
    if (typeof data.gender !== 'undefined') {
      if (data.gender === 0 || data.gender === '0') {
        formattedData.gender = null;
      } else {
        formattedData.gender = data.gender;
      }
    }
    if (typeof data.insurance_company !== 'undefined') {
      formattedData.insurance_company = data.insurance_company;
    }
    // base info end

    // contact info start
    if (typeof data.phone_number !== 'undefined') {
      formattedData.phone_number = data.phone_number;
    }
    if (typeof data.address !== 'undefined') {
      formattedData.address = new Address(data.address);
    }
    if (typeof data.contact_address !== 'undefined') {
      formattedData.contact_address = new Address(data.contact_address);
    }
    // contact info end

    // internal info start
    if (typeof data.medical_records !== 'undefined') {
      formattedData.medical_records = data.medical_records;
    }
    // internal info end

    // rest info start
    if (typeof data.role_id !== 'undefined') {
      formattedData.role_id = data.role_id;
    }
    if (typeof data.member_type_id !== 'undefined') {
      formattedData.member_type_id = data.member_type_id;
    }
    if (typeof data.image_id !== 'undefined') {
      formattedData.image_id = data.image_id || null;
    }
    console.log({ formattedData });
    // rest info end
    return formattedData;
  }
}

// /**
//  * @property {int} id
//  * @property {moment.Moment} created_at
//  * @property {moment.Moment|null} approved_at
//  * @property {moment.Moment|null} rejected_at
//  * @property {FullMember} member
//  * @property {string|null} note
//  */
// export class MemberUpdate {
//   /**
//    * @param {object} data
//    */
//   constructor(data) {
//     console.log({ data });
//     this.id = data.id;
//     this.created_at = moment(data.created_at);
//     this.approved_at = this.approved_at ? moment(data.created_at) : null;
//     this.rejected_at = this.rejected_at ? moment(data.rejected_at) : null;
//     this.member = new FullMember(data.data);
//     this.note = data.note;
//   }
// }
