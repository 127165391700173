import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableLoadingContent from 'components/Table/TableLoadingContent';
import styled from 'styled-components';
import green from '@material-ui/core/colors/green';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import { StyledTable } from 'components/Table/StyledTable';
import CustomLink from 'components/Base/Link';
import EmptyContentPlaceholder from 'components/Base/EmptyContentPlaceholder';
import { i18n } from 'App.js';
import ContextMenu from 'components/Base/ContextMenu';

// eslint-disable-next-line
const StyledAvatar = styled(({ active, ...rest }) => <Avatar {...rest} />)`
  && {
    background-color: ${props => (props.active ? green[500] : props.theme.palette.grey[400])};
  }
`;

class SeasonsTable extends Component {
  static propTypes = {
    seasons: PropTypes.array,
    loading: PropTypes.bool,
    editSeason: PropTypes.func.isRequired,
    deleteSeason: PropTypes.func.isRequired,
    makeSeasonActive: PropTypes.func.isRequired,
    managePermissions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    seasons: [],
    loading: false,
  };

  render() {
    const {
      seasons,
      loading,
      editSeason,
      deleteSeason,
      makeSeasonActive,
      managePermissions,
    } = this.props;

    if (!loading && seasons.length === 0) {
      return (
        <EmptyContentPlaceholder variant="large">
          <Trans id="msg.seasons.empty" />
        </EmptyContentPlaceholder>
      );
    }

    return (
      <div>
        <Card>
          <StyledTable padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans id="msg.seasons.number" />
                </TableCell>
                <TableCell padding="none" style={{ width: '100%' }}>
                  <Trans id="msg.name" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.seasons.season_start" />
                </TableCell>
                <TableCell align="center">
                  <Trans id="msg.seasons.season_end" />
                </TableCell>
                {managePermissions && (
                  <TableCell style={{ textAlign: 'center' }}>
                    <Trans id="msg.actions" />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoadingContent colSpan={managePermissions ? 5 : 4} />}
              {!loading &&
                seasons.map((s, index) => (
                  <TableRow key={s.id}>
                    <TableCell>
                      <StyledAvatar active={s.is_active}>{seasons.length - index}</StyledAvatar>
                    </TableCell>
                    <TableCell padding="none">
                      {managePermissions ? (
                        <CustomLink
                          to="#"
                          onClick={evt => {
                            evt.preventDefault();
                            editSeason(s);
                          }}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {s.name}
                        </CustomLink>
                      ) : (
                        s.name
                      )}
                      {s.is_active && (
                        <React.Fragment>
                          <br />
                          <Trans id="msg.seasons.active_season_desc" />
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {moment(s.range.from).isValid() ? s.range.from.format('D. M. YYYY') : '-'}
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {moment(s.range.to).isValid() ? s.range.to.format('D. M. YYYY') : '-'}
                    </TableCell>
                    {managePermissions && (
                      <TableCell style={{ textAlign: 'center' }}>
                        <ContextMenu
                          variant="icon"
                          tooltip={i18n._('msg.actions')}
                          color="inherit"
                          icon="more_vert"
                          menuItems={[
                            !s.is_active && {
                              text: i18n._('msg.record_make_active'),
                              action: () => makeSeasonActive(s),
                            },
                            {
                              text: i18n._('msg.record_update'),
                              action: () => editSeason(s),
                            },
                            !s.is_constrained &&
                              !s.is_active && {
                                text: i18n._('msg.record_delete'),
                                action: () => deleteSeason(s.id),
                                variant: 'delete',
                              },
                          ]}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>
        </Card>
      </div>
    );
  }
}

export default SeasonsTable;
