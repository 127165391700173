import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls/src';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Card, CardContent, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { MemberManagementService } from 'api/member.service';
import routes from 'Routes';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import LoadingMessage from 'components/Base/LoadingMessage';
import { withSnackbar } from 'notistack';
import styled from 'styled-components';
import MemberRepresentativesTable from 'components/Management/Members/MemberRepresentativesTable';
import MemberUsersTable from 'components/Management/Members/MemberUsersTable';
import MemberInvitationDialog from 'components/Management/Members/MemberInvitationDialog';
import MemberRepresentativeDialog from 'components/Management/Members/MemberRepresentativeDialog';
import { MemberRepresentative } from 'models/MemberRepresentative';
import PageHeader from 'components/PageHeader';
import FabButton from 'components/PageHeader/FabButton';
import ability from 'ability.js';

const InfoContainer = styled.dl`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

const InfoHeader = styled.dt`
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding: 12px 16px 8px;
  display: flex;
  align-items: center;

  @media (min-width: 1280px) {
    float: left;
    width: 260px;
    clear: both;
    padding: 8px 16px;
    border-bottom: 1px solid #ccc;

    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

const InfoContent = styled.dd`
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #ccc;
  padding: 8px 16px 12px;
  display: flex;
  align-items: center;

  @media (min-width: 1280px) {
    float: left;
    width: calc(100% - 260px);
    padding: 8px 16px;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

class Detail extends Component {
  state = {
    value: 0,
    representatives: null,
    invitations: null,
    users: null,
    dialogOpened: false,
    representativeDialogOpened: false,
    currentlyEditedRepresentative: null,
    currentlyEditedInvitation: null,
  };

  static propTypes = {
    member: PropTypes.object,
    match: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  static defaultProps = {
    member: {},
  };

  componentDidMount = () => {
    const {
      match: {
        params: { memberId },
      },
      member,
    } = this.props;

    if (Object.keys(member).length === 0) {
      this.fetchMember(memberId);
    } else {
      // this branch of condition is not working at the moment
      this.setState({ member });
    }
  };

  fetchMember = memberId => {
    const { enqueueSnackbar } = this.props;
    MemberManagementService.get(memberId)
      .then(response => {
        // this.setState({ currentMember: member });
        this.setState({ member: response });
        this.fetchRepresentatives();
        // this.fetchInvitations();
      })
      .catch(error => {
        this.setState({
          member: null,
        });
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  fetchRepresentatives = () => {
    const { member } = this.state;
    const { enqueueSnackbar } = this.props;

    MemberManagementService.getAllRepresentatives(member.id)
      .then(response => {
        this.setState({ representatives: response }, this.fetchInvitations);
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  fetchInvitations = () => {
    const { member } = this.state;
    const { enqueueSnackbar } = this.props;

    MemberManagementService.getAllInvitations(member.id)
      .then(response => {
        this.setState({ invitations: response }, () => {
          enqueueSnackbar(i18n._('msg.members.detail.loading_success'), { variant: 'success' });
          this.mergeRepresentativesAndInvitations();
        });
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleCreateInvitationSubmit = async (values, actions) => {
    await this.createInvitation(values);

    actions.setSubmitting(false);
    this.closeDialog();
  };

  openCreateInvitation = () => {
    this.setState({
      dialogOpened: true,
    });
  };

  handleRepresentativeSubmit = async (values, actions) => {
    const { currentlyEditedRepresentative } = this.state;

    const promise = currentlyEditedRepresentative
      ? this.updateRepresentative(currentlyEditedRepresentative.id, values)
      : this.createRepresentative(values);

    this.operationCallback(promise, actions);
  };

  operationCallback = (promise, actions) =>
    promise
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm();
        this.closeDialog();
      })
      .catch(error => {
        const errors = {};
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          // eslint-disable-next-line
          Object.keys(error.response.data.errors).map(e => {
            errors[e] = error.response.data.errors[e]
              .map(errorRule => errorRule.message)
              .join(', ');
          });
        }

        if (error.response.data.message) {
          errors.status = error.response.data.message;
        }

        actions.setErrors({ ...errors });
        actions.setSubmitting(false);
      });

  openRepresentativeDialog = () => {
    this.setState({
      representativeDialogOpened: true,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogOpened: false,
      representativeDialogOpened: false,
      currentlyEditedRepresentative: null,
      currentlyEditedInvitation: null,
    });
  };

  mergeRepresentativesAndInvitations = () => {
    const { member, invitations, representatives } = this.state;
    let mergedRepresentativesAndInvitations = [];

    // as first will be displayed member himself
    mergedRepresentativesAndInvitations.push({
      id: member.id,
      first_name: member.first_name,
      last_name: member.last_name,
      contact_email: member.contact_email || '',
      login_email: member.user ? member.user.email : null,
      user_id: member.user ? member.user.id : null,
      main_user: true,
      invitation: invitations.find(i => i.type === 'user') || null,
    });

    mergedRepresentativesAndInvitations = [
      // member himself
      ...mergedRepresentativesAndInvitations,
      // representatives with user_id
      ...representatives.filter(r => r.user_id),
      // invitations
      ...invitations
        .filter(i => i.type !== 'user')
        .map(i => ({
          contact_email: i.email,
          invitation: i,
        })),
    ];

    this.setState({ users: mergedRepresentativesAndInvitations });
  };

  editInvitation = invitation => {
    this.setState({
      dialogOpened: true,
      currentlyEditedInvitation: invitation,
    });
  };

  deleteInvitation = async (invitationId, doNotNotifyAboutSuccess = false) => {
    const {
      member: { id },
      invitations,
    } = this.state;
    const { enqueueSnackbar } = this.props;

    MemberManagementService.deleteInvitation(id, invitationId)
      .then(() => {
        // remove invitation from current invitations for member:
        const deletedInvitationIndexInInvitations = invitations.findIndex(
          i => i.id === invitationId
        );
        invitations.splice(deletedInvitationIndexInInvitations, 1);
        this.setState({ invitations }, () => {
          if (!doNotNotifyAboutSuccess) {
            enqueueSnackbar(i18n._('msg.members.invitation.delete_success'), { variant: 'info' });
          }
          this.mergeRepresentativesAndInvitations();
        });
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  createInvitation = async data => {
    const {
      member: { id },
      invitations,
      currentlyEditedInvitation,
    } = this.state;
    const { enqueueSnackbar } = this.props;

    // check if currently clicked invitation has an ID
    // if so, it means, user want to resend invitation
    // and we have to wait for delete previous one
    if (currentlyEditedInvitation && currentlyEditedInvitation.id) {
      await this.deleteInvitation(currentlyEditedInvitation.id, true);
    }

    // create new invitation
    return MemberManagementService.createInvitation(id, data)
      .then(response => {
        this.setState(
          {
            invitations: [...invitations, response],
          },
          () => {
            enqueueSnackbar(i18n._('msg.members.invitation.create_success'), {
              variant: 'success',
            });
            this.mergeRepresentativesAndInvitations();
          }
        );
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  editRepresentative = representative => {
    this.setState({
      representativeDialogOpened: true,
      currentlyEditedRepresentative: representative,
    });
  };

  createRepresentative = async values => {
    const { enqueueSnackbar } = this.props;
    const { representatives, member } = this.state;
    const formattedData = MemberRepresentative.formatFormData(values);
    let newRepresentativeId = null;

    await MemberManagementService.createRepresentative(member.id, formattedData).then(response => {
      newRepresentativeId = response.id;
      this.setState(
        {
          representatives: [...representatives, response],
        },
        () => {
          enqueueSnackbar(i18n._('msg.members.representative.create_success'), {
            variant: 'success',
          });
        }
      );
    });

    if (values.send_invitation && newRepresentativeId) {
      await this.createInvitation({
        email: values.contact_email,
        type: 'representative',
        member_representative_id: newRepresentativeId,
      }); // TODO create and send invitation
    }
  };

  updateRepresentative = async (representativeId, values) => {
    const { enqueueSnackbar } = this.props;
    const { representatives } = this.state;
    const formattedData = MemberRepresentative.formatFormData(values);
    // const { user_id, ...formattedDataWithoutUserId } = formattedData;
    await MemberManagementService.updateRepresentative(representativeId, formattedData).then(
      response => {
        const updatedRepresentativeIndexInRepresentatives = representatives.findIndex(
          r => r.id === representativeId
        );

        representatives[updatedRepresentativeIndexInRepresentatives] = response;
        this.setState(
          {
            representatives,
          },
          enqueueSnackbar(i18n._('msg.members.representative.update_success'), {
            variant: 'success',
          })
        );
      }
    );
  };

  removeRepresentative = async representativeId => {
    const {
      member: { id },
      representatives,
      invitations,
    } = this.state;

    MemberManagementService.deleteRepresentative(id, representativeId).then(() => {
      // find index of removed representative and remove it from state variable
      const deletedRepresentativeIndexInRepresentatives = representatives.findIndex(
        u => u.id === representativeId
      );
      representatives.splice(deletedRepresentativeIndexInRepresentatives, 1);

      // find optional invitation connected to representative and try to delete it
      const invitationConnectedToDeletedRepresentative = invitations.find(
        i => i.member_representative_id === representativeId
      );
      if (invitationConnectedToDeletedRepresentative) {
        this.deleteInvitation(invitationConnectedToDeletedRepresentative.id);
      }

      // update state of representatives and invitations and after that,
      // merge them for member users table component
      this.setState({ representatives }, this.mergeRepresentativesAndInvitations);
    });
  };

  revokeUser = async userId => {
    const { member, users } = this.state;
    const { enqueueSnackbar } = this.props;

    await MemberManagementService.revoke(member.id, userId)
      .then(() => {
        // remove user from users state variable
        const deletedUserIndexInUsers = users.findIndex(u => u.user_id === userId);
        users.splice(deletedUserIndexInUsers, 1);

        // check if deleted user was member himself
        const deletedUserWasMemberHimself = users[deletedUserIndexInUsers].main_user;

        // if we deleted member's user, set null to current member's user
        // it refresh member's state in users table
        if (deletedUserWasMemberHimself) {
          member.user = null;
        }

        this.setState({ users, member }, () => {
          enqueueSnackbar(i18n._('msg.members.user.revoke_success'), { variant: 'success' });
          this.mergeRepresentativesAndInvitations();
        });
      })
      .catch(error => enqueueSnackbar(error.response.data.message, { variant: 'error' }));
  };

  render() {
    const {
      member,
      value,
      representatives,
      users,
      dialogOpened,
      representativeDialogOpened,
      currentlyEditedRepresentative,
      currentlyEditedInvitation,
      invitations,
    } = this.state;
    const { accessLevel } = this.props;

    const invitationsIds = invitations
      ? invitations.filter(i => i.type !== 'user').map(i => i.member_representative_id)
      : [];

    return member ? (
      <div style={{ flex: 1 }}>
        <PageHeader
          breadcrumbsItems={[
            {
              url: routes.management.members.all,
              title: 'some title',
              text: i18n._('msg.members.page_title'),
            },
            { url: null, text: `${member.first_name} ${member.last_name}` },
          ]}
          title={`${member.first_name} ${member.last_name}`}
          customControlsPrimary={[
            ability.can('manage', {
              __type: 'Members',
              level: accessLevel,
            }) && (
              <FabButton
                component={Link}
                to={reverse(routes.management.members.single.update, {
                  memberId: member.id,
                })}
                icon="edit"
              >
                <Trans id="msg.members.edit_member" />
              </FabButton>
            ),
          ]}
          profilePhotoId={member.image_id || 'default'}
        />

        <Paper>
          <AppBar position="static" color="default" elevation={0}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              // fullWidth
              // centered
            >
              <Tab label={i18n._('msg.members.detail.tabs.member_info')} />
              <Tab label={i18n._('msg.members.detail.tabs.profile_info')} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={this.handleChangeIndex}
            style={{ flex: 1 }}
          >
            <div>
              <Card elevation={0} square>
                <CardContent>
                  <Typography variant="h5">
                    <Trans id="msg.members.detail.base_info" />
                  </Typography>
                  {/* <Banner variant="error" message="error message if exists" opened noClose/> */}
                  <InfoContainer>
                    <InfoHeader component="th" width={250}>
                      <Trans id="msg.members.name" />
                    </InfoHeader>
                    <InfoContent>
                      {member.first_name} {member.last_name}
                    </InfoContent>
                    <InfoHeader component="th">
                      <Trans id="msg.members.date_of_birth" />
                    </InfoHeader>
                    <InfoContent>{member.birthdate.format('D. M. YYYY')}</InfoContent>
                  </InfoContainer>
                </CardContent>
                <CardContent>
                  <Typography variant="h5">
                    <Trans id="msg.members.detail.contact_info" />
                  </Typography>
                  {/* <Banner variant="warning" message="error message if exists" opened noClose/> */}
                  <InfoContainer>
                    <InfoHeader component="th" width={250}>
                      <Trans id="msg.email_address" />
                    </InfoHeader>
                    <InfoContent>
                      {member.contact_email ? (
                        <a href={`mailto:${member.contact_email}`}>{member.contact_email}</a>
                      ) : (
                        '-'
                      )}
                    </InfoContent>
                    <InfoHeader component="th">
                      <Trans id="msg.phone_number" />
                    </InfoHeader>
                    <InfoContent>
                      {member.phone_number ? (
                        <a href={`tel:${member.phone_number}`}>{member.phone_number}</a>
                      ) : (
                        '-'
                      )}
                    </InfoContent>
                    <InfoHeader component="th">
                      <Trans id="msg.address.street" />
                    </InfoHeader>
                    <InfoContent>{member.address.street || '-'}</InfoContent>
                    <InfoHeader component="th">
                      <Trans id="msg.address.city" />
                    </InfoHeader>
                    <InfoContent>{member.address.city || '-'}</InfoContent>
                    <InfoHeader component="th">
                      <Trans id="msg.address.postal_code" />
                    </InfoHeader>
                    <InfoContent>{member.address.postal_code || '-'}</InfoContent>
                  </InfoContainer>
                </CardContent>
                {users && (
                  <CardContent>
                    <MemberUsersTable
                      users={users}
                      openDialog={this.openCreateInvitation}
                      // createInvitation={this.createInvitation}
                      editInvitation={this.editInvitation}
                      deleteInvitation={this.deleteInvitation}
                      isRepresentable={member.is_representable}
                      revokeUser={this.revokeUser}
                      managePermissions={ability.can('manage', {
                        __type: 'Members',
                        level: accessLevel,
                      })}
                    />
                  </CardContent>
                )}
                {representatives && (
                  <CardContent>
                    <MemberRepresentativesTable
                      representatives={representatives}
                      openDialog={this.openRepresentativeDialog}
                      // createInvitation={this.createInvitation}
                      editRepresentative={this.editRepresentative}
                      removeRepresentative={this.removeRepresentative}
                      editInvitation={this.editInvitation}
                      isRepresentable={member.is_representable}
                      invitationsIds={invitationsIds}
                      managePermissions={ability.can('manage', {
                        __type: 'Members',
                        level: accessLevel,
                      })}
                    />
                  </CardContent>
                )}
                {/* <CardContent>
                    <Button
                      variant="contained"
                      component={Link}
                      to={reverse(routes.management.members.single.update, {
                        memberId: member.id,
                      })}
                    >
                      <Trans id="msg.members.edit_member" />
                    </Button>
                  </CardContent> */}
              </Card>
            </div>
            <div>
              <Card elevation={0} square>
                <CardContent>
                  <Typography variant="h5">User profile</Typography>
                </CardContent>
              </Card>
            </div>
          </SwipeableViews>

          <MemberInvitationDialog
            dialogOpened={dialogOpened}
            onClose={this.closeDialog}
            handleSubmit={this.handleCreateInvitationSubmit}
            invitation={currentlyEditedInvitation}
          />

          <MemberRepresentativeDialog
            dialogOpened={representativeDialogOpened}
            onClose={this.closeDialog}
            handleSubmit={this.handleRepresentativeSubmit}
            representative={currentlyEditedRepresentative}
          />
          {/* <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <ProfilePhoto
              desktopWidth={300}
              desktopHeight={400}
              mobileWidth={200}
              mobileHeight={200}
            >
              {member.image_id ? (
                <ImageDisplayStandalone imageId={member.image_id} />
              ) : (
                <img src="//satyr.io/300x400" alt="user profile" />
              )}
            </ProfilePhoto>
            [[ SwipeableViews ]]
            [[ Dialogs ]]
          </div> */}
        </Paper>
      </div>
    ) : (
      <LoadingMessage />
    );
  }
}

export default withSnackbar(Detail);
