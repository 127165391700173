import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Icon as IconMDI } from '@mdi/react';
import { mdiPencil, mdiPencilOff } from '@mdi/js';
import { ABILITY_LEVEL_WRITE_TEXT } from 'ability.js';
import styled from 'styled-components';
import { green, red } from '@material-ui/core/colors';

const PermissionItem = styled.div`
  color: ${props => (props.permissionLevel === ABILITY_LEVEL_WRITE_TEXT ? green[500] : red[500])}
  display: flex;
  align-items: center;
  padding: 8px 0;
  
  & > span {
    margin-left: 8px;
  }
`;

const PermissionsBlock = ({ permissions, modules, permissionArea }) => {
  if (
    !permissions[permissionArea] ||
    permissions[permissionArea].length === 0 ||
    !modules[permissionArea] ||
    modules[permissionArea].length === 0
  ) {
    return null;
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" gutterBottom>
        {permissionArea}
      </Typography>
      {permissions[permissionArea].map(permission => {
        const matchingModuleAndPermission = modules[permissionArea].find(
          module => module.value === permission.module
        );

        if (
          !matchingModuleAndPermission ||
          typeof matchingModuleAndPermission.label === 'undefined'
        ) {
          return null;
        }

        return (
          <PermissionItem
            permissionLevel={permission.level}
            key={matchingModuleAndPermission.value}
          >
            <IconMDI
              path={permission.level === ABILITY_LEVEL_WRITE_TEXT ? mdiPencil : mdiPencilOff}
              size={0.8}
              color="currentColor"
            />
            <span>
              {matchingModuleAndPermission.label}: {permission.level}
            </span>
          </PermissionItem>
        );
      })}
    </Grid>
  );
};
PermissionsBlock.propTypes = {
  permissions: PropTypes.object.isRequired,
  modules: PropTypes.object.isRequired,
  permissionArea: PropTypes.oneOf(['management', 'web']).isRequired,
};

class RoleStaticView extends Component {
  static propTypes = {
    role: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    modules: PropTypes.object.isRequired,
  };

  render() {
    const { role, permissions, modules } = this.props;
    return (
      <div>
        <Typography component="div">
          <p>
            <strong>Role access type</strong>: {role.type}
          </p>

          <p>
            <strong>Role description:</strong>
          </p>
          <p>{role.name}</p>

          <Typography variant="h5" gutterBottom>
            Permissions
          </Typography>
          <Grid container spacing={24}>
            {['management', 'web'].map(area => (
              <PermissionsBlock
                key={area}
                permissions={permissions}
                modules={modules}
                permissionArea={area}
              />
            ))}
          </Grid>
        </Typography>
      </div>
    );
  }
}

export default RoleStaticView;
