import { apiCall } from '../utils/apiCall';
import { ApiService } from './api.service';
import { Image } from '../shared/image.model';

/**
 * @static
 */
export class ImageService extends ApiService {
  static endpoint = 'images';

  /**
   * @param {int} id
   * @param {object} params={}
   * @param {object} config={}
   * @return {Promise<object>}
   * @public
   */
  static async get(id, params = {}, config = {}) {
    return apiCall
      .get(ImageService.buildUrl(id), { ...config, params })
      .then(response => new Image(response.data));
  }

  static async create(data, headers) {
    return apiCall.post(ImageService.buildUrl(), data, { headers }).then(
      response =>
        // console.log({ response });
        new Image(response.data),
      error => console.log({ error })
    );
  }
}
