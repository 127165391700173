import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'App.js';
import { Typography, TextField, Grid, Icon, IconButton, InputAdornment } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import WhispererItem from './WhispererItem';

class Whisperer extends Component {
  static propTypes = {
    possibleMembers: PropTypes.array.isRequired,
    addMembersToRoster: PropTypes.func.isRequired,
    rosterType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    searchTerm: '', // stored in lower case
    filteredMembers: [],
  };

  componentDidMount() {
    const { possibleMembers } = this.props;
    this.setState({
      possibleMembers,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.possibleMembers !== prevState.possibleMembers) {
      return {
        possibleMembers: nextProps.possibleMembers,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { possibleMembers } = this.props;
    if (prevProps.possibleMembers !== possibleMembers) {
      this.filterMembers();
    }
  }

  filterMembers = () => {
    const { searchTerm, possibleMembers } = this.state;

    if (searchTerm.length >= 3) {
      const filteredMembers = possibleMembers
        .map(member => ({
          ...member,
          full_name: `${member.first_name.toLowerCase()} ${member.last_name.toLowerCase()}`,
        }))
        .filter(member => member.full_name.indexOf(searchTerm) > -1);
      this.setState({ filteredMembers });
    } else {
      this.setState({ filteredMembers: [] });
    }

    if (searchTerm === '') {
      this.setState({
        filteredMembers: [],
      });
    }
  };

  handleChange = evt => {
    console.log(evt.target.value);
    this.setState(
      {
        searchTerm: evt.target.value.toLowerCase(),
      },
      this.filterMembers
    );
  };

  clearSearchTerm = () => {
    this.setState(
      {
        searchTerm: '',
      },
      this.filterMembers
    );
  };

  render() {
    const { addMembersToRoster, rosterType, disabled } = this.props;
    const { searchTerm, filteredMembers } = this.state;
    return (
      <div style={{ padding: '0 4px' }}>
        <Grid container spacing={8} style={{ alignItems: 'center' }}>
          <Grid item>
            <Icon>person_add</Icon>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <TextField
              id="searchTerm"
              // label="Member name"
              placeholder={i18n._('msg.members.name')}
              value={searchTerm}
              onChange={evt => this.handleChange(evt)}
              fullWidth
              variant="outlined"
              margin="dense"
              disabled={disabled}
              InputProps={{
                autoComplete: 'off',
                endAdornment: searchTerm.length ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.clearSearchTerm}
                    >
                      <Icon>close</Icon>
                      {/* {this.state.showPassword ? <VisibilityOff /> : <Visibility />} */}
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
        </Grid>
        <div style={{ maxHeight: '400px', overflow: 'auto', paddingBottom: '4px' }}>
          {filteredMembers.length
            ? filteredMembers.map(member => (
                <WhispererItem
                  member={member}
                  key={member.id}
                  rosterType={rosterType}
                  addMembersToRoster={addMembersToRoster}
                />
              ))
            : searchTerm.length >= 3 && (
                <Typography variant="body2">
                  <Trans id="msg.rosters.no_search_match" />
                </Typography>
              )}
        </div>
      </div>
    );
  }
}

export default Whisperer;
