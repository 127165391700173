import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HallManagementService } from 'api/hall.service';
import HallsTable from 'components/Management/Halls/HallsTable';
import HallDialog from 'components/Management/Halls/HallDialog';
import { Link } from 'react-router-dom';
import routes from 'Routes.js';
import { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import { i18n } from 'App.js';
import { Hall } from 'models/Hall';
import PageHeader from 'components/PageHeader';
import ContextMenu from 'components/Base/ContextMenu';
import FabButton from 'components/PageHeader/FabButton';
import ability from 'ability.js';

class Halls extends Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    accessLevel: PropTypes.string.isRequired,
  };

  state = {
    halls: [],
    hallsDeleted: null,
    loading: false,
    loadingDeleted: false,
    dialogOpened: false,
    dialogComponent: null,
    newHallsOrder: null,
    initialHallsOrder: null,
    isSubmitting: false,
  };

  setInitialHallsOrder = halls =>
    halls.map((hall, index) => ({
      id: hall.id,
      name: hall.name,
      order: index + 1, // this is because of the order dont't have to be row of numbers
    }));

  fetchHalls = () => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loading: true,
    });

    HallManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .fetch()
      .then(response => {
        const initialHallsOrder = this.setInitialHallsOrder(response);

        this.setState(
          {
            halls: response,
            initialHallsOrder,
            loading: false,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.loading_success'), { variant: 'success' });
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            halls: [],
            loading: false,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.loading_error'), { variant: 'error' });
          }
        );
      });
  };

  fetchDeletedHalls = () => {
    const { enqueueSnackbar } = this.props;

    this.setState({
      loadingDeleted: true,
    });

    HallManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .deleted()
      .fetch()
      .then(response => {
        this.setState({
          hallsDeleted: response,
          loadingDeleted: false,
        });
        // enqueueSnackbar(i18n._('msg.halls.loading_success'), { variant: 'success' });
      })
      .catch(error => {
        this.setState({
          hallsDeleted: null,
          loadingDeleted: false,
        });
        enqueueSnackbar(i18n._('msg.member_types.loading_error'), { variant: 'error' });
      });
  };

  deleteHall = hallId => {
    const { enqueueSnackbar } = this.props;

    HallManagementService.delete(hallId)
      .then(() => {
        const { halls, hallsDeleted, newHallsOrder } = this.state;
        let { initialHallsOrder } = this.state;
        const deletedHallIndexInHalls = halls.findIndex(t => t.id === hallId);

        const newlyDeletedHall = halls.splice(deletedHallIndexInHalls, 1);

        // remove deleted hall from initialHallsOrder and from newHallsOrder if this is set
        const deletedHallIndexInInitialHallsOrder = initialHallsOrder.findIndex(
          hall => hall.id === hallId
        );
        if (deletedHallIndexInInitialHallsOrder > -1) {
          initialHallsOrder = this.setInitialHallsOrder(halls);
        }
        if (newHallsOrder) {
          const deletedHallIndexInNewHallsOrder = newHallsOrder.findIndex(
            hall => hall.id === hallId
          );

          if (deletedHallIndexInNewHallsOrder > -1) {
            newHallsOrder.splice(deletedHallIndexInNewHallsOrder, 1);
          }
        }

        this.setState(
          {
            halls,
            // if hallsDeleted is not set, we dont have fetched deleted halls yet,
            // we will have to do it in future when user will display restore window,
            // so for this time, we can omit updating hallsDeleted update in state
            hallsDeleted: hallsDeleted ? [...hallsDeleted, newlyDeletedHall[0]] : null,
            initialHallsOrder,
            newHallsOrder,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.delete_success'), { variant: 'info' });
          }
        );
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  handleSubmit = () => {
    const { dialogComponent, newHallsOrder, initialHallsOrder } = this.state;

    if (
      dialogComponent === 'sortable' &&
      newHallsOrder &&
      !isEqual(initialHallsOrder, newHallsOrder)
    ) {
      this.setState({
        isSubmitting: true,
      });
      this.requestForNewOrderedHalls(newHallsOrder);
    } else {
      this.closeDialog();
    }
  };

  openDeletedHalls = () => {
    const { hallsDeleted } = this.state;
    this.setState({
      dialogComponent: 'deleted',
      dialogOpened: true,
      loadingDeleted: !hallsDeleted,
    });
    if (!hallsDeleted) {
      this.fetchDeletedHalls();
    }
  };

  openSortableHalls = () => {
    this.setState({ dialogComponent: 'sortable', dialogOpened: true });
  };

  closeDialog = () => {
    this.setState({
      dialogComponent: '',
      dialogOpened: false,
    });
  };

  setNewHallsOrder = newHallsOrder => {
    this.setState({ newHallsOrder });
  };

  requestForNewOrderedHalls = async newOrder => {
    const { enqueueSnackbar } = this.props;
    const newOrderNormalized = newOrder.map(hall => Hall.formatForSimpleSorter(hall));

    this.setState({
      loading: true,
    });

    await HallManagementService.updateMultiple(newOrderNormalized)
      .then(response => {
        this.setState(
          {
            halls: response,
            initialHallsOrder: response.map(p => Hall.formatForSorter(p)),
            newHallsOrder: null,
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(i18n._('msg.halls.update_success'), { variant: 'success' });
          }
        );
      })
      .catch(e => {
        this.setState(
          {
            loading: false,
            isSubmitting: false,
          },
          () => {
            this.closeDialog();
            enqueueSnackbar(e.response.data.message, { variant: 'error' });
          }
        );
      });
  };

  restoreHall = async hallId => {
    const { enqueueSnackbar } = this.props;

    HallManagementService.restore(hallId)
      .then(response => {
        const { halls, hallsDeleted } = this.state;

        // push restored hall into state
        halls.push(response);
        // set new initial order
        const initialHallsOrder = this.setInitialHallsOrder(halls);

        // remove restored hall from deleted halls
        const restoredHallIndexInDeletedHalls = hallsDeleted.findIndex(dh => dh.id === response.id);
        hallsDeleted.splice(restoredHallIndexInDeletedHalls, 1);

        this.setState(
          {
            halls,
            hallsDeleted,
            initialHallsOrder,
            newHallsOrder: null,
          },
          () => {
            enqueueSnackbar(i18n._('msg.halls.restore_success'), { variant: 'success' });
          }
        );
        // //
      })
      .catch(error => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  toggleVisibility = hallId => {
    const { enqueueSnackbar } = this.props;
    const { halls } = this.state;

    const hallIndex = halls.findIndex(h => h.id === hallId);

    if (hallIndex > -1) {
      const hallData = halls[hallIndex];
      const formattedData = Hall.formatFormData({
        ...hallData,
        visible_on_web: !hallData.visible_on_web,
      });
      HallManagementService.update(hallId, formattedData)
        .then(response => {
          halls[hallIndex] = response;
          this.setState(
            {
              halls,
            },
            () => {
              enqueueSnackbar(i18n._('msg.halls.update_success'), { variant: 'success' });
            }
          );
        })
        .catch(error => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    this.fetchHalls();
  };

  render() {
    const {
      halls,
      hallsDeleted,
      loading,
      dialogOpened,
      dialogComponent,
      loadingDeleted,
      isSubmitting,
    } = this.state;
    const { accessLevel } = this.props;

    return (
      <div>
        <PageHeader
          title={i18n._('msg.halls.page_title')}
          customControlsPrimary={
            ability.can('manage', {
              __type: 'Halls',
              level: accessLevel,
            })
              ? [
                  <FabButton
                    variant="round"
                    size="small"
                    component={Link}
                    to={routes.management.halls.create}
                  />,
                  <ContextMenu
                    text={i18n._('msg.actions')}
                    variant="withIcon"
                    menuItems={[
                      {
                        text: i18n._('msg.halls.halls_deleted'),
                        action: this.openDeletedHalls,
                      },
                      {
                        text: i18n._('msg.halls.halls_sort'),
                        action: this.openSortableHalls,
                      },
                    ]}
                  />,
                ]
              : []
          }
        />

        <HallsTable
          halls={halls}
          loading={loading}
          deleteHall={this.deleteHall}
          toggleVisibility={this.toggleVisibility}
          managePermissions={ability.can('manage', {
            __type: 'Halls',
            level: accessLevel,
          })}
        />

        <HallDialog
          opened={dialogOpened}
          component={dialogComponent}
          data={dialogComponent === 'deleted' ? hallsDeleted : halls}
          onClose={this.closeDialog}
          requestForNewOrderedRecords={this.requestForNewOrderedHalls}
          setNewRecordsOrder={this.setNewHallsOrder}
          restoreRecord={this.restoreHall}
          loadingDeleted={loadingDeleted}
          isSubmitting={isSubmitting}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default withSnackbar(Halls);
