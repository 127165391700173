import fallbackClubTheme from '../fallbackClubTheme';

/**
 * @property {int} id
 * @property {string} name
 * @property {string} domain_name
 * @property {string} country_id
 * @property {string} language_id
 * @property {string} sport
 * @property {string} currency_id
 * @property {object|null} ui_settings
 * @property {number|null} deciding_age
 * @property {boolean} is_app_active
 * @property {string|null} app_code
 * @property {ClubBillingInformation|null} billing_information
 */

export class Club {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.domain_name = data.domain_name;
    this.country_id = data.country_id;
    this.language_id = data.language_id;
    this.sport = data.sport;
    this.currency_id = data.currency_id;
    this.ui_settings = data.ui_settings || fallbackClubTheme;
    this.deciding_age = data.deciding_age || null;
    this.is_app_active = data.is_app_active || false;
    this.app_code = data.app_code || null;
  }
}
