import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import SeasonForm from './SeasonForm';

class SeasonDialog extends Component {
  static propTypes = {
    dialogOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    season: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dialogOpened: false,
    season: null,
  };

  render() {
    const { dialogOpened, onClose, season, handleSubmit } = this.props;

    return (
      <Dialog open={dialogOpened} onClose={onClose} aria-labelledby="form-dialog-title">
        <CustomDialogTitle onClose={onClose}>
          {season ? (
            <Trans id="msg.seasons.popup_title_update" />
          ) : (
            <Trans id="msg.seasons.popup_title_add_new" />
          )}
        </CustomDialogTitle>
        <DialogContent>
          <SeasonForm handleSubmit={handleSubmit} season={season} closeDialog={onClose} />
        </DialogContent>
      </Dialog>
    );
  }
}

SeasonDialog.propTypes = {};

export default SeasonDialog;
