import React from 'react';
import PropTypes from 'prop-types';
import { Button, Fab, Icon } from '@material-ui/core';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  && {
    margin-right: ${props => (props.variant === 'extended' ? '8px' : 0)};
  }
`;

const FabButton = ({ variant, onClick, component, to, color, size, children, icon, ...rest }) => (
  <Fab
    variant={variant}
    onClick={onClick}
    color={color}
    size={size}
    component={component}
    to={to}
    {...rest}
  >
    <StyledIcon variant={variant}>{icon}</StyledIcon>
    {children}
  </Fab>
);

FabButton.propTypes = {
  variant: PropTypes.oneOf(['extended', 'round']),
  onClick: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  to: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit', 'default']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.element,
  icon: PropTypes.string,
};

FabButton.defaultProps = {
  variant: 'extended',
  onClick: null,
  component: Button,
  to: '#',
  color: 'primary',
  size: 'medium',
  children: null,
  icon: 'add',
};

export default FabButton;
