import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Formik } from 'formik';
import EosTextInput from 'components/Forms/EosTextInput';
import EosSelect from 'components/Forms/EosSelect';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import SubmitButton from 'components/Forms/SubmitButton';
import EosRichText from 'components/Forms/EosRichText';
import { i18n } from 'App.js';
import * as yup from 'yup';
import { AuthConsumer } from 'AuthContext.js';

const WebFormComponent = ({
  isSubmitting,
  errors,
  // values,
  setFieldValue,
  setFieldTouched,
  resetForm,
  teamViewTypes,
  boolYesNo,
  boolShowHide,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Card>
      <CardContent>
        <Grid container spacing={24}>
          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.show"
              label={i18n._('msg.teams.web_settings.show_label')} // "Zobrazení družstva na webu"
              // options={dummyShowHide}
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Field
              type="text"
              name="stats_link"
              label={i18n._('msg.teams.web_settings.stats_link_label')} // "Odkaz na profil družstva na ceskyflorbal.cz"
              component={EosTextInput}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.view_type"
              label={i18n._('msg.teams.web_settings.view_type_label')} // "Zobrazení soupisky"
              options={teamViewTypes}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.show_positions"
              label={i18n._('msg.teams.web_settings.show_positions_label')} // "Soupiska - rozlišovat posty"
              // options={dummyBool}
              options={boolYesNo}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.show_matches"
              label={i18n._('msg.teams.web_settings.show_matches_label')}
              // options={dummyBool}
              options={boolYesNo}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.table"
              label={i18n._('msg.teams.web_settings.table_label')} // "Tabulka soutěží na webu"
              // options={dummyShowHide}
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.show_stats"
              label={i18n._('msg.teams.web_settings.show_stats_label')} // "Statistiky soutěže na webu"
              // options={dummyShowHide}
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>

          {/* <Grid item xs={12} md={4}>
              <Field
                name="web_settings.show_lineup_only"
                label={i18n._('msg.teams.web_settings.show_lineup_only_label')} // "Zápasy - filtr doma / venku"
                // options={dummyShowHide}
                options={boolShowHide}
                component={EosSelect}
              />
            </Grid> */}

          <Grid item xs={12} md={4}>
            <Field
              name="web_settings.show_results"
              label={i18n._('msg.teams.web_settings.show_results_label')} // "Zobrazit výsledky zápasů"
              // options={dummyShowHide}
              options={boolShowHide}
              component={EosSelect}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="trainings"
              label={i18n._('msg.teams.web_settings.trainings_label')} // "Rozpis tréninků"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              component={EosRichText}
            />
          </Grid>

          <Grid item xs={12}>
            <SubmitArea align="left" isSubmitting={isSubmitting}>
              <SubmitButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
              </SubmitButton>{' '}
              <Button
                variant="contained"
                color="secondary"
                type="reset"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                }}
              >
                {i18n._('msg.reset')}
              </Button>
            </SubmitArea>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </EosForm>
);

WebFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  teamViewTypes: PropTypes.array,
  boolYesNo: PropTypes.array,
  boolShowHide: PropTypes.array,
};

WebFormComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
  teamViewTypes: [],
  boolYesNo: [],
  boolShowHide: [],
};

class TeamsConfigWebForm extends Component {
  static propTypes = {
    updateTeam: PropTypes.func.isRequired,
    team: PropTypes.object.isRequired,
  };

  validationSchema = yup.object().shape({
    stats_link: yup
      .string()
      .url(i18n._('msg.form.invalid_url'))
      .nullable(),
  });

  render() {
    const { updateTeam, team } = this.props;
    return (
      <AuthConsumer>
        {({ enumerators }) => (
          <Formik
            initialValues={{
              ...team,
            }}
            onSubmit={updateTeam}
            validationSchema={this.validationSchema}
            render={formikProps => (
              <WebFormComponent
                {...formikProps}
                teamViewTypes={enumerators.teamViewTypes}
                boolShowHide={enumerators.boolShowHide}
                boolYesNo={enumerators.boolYesNo}
              />
            )}
          />
        )}
      </AuthConsumer>
    );
  }
}

export default TeamsConfigWebForm;
