import React from 'react';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCircularProgress = styled(CircularProgress)`
  margin-left: ${props => `${props.theme.spacing.unit}px`};
`;

const SubmitButton = ({ isSubmitting, children, ...props }) => (
  <Button {...props}>
    {children}
    {isSubmitting && <StyledCircularProgress color="secondary" size={16} />}
  </Button>
);

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
SubmitButton.defaultProps = {
  isSubmitting: false,
};
export default SubmitButton;
