// things which have to be solved:
// - filter as component (due to labels width)
// - split filters (season ⚔️ team) to reusable component
// - clear searched term after tab switch
//
// solved things:
// - loading by parts
//   - first line - filters (season, teams) - DONE
//   - content - tabs (players, organizers) - DONE
//
// - state without seasons - DONE
// - state without teams for current season - DONE
// - state with roster defined by ID in URL - DONE
// - update URL after team change - DONE
// - update URL after season change and first team is selected - DONE

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { sortBy, toLower } from 'lodash';
import { reverse } from 'named-urls';
import routes from 'Routes';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Badge, Card, CardContent, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { SeasonManagementService } from 'api/season.service';
import { TeamManagementService } from 'api/team.service';
import { MemberManagementService } from 'api/member.service';
import { TeamMembersManagementService } from 'api/team-member.service';
import { TrainerRoleManagementService } from 'api/trainer-role.service';
import { Trans } from '@lingui/macro';
import Whisperer from 'components/Management/Rosters/Whisperer';
import RosterTable from 'components/Management/Rosters/RosterTable';
import RosterDialog from 'components/Management/Rosters/RosterDialog';
import RosterMultiAdd from 'components/Management/Rosters/RosterMultiAdd';
import LoadingMessage from 'components/Base/LoadingMessage';
import SeasonsList from 'components/Management/Shared/SeasonsList';
import TeamsList from 'components/Management/Shared/TeamsList';

const styles = theme => ({
  badge: {
    top: '50%',
    right: -16,
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
});

const NoSeasonContent = () => (
  <Typography>
    <Trans id="msg.rosters.no_season_message">
      There is no season. Try to{' '}
      <Link to={routes.management.settings.seasons}>create a new one</Link>
    </Trans>
  </Typography>
);

const NoTeamContent = ({ seasonId }) => (
  <Typography>
    <Trans id="msg.rosters.no_team_message">
      There is no team for selected season. Try to{' '}
      <Link to={reverse(routes.management.teams.season.list, { seasonId })}>create a new one</Link>
    </Trans>
  </Typography>
);
NoTeamContent.propTypes = {
  seasonId: PropTypes.number.isRequired,
};

class Rosters extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    defaultSelectedSeason: null,
    defaultSelectedTeam: null,
    selectedSeason: null,
    selectedTeam: null,
    seasons: [],
    teams: [],
    requestedTeamId: null,

    seasonsTeams: [],

    loadingFilters: false,
    loadingContent: false,

    noSeasonsFound: false,
    noTeamsFound: false,
    notFoundTeamByID: false,

    allMembers: [], // all members
    teamMembers: [], // members who are connected to selected team
    fullTeamMembers: [], // merged info from members and team-members tables
    possibleMembers: [], // members who are listed in whisperer // TODO - differences in members / organizers tabs
    memberToUpdate: null,

    value: 0,
    rosterType: 'player',

    teamRestrictions: {
      from: null,
      to: null,
      gender: null,
    },
    trainerRoles: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps');
    const {
      match: {
        params: { teamId },
      },
    } = nextProps;

    // team ID in URL changed and still it's number
    if (!Number.isNaN(parseInt(teamId)) && parseInt(teamId) !== prevState.requestedTeamId) {
      console.log(`getDerivedStateFromProps - new team id is ${teamId} ${typeof teamId}`);

      return {
        requestedTeamId: parseInt(teamId),
      };
    }

    // team ID is not number
    if (Number.isNaN(parseInt(teamId)) && teamId !== prevState.requestedTeamId) {
      console.log(
        `getDerivedStateFromProps - new team id is not number (${typeof teamId} "${teamId}")`
      );
      return {
        requestedTeamId: teamId,
        notFoundTeamByID: !!teamId, // true, // if ID is not a number, team can't exists
      };
    }

    return null;
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.fetchAllMembers();
    this.fetchTrainerRoles();

    this.setState({
      // eslint-disable-next-line
      labelWidthSeasons: ReactDOM.findDOMNode(this.InputLabelRefSeasons) && ReactDOM.findDOMNode(this.InputLabelRefSeasons).offsetWidth || 0,
      // eslint-disable-next-line
      labelWidthTeams: ReactDOM.findDOMNode(this.InputLabelRefTeams) && ReactDOM.findDOMNode(this.InputLabelRefTeams).offsetWidth || 0,
      loadingFilters: true,
      loadingContent: true,
    });

    const {
      match: {
        params: { teamId },
      },
    } = this.props;

    this.getDefaultSeasonAndTeam(() => {
      if (!Number.isNaN(parseInt(teamId))) {
        console.log(`componentDidMount - new team id is ${teamId}`);

        this.setState({
          requestedTeamId: parseInt(teamId),
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { requestedTeamId } = this.state;
    if (prevState.requestedTeamId !== requestedTeamId) {
      console.log(`!!! C H A N G E ${prevState.requestedTeamId} -> ${requestedTeamId}!!!`);
      this.getSeasonByTeamId(requestedTeamId);
    }
  }

  getSeasonByTeamId = teamId => {
    console.log(`give me the season for team ${teamId}`);

    const { seasonsTeams, defaultSelectedSeason, defaultSelectedTeam } = this.state;

    /*
    this.state.seasonsTeams has structure:
    [{ seasonId: 1, teams: [{...}, {...}]}, { seasonId: 2, teams: [{...}, {...}]}]
    we want to find season containing requested team, so we want to find team's ID indexe in each season
    after this we find index of zero-or-greater tam index
    if we fail and get -1 index there is no team with requested ID and we have to use default season and team
    */
    const seasonDefinedByTeam = seasonsTeams
      .map(s => s.teams.findIndex(t => t.id === teamId))
      .findIndex(index => index >= 0);

    // we have found season for requested team, so we can set state for requested team and it's season,
    // we have to update team list too
    if (seasonDefinedByTeam > -1) {
      console.log(
        `change seasons to season with ID ${seasonDefinedByTeam} and team to team with ID ${teamId}`
      );
      this.setState(
        {
          selectedSeason: seasonsTeams[seasonDefinedByTeam].seasonId,
          selectedTeam: teamId,
          teams: seasonsTeams[seasonDefinedByTeam].teams,
          loadingFilters: false,
          notFoundTeamByID: false,
        },
        this.fetchTeamMembers
      );
    } else {
      // if we didn't found appropriate season, use the default season, team and team list
      console.log(
        `use default season with ID ${defaultSelectedSeason} and default team with ID ${defaultSelectedTeam}`
      );
      console.log({ state: this.state });
      const possibleTeams = seasonsTeams.find(st => st.seasonId === defaultSelectedSeason);

      this.setState(
        {
          selectedSeason: defaultSelectedSeason,
          selectedTeam: defaultSelectedTeam,
          teams: possibleTeams ? possibleTeams.teams : [],
          loadingFilters: false,
          notFoundTeamByID: !!teamId,
        },
        this.fetchTeamMembers
      );
    }
  };

  getDefaultSeasonAndTeam = async callback => {
    console.log('getDefaultSeasonAndTeam');
    let { defaultSelectedSeason, defaultSelectedTeam } = this.state;
    const { requestedTeamId } = this.state;

    // const seasons = [];
    const seasons = await SeasonManagementService.getAll()
      .sortBy('-id')
      // .turnOffPagination()
      .fetch();

    const teams = await TeamManagementService.getAll()
      .sortBy('order')
      .turnOffPagination()
      .fetch();

    let teamsForDefaultSelectedSeason = [];
    let seasonsTeams = [];
    if (seasons.length) {
      seasonsTeams = seasons.map(s => ({
        seasonId: s.id,
        teams: teams.length
          ? teams
              .filter(t => t.season_id === s.id)
              .map(t => ({
                id: t.id,
                name: t.name,
                web_settings: t.web_settings,
                birth_year_range: t.birth_year_range,
              }))
          : [],
      }));

      const activeSeasonObject = seasons.find(season => season.active) || {};
      defaultSelectedSeason = Object.prototype.hasOwnProperty.call(activeSeasonObject, 'id')
        ? activeSeasonObject.id // season with active flag ID
        : seasons[0].id; // first returned (with the highest ID) ID

      teamsForDefaultSelectedSeason = teams.filter(t => t.season_id === defaultSelectedSeason);
      if (teamsForDefaultSelectedSeason.length) {
        defaultSelectedTeam = teamsForDefaultSelectedSeason[0].id;
      }
    }

    if (requestedTeamId) {
      this.setState(
        {
          seasons,
          seasonsTeams,
          defaultSelectedSeason,
          defaultSelectedTeam,
        },
        () => this.getSeasonByTeamId(requestedTeamId)
      );
    } else {
      this.setState(
        {
          seasons,
          seasonsTeams,
          teams: teamsForDefaultSelectedSeason,
          defaultSelectedSeason,
          defaultSelectedTeam,
          selectedSeason: defaultSelectedSeason,
          selectedTeam: defaultSelectedTeam,
          loadingFilters: false,
        },
        this.fetchTeamMembers
      );
    }

    if (typeof callback === 'function') {
      callback.call();
    }
  };

  fetchAllMembers = async () => {
    const allMembers = await MemberManagementService.getAll()
      .with('user')
      .sortBy('last_name,first_name')
      .turnOffPagination()
      .fetch();

    this.setState({
      allMembers,
    });
  };

  fetchTeamMembers = async () => {
    const { selectedTeam, teams } = this.state;
    if (selectedTeam === null) {
      this.setState(
        {
          teamMembers: [],
          manageMemberVisibility: false,
          loadingContent: false,
        },
        this.mergeTeamAndFullMembers
      );
      return;
    }

    this.setState({
      loadingContent: true,
    });

    console.log(`Fetch members for team ${selectedTeam}`);
    const teamMembers = await TeamMembersManagementService.getAll(selectedTeam);
    console.log({ teamMembers });
    const requestedTeam = teams.find(t => t.id === selectedTeam);
    const manageMemberVisibility = requestedTeam ? Boolean(requestedTeam.web_settings.show) : false;
    this.setState(
      {
        teamMembers,
        manageMemberVisibility,
        loadingContent: false,
      },
      this.mergeTeamAndFullMembers
    );
  };

  fetchTrainerRoles = async () => {
    const trainerRoles = await TrainerRoleManagementService.getAll()
      .sortBy('order')
      // .turnOffPagination()
      .fetch();
    this.setState({
      trainerRoles,
    });
  };

  mergeTeamAndFullMembers = () => {
    const { teamMembers, allMembers } = this.state;

    const fullTeamMembers = teamMembers
      .map(member => {
        const correspondingFullMember = allMembers.find(
          fullMember => member.member_id === fullMember.id
        );
        if (correspondingFullMember) {
          // eslint-disable-next-line
          const { id, dress_number, position_id, ...rest } = correspondingFullMember;

          return {
            ...rest,
            ...member,
            default_dress_number: correspondingFullMember.dress_number,
            default_position_id:
              correspondingFullMember.position_id /* full_member_id: correspondingFullMember.id, */,
          };
        }
        return {};
      })
      .filter(fullTeamMember => Object.prototype.hasOwnProperty.call(fullTeamMember, 'id'));

    // const sortedFullTeamMembers = sortBy(fullTeamMembers, ['position_id', 'last_name', 'first_name']);

    const sortedFullTeamMembers = sortBy(
      fullTeamMembers,
      [
        item => item.position_id,
        item => item.dress_number,
        item => toLower(item.last_name),
        item => toLower(item.first_name),
      ],
      [
        (a, b) => a - b,
        (a, b) => a - b,
        (a, b) => a.toString().localeCompare(b, 'cs'),
        (a, b) => a.toString().localeCompare(b, 'cs'),
      ]
    );
    this.setState({
      fullTeamMembers: sortedFullTeamMembers,
    });
    this.findPossibleMembers();
  };

  findPossibleMembers = () => {
    const {
      teams,
      selectedTeam,
      allMembers,
      teamMembers,
      teamRestrictions: oldTeamRestrictions,
      rosterType,
    } = this.state;

    const selectedTeamObject = teams.find(t => t.id === selectedTeam);
    if (!selectedTeamObject) {
      return false;
    }
    const {
      birth_year_range: { from, to },
    } = selectedTeamObject;
    const teamRestrictions = rosterType === 'player' ? { ...oldTeamRestrictions, from, to } : {};

    let possibleMembers = [...allMembers];
    let alreadyMemberIDs = [];
    if (rosterType === 'player') {
      alreadyMemberIDs = teamMembers.filter(m => m.is_player).map(m => m.member_id);
    } else if (rosterType === 'organizer') {
      alreadyMemberIDs = teamMembers.filter(m => m.is_organizer).map(m => m.member_id);
    }

    // remove all members who are already on roster
    // TODO
    possibleMembers = possibleMembers.filter(m => alreadyMemberIDs.indexOf(m.id) === -1);

    // remove all gender faulty members
    // TODO
    if (teamRestrictions.gender) {
      // some restrictions due to gender
    }

    // remove all members outside of birthdate range
    // TODO
    if (teamRestrictions.from) {
      possibleMembers = possibleMembers.filter(m => m.birthdate.year() >= teamRestrictions.from);
    }

    if (teamRestrictions.to) {
      possibleMembers = possibleMembers.filter(m => m.birthdate.year() <= teamRestrictions.to);
    }

    this.setState({ possibleMembers, teamRestrictions });
  };

  // fetchSeasons = async () => {
  //   const seasons = await SeasonManagementService.getAll()
  //     .sortBy('-id')
  //     // .turnOffPagination()
  //     .fetch();

  //   this.setState({
  //     seasons,
  //   });
  // };

  // fetchTeams = async () => {
  //   const teams = await TeamManagementService.getAll()
  //     .sortBy('order')
  //     .turnOffPagination()
  //     .fetch();

  //   this.setState({
  //     teams,
  //   });
  // };

  // getTeamsForSeason

  handleChange = (event, value) => {
    this.setState(
      { value, rosterType: value === 0 ? 'player' : 'organizer' },
      this.mergeTeamAndFullMembers
    );
  };

  handleChangeIndex = index => {
    this.setState(
      { value: index, rosterType: index === 0 ? 'player' : 'organizer' },
      this.mergeTeamAndFullMembers
    );
  };

  handleSeasonChange = evt => {
    const { seasonsTeams } = this.state;
    const { history } = this.props;
    const selectedSeason = evt.target.value;
    // console.log(`selected season ID: ${selectedSeason}`);
    // console.log(
    //   `first team ID which is selected by default: ${seasonsTeams.find(st => st.seasonId === selectedSeason).teams[0].id}`
    // );

    const defaultSelectedTeamAfterSeasonChange = seasonsTeams.find(
      s => s.seasonId === selectedSeason
    ).teams.length
      ? seasonsTeams.find(s => s.seasonId === selectedSeason).teams[0].id
      : null;
    const teamsConnectedToCurrentSeason = seasonsTeams.find(st => st.seasonId === selectedSeason)
      .teams.length
      ? seasonsTeams.find(st => st.seasonId === selectedSeason).teams
      : [];

    this.setState(
      {
        selectedSeason,
        teams: teamsConnectedToCurrentSeason,
        selectedTeam: defaultSelectedTeamAfterSeasonChange,
      },
      () => {
        if (defaultSelectedTeamAfterSeasonChange !== null) {
          history.push(
            reverse(routes.management.rosters.single.update, {
              teamId: defaultSelectedTeamAfterSeasonChange,
            })
          );
        }
      }
    );
  };

  handleTeamChange = evt => {
    const { history } = this.props;
    const selectedTeam = evt.target.value;

    this.setState(
      {
        selectedTeam,
      },
      () => {
        history.push(reverse(routes.management.rosters.single.update, { teamId: selectedTeam }));
      }
    );
  };

  addMembersToRoster = async (membersData, isPlayer, isOrganizer) => {
    const { selectedTeam } = this.state;
    const { teamMembers } = this.state;
    // let data = null;
    let newAddedMembers = null;

    // if type of membersData is an array, members must be added from multi add component
    // and this is possible only for players
    if (typeof membersData === typeof []) {
      // prepare data for multi add
      const preparedData = membersData.map(o => ({
        member_id: o,
        show_on_web: 1,
        is_player: 1,
        is_organizer: 0,
      }));
      console.log({ preparedData });
      const createdMembers = await TeamMembersManagementService.create(selectedTeam, preparedData);

      if (createdMembers) {
        newAddedMembers = createdMembers;
        newAddedMembers.map(o => {
          teamMembers.splice(0, 0, { ...o, dress_number: null, position_id: null });
          return o;
        });
      }
    } else {
      // scenarios
      // 1. member is not on roster, we create new record
      // 2. member is on roster, so we have to update the record
      // WARNING: both of them are realized by POST request

      const indexOfMemberOnRoster = teamMembers.findIndex(tm => tm.member_id === membersData);
      let data = {}; // object which will contain data needed for create / update of team member record
      let updatedRecord = null; // if wea are updating team member record, this will be filled with member's data

      // scenario 1 - create new record
      if (indexOfMemberOnRoster === -1) {
        data = [
          {
            member_id: membersData,
            show_on_web: 1, // member is visible, by default
            is_player: isPlayer, // parameter of function, set during method call
            is_organizer: isOrganizer, // parameter of function, set during method call
            trainer_role_id: null, // set trainer role to null, by default, update in editing popup
          },
        ];
        console.log({ 'CREATE TEAM MEMBER RECORD': data });
      }

      // scenario 2 - update existing record
      else {
        updatedRecord = teamMembers[indexOfMemberOnRoster];
        data = [
          {
            member_id: updatedRecord.member_id,
            dress_number: updatedRecord.dress_number,
            position_id: updatedRecord.position_id,
            show_on_web: updatedRecord.show_on_web,
            is_player: isPlayer || updatedRecord.is_player,
            is_organizer: isOrganizer || updatedRecord.is_organizer,
            trainer_role_id: isOrganizer ? null : updatedRecord.trainer_role_id,
          },
        ];
        console.log({ 'UPDATE TEAM MEMBER RECORD': data });
      }

      // fire POST request and wait for response
      const createdMember = await TeamMembersManagementService.create(selectedTeam, data);

      if (createdMember) {
        // in this case, we are working with single users, so take the first object from returned array
        [newAddedMembers] = createdMember;

        // are we updating record?
        if (!updatedRecord) {
          // if not, insert returned object into team members...
          teamMembers.splice(0, 0, {
            ...newAddedMembers,
          });
        } else {
          // if so, update object defined by it's index in teamMembers array
          teamMembers[indexOfMemberOnRoster] = {
            ...updatedRecord,
            ...newAddedMembers,
          };
        }
      }
    }

    // now we have to update team members in state,
    // and in callback merge with full members, because of data, of course
    this.updateTeamMembersInState(teamMembers);

    return newAddedMembers;
  };

  removeMemberFromRoster = async (recordId, removedRole) => {
    const { teamMembers } = this.state;
    const removedRecordIndex = teamMembers.findIndex(m => m.id === recordId);

    if (removedRecordIndex > -1) {
      let roles = {};
      switch (removedRole) {
        case 'organizer':
          roles = {
            is_organizer: false,
          };
          break;
        default:
          roles = {
            is_player: false,
          };
      }
      // eslint-disable-next-line
      const { created, ...restOfRemovedRecord } = teamMembers[removedRecordIndex]; // value of "created" is threw away, it shouldn't be updated
      const mergedData = {
        ...restOfRemovedRecord,
        ...roles,
      };

      const removedMember = await TeamMembersManagementService.update(recordId, mergedData);

      if (Object.keys(removedMember).length) {
        teamMembers[removedRecordIndex] = { ...removedMember };
      } else {
        // eslint-disable-next-line
        const deletedMemberToThrowAway = teamMembers.splice(removedRecordIndex, 1);
      }

      this.updateTeamMembersInState(teamMembers);
    }
  };

  toggleMemberVisibility = async (recordId, value) => {
    const { teamMembers } = this.state;
    const memberToUpdate = teamMembers.find(m => m.id === recordId);
    if (memberToUpdate) {
      // eslint-disable-next-line
      const { dress_number, is_player, trainer_role_id, position_id } = memberToUpdate;
      const updatedMember = await TeamMembersManagementService.update(recordId, {
        dress_number,
        show_on_web: value,
        is_player,
        trainer_role_id,
        position_id,
      });
      const updateIndex = teamMembers.findIndex(m => m.id === updatedMember.id);
      const newTeamMembers = [...teamMembers];
      newTeamMembers[updateIndex] = {
        ...memberToUpdate,
        ...updatedMember,
      };
      this.setState(
        {
          teamMembers: newTeamMembers,
        },
        () => {
          this.mergeTeamAndFullMembers();
        }
      );
    }
  };

  closeDialog = () => {
    this.setState({
      memberToUpdate: null,
    });
  };

  setMemberToUpdate = recordId => {
    const { fullTeamMembers } = this.state;
    const requestedMember = fullTeamMembers.find(m => m.id === recordId);
    if (requestedMember) {
      this.setState({ memberToUpdate: requestedMember });
    }
    return null;
  };

  updateTeamMembersInState = (teamMembers, closePopup = false) => {
    this.setState(
      {
        teamMembers,
      },
      () => {
        this.mergeTeamAndFullMembers();
        if (closePopup) {
          this.closeDialog();
        }
      }
    );
  };

  updateMemberRoster = async (recordId, values) => {
    // console.log({ recordId, values });
    const { teamMembers } = this.state;
    const memberToUpdate = teamMembers.find(m => m.id === recordId);
    if (memberToUpdate) {
      const data = {
        dress_number: values.dress_number || null,
        show_on_web: !!values.show_on_web,
        is_player: memberToUpdate.is_player,
        is_organizer: memberToUpdate.is_organizer,
        trainer_role_id: values.trainer_role_id || null,
        position_id: values.position_id || null,
      };

      console.log({ memberToUpdate, data });

      const updatedMember = await TeamMembersManagementService.update(recordId, data);
      const updateIndex = teamMembers.findIndex(m => m.id === updatedMember.id);

      teamMembers[updateIndex] = {
        ...memberToUpdate,
        ...updatedMember,
      };
      this.updateTeamMembersInState(teamMembers, true);
    }
  };

  render() {
    const {
      selectedTeam,
      selectedSeason,
      seasons,
      teams,
      loadingFilters,
      loadingContent,
      noSeasonsFound,
      noTeamsFound,
      notFoundTeamByID,
      value,
      teamRestrictions,
      trainerRoles,
      manageMemberVisibility,
      fullTeamMembers,
      possibleMembers,
      memberToUpdate,
    } = this.state;

    const { classes } = this.props;

    const rosterTitleMembers = teams.length
      ? `${teams.find(t => t.id === selectedTeam).name} - soupiska (team ID: ${selectedTeam})`
      : 'Soupiska';
    const rosterTitleManagement = teams.length
      ? `${teams.find(t => t.id === selectedTeam).name} - realizační tým`
      : 'Realizační tým';

    const players = fullTeamMembers.filter(m => m.is_player);
    const playersCount = players.length;
    const organizers = fullTeamMembers.filter(m => m.is_organizer);
    const organizersCount = organizers.length;

    return (
      <div>
        <Link to={reverse(routes.management.rosters.all)}>default</Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 1 })}>1</Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 2 })}>2</Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 20 })}>20</Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 22 })}>22</Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 45 })}>
          team doesn't exists
        </Link>
        {' | '}
        <Link to={reverse(routes.management.rosters.single.update, { teamId: 'aaa' })}>
          team with &laquo;STRING&raquo; ID
        </Link>
        <p>Rosters</p>
        <p>selected season: {selectedSeason || '-'}</p>
        <p>selected team: {selectedTeam || '-'}</p>
        <Typography variant="h1" gutterBottom>
          <Trans id="msg.rosters.page_title" />
        </Typography>
        <div>
          {noSeasonsFound && (
            // <div>Nebyla nalezena žádná sezona, nelze měnit nastavení soupisek</div>
            <Trans id="msg.rosters.no_season_found" />
          )}

          {noTeamsFound && (
            // <div>Nebylo nalezeno žádné družstvo, nelze měnit nastavení soupisek</div>
            <Trans id="msg.rosters.no_team_found" />
          )}

          {loadingFilters && (
            <div>
              <Typography>
                <Trans id="msg.rosters.loading_filters" />
              </Typography>
              <LoadingMessage />
            </div>
          )}

          {!loadingFilters && (
            <Grid container spacing={24} alignItems="center">
              {seasons.length ? (
                <Grid item xs={12} md={6}>
                  <SeasonsList
                    seasons={seasons}
                    handleChange={this.handleSeasonChange}
                    selectedSeason={selectedSeason || 0}
                  />
                </Grid>
              ) : (
                <NoSeasonContent />
              )}

              {seasons.length > 0 && (
                <Grid item xs={12} md={6}>
                  {teams.length > 0 ? (
                    <TeamsList
                      teams={teams}
                      selectedTeam={selectedTeam || 0}
                      handleChange={this.handleTeamChange}
                    />
                  ) : (
                    <NoTeamContent seasonId={selectedSeason} />
                  )}
                </Grid>
              )}
            </Grid>
          )}

          {notFoundTeamByID && seasons.length > 0 && teams.length > 0 && (
            <Typography>
              <Trans id="msg.rosters.team_not_found">
                Team with requested ID was not found, we display current season and first team
                instead
              </Trans>
            </Typography>
          )}

          {!loadingFilters && seasons.length > 0 && teams.length > 0 && (
            <React.Fragment>
              <AppBar elevation={0} style={{ position: 'static' }} color="default">
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label={
                      <Badge
                        color="primary"
                        invisible={loadingContent || playersCount === 0}
                        badgeContent={playersCount}
                        classes={{ badge: classes.badge }}
                      >
                        <Trans id="msg.rosters.tabs.members">Members</Trans>
                      </Badge>
                    }
                    disabled={loadingContent}
                  />
                  <Tab
                    label={
                      <Badge
                        color="secondary"
                        invisible={loadingContent || organizersCount === 0}
                        badgeContent={organizersCount}
                        classes={{ badge: classes.badge }}
                      >
                        <Trans id="msg.rosters.tabs.organizers">Organizers</Trans>
                      </Badge>
                    }
                    disabled={loadingContent}
                  />
                </Tabs>
              </AppBar>

              <div>
                <SwipeableViews
                  axis="x"
                  index={value}
                  onChangeIndex={this.handleChangeIndex}
                  // animateHeight
                  containerStyle={{ height: 'auto' }}
                >
                  <Card>
                    <CardContent>
                      <Whisperer
                        possibleMembers={possibleMembers}
                        rosterType="player"
                        addMembersToRoster={this.addMembersToRoster}
                        disabled={loadingContent}
                      />
                    </CardContent>
                    <CardContent>
                      <RosterTable
                        title={rosterTitleMembers}
                        rosterType="player"
                        members={players}
                        manageMemberVisibility={manageMemberVisibility}
                        toggleMemberVisibility={this.toggleMemberVisibility}
                        removeMemberFromRoster={this.removeMemberFromRoster}
                        setMemberToUpdate={this.setMemberToUpdate}
                        teamRestrictions={teamRestrictions}
                        loadingContent={loadingContent}
                      />
                    </CardContent>

                    {!loadingContent &&
                      (teamRestrictions.from || teamRestrictions.to || teamRestrictions.gender) && (
                        <CardContent>
                          <RosterMultiAdd
                            possibleMembers={possibleMembers}
                            addMembersToRoster={this.addMembersToRoster}
                          />
                        </CardContent>
                      )}
                  </Card>
                  <Card>
                    <CardContent>
                      <Whisperer
                        possibleMembers={possibleMembers}
                        rosterType="organizer"
                        addMembersToRoster={this.addMembersToRoster}
                      />
                    </CardContent>
                    <CardContent>
                      <RosterTable
                        title={rosterTitleManagement}
                        rosterType="organizer"
                        members={organizers}
                        manageMemberVisibility={manageMemberVisibility}
                        toggleMemberVisibility={this.toggleMemberVisibility}
                        removeMemberFromRoster={this.removeMemberFromRoster}
                        setMemberToUpdate={this.setMemberToUpdate}
                        teamRestrictions={teamRestrictions}
                        trainerRoles={trainerRoles}
                        loadingContent={loadingContent}
                      />
                    </CardContent>
                  </Card>
                </SwipeableViews>
              </div>
            </React.Fragment>
          )}
        </div>
        {memberToUpdate && (
          <RosterDialog
            member={memberToUpdate}
            opened={!!memberToUpdate}
            onClose={this.closeDialog}
            manageMemberVisibility={manageMemberVisibility}
            updateMemberRoster={this.updateMemberRoster}
            trainerRoles={trainerRoles}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Rosters);
