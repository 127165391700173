import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle, IconButton, Typography, Icon } from '@material-ui/core';

const CustomDialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: `0 0 ${theme.spacing.unit * 2}px`,
    padding: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 8,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export default CustomDialogTitle;
