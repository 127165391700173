import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';
import EosColorPicker from 'components/Forms/EosColorPicker';
import EosSelect from 'components/Forms/EosSelect';
import EosCheckbox from 'components/Forms/EosCheckbox';
import { eventTypesIcons } from 'data/staticDataConstants';
import { hexColor as hexColorRegExp } from 'utils/regexp';
import EventTypeIcon from './EventTypeIcon';

const EventTypeFormComponent = ({
  isSubmitting,
  errors,
  values,
  setFieldValue,
  // setFieldTouched,
  closeDialog,
  eventType,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          type="text"
          name="name"
          label={i18n._('msg.event_types.event_type_name_label')}
          required
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="icon"
          label={i18n._('msg.event_types.event_type_icon_label')}
          options={eventTypesIcons.map(i => ({
            icon: i.value,
            label: i18n._(i.label),
            value: i.value,
          }))}
          customVariant="withIconAtStart"
          component={EosSelect}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="color"
          placeholder="#ff6600"
          label={i18n._('msg.event_types.event_type_color_label')}
          component={EosColorPicker}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type="checkbox"
          name="has_attendance"
          label={i18n._('msg.event_types.event_type_has_attendance_label')}
          component={EosCheckbox}
          onChange={() => {
            setFieldValue('requires_excuse', false);
          }}
        />
        <Field
          type="checkbox"
          name="requires_excuse"
          label={i18n._('msg.event_types.event_type_requires_excuse_label')}
          component={EosCheckbox}
          disabled={!values.has_attendance}
          checked={() => {
            if (!values.has_attendance) {
              return false;
            }
            return values.requires_excuse;
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {/*
        params:
        size - size in px, icon has half size
        color - value of color picker
        symbolColor - icon symbol color overriding color contrast computing
        icon - selected icon from combo box
        variant - withShadow|withoutShadow - default is withoutShadow
        */}
        <EventTypeIcon
          // size={90}
          color={values.color}
          icon={values.icon}
          variant="withShadow"
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
          {isSubmitting ? (
            <Trans id="msg.saving_changes" />
          ) : eventType ? (
            <Trans id="msg.save_changes" />
          ) : (
            <Trans id="msg.event_types.add_new" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

EventTypeFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  eventType: PropTypes.object,
};

EventTypeFormComponent.defaultProps = {
  isSubmitting: false,
  eventType: null,
};

class EventTypeForm extends Component {
  validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
    icon: yup.string().required(i18n._('msg.form.required')),
    color: yup
      .string()
      .matches(hexColorRegExp, i18n._('msg.form.invalid_format'))
      .required(i18n._('msg.form.required')),
    has_attendance: yup.boolean().oneOf([true, false]),
    requires_excuse: yup.boolean().oneOf([true, false]),
  });

  initialValues = {
    name: '',
    icon: 'star',
    color: '#dedede',
    has_attendance: false,
    requires_excuse: false,
  };

  render() {
    const { eventType, closeDialog, handleSubmit } = this.props;
    return (
      <div>
        <Formik
          initialValues={eventType || this.initialValues}
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <EventTypeFormComponent
              {...formikProps}
              closeDialog={closeDialog}
              eventType={eventType}
            />
          )}
        />
      </div>
    );
  }
}

EventTypeForm.propTypes = {
  eventType: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

EventTypeForm.defaultProps = {
  eventType: null,
};

export default EventTypeForm;
