// import classnames from 'classnames';
// import { unflatten } from 'flat';
import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  DateTimePicker,
} from 'material-ui-pickers';
import PropTypes from 'prop-types';

import { get, set } from './helpers';

const EosDatepicker = ({
  // eslint-disable-next-line
  field: { name, value /* , ...field */ }, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue, setFieldTouched, setFieldError, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  // className,
  label,
  // onChange,
  // onBlur,
  keyboard,
  type,
  disabled,
  ...props
}) => {
  const error = get(errors, name);
  const touch = get(touched, name);

  // const classes = classnames(
  //   'form-group',
  //   {
  //     error: !!error && !!touch,
  //   },
  //   className
  // );

  // kindly stolen from here: https://github.com/jaredpalmer/formik/pull/207#issuecomment-336193634
  const handleChange = date => {
    const path = name.split('.');
    if (path.length > 1) {
      setFieldValue(path[0], set(path, date, values));
      setFieldTouched(path[0], true);
    } else {
      setFieldValue(path, date);
      setFieldTouched(path, true);
    }

    // setFieldValue([name], date);
    // setFieldTouched([name], true);
  };

  const handleError = () => {
    const path = name.split('.');
    if (path.length > 1) {
      setFieldError(path[0], set(path, error, errors));
      setFieldTouched(path[0], true);
    } else {
      setFieldValue(path, error);
      setFieldTouched(path, true);
    }
  };

  // const keyboardFlag = typeof keyboard === 'undefined' || keyboard === null ? true : keyboard;
  const commonProps = {
    name,
    value,
    label,
    // keyboard: keyboardFlag,
    // disableOpenOnEnter: keyboardFlag,
    keyboard,
    disableOpenOnEnter: keyboard,
    showTodayButton: true,
    helperText: error,
    error: !!error && !!touch,
    onChange: date => handleChange(date),
    onError: () => handleError(),
    disabled: isSubmitting || disabled,
    variant: 'outlined',
    margin: 'dense',
    fullWidth: true,
    // clearable: true,
  };

  const Picker = () => {
    switch (type) {
      case 'datetime':
        return (
          <DateTimePicker
            ampm={false}
            // onChange={handleChange}
            format="D. M. YYYY HH:MM"
            {...commonProps}
            {...props}
          />
        );
      case 'time':
        return (
          <TimePicker
            ampm={false}
            // onChange={handleChange}
            {...commonProps}
            {...props}
          />
        );
      default:
        return (
          <DatePicker
            // onChange={handleChange}
            format="D. M. YYYY"
            {...commonProps}
            {...props}
          />
        );
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Picker />
    </MuiPickersUtilsProvider>
  );
};

EosDatepicker.propTypes = {
  // form: PropTypes.object.isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  keyboard: PropTypes.bool,
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
  disabled: PropTypes.bool,
};

EosDatepicker.defaultProps = {
  keyboard: true,
  type: 'date',
  disabled: false,
};

export default EosDatepicker;
