import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import EosTextInput from 'components/Forms/EosTextInput';
import EosCheckbox from 'components/Forms/EosCheckbox';
import { dummyGroups } from 'data/staticDataConstants';
import EosSelect from 'components/Forms/EosSelect';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import ErrorMessage from 'components/Forms/ErrorMessage';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';

const RosterUpdateFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  resetForm,
  // seasons,
  // selectedSeason,
  manageMemberVisibility,
  defaultDressNumber,
  defaultPositionId,
  onClose,
  trainerRoles,
  isOrganizer,
  isPlayer,
}) => (
  <EosForm>
    <Grid container spacing={24}>
      {isOrganizer && (
        <Grid item xs={12}>
          <Field
            name="trainer_role_id"
            label={i18n._('msg.rosters.trainer_position_label')}
            options={trainerRoles.map(role => ({ label: role.name, value: role.id }))}
            component={EosSelect}
          />
        </Grid>
      )}

      {isPlayer && (
        <React.Fragment>
          {/* dress # */}
          <Grid item xs={12}>
            <Field
              type="number"
              name="dress_number"
              placeholder={defaultDressNumber ? defaultDressNumber.toString() : '13'}
              label={i18n._('msg.rosters.dress_number_label')}
              InputLabelProps={{
                min: 1,
                max: 99,
              }}
              component={EosTextInput}
            />
            {defaultDressNumber && (
              <Typography variant="body1" component="small">
                Player's default number from his profile is {defaultDressNumber}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Field
              name="position_id"
              label={i18n._('msg.rosters.position_label')}
              options={dummyGroups}
              component={EosSelect}
            />
            {defaultPositionId && (
              <Typography variant="body1" component="small">
                Player's default position from his profile is {defaultPositionId}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Field
              type="checkbox"
              name="show_on_web"
              label={i18n._('msg.rosters.web_visibility_label')}
              disabled={!manageMemberVisibility}
              // checked={values.show_on_web}
              component={EosCheckbox}
            />
          </Grid>
        </React.Fragment>
      )}
      {0 && Object.keys(errors).length > 0 ? (
        <Grid item xs={12}>
          <ErrorMessage>{errors}</ErrorMessage>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <SubmitArea align="left">
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
          </Button>{' '}
          <Button variant="contained" color="secondary" disabled={isSubmitting} onClick={onClose}>
            <Trans id="msg.close" />
          </Button>
        </SubmitArea>
      </Grid>
    </Grid>
  </EosForm>
);
RosterUpdateFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  // seasons: PropTypes.array,
  manageMemberVisibility: PropTypes.bool,
  defaultDressNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultPositionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  trainerRoles: PropTypes.array,
  isOrganizer: PropTypes.bool,
  isPlayer: PropTypes.bool,
};

RosterUpdateFormComponent.defaultProps = {
  isSubmitting: false,
  // seasons: [],
  manageMemberVisibility: false,
  defaultDressNumber: '',
  defaultPositionId: '',
  trainerRoles: [],
  isOrganizer: false,
  isPlayer: false,
};

class RosterUpdateForm extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    updateMemberRoster: PropTypes.func.isRequired,
    manageMemberVisibility: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    trainerRoles: PropTypes.array.isRequired,
  };

  validationSchema = yup.object().shape({
    dress_number: yup
      .number()
      .min(1, i18n._('msg.form.min_number', { min_number: 0 }))
      .max(99, i18n._('msg.form.max_number', { max_number: 0 }))
      .nullable(),
    position_id: yup.number().oneOf(dummyGroups.map(g => g.value)),
    show_on_web: yup.boolean(),
  });

  render() {
    const {
      member,
      updateMemberRoster,
      manageMemberVisibility,
      onClose,
      trainerRoles,
    } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            dress_number: member.dress_number || '',
            show_on_web: !!member.show_on_web,
            position_id: member.position_id || '',
            trainer_role_id: member.trainer_role_id || '',
          }}
          validationSchema={this.validationSchema}
          onSubmit={values => updateMemberRoster(member.id, values)}
          // component={TeamCreateFormComponent}
          render={formikProps => (
            <RosterUpdateFormComponent
              {...formikProps}
              member={member}
              manageMemberVisibility={manageMemberVisibility}
              defaultDressNumber={member.default_dress_number}
              defaultPositionId={member.default_position_id}
              trainerRoles={trainerRoles}
              onClose={onClose}
              isPlayer={member.is_player}
              isOrganizer={member.is_organizer}
            />
          )}
        />
      </div>
    );
  }
}

RosterUpdateForm.propTypes = {};

export default RosterUpdateForm;
