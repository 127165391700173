import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Fab, /* Icon, IconButton, */ Link, Typography } from '@material-ui/core';
import { i18n } from 'App.js';
import { Icon as IconMDI } from '@mdi/react';
import { mdiCloudQuestion } from '@mdi/js';
import BreadCrumbs from './BreadCrumbs';
import HeaderImage from './HeaderImage';

const StyledPageHeader = styled.div`
  background: white;
  padding: 16px 24px;
  position: sticky;
  z-index: 2;
  margin: -24px -24px 24px;
  top: -24px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
`;

const StyledPageHeaderInner = styled.div`
  flex: 1 0 auto;
`;

const StyledPageHeaderMainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledPageHeaderSecondaryRow = styled.div`
  // border: 1px solid red;
  margin-top: 10px;
`;

const StyledCustomControlsPrimary = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;

    &,
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledCustomControlsSecondary = styled.div`
  // background: pink;
`;

const StyledPageTitle = styled(({ variant, ...rest }) => (
  <Typography variant={variant || 'h1'} {...rest} />
))`
  && {
    font-size: 24px;
    font-weight: 500;
  }
`;

const StyledControlComponent = styled.div`
  margin: 0 5px;

  &:first-child {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 10px;
  }

  ${StyledCustomControlsSecondary} & {
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const PageHeader = ({
  title,
  breadcrumbsItems,
  helpLink,
  customControlsPrimary,
  customControlsSecondary,
  profilePhotoId,
}) => (
  <StyledPageHeader>
    {profilePhotoId ? <HeaderImage imageId={profilePhotoId} /> : null}
    <StyledPageHeaderInner>
      {breadcrumbsItems.length > 0 && <BreadCrumbs items={breadcrumbsItems} />}

      <StyledPageHeaderMainRow>
        <StyledPageTitle>{title}</StyledPageTitle>

        {(customControlsPrimary.length > 0 || (helpLink && helpLink.url)) && (
          <StyledCustomControlsPrimary>
            {customControlsPrimary.length > 0 &&
              customControlsPrimary.map((control, index) => (
                <StyledControlComponent key={index}>{control}</StyledControlComponent>
              ))}
            {helpLink && helpLink.url && (
              <React.Fragment>
                {/* <IconButton
                  component={Link}
                  color="primary"
                  href={helpLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={helpLink.title || i18n._('msg.help.default_title')}
                  aria-label={helpLink.title || i18n._('msg.help.default_title')}
                >
                  <Icon>help_outline</Icon>
                </IconButton> */}
                <Fab
                  size="small"
                  color="primary"
                  aria-label={helpLink.title || i18n._('msg.help.default_title')}
                  component={Link}
                  href={helpLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={helpLink.title || i18n._('msg.help.default_title')}
                >
                  {/* <Icon>help</Icon> */}
                  <IconMDI path={mdiCloudQuestion} size={1} color="#fff" />
                </Fab>
              </React.Fragment>
            )}
          </StyledCustomControlsPrimary>
        )}
      </StyledPageHeaderMainRow>
      {customControlsSecondary.length > 0 && (
        <StyledPageHeaderSecondaryRow>
          <StyledCustomControlsSecondary>
            {customControlsSecondary.length > 0 &&
              customControlsSecondary.map((control, index) => (
                <StyledControlComponent key={index}>{control}</StyledControlComponent>
              ))}
          </StyledCustomControlsSecondary>
        </StyledPageHeaderSecondaryRow>
      )}
    </StyledPageHeaderInner>
  </StyledPageHeader>
);

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  breadcrumbsItems: PropTypes.array,
  helpLink: PropTypes.object,
  customControlsPrimary: PropTypes.array,
  customControlsSecondary: PropTypes.array,
  profilePhotoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PageHeader.defaultProps = {
  title: '',
  breadcrumbsItems: [],
  helpLink: {
    url: '//example.com',
    title: 'HELP page',
  },
  customControlsPrimary: [],
  customControlsSecondary: [],
  profilePhotoId: null,
};

export default PageHeader;
