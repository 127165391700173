/* eslint-disable */
import { Field, Formik } from "formik";
import React, { Component } from "react";
import * as yup from "yup";
import * as moment from "moment";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider/Divider";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography";
import EosCheckbox from "components/Forms/EosCheckbox.js";
import EosForm, { SubmitArea } from "components/Forms/EosForm";
import EosRichText from "components/Forms/EosRichText";
import EosSelect from "components/Forms/EosSelect";
import EosTextInput from "components/Forms/EosTextInput";
import ErrorMessage from "components/Forms/ErrorMessage";
import EosAutocomplete from "components/Forms/EosAutocomplete";
import EosRadio from "components/Forms/EosRadio";
import EosDatepicker from "components/Forms/EosDatepicker";
import SubmitButton from "components/Forms/SubmitButton";

import ImageUploader from "components/ImageUploader";

import { ImageService } from "../api/image.service";
import ImageDisplay from "../components/Base/ImageDisplay";

const selectOptions = [
  { value: 1, label: "Option no. 1" },
  { value: 2, label: "Option no. 2" },
  { value: 3, label: "Option no. 3" },
  { value: 4, label: "Option no. 4" }
];

const radioOptions = [
  { value: "1", label: "Option no. 1" },
  { value: "2", label: "Option no. 2" },
  { value: "3", label: "Option no. 3", disabled: true },
  { value: "4", label: "Option no. 4" }
];

const alternativeInitialValues = {
  email: "dev@eosmedia.cz",
  password: "123456",
  password_confirm: "123456",
  checkMe: true,
  checkMe2: false,
  radios: "1",
  custom_select_single: selectOptions[0].value,
  custom_select_multiple: [selectOptions[1].value, selectOptions[2].value],
  phone: "777123987",
  richtext: "<strong>Hello</strong>, world!",
  imageId: null
};

const alternativeInitialValues2 = {
  email: "aaa@bbb.ccc",
  password: "123123",
  password_confirm: "123123",
  checkMe: false,
  checkMe2: true,
  radios: "2",
  custom_select_single: selectOptions[1].value,
  custom_select_multiple: [selectOptions[0].value, selectOptions[3].value],
  phone: "605987123",
  richtext: "<h1>Hello</h1>",
  imageId: null
};


const FormComponent = ({
  isSubmitting,
  values,
  errors,
  resetForm,
  setFieldValue,
  setFieldTouched,
  acceptUploadedImageId
}) => (
  <EosForm noValidate>
    <div style={{ margin: 50, width: 220 }}>
      <ImageUploader
        multiple={false}
        width={480}
        height={640}
        acceptedFileTypes={['jpeg', 'png']}
        acceptUploadedImageId={acceptUploadedImageId}
        currentImageId={2}
        // maxSize={2*1024}
      />
    </div>
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6}>
        <Field
          type="email"
          name="email"
          label="E-mail"
          autoComplete="off"
          required
          icon="User"
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type="email"
          name="email_disabled"
          label="E-mail"
          autoComplete="off"
          disabled
          icon="User"
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field type="password" name="password" label="Password" component={EosTextInput}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type="password"
          name="password_confirm"
          label="Password confirm"
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          type="checkbox"
          name="checkMe"
          label="Check me if you want"
          groupLabel="Checkbox upper label"
          // checked={values.checkMe}
          component={EosCheckbox}
        />
        <Divider/>
        <Field
          type="checkbox"
          name="checkMe2"
          label="Check me if you want 2"
          disabled
          // checked={values.checkMe2}
          component={EosCheckbox}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="radios"
          groupLabel="Radio group"
          options={radioOptions}
          component={EosRadio}
          // disabled // {true|false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          name="custom_select_single"
          // value={values.custom_select_single}
          label="Single select"
          // onChange={setFieldValue}
          // onBlur={setFieldTouched}
          options={selectOptions}
          component={EosSelect}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          name="custom_select_multiple"
          // value={values.custom_select_multiple}
          label="Multiple select"
          // onChange={setFieldValue}
          // onBlur={setFieldTouched}
          options={selectOptions}
          component={EosSelect}
          multiple
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          name="autocomplete"
          label="Autocomplete test"
          options={selectOptions}
          isMulti
          component={EosAutocomplete}
        />
      </Grid>
      <Grid item xs={12}>
        <Field type="text" name="phone" label="Phone number" component={EosTextInput}/>
      </Grid>
      <Grid item xs={12}>
        <Field
          name="richtext"
          label="Rich text"
          component={EosRichText}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Field
          // disabled
          name="datepicker"
          type="date"
          label="Date Picker"
          format="DD. M. YYYY"
          placeholder={`${moment().format("DD. M. YYYY")}`}
          component={EosDatepicker}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Field
          // disabled
          name="timepicker"
          type="time"
          label="Time Picker"
          format="HH:mm"
          placeholder={`${moment().format("HH:mm")}`}
          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
          component={EosDatepicker}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Field
          // disabled
          name="datetimepicker"
          type="datetime"
          label="Date & Time Picker"
          format="DD. M. YYYY HH:mm"
          placeholder={`${moment().format("DD. M. YYYY HH:mm")}`}
          component={EosDatepicker}
        />
      </Grid>
    </Grid>

    {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}

    <SubmitArea isSubmitting={isSubmitting}>
      <SubmitButton
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
      >
        {isSubmitting ? "Loading" : "Submit action"}
      </SubmitButton>{" "}
      <Button
        color="secondary"
        variant="contained"
        type="reset"
        disabled={isSubmitting}
        onClick={() => {
          resetForm();
        }}
      >
        Reset
      </Button>
    </SubmitArea>
  </EosForm>
);

FormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  acceptUploadedImageId: PropTypes.func,
};

FormComponent.defaultProps = {
  isSubmitting: false,
  acceptUploadedImageId: null,
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  password: yup
    .string()
    .min(6, "Password has to be longer than 6 characters!")
    .required("Password is required!"),
  password_confirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required("Confirm Password is required"),
  radios: yup
    .string()
    .oneOf(["1", "3"], "Please select values from given options only (${values})")
    .required("You must choose value"),
  checkMe: yup.boolean().oneOf([true], "You must check this awesome checkbox"),
  custom_select_single: yup
    .number()
    .oneOf([1, 2, 3], "Please select only accepted values(${values})")
    .nullable()
    .required("Select something from single selectbox!"),
  custom_select_multiple: yup
    .array()
    .min(2, "Please select at least ${min} option(s)")
    .of(yup.number())
    .required("Select something from multi selectbox!"),
  phone: yup
    .string()
    .phone("Insert valid phone number!")
    .required("Phone is required!")
});

class Playground extends Component {
  static propTypes = {};

  state = { data: null, fetchedImage: null, uploadedImageId: null };

  initialValues = {
    email: "",
    password: "",
    password_confirm: "",
    checkMe: false,
    checkMe2: false,
    radios: "1",
    custom_select_single: "",
    custom_select_multiple: [],
    phone: "",
    richtext: "",
    imageId: null
  };

  handleSubmit = (values, actions) => {
    const { uploadedImageId } = this.state;
    let valuesToSend = { ...values };
    if (uploadedImageId) {
      valuesToSend.imageId = uploadedImageId;
    }
    console.log({ values: valuesToSend });

    setTimeout(() => {
      actions.setSubmitting(false);
    }, 2000);
  };

  acceptUploadedImageId = (uploadedImageId) => {
    this.setState({
      uploadedImageId,
    });
  };

  fetchSingleImage = async imageId => {
    const image = await ImageService.get(imageId); //.then( response => console.log({response}), e => console.log({e}));

    if (image) {
      this.setState({
        fetchedImage: image
      });
    }
  };

  fetchSingleImageWithParams = async (imageId, params) => {
    const image = await ImageService.get(imageId, params); //.then( response => console.log({response}), e => console.log({e}));

    if (image) {
      this.setState({
        fetchedImage: image
      });
    }
  };

  render() {
    const { data, uploadedImageId } = this.state;
    console.log(this.initialValues);
    return (
      <div style={{ overflow: 'auto' }}>
        <Helmet>
          <title>Forms playground</title>
        </Helmet>
        <Typography variant="h1" gutterBottom>
          Form playground
        </Typography>
        <Paper elevation={0} square color="inherit">
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              e.preventDefault();
              this.setState({ data: alternativeInitialValues });
            }}
          >
            Change dataset
          </Button>{" "}
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              e.preventDefault();
              this.setState({ data: alternativeInitialValues2 });
            }}
          >
            Change dataset
          </Button>

          <div>
            <Button onClick={() => this.fetchSingleImage(1)}>Load image 1 (png)</Button>
            <Button onClick={() => this.fetchSingleImage(4)}>Load image 4 (png)</Button>
            <Button onClick={() => this.fetchSingleImage(8)}>Load image 8 (jpeg)</Button>
            <Button onClick={() => this.fetchSingleImage(9)}>Load image 9 (jpeg)</Button>
            <Button onClick={() => this.fetchSingleImageWithParams(9, {fit_canvas: '450x300xf60'})}>Load image 9 (jpeg, 450x300, #ff6600)</Button>
            <Button onClick={() => this.fetchSingleImageWithParams(9, {resize: '450x300'})}>Load image 9 (jpeg, 450x300)</Button>
            <div>
              {this.state.fetchedImage && <ImageDisplay file={this.state.fetchedImage}/>}
            </div>
          </div>

        </Paper>

        <Paper>
          <Typography variant="h1">Form:</Typography>
          <Formik
            initialValues={data || this.initialValues}
            enableReinitialize
            onSubmit={this.handleSubmit}
            validationSchema={validationSchema}
            render={formikProps => (
              <FormComponent {...formikProps} acceptUploadedImageId={this.acceptUploadedImageId} />
            )}
          />
        </Paper>
      </div>
    );
  }
}

export default Playground;
