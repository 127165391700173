/**
 * @class {DataGridState}
 * @property {number} page
 * @property {number} rowsPerPage
 * @property {object} sortBy
 * @property {array.<string>} visibleColumns
 * @property {array.<object>} customFilters
 * @property {number} count
 * @property {array.<object>} count
 */
import { PaginatedData } from './paginated.data.model';

export class DataGridState {
  /**
   * @param {object=} defaults
   */
  constructor(defaults = {}) {
    this.page = defaults.page || 0;
    this.rowsPerPage = defaults.rowsPerPage || 5;
    this.columns = defaults.columns || [];
    this.sortBy = defaults.sortBy || null;
    this.visibleColumns = defaults.visibleColumns || [];
    this.customFilters = defaults.customFilters || [];
    this.count = defaults.count || 0;
    this.data = defaults.data || null;
  }

  /**
   * @return {{items_per_page: number, filters: Array<Object>, page: number, sort: string}}
   */
  getQueryParams() {
    return {
      items_per_page: this.rowsPerPage,
      filters: this.customFilters,
      page: this.page,
      sort: this.sortBy,
    };
  }

  /**
   * @param {PaginatedData} paginatedData
   * @param {object} previousState
   * @return {DataGridState}
   */
  static fromPaginatedResponse(paginatedData, previousState = null) {
    return new DataGridState({
      ...(previousState || {}),
      page: paginatedData.meta.current_page,
      count: paginatedData.meta.item_count,
      rowsPerPage: paginatedData.meta.items_per_page,
      data: paginatedData.data,
    });
  }

  /**
   * @param {object} response
   * @param {object} previousState
   * @return {DataGridState}
   */
  static fromResponse(response, previousState = null) {
    if (response instanceof PaginatedData) {
      return this.fromPaginatedResponse(response, previousState);
    }

    return new DataGridState({
      ...(previousState || {}),
      data: response,
    });
  }
}
