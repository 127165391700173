import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import blue from '@material-ui/core/colors/blue';

// eslint-disable-next-line
const CustomizedLink = styled(({ bold, noWrap, ...rest }) => <Link {...rest} />)`
  /* color: ${props => props.theme.club.link_color}; */
  color: ${blue[400]};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  font-weight: ${props => (props.bold ? 500 : 400)};

  &:hover {
    text-decoration: none;
  }
`;
CustomizedLink.propTypes = {
  bold: PropTypes.bool,
  noWrap: PropTypes.bool,
};

CustomizedLink.defaultProps = {
  bold: false,
  noWrap: false,
};

export default CustomizedLink;
