import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { i18n } from 'App.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import ErrorMessage from 'components/Forms/ErrorMessage';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import StandaloneCard from 'components/StandaloneCard';
import EosDatepicker from 'components/Forms/EosDatepicker';
import * as moment from 'moment';

const RegistrationFormComponent = ({ isSubmitting, errors }) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          name="first_name"
          label={i18n._('msg.general.first_name_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="last_name"
          label={i18n._('msg.general.last_name_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type="date"
          name="birthdate"
          label={i18n._('msg.general.date_of_birth_label')}
          component={EosDatepicker}
          format="D. M. YYYY"
          disableFuture
          placeholder={`${moment().format('D. M. YYYY')}`}
          openTo="year"
          views={['year', 'month', 'day']}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="contact_email"
          label={i18n._('msg.general.email_label')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="phone_number"
          label={i18n._('msg.general.phone_number_label')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="comment"
          label={i18n._('msg.comment_label')}
          component={EosTextInput}
          multiline
          rows={5}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="center" noBottomPadding>
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        {isSubmitting ? <Trans id="msg.sending" /> : <Trans id="msg.send" />}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

RegistrationFormComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

class RegistrationComponent extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    registrationDone: PropTypes.bool.isRequired,
  };

  validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
    last_name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
    birthdate: yup
      .date(
        'format',
        i18n._('msg.form.invalid_format_with_helper', {
          helper_string: `17. 2. ${new Date().getFullYear()}`,
        })
      )
      .max(new Date(), i18n._('msg.form.data_past'))
      .required(i18n._('msg.form.required')),
    contact_email: yup
      .string()
      .email(i18n._('msg.form.invalid_email'))
      .required(i18n._('msg.form.required')),
    phone_number: yup.string().phone(i18n._('msg.form.invalid_format')),
    comment: yup
      .string()
      .transform(value => (value.toString().length ? value : null))
      .nullable(),
  });

  render() {
    const { handleSubmit, registrationDone } = this.props;
    return (
      <StandaloneCard title={i18n._('msg.registration.page_title')}>
        <CardContent>
          {registrationDone ? (
            <Typography>
              <Trans id="msg.registration.confirm_message">
                Your data were successfully sent to club. Please wait, it has to be processed ;)
              </Trans>
            </Typography>
          ) : (
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                birthdate: moment(),
                contact_email: '',
                phone_number: '',
                comment: '',
              }}
              validationSchema={this.validationSchema}
              onSubmit={handleSubmit}
              render={formikProps => <RegistrationFormComponent {...formikProps} />}
            />
          )}
        </CardContent>
      </StandaloneCard>
    );
  }
}

export default RegistrationComponent;
