import { apiCall } from '../utils/apiCall';
import { User } from '../models/User';
import { Club } from '../models/Club';

/**
 * @static
 */
export class CurrentService {
  static userAndClub() {
    return apiCall.get('/auth/current').then(response =>
      // console.log({ response });
      ({
        user: new User(response.data),
        club: new Club(response.data.club),
      })
    );
  }

  /**
   * @public
   * @return {Promise<User>}
   */
  static user() {
    return apiCall.get('/auth/current').then(
      response =>
        // console.log({ 'CurrentService.user() response': response });
        new User(response.data)
    );
  }

  /**
   * @public
   * @return {Promise<User>}
   */
  static switchActiveUser(userId) {
    return apiCall.put('/auth/current', { active_member: userId }).then(response => {
      console.log(`CurrentService.switchActiveUser(${userId}) response`, { response });
      return new User(response.data);
    });
  }

  /**
   * @public
   * @return {Promise<Club>}}
   */
  static club() {
    return apiCall.get('/auth/current').then(response => new Club(response.data.club));
  }

  /**
   * @returns {Promise<void | never>}
   */
  static modules() {
    return apiCall.get('/modules').then(response => {
      const rawModulesObject = response.data.data;
      return {
        management: Object.keys(rawModulesObject.management).map(key => ({
          value: key,
          label: rawModulesObject.management[key],
        })),
        web: Object.keys(rawModulesObject.web).map(key => ({
          value: key,
          label: rawModulesObject.web[key],
        })),
      };
    });
  }
}
