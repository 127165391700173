import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { get } from './helpers';

const EosRadio = ({
  field: { name, value }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  className,
  groupLabel,
  options,
  disabled,
  inline,
  onChange,
  value: manualValue,
  // ...props
}) => {
  const error = get(errors, name);
  const touch = get(touched, name);

  const classes = classnames(
    'form-group',
    {
      error: !!error && !!touch,
    },
    className
  );

  return (
    <FormControl error={!!error} className={classes} disabled={isSubmitting || disabled}>
      {groupLabel && <FormLabel>{groupLabel}</FormLabel>}
      <RadioGroup
        name={name}
        value={manualValue || value}
        onChange={evt => {
          // TODO: check if works everywhere
          setFieldValue(name, evt.target.value);
          setFieldTouched(name, true);
          if (onChange) {
            onChange(evt.target.value);
          }
        }}
        style={{ flexDirection: inline ? 'row' : 'column' }}
      >
        {options.map(o => (
          <FormControlLabel
            key={o.value}
            disabled={
              disabled || isSubmitting || Object.keys(o).indexOf('disabled') > -1
                ? o.disabled
                : null
            }
            control={<Radio />}
            label={o.label}
            value={o.value}
          />
        ))}
      </RadioGroup>
      {touch && error && <FormHelperText>{String(error)}</FormHelperText>}
    </FormControl>
  );
};

EosRadio.propTypes = {
  // checked: PropTypes.bool,
  // label: PropTypes.string,

  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  groupLabel: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EosRadio.defaultProps = {
  // checked: false,
  // label: '',
  className: '',
  groupLabel: '',
  disabled: false,
  inline: false,
  onChange: null,
  value: null,
};

export default EosRadio;
