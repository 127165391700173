import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik } from 'formik';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Grid } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import ErrorMessage from 'components/Forms/ErrorMessage';
import ImageUploader from 'components/ImageUploader';

const MemberFormTempComponent = ({
  isSubmitting,
  errors,
  // values,
  setFieldValue,
  // setFieldTouched,
  requestedImageSize,
  // acceptUploadedImageId,
  currentImageId,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <ImageUploader
          multiple={false}
          width={requestedImageSize.width}
          height={requestedImageSize.height}
          acceptedFileTypes={['jpeg', 'png']}
          acceptUploadedImageId={newUploadedImageId => {
            setFieldValue('image_id', newUploadedImageId);
          }}
          currentImageId={currentImageId}
          // maxSize={2*1024}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="left">
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

MemberFormTempComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  // values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
  requestedImageSize: PropTypes.object.isRequired,
  // acceptUploadedImageId: PropTypes.func,
  currentImageId: PropTypes.number,
};

MemberFormTempComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
  // acceptUploadedImageId: null,
  currentImageId: null,
};

class MemberFormTemp extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    requestedImageSize: PropTypes.object.isRequired,
    // acceptUploadedImageId: PropTypes.func,
    // currentImageId: PropTypes.number,
  };

  state = {
    memberPartialData: null,
    // acceptUploadedImageId: null,
    // currentImageId: null,
  };

  componentDidMount() {
    const {
      // eslint-disable-next-line
      member: { image_id },
    } = this.props;
    const memberPartialData = {
      image_id,
    };

    this.setState({
      memberPartialData,
    });
  }

  render() {
    const { handleSubmit, requestedImageSize } = this.props;
    const { memberPartialData } = this.state;

    if (!memberPartialData) {
      return null;
    }

    return (
      <div>
        <Formik
          initialValues={memberPartialData}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            // internal info
            image_id: yup.number(),
          })}
          render={formikProps => (
            <MemberFormTempComponent
              {...formikProps}
              requestedImageSize={requestedImageSize}
              // acceptUploadedImageId={acceptUploadedImageId}
              currentImageId={memberPartialData.image_id}
            />
          )}
        />
      </div>
    );
  }
}

export default MemberFormTemp;
