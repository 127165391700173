/**
 * @property {int} id
 * @property {string} name
 * @property {string} color
 * @property {boolean} has_attendance
 * @property {boolean} requires_excuse
 * @property {string} icon
 * @property {boolean} is_protected
 * @property {boolean|null} is_constrained
 */
export class EventType {
  /**
   * @param {object} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.color = data.color || '';
    this.has_attendance = data.has_attendance || false;
    this.requires_excuse = data.requires_excuse || false;
    this.icon = data.icon;
    this.is_protected = data.is_protected;
    this.is_constrained = typeof data.is_constrained !== 'undefined' ? data.is_constrained : null;
  }

  static formatFormData(data) {
    return {
      name: data.name,
      color: data.color,
      has_attendance: data.has_attendance,
      requires_excuse: data.requires_excuse,
      icon: data.icon,
    };
  }
}
