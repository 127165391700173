import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import 'react-image-crop/dist/ReactCrop.css';
import classNames from 'classnames';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { ImageService } from 'api/image.service';
import styled from 'styled-components';

const ParentComponent = styled.div`
  border: 1px solid ${props => (props.isCroppedAreaEnough ? green[400] : red[400])};
  border-radius: 4px;
  padding: 8px;
  max-width: ${props => `${props.width}px`};
  margin-top: ${props => (props.variant === 'withMargin' ? '8px' : 0)};
`;

const ImageUploaderWrapper = styled.div`
  width: 100%;
  padding-bottom: calc(${props => props.height / props.width} * 100%);
  position: relative;
`;

const ImageUploaderComponent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DropzoneInnerComponent = styled.div`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      
      ${props =>
        props.loading && {
          opacity: 0.5,
        }}
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const WarningIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 14px;
  color: ${red[400]};
`;

const ImageDisplay = styled.img`
  width: 100%;
  display: block;
  margin: 0;
`;

const ImageUploaderError = styled(Typography)`
  && {
    color: ${red[400]};
  }
`;

const Loading = () => (
  <LoadingOverlay>
    <CircularProgress color="secondary" />
  </LoadingOverlay>
);

class ImageUploader extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    multiple: PropTypes.bool,
    acceptedFileTypes: PropTypes.array,
    maxSize: PropTypes.number,
    acceptUploadedImageId: PropTypes.func,
    currentImageId: PropTypes.number,
    variant: PropTypes.oneOf(['withMargin', 'withoutMargin']),
  };

  static defaultProps = {
    multiple: false,
    acceptedFileTypes: [
      'png',
      'jpeg',
      'gif',
      'bmp',
      'webp',
      'x-icon',
      'x-ms-bmp',
      'svg',
      'svg+xml',
    ],
    maxSize: 10 * 1024 * 1024, // 10MB file size
    acceptUploadedImageId: null,
    currentImageId: null,
    variant: 'withMargin',
  };

  state = {
    // provided file info
    file: null,
    errorState: null,
    dialogOpened: false,
    requestedSize: {
      width: null,
      height: null,
    },
    isCroppedAreaEnough: true,
    crop: {},
    uploadedImage: null,
    loading: false,
  };

  componentDidMount() {
    const { width, height, currentImageId } = this.props;

    // toBlob polyfill
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value(callback, type, quality) {
          const dataURL = this.toDataURL(type, quality).split(',')[1];
          setTimeout(() => {
            const binStr = atob(dataURL);

            const len = binStr.length;

            const arr = new Uint8Array(len);

            for (let i = 0; i < len; i += 1) {
              arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        },
      });
    }

    this.setState(
      {
        requestedSize: {
          width,
          height,
          aspect: width / height,
        },
      },
      () => {
        if (currentImageId) {
          this.fetchCurrentImage(currentImageId);
        }
      }
    );
  }

  fetchCurrentImage = async currentImageId => {
    const { requestedSize } = this.state;

    this.setState({
      loading: true,
    });

    const currentImage = await ImageService.get(currentImageId);
    this.setState({
      currentImage,
      currentImageId,
      isCroppedAreaEnough:
        currentImage.width >= requestedSize.width && currentImage.height >= requestedSize.height,
      loading: false,
    });
  };

  onDrop = (accepted, rejected) => {
    const { crop, requestedSize } = this.state;

    console.log({ accepted, rejected });

    // scenario when user posted:
    // 1) multiple images or
    // 2) unsupported file type
    // 3) large file
    if (accepted.length === 0 && rejected.length > 0) {
      let errorState;

      if (rejected.length === 1) {
        const { maxSize } = this.props;
        if (maxSize && rejected[0].size > maxSize) {
          errorState = 'fileTooLarge';
        } else {
          errorState = 'unsupportedFileType';
        }
      }

      if (rejected.length > 1) {
        errorState = 'singleImageOnly';
      }

      this.setState({
        file: null,
        crop: {},
        errorState,
      });

      return false;
    }

    // is there any file?
    // TODO: add support for multiple images at once, if needed
    // const { multiple } = this.props;

    const fileSource = accepted[0];

    // placeholder variable for file content
    let base64ImageData = null;

    // new file reader
    const reader = new FileReader();

    // read as base64 encoded data, not used now
    // reader.readAsDataURL(fileSource);

    // read as binary data
    reader.readAsBinaryString(fileSource);

    // onLoad callback runs when file data are retrieved
    reader.onload = () => {
      // convert raw data to base64 form
      base64ImageData = `data:${fileSource.type};base64,${btoa(reader.result)}`;

      // create new image to get image dimensions in px
      const imageElement = new Image();
      imageElement.src = base64ImageData;

      // after img tag / element is created and loaded,
      // we have access to dimensions, not earlier
      imageElement.onload = () => {
        // create new state object
        const newFile = {
          type: fileSource.type, // image type
          size: fileSource.size, // image size (data)
          name: fileSource.name.substr(0, fileSource.name.lastIndexOf('.')) || fileSource.name, // image name
          preview: base64ImageData, // image content - base64 encoded
          width: imageElement.width, // image width in px
          height: imageElement.height, // image height in px
        };

        // extend crop info
        const newCrop = {
          ...crop,
          // new aspect, depends on props provided from parent component
          aspect: requestedSize.width / requestedSize.height,
          x: 0, // position of cropped area - x, by default at x: 0
          y: 0, // position of cropped area - y, by default at y: 0
        };

        // set default crop area
        if (requestedSize.width > requestedSize.height) {
          // landscape area
          newCrop.width = 100;
          // newCrop.height = 100 * (width / height);
        } else if (requestedSize.width < requestedSize.height) {
          // portrait area
          newCrop.height = 100;
          // newCrop.width = 100 * (width / height);
        } else {
          // square area
          newCrop.width = 100;
          newCrop.height = 100;
        }

        this.setState({
          file: newFile,
          errorState: null,
          dialogOpened: true,
          crop: newCrop,
          // crop: {
          //   ...crop,
          //   aspect: width / height,
          //   width: (width / height) * newFile.width,
          //   height: (width / height) * newFile.height,
          // },
        });
      };
    };
  };

  closeCropper = () => {
    this.setState({
      dialogOpened: false,
    });
  };

  getCroppedImg = (image, pixelCrop, fileType, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // deprecated canvas to base64 encode, problems with decoding
    // return canvas.toDataURL(fileType);

    // toBlob - only modern browsers, used polyfill in componentDidMount
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        // returns image data prepared for preview,
        // resolve(this.fileUrl);

        // we return raw data, preview is generated in Dropzone preview
        resolve({ data: blob, preview: this.fileUrl });
      }, fileType);
    });
  };

  makeClientCrop = async (crop, pixelCrop) => {
    const { file } = this.state;

    if (this.imageRef && crop.width && crop.height) {
      const result = await this.getCroppedImg(this.imageRef, pixelCrop, file.type, file.name);
      // TODO: change to save data to "file" state object
      this.setState({
        file: {
          ...file,
          croppedData: result.data,
          croppedPreview: result.preview,
        },
      });
    }
  };

  // fired when cropper loads image provided
  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;

    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;

    if (crop.aspect && crop.height && crop.width) {
      // this.setState({
      //   // crop: { ...crop, height: null },
      // });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }

    this.checkCropSize();
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop }, () => {
      this.checkCropSize();
    });
  };

  checkCropSize = () => {
    const { isCroppedAreaEnough, requestedSize, crop, file } = this.state;

    const actualSize = {
      width: Math.round(file.width * (crop.width / 100)),
      height: Math.round(file.height * (crop.height / 100)),
    };
    const result =
      actualSize.width >= requestedSize.width && actualSize.height >= requestedSize.height;

    if (isCroppedAreaEnough !== result) {
      this.setState({
        isCroppedAreaEnough: result,
      });
    }
  };

  clearFile = () => {
    const { acceptUploadedImageId } = this.props;

    this.setState(
      {
        file: null,
        isCroppedAreaEnough: true,
        uploadedImage: null,
        currentImage: null,
        currentImageId: null,
      },
      () => {
        acceptUploadedImageId(null);
        this.closeCropper();
      }
    );
  };

  storeImage = async (image /* , crop */) => {
    const { acceptUploadedImageId } = this.props;
    const { type, name /* size */ } = image;
    const headers = {
      'Content-Type': type,
      // "Content-Length": size
      'X-Image-name': name,
    };

    this.setState({
      loading: true,
    });

    await ImageService.create(image.croppedData, headers).then(
      response => {
        // console.log({ createImageResponse: { response } });
        if (response) {
          this.setState(
            {
              uploadedImage: response,
              loading: false,
            },
            () => {
              acceptUploadedImageId(response.id);
            }
          );
        }
      },
      error => {
        this.setState({
          loading: false,
        });
        console.log({ error });
      }
    );
  };

  render() {
    const {
      file,
      errorState,
      dialogOpened,
      crop,
      requestedSize: { width, height },
      isCroppedAreaEnough,
      loading,
      uploadedImage,
      currentImage,
      currentImageId,
    } = this.state;
    const { multiple, acceptedFileTypes, maxSize, variant } = this.props;

    // shod Dropzone or current image or placeholder
    const showDropzone = (!loading || (loading && file)) && !uploadedImage && !currentImageId;
    const showImage = currentImage || uploadedImage;

    // info about too low resolution and clear button
    const showDropzoneInfoOverlay = file || currentImage;

    // show
    // 1) button + text for select image
    // or
    // 2) message when dropzone is hovered with file
    const showDropzoneControls = !file || !file.croppedPreview;

    return (
      <ParentComponent isCroppedAreaEnough={isCroppedAreaEnough} width={width} variant={variant}>
        <ImageUploaderWrapper width={width} height={height}>
          <ImageUploaderComponent>
            {showDropzone && (
              <Dropzone
                accept={acceptedFileTypes.map(ft => `image/${ft}`).join(', ')}
                onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                multiple={multiple}
                maxSize={maxSize}
                disabled={loading}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <DropzoneInnerComponent
                    loading={loading}
                    {...getRootProps()}
                    className={classNames('dropzone', {
                      'dropzone--isActive': isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />

                    {showDropzoneControls &&
                      (isDragActive ? (
                        <Typography>
                          <Trans id="msg.images.drop_active_text" />
                        </Typography>
                      ) : (
                        <React.Fragment>
                          <Button variant="contained" color="primary">
                            <Trans id="msg.images.select_file_button" />
                          </Button>
                          <Typography component="div">
                            <p>
                              <Trans id="msg.images.or" />
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              <Trans id="msg.images.drop_default_text" />
                            </p>
                          </Typography>
                        </React.Fragment>
                      ))}

                    {!showDropzoneControls && (
                      <ImageDisplay
                        src={file.croppedPreview}
                        alt={i18n._('msg.images.new_attached_image')}
                      />
                    )}
                  </DropzoneInnerComponent>
                )}
              </Dropzone>
            )}

            {showImage && (
              <ImageDisplay
                src={`data:${
                  currentImageId ? currentImage.mime_type : uploadedImage.mime_type
                };base64,${currentImageId ? currentImage.content : uploadedImage.content}`}
                alt={i18n._('msg.images.current_attached_image')}
              />
            )}

            {loading && (
              <Loading>
                <Trans id="msg.images.loading" />
              </Loading>
            )}

            {showDropzoneInfoOverlay && (
              <React.Fragment>
                <ClearButton>
                  <Tooltip title={i18n._('msg.images.clear_current_image')}>
                    <IconButton onClick={this.clearFile}>
                      <Icon>clear</Icon>
                    </IconButton>
                  </Tooltip>
                </ClearButton>
                {!isCroppedAreaEnough && (
                  <WarningIcon>
                    <Tooltip title={i18n._('msg.images.resolution_too_low')}>
                      <Icon>warning</Icon>
                    </Tooltip>
                  </WarningIcon>
                )}
              </React.Fragment>
            )}

            {file && (
              <Dialog
                open={dialogOpened}
                onClose={this.closeCropper}
                disableBackdropClick
                disableEscapeKeyDown
              >
                <CustomDialogTitle onClosse={this.closeCropper}>
                  {i18n._('msg.images.popup_title_cropper')}
                </CustomDialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                  <Typography component="div" align="left">
                    <p style={{ margin: '0 0 .5em' }}>
                      <Trans id="msg.images.requested_crop_area" />: {width}px x {height}px
                    </p>
                    <p
                      style={{
                        margin: '0',
                        color: `${isCroppedAreaEnough ? 'inherit' : red[400]}`,
                      }}
                    >
                      <Trans id="msg.images.currently_cropped_area" />:{' '}
                      {Math.round((crop.width / 100) * file.width)}px x{' '}
                      {Math.round((crop.height / 100) * file.height)}px
                    </p>
                    {!isCroppedAreaEnough && (
                      <p style={{ margin: '.5em 0', display: 'flex' }}>
                        <Icon style={{ marginRight: 8 }}>warning</Icon>{' '}
                        <Trans id="msg.images.resolution_too_low" />
                      </p>
                    )}
                  </Typography>
                  <ReactCrop
                    src={file.preview}
                    crop={crop}
                    // minWidth={40}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.storeImage(file);
                      this.closeCropper();
                    }}
                    color="primary"
                  >
                    <Trans id="msg.images.apply_crop" />
                  </Button>
                  <Button onClick={this.clearFile} color="primary">
                    <Trans id="msg.images.cancel_crop" />
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {errorState === 'singleImageOnly' && (
              <ImageUploaderError>{i18n._('msg.images.single_image_only')}</ImageUploaderError>
            )}

            {errorState === 'unsupportedFileType' && (
              <ImageUploaderError>{i18n._('msg.images.unsupported_file_type')}</ImageUploaderError>
            )}

            {errorState === 'fileTooLarge' && (
              <ImageUploaderError>{i18n._('msg.images.file_too_large')}</ImageUploaderError>
            )}
          </ImageUploaderComponent>
        </ImageUploaderWrapper>
      </ParentComponent>
    );
  }
}

ImageUploader.propTypes = {};

export default ImageUploader;
