import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import CustomDialogTitle from 'components/Dialog/CustomDialogTitle';
import { Trans } from '@lingui/macro';
import Sorter from 'components/Dialog/Sorter';
import Restore from 'components/Dialog/Restore';

const RenderedComponent = ({
  component,
  data,
  requestForNewOrderedRecords,
  setNewRecordsOrder,
  restoreRecord,
  loadingDeleted,
  isSubmitting,
  closeDialog,
  handleSubmit,
}) => {
  switch (component) {
    case 'deleted':
      return <Restore data={data} restoreRecord={restoreRecord} loadingDeleted={loadingDeleted} />;
    case 'sortable':
      return (
        <Sorter
          data={data}
          requestForNewOrderedRecords={requestForNewOrderedRecords}
          setNewRecordsOrder={setNewRecordsOrder}
          isSubmitting={isSubmitting}
          closeDialog={closeDialog}
          handleSubmit={handleSubmit}
        />
      );
    default:
      return null;
  }
};
RenderedComponent.propTypes = {
  component: PropTypes.string,
  data: PropTypes.array,
  requestForNewOrderedRecords: PropTypes.func.isRequired,
  setNewRecordsOrder: PropTypes.func.isRequired,
  restoreRecord: PropTypes.func.isRequired,
  loadingDeleted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

RenderedComponent.defaultProps = {
  component: '',
  data: [],
  isSubmitting: false,
};

const dialogTitle = component => {
  switch (component) {
    case 'deleted':
      return <Trans id="msg.halls.popup_title_deleted_halls" />;
    case 'sortable':
      return <Trans id="msg.halls.popup_title_sortable_halls" />;
    default:
      return '';
  }
};

class HallDialog extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.array,
    requestForNewOrderedRecords: PropTypes.func.isRequired,
    setNewRecordsOrder: PropTypes.func.isRequired,
    component: PropTypes.string,
    restoreRecord: PropTypes.func.isRequired,
    loadingDeleted: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    opened: false,
    onClose: null,
    data: [],
    component: '',
    loadingDeleted: false,
    isSubmitting: false,
  };

  render() {
    const {
      opened,
      onClose,
      component,
      data,
      requestForNewOrderedRecords,
      setNewRecordsOrder,
      restoreRecord,
      loadingDeleted,
      isSubmitting,
      handleSubmit,
    } = this.props;

    return (
      <Dialog open={opened} onClose={onClose}>
        <CustomDialogTitle onClose={onClose}>{dialogTitle(component)}</CustomDialogTitle>
        <DialogContent>
          <RenderedComponent
            component={component}
            data={data}
            requestForNewOrderedRecords={requestForNewOrderedRecords}
            setNewRecordsOrder={setNewRecordsOrder}
            restoreRecord={restoreRecord}
            loadingDeleted={loadingDeleted}
            isSubmitting={isSubmitting}
            closeDialog={onClose}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default HallDialog;
