import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

class RosterMultiAddItem extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    handleMemberCheck: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
  };

  state = {
    member: null,
    isSelected: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isSelected !== prevState.isSelected) {
      return {
        isSelected: nextProps.isSelected,
      };
    }
    return null;
  }

  componentDidMount() {
    const { member, isSelected } = this.props;

    this.setState({
      member,
      isSelected,
    });
  }

  render() {
    const { handleMemberCheck } = this.props;
    const { member, isSelected } = this.state;

    if (!member) {
      return null;
    }

    const name = `${member.last_name} ${member.first_name}`;
    const birthdate = member.birthdate ? `(${member.birthdate.format('D. M. YYYY')})` : null;

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected}
            onChange={handleMemberCheck}
            value={member.id.toString()}
          />
        }
        label={`${name} ${birthdate}`}
      />
    );
  }
}

export default RosterMultiAddItem;
