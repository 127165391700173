import 'utils/customYupValidators';
import { Field, Formik } from 'formik';
import React, { Component } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosSelect from 'components/Forms/EosSelect';
import EosTextInput from 'components/Forms/EosTextInput';
import ErrorMessage from 'components/Forms/ErrorMessage';
import EosDatepicker from 'components/Forms/EosDatepicker';
import EosCheckbox from 'components/Forms/EosCheckbox';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';

const MemberCreateFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  resetForm,
  roles,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="last_name"
          label={i18n._('msg.members.create.last_name')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="text"
          name="first_name"
          label={i18n._('msg.members.create.first_name')}
          component={EosTextInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          type="date"
          name="birthdate"
          label={i18n._('msg.members.create.date_of_birth')}
          component={EosDatepicker}
          format="D. M. YYYY"
          disableFuture
          placeholder={`${moment().format('D. M. YYYY')}`}
          openTo="year"
          views={['year', 'month', 'day']}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="role_id"
          label={i18n._('msg.members.create.role_id')}
          options={roles}
          component={EosSelect}
          displayEmpty
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          type="email"
          name="contact_email"
          label={i18n._('msg.members.create.email')}
          component={EosTextInput}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          type="checkbox"
          name="send_invitation"
          label={i18n._('msg.members.create.send_invitation')}
          component={EosCheckbox}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <Grid item xs={12}>
        <SubmitArea isSubmitting={isSubmitting} align="left">
          <SubmitButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
          >
            {isSubmitting ? i18n._('msg.saving_changes') : i18n._('msg.save_changes')}
          </SubmitButton>{' '}
          <Button
            variant="contained"
            color="secondary"
            type="reset"
            disabled={isSubmitting}
            onClick={() => {
              resetForm();
            }}
          >
            <Trans id="msg.members.create.reset" />
          </Button>
        </SubmitArea>
      </Grid>
    </Grid>
  </EosForm>
);

MemberCreateFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  // values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

MemberCreateFormComponent.defaultProps = {
  isSubmitting: false,
};

class MemberCreateForm extends Component {
  initialValues = {
    first_name: '',
    last_name: '',
    birthdate: moment(),
    role_id: 0,
    send_invitation: false,
    contact_email: '',
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.member !== prevState.member) {
  //     console.log({ member: nextProps.member });
  //     return { member: nextProps.member };
  //   }
  //   return null;
  // }

  render() {
    const { handleSubmit, roles } = this.props;

    const validationSchema = yup.object().shape({
      // base info:
      last_name: yup.string().required(i18n._('msg.form.required')),
      first_name: yup.string().required(i18n._('msg.form.required')),
      birthdate: yup
        .date(
          'format',
          i18n._('msg.form.invalid_format_with_helper', {
            helper_string: `17. 2. ${new Date().getFullYear()}`,
          })
        )
        .max(new Date(), i18n._('msg.form.data_past'))
        .required(i18n._('msg.form.required')),
      send_invitation: yup.bool(),
      role_id: yup.mixed().oneOf([...roles.map(role => role.value)]),
      // .required(i18n._('msg.form.required')),
      contact_email: yup
        .string()
        .email(i18n._('msg.form.invalid_email'))
        .when('send_invitation', {
          is: true,
          then: yup.string().required(i18n._('msg.form.required')),
        }),
    });

    return (
      <Card>
        <CardContent>
          <Formik
            initialValues={this.initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={formikProps => <MemberCreateFormComponent {...formikProps} roles={roles} />}
          />
        </CardContent>
      </Card>
    );
  }
}

MemberCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default MemberCreateForm;
