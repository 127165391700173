import { FullMember } from '../models/Member';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';
import { MemberInvitation } from '../models/MemberInvitation';
import { MemberRepresentative } from '../models/MemberRepresentative';

/**
 * @static
 */
export class MemberManagementService extends ManagementService {
  static endpoint = 'members';

  /**
   * @param {number} id
   * @return {Promise<FullMember>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(MemberManagementService.buildUrl(id), {
        config: null,
        params: { with: 'user,is_representable' },
      })
      .then(response => new FullMember(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(MemberManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<FullMember>>)}
   * @private
   */
  static async fetchAll(params = {}, config = {}) {
    return apiCall.get(MemberManagementService.buildUrl(), { ...config, params }).then(response => {
      const members = response.data.data.map(data => new FullMember(data));

      if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
        return members;
      }

      return new PaginatedData(members, response.data.meta);
    });
  }

  /**
   * @param {object} data
   * @return {Promise<FullMember>}
   * @public
   */
  static async create(data) {
    return apiCall
      .post(MemberManagementService.buildUrl(), data)
      .then(response => new FullMember(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<FullMember>}
   * @public
   */
  static async update(id, data) {
    return apiCall
      .patch(MemberManagementService.buildUrl(id), data)
      .then(response => new FullMember(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise}
   * @public
   */
  static async remove(id) {
    return apiCall.delete(MemberManagementService.buildUrl(id));
  }

  /**
   *
   * @param {int} memberId
   * @param {object} data
   * @returns {Promise<MemberRepresentative>}
   */
  static async createRepresentative(memberId, data) {
    return apiCall
      .post(`${MemberManagementService.buildUrl(memberId)}/representatives`, data)
      .then(response => new MemberRepresentative(response.data));
  }

  /**
   * @param {int} representativeId
   * @param {object} data
   * @returns {Promise<MemberRepresentative>}
   */
  static async updateRepresentative(representativeId, data) {
    return apiCall
      .put(`/management/member-representatives/${representativeId}`, data)
      .then(response => new MemberRepresentative(response.data));
  }

  /**
   * @param {int} id
   * @param {int} representativeId
   * @returns {Promise}
   */
  static async deleteRepresentative(id, representativeId) {
    return apiCall.delete(
      `${MemberManagementService.buildUrl(id)}/representatives/${representativeId}`
    );
  }

  /**
   * @param {int} memberId
   * @param {int} representativeId
   * @returns {Promise<MemberRepresentative>}
   */
  static getSingleRepresentative(memberId, representativeId) {
    return apiCall
      .get(`${MemberManagementService.buildUrl(memberId)}/representatives/${representativeId}`)
      .then(response => new MemberRepresentative(response));
  }

  /**
   * @param {int} id
   * @returns {Promise<array.<MemberRepresentative>>}
   */
  static getAllRepresentatives(id) {
    return apiCall
      .get(`${MemberManagementService.buildUrl(id)}/representatives`)
      .then(response => response.data.data.map(r => new MemberRepresentative(r)));
  }

  /**
   * @param {int} id
   * @param {object} data
   * @return {Promise<MemberInvitation>}
   */
  static createInvitation(id, data) {
    return apiCall
      .post(`${MemberManagementService.buildUrl(id)}/invitations`, data)
      .then(response => new MemberInvitation(response.data));
  }

  /**
   * @param {int} memberId
   * @param {int} invitationId
   * @returns {Promise<MemberInvitation>}
   */
  static getSingleInvitation(memberId, invitationId) {
    return apiCall
      .get(`${MemberManagementService.buildUrl(memberId)}/invitations/${invitationId}`)
      .then(response => new MemberInvitation(response));
  }

  /**
   * @param {int} id
   * @returns {Promise<array.<MemberInvitation>>}
   */
  static getAllInvitations(id) {
    return apiCall
      .get(`${MemberManagementService.buildUrl(id)}/invitations`)
      .then(response => response.data.data.map(r => new MemberInvitation(r)));
  }

  /**
   * @param {int} id
   * @param {int} invitationId
   * @return {Promise<MemberInvitation>}
   */
  static deleteInvitation(id, invitationId) {
    return apiCall.delete(`${MemberManagementService.buildUrl(id)}/invitations/${invitationId}`);
  }

  /**
   * @param {int} id
   * @param {int} userId
   * @returns {Promise}
   */
  static revoke(id, userId) {
    return apiCall.delete(`${MemberManagementService.buildUrl(id)}/users/${userId}`);
  }

  /**
   * @param {int} id
   * @param {object} data
   * @returns {Promise<FullMember>}
   */
  static assignRole(id, data) {
    return apiCall
      .patch(MemberManagementService.buildUrl(id), data)
      .then(response => new FullMember(response.data));
  }
}
