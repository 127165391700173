import { Season } from '../models/Season';
import { ManagementService } from './management.service';
import { apiCall } from '../utils/apiCall';
import { PaginatedData } from '../shared/paginated.data.model';
import { DataGridUrlBuilder } from '../shared/datagrid-url-builder.model';
// import { FullMember } from '../models/Member';

/**
 * @static
 */
export class SeasonManagementService extends ManagementService {
  static endpoint = 'seasons';

  /**
   * @param {number} id
   * @return {Promise<Season>}
   * @public
   */
  static async get(id) {
    return apiCall
      .get(SeasonManagementService.buildUrl(id))
      .then(response => new Season(response.data));
  }

  /**
   * @return {DataGridUrlBuilder}
   * @public
   */
  static getAll() {
    return new DataGridUrlBuilder(SeasonManagementService.fetchAll);
  }

  /**
   * @param {object} params={}
   * @param {object} config={}
   * @return {(Promise<PaginatedData>|Promise<array.<Season>>)}
   * @private
   */
  static fetchAll(params = {}, config = {}) {
    return apiCall.get(SeasonManagementService.buildUrl(), { ...config, params }).then(response => {
      const seasons = response.data.data.map(data => new Season(data));

      if (!Object.prototype.hasOwnProperty.call(response.data, 'meta')) {
        return seasons;
      }

      return new PaginatedData(seasons, response.data.meta);
    });
  }

  /**
   * @param {object} data
   * @return {Promise<Season>}
   * @public
   */
  static create(data) {
    return apiCall
      .post(SeasonManagementService.buildUrl(), data)
      .then(response => new Season(response.data));
  }

  /**
   * @param {number} id
   * @param {object} data
   * @return {Promise<Season>}
   * @public
   */
  static update(id, data) {
    return apiCall
      .put(SeasonManagementService.buildUrl(id), data)
      .then(response => new Season(response.data));
  }

  /**
   * @param {number} id
   * @return {Promise<Season>}
   * @public
   */
  static delete(id) {
    return apiCall.delete(SeasonManagementService.buildUrl(id));
  }
}
