import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthConsumer } from 'AuthContext.js';
import routes from 'Routes';
import queryString from 'utils/queryString';
import PropTypes from 'prop-types';

const UnauthenticatedRoute = ({ component: C, props: childProps, ...rest }) => {
  const redirect = queryString('redirect');
  // console.log({ redirect, C });
  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          {...rest}
          render={props =>
            !isAuth || C.name === 'Invitation' ? (
              <C {...props} {...childProps} />
            ) : (
              // <Redirect to={redirect === '' || redirect === null ? routes.dashboard : redirect} />
              <Redirect to={redirect === '' || redirect === null ? routes.home : redirect} />
            )
          }
        />
      )}
    </AuthConsumer>
  );
};
UnauthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  props: PropTypes.object.isRequired,
};

export default UnauthenticatedRoute;
