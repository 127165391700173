import React from 'react';
import styled from 'styled-components';
import { Card } from '@material-ui/core';

const LoadingCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 300px;
`;

const LoadingMessage = () => <LoadingCard>Loading data...</LoadingCard>;

export default LoadingMessage;
