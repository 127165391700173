import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import EosTextInput from 'components/Forms/EosTextInput';
import SubmitButton from 'components/Forms/SubmitButton';
import { i18n } from 'App.js';
import { Trans } from '@lingui/macro';
import ErrorMessage from 'components/Forms/ErrorMessage';

const TeamGroupFormComponent = ({
  isSubmitting,
  errors,
  // values,
  // setFieldValue,
  // setFieldTouched,
  closeDialog,
  teamGroup,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Field
          type="text"
          name="name"
          label={i18n._('msg.team_groups.team_group_name_label')}
          required
          component={EosTextInput}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}

      <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog">
        <SubmitButton
          color="primary"
          type="submit"
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
        >
          {/* eslint-disable-next-line */}
          {isSubmitting ? (
            <Trans id="msg.saving_changes" />
          ) : teamGroup ? (
            <Trans id="msg.save_changes" />
          ) : (
            <Trans id="msg.team_groups.add_new" />
          )}
        </SubmitButton>{' '}
        <SubmitButton color="secondary" onClick={closeDialog}>
          <Trans id="msg.cancel" />
        </SubmitButton>
      </SubmitArea>
    </Grid>
  </EosForm>
);

TeamGroupFormComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  // values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  teamGroup: PropTypes.object,
};

TeamGroupFormComponent.defaultProps = {
  isSubmitting: false,
  teamGroup: null,
};

class TeamGroupForm extends Component {
  validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(3, i18n._('msg.form.min_length', { min_length: 3 }))
      .required(i18n._('msg.form.required')),
  });

  initialValues = {
    name: '',
  };

  render() {
    const { teamGroup, closeDialog, handleSubmit } = this.props;
    return (
      <div>
        <Formik
          initialValues={
            teamGroup && Object.keys(teamGroup).length > 0 ? teamGroup : this.initialValues
          }
          onSubmit={handleSubmit}
          validationSchema={this.validationSchema}
          enableReinitialize
          render={formikProps => (
            <TeamGroupFormComponent
              {...formikProps}
              closeDialog={closeDialog}
              teamGroup={teamGroup}
            />
          )}
        />
      </div>
    );
  }
}

TeamGroupForm.propTypes = {
  teamGroup: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TeamGroupForm.defaultProps = {
  teamGroup: null,
};

export default TeamGroupForm;
