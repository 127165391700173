import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const StyledBlocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Blocker extends Component {
  static propTypes = {
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { loading } = this.props;
    return (
      loading && (
        <StyledBlocker>
          <CircularProgress color="secondary" />
        </StyledBlocker>
      )
    );
  }
}

export default Blocker;
