import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { possibleInsuranceCompanies } from 'data/staticDataConstants';
import * as yup from 'yup';
import { i18n } from 'App.js';
import { Field, Formik } from 'formik';
import { AuthConsumer } from 'AuthContext.js';
import EosForm, { SubmitArea } from 'components/Forms/EosForm';
import { Grid } from '@material-ui/core';
import EosTextInput from 'components/Forms/EosTextInput';
import EosDatepicker from 'components/Forms/EosDatepicker';
import EosSelect from 'components/Forms/EosSelect';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import * as moment from 'moment';
import ErrorMessage from 'components/Forms/ErrorMessage';
import { birthnumber as birthnumberRegExp } from 'utils/regexp';

const MemberFormBaseInfoComponent = ({
  isSubmitting,
  errors,
  values,
  // setFieldValue,
  // setFieldTouched,
  // resetForm,
  gender,
}) => (
  <EosForm noValidate submitting={isSubmitting} errors={errors}>
    <Grid container spacing={24}>
      <Grid item xs={12} lg={6}>
        <Field
          type="text"
          name="first_name"
          label={i18n._('msg.members.first_name')}
          component={EosTextInput}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field
          type="text"
          name="last_name"
          label={i18n._('msg.members.last_name')}
          component={EosTextInput}
          required
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field
          type="date"
          name="birthdate"
          label={i18n._('msg.members.date_of_birth')}
          component={EosDatepicker}
          format="D. M. YYYY"
          placeholder={`${moment().format('D. M. YYYY')}`}
          openTo="year"
          views={['year', 'month', 'day']}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Field
          type="text"
          name="birthnumber"
          label={i18n._('msg.members.birthnumber')}
          component={EosTextInput}
          helperText={
            birthnumberRegExp.test(values.birthnumber) || values.birthnumber.length === 0
              ? null
              : i18n._('msg.form.birthnumber_format_not_match_cz_format')
          }
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field
          name="gender"
          label={i18n._('msg.members.gender')}
          options={gender}
          component={EosSelect}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <Field
          name="insurance_company"
          // value={values.insurance_company}
          label={i18n._('msg.members.insurance_company')}
          options={possibleInsuranceCompanies}
          component={EosSelect}
        />
      </Grid>

      {errors.status && (
        <Grid item xs={12}>
          <ErrorMessage>{errors.status}</ErrorMessage>
        </Grid>
      )}
    </Grid>

    <SubmitArea align="left">
      <SubmitButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
      </SubmitButton>
    </SubmitArea>
  </EosForm>
);

MemberFormBaseInfoComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object.isRequired,
  // setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  // resetForm: PropTypes.func.isRequired,
  gender: PropTypes.array,
};

MemberFormBaseInfoComponent.defaultProps = {
  isSubmitting: false,
  errors: {},
  gender: [],
};

class MemberFormBaseInfo extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    memberPartialData: null,
  };

  componentDidMount() {
    const {
      // eslint-disable-next-line
      member: { first_name, last_name, birthdate, birthnumber, gender, insurance_company },
    } = this.props;
    const memberPartialData = {
      first_name,
      last_name,
      birthdate,
      birthnumber,
      gender,
      insurance_company,
    };

    this.setState({
      memberPartialData,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { memberPartialData } = this.state;

    if (!memberPartialData) {
      return null;
    }

    return (
      <AuthConsumer>
        {({ enumerators }) => (
          <div>
            <Formik
              initialValues={memberPartialData}
              onSubmit={handleSubmit}
              validationSchema={yup.object().shape({
                // base info:
                first_name: yup.string().required(i18n._('msg.form.required')),
                last_name: yup.string().required(i18n._('msg.form.required')),
                birthdate: yup
                  .date()
                  .max(new Date(), i18n._('msg.form.data_past'))
                  .required(i18n._('msg.form.required')),
                birthnumber: yup.string().nullable(),
                // .matches(birthnumberRegExp, i18n._('msg.form.invalid_format'))
                // .required(i18n._('msg.form.required')),
                gender: yup.mixed().oneOf(
                  enumerators.gender.map(g => g.value),
                  i18n._('msg.form.oneOfValues', {
                    values: enumerators.gender.map(g => g.label).join(', '),
                  })
                ),
                insurance_company: yup.string(), // .required(i18n._('msg.form.required')),
              })}
              render={formikProps => (
                <MemberFormBaseInfoComponent {...formikProps} gender={enumerators.gender} />
              )}
            />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default MemberFormBaseInfo;
