import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TeamManagementService } from 'api/team.service';
import { TeamGroupManagementService } from 'api/team-group.service';
import { Typography, Tabs, Tab, Paper, AppBar, Button } from '@material-ui/core';
import routes from 'Routes';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import TeamsConfigBasicForm from 'components/Management/Teams/TeamsConfigBasicForm';
import TeamsConfigWebForm from 'components/Management/Teams/TeamsConfigWebForm';
import TeamsConfigPhotoForm from 'components/Management/Teams/TeamsConfigPhotoForm';
import { Trans } from '@lingui/macro';
import { i18n } from 'App.js';
import LoadingMessage from 'components/Base/LoadingMessage';
import { withSnackbar } from 'notistack';
import { FullTeam } from 'models/Team';
import PageHeader from 'components/PageHeader';

class Update extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    value: 0,
    loading: false,
  };

  componentDidMount = async () => {
    let team = null;
    let teamGroups = [{ value: 0, label: i18n._('msg.team_groups.team_group_not_selected_label') }];

    const {
      match: {
        params: { teamId },
      },
    } = this.props;
    this.setState({ loading: true });
    await TeamManagementService.get(teamId)
      .catch(error => console.log({ error }))
      .then(fetchedTeam => {
        team = fetchedTeam;
      });

    await TeamGroupManagementService.getAll()
      .sortBy('order')
      .fetch()
      .then(fetchedTeamGroups => {
        const formattedTeamGroups = fetchedTeamGroups.map(group => ({
          label: group.name,
          value: group.id,
        }));
        teamGroups = [...teamGroups, ...formattedTeamGroups];
      })
      .catch(error => console.log({ error }));

    this.setState({
      team,
      teamGroups,
      teamId,
      loading: false,
    });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  updateTeam = (values, actions) => {
    const { teamId } = this.state;
    const { enqueueSnackbar } = this.props;
    const formattedData = FullTeam.formatCreateFormData(values);
    // const valuesToUpdate = {
    //   ...values,
    //   birth_year_range: {
    //     from: !Number.isNaN(parseInt(values.birth_year_range.from))
    //       ? parseInt(values.birth_year_range.from)
    //       : null,
    //     to: !Number.isNaN(parseInt(values.birth_year_range.to))
    //       ? parseInt(values.birth_year_range.to)
    //       : null,
    //   },
    //   team_group_id:
    //     values.team_group_id !== '' && values.team_group_id !== 0 ? values.team_group_id : null,
    //   color: values.color !== '' ? values.color : null,
    // };
    // console.log({ values, valuesToUpdate, formattedData });
    // return;
    TeamManagementService.update(teamId, formattedData).then(
      response => {
        this.setState(
          {
            team: response,
          },
          () => {
            enqueueSnackbar(i18n._('msg.teams.update_success'), { variant: 'success' });
            actions.setSubmitting(false);
          }
        );
      },
      error => {
        actions.setSubmitting(false);
        console.log({ error });
        actions.setErrors({ status: error.response.data.message });
      }
    );
  };

  render() {
    const { team, teamGroups, value, loading } = this.state;

    if (loading) {
      return <LoadingMessage />;
    }

    return team ? (
      <div>
        <PageHeader
          title={team.name}
          breadcrumbsItems={[
            {
              url: routes.management.teams.all,
              text: i18n._('msg.teams.page_title'),
            },
            { url: null, text: team.name },
          ]}
        />

        <Paper>
          <AppBar elevation={0} style={{ position: 'static' }} color="default">
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={i18n._('msg.settings')} />
              <Tab label={i18n._('msg.web')} />
              <Tab label={i18n._('msg.photo')} />
            </Tabs>
          </AppBar>
          <div>
            <SwipeableViews
              axis="x"
              index={value}
              onChangeIndex={this.handleChangeIndex}
              animateHeight
            >
              <TeamsConfigBasicForm
                updateTeam={this.updateTeam}
                team={team}
                teamGroups={teamGroups}
              />
              <TeamsConfigWebForm updateTeam={this.updateTeam} team={team} />
              <TeamsConfigPhotoForm updateTeam={this.updateTeam} team={team} />
            </SwipeableViews>
          </div>
        </Paper>
      </div>
    ) : (
      <React.Fragment>
        <Typography variant="h1" gutterBottom>
          <Trans id="msg.teams.team_not_found" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={routes.management.teams.all}
        >
          <Trans id="msg.teams.back_to_teams" />
        </Button>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Update);
