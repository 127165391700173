import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import muiTheme from 'muiTheme';

const getColorValue = color => {
  switch (color) {
    case 'primary':
      return muiTheme.palette.primary.main;
    case 'secondary':
      return muiTheme.palette.secondary.main;
    case 'error':
      return red[500];
    case 'warning':
      return yellow[700];
    case 'success':
      return green[500];
    case 'neutral':
      return blue[300];
    default:
      return 'inherit';
  }
};

// eslint-disable-next-line
const ColorMessage = styled(({ color, block, ...rest }) => <span {...rest} />)`
  display: ${props => (props.block ? 'block' : 'inline-block')};
  color: ${props => getColorValue(props.color)};
`;

ColorMessage.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
    'neutral',
    'inherit',
  ]),
  block: PropTypes.bool,
};

ColorMessage.defaultProps = {
  color: 'inherit',
  block: false,
};

export default ColorMessage;
