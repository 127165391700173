import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Icon, Typography } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import SubmitButton from 'components/Forms/SubmitButton';
import { SubmitArea } from 'components/Forms/EosForm';

const grid = 8;
const SORT_ON_DRAGEND = false;

class Sorter extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      data,
    });
  }

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: `${grid}px 0`,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'white' : 'rgba(255,255,255,.7)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    boxShadow: isDragging ? '0 1px 3px 0 rgba(222,222,222,.75)' : '0 1px 0 0 rgba(222,222,222,.5)',
    maxWidth: '100%',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#dedede' : '#fff',
    margin: `0 -${grid}px -${grid}px`,
    padding: grid,
    paddingBottom: 1,
    width: `calc(100% + ${grid * 2}px)`,
    minWidth: 270,
  });

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result, requestForNewOrderedRecords, setNewRecordsOrder) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { data } = this.state;

    const items = this.reorder(data, result.source.index, result.destination.index);

    const newlyRequestedOrder = [];
    items.map((item, index) =>
      newlyRequestedOrder.push({
        id: item.id,
        name: item.name,
        order: index + 1,
      })
    );

    if (SORT_ON_DRAGEND) {
      requestForNewOrderedRecords(newlyRequestedOrder);
    } else {
      setNewRecordsOrder(newlyRequestedOrder);
    }

    this.setState({
      data: items,
    });
  };

  render() {
    const { data: dataState } = this.state;
    const {
      data: dataProps,
      requestForNewOrderedRecords,
      setNewRecordsOrder,
      isSubmitting,
      closeDialog,
      handleSubmit,
    } = this.props;

    const data = dataState || dataProps;

    if (data.length) {
      return (
        <div>
          <DragDropContext
            onDragEnd={result =>
              this.onDragEnd(result, requestForNewOrderedRecords, setNewRecordsOrder)
            }
          >
            <Droppable droppableId="droppable">
              {(droppableProvided, droppableSnapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  style={this.getListStyle(droppableSnapshot.isDraggingOver)}
                >
                  {data.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(draggableProvided, draggableSnapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={this.getItemStyle(
                            draggableSnapshot.isDragging,
                            draggableProvided.draggableProps.style
                          )}
                        >
                          <Icon>more_vert</Icon>{' '}
                          <span style={{ marginLeft: `${grid}px`, display: 'inline-block' }}>
                            <Typography component="span">{item.name}</Typography>
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <SubmitArea isSubmitting={isSubmitting} align="right" variant="dialog" sorter>
            <SubmitButton
              color="primary"
              type="submit"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting ? <Trans id="msg.saving_changes" /> : <Trans id="msg.save_changes" />}
            </SubmitButton>{' '}
            <SubmitButton color="secondary" onClick={closeDialog}>
              <Trans id="msg.cancel" />
            </SubmitButton>
          </SubmitArea>
        </div>
      );
    }
    return <Typography id="msg.sorter.no_data">Sorry, data to sort</Typography>;
  }
}

Sorter.propTypes = {
  data: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool,
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  requestForNewOrderedRecords: PropTypes.func.isRequired,
  setNewRecordsOrder: PropTypes.func.isRequired,
};

Sorter.defaultProps = {
  isSubmitting: false,
  closeDialog: null,
  handleSubmit: null, // TODO change to required after refactoring
};

export default Sorter;
