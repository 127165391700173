import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import AdminDashboard from './containers/Admin/Dashboard';
import Dashboard from './containers/Dashboard';
import Login from './containers/Login';
import Invitation from './containers/Invitation';
import ResetPassword from './containers/ResetPassword';
import Registration from './containers/Registration';
import Management from './containers/Management/Dashboard';
import Announcements from './containers/Management/Announcements';
import Documents from './containers/Management/Documents';
import Members from './containers/Management/Members';
import MemberDetail from './containers/Management/Members/Detail';
import MemberEdit from './containers/Management/Members/Update';
import MemberCreate from './containers/Management/Members/Create';
import Teams from './containers/Management/Teams';
import TeamUpdate from './containers/Management/Teams/Update';
import Rosters from './containers/Management/Rosters';
import Settings from './containers/Management/Settings';
import Seasons from './containers/Management/Settings/Seasons';
import Positions from './containers/Management/Settings/Positions';
import MemberTypes from './containers/Management/Settings/MemberTypes';
import EventTypes from './containers/Management/Settings/EventTypes';
import TeamGroups from './containers/Management/Settings/TeamGroups';
import Halls from './containers/Management/Halls';
import HallUpdate from './containers/Management/Halls/Update';
import HallCreate from './containers/Management/Halls/Create';
import Roles from './containers/Management/Roles';
import RoleUpdate from './containers/Management/Roles/Update';
import Page404 from './containers/Page404';
import Playground from './containers/Playground';
import routes from './Routes';

const RoutesSwitch = ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute path="/" exact component={Dashboard} props={childProps} />

    {/* LOGIN route should be un-authenticated, if user is logged, this type of route switch him to dashboard */}
    <UnauthenticatedRoute path={routes.login} exact component={Login} props={childProps} />

    {/* INVITATION route should be un-authenticated */}
    <UnauthenticatedRoute
      path={routes.invitation}
      exact
      component={Invitation}
      props={childProps}
    />

    {/* RESET PASSWORD route should be un-authenticated */}
    <UnauthenticatedRoute
      path={routes.resetPassword}
      exact
      component={ResetPassword}
      props={childProps}
    />

    {/* REGISTRATION route should be un-authenticated */}
    <UnauthenticatedRoute
      path={routes.registration}
      exact
      component={Registration}
      props={childProps}
    />

    {/* Playground for testing */}
    <AppliedRoute path={routes.playground} exact component={Playground} props={childProps} />

    {/* Management section of eos club zone */}
    <AuthenticatedRoute
      path={routes.management.dashboard}
      exact
      component={Management}
      props={{ ...childProps, requiredPermissions: 'access_management' }}
    />
    <AuthenticatedRoute
      path={routes.management.documents}
      exact
      component={Documents}
      props={childProps}
    />
    <AuthenticatedRoute
      path={routes.management.announcements}
      exact
      component={Announcements}
      props={childProps}
    />
    <AuthenticatedRoute
      path={routes.management.members.all}
      exact
      component={Members}
      props={{ ...childProps, requiredPermissions: ['members'] }}
    />
    <AuthenticatedRoute
      path={routes.management.members.single.detail}
      exact
      component={MemberDetail}
      props={{ ...childProps, requiredPermissions: ['members'] }}
    />
    <AuthenticatedRoute
      path={routes.management.members.single.update}
      exact
      component={MemberEdit}
      props={{ ...childProps, requiredPermissions: ['members'], minimalPermissionLevel: 'write' }}
    />
    <AuthenticatedRoute
      path={routes.management.members.create}
      exact
      component={MemberCreate}
      props={{ ...childProps, requiredPermissions: ['members'], minimalPermissionLevel: 'write' }}
    />

    <AuthenticatedRoute
      path={routes.management.rosters.all}
      exact
      component={Rosters}
      props={childProps}
    />
    <AuthenticatedRoute
      path={routes.management.rosters.single.update}
      exact
      component={Rosters}
      props={childProps}
    />

    <AuthenticatedRoute
      path={routes.management.settings.all}
      exact
      component={Settings}
      props={{ ...childProps, requiredPermissions: ['settings'] }}
    />

    <AuthenticatedRoute
      path={routes.management.settings.seasons}
      exact
      component={Seasons}
      props={{ ...childProps, requiredPermissions: ['settings', 'seasons'] }}
    />

    <AuthenticatedRoute
      path={routes.management.settings.positions}
      exact
      component={Positions}
      props={{ ...childProps, requiredPermissions: ['settings', 'members'] }}
    />

    <AuthenticatedRoute
      path={routes.management.settings.memberTypes}
      exact
      component={MemberTypes}
      props={{ ...childProps, requiredPermissions: ['settings', 'members'] }}
    />

    <AuthenticatedRoute
      path={routes.management.settings.eventTypes}
      exact
      component={EventTypes}
      props={{ ...childProps, requiredPermissions: ['settings', 'events'] }}
    />

    <AuthenticatedRoute
      path={routes.management.settings.teamGroups}
      exact
      component={TeamGroups}
      props={{ ...childProps, requiredPermissions: ['team_groups'] }}
    />

    <AuthenticatedRoute
      path={routes.management.halls.all}
      exact
      component={Halls}
      props={{ ...childProps, requiredPermissions: ['halls'] }}
    />
    <AuthenticatedRoute
      path={routes.management.halls.single.update}
      exact
      component={HallUpdate}
      props={{ ...childProps, requiredPermissions: ['halls'], minimalPermissionLevel: 'write' }}
    />
    <AuthenticatedRoute
      path={routes.management.halls.create}
      exact
      component={HallCreate}
      props={{ ...childProps, requiredPermissions: ['halls'], minimalPermissionLevel: 'write' }}
    />

    <AuthenticatedRoute
      path={routes.management.teams.all}
      exact
      component={Teams}
      props={{ ...childProps, requiredPermissions: ['teams'] }}
    />
    <AuthenticatedRoute
      path={routes.management.teams.season.list}
      exact
      component={Teams}
      props={{ ...childProps, requiredPermissions: ['teams'] }}
    />
    <AuthenticatedRoute
      path={routes.management.teams.single.update}
      exact
      component={TeamUpdate}
      props={{ ...childProps, requiredPermissions: ['teams'], minimalPermissionLevel: 'write' }}
    />

    <AuthenticatedRoute
      path={routes.management.roles.all}
      exact
      component={Roles}
      props={{ ...childProps, requiredPermissions: ['permissions'] }}
    />
    <AuthenticatedRoute
      path={routes.management.roles.single.update}
      exact
      component={RoleUpdate}
      props={{ ...childProps, requiredPermissions: ['permissions'] }}
    />
    {/* Management section of eos club zone - END */}

    {/* Administration section of eos club zone */}
    <AuthenticatedRoute
      path={routes.admin.dashboard}
      exact
      component={AdminDashboard}
      props={childProps}
    />
    {/* Administration section of eos club zone - END */}

    {/* Finally, catch all unmatched routes */}
    <Route component={Page404} />
  </Switch>
);

RoutesSwitch.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default RoutesSwitch;
