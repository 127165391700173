import React, { Component } from 'react';
import { AuthConsumer } from 'AuthContext.js';
import RegistrationComponent from 'components/Registration/RegistrationComponent';

class Registration extends Component {
  static propTypes = {};

  state = {
    registrationDone: false,
  };

  handleSubmit = (values, actions) => {
    console.log(`TODO: register member:`, { values });
    setTimeout(() => {
      actions.setSubmitting(false);
      this.setState({
        registrationDone: true,
      });
    }, 1000);
  };

  render() {
    const { registrationDone } = this.state;
    return (
      <AuthConsumer>
        {({ logout }) => {
          logout();
          return (
            <RegistrationComponent
              handleSubmit={this.handleSubmit}
              registrationDone={registrationDone}
            />
          );
        }}
      </AuthConsumer>
    );
  }
}

export default Registration;
